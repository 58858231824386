import axios from "axios";
import config from "@/../public/config";

export function setAxiosBaseURL() {
    axios.create({
        baseURL: window.location.origin
    }).get('./config.json').then(async (response) => {
        config.base_url = response.data.base_url
        config.base_url_frontend = response.data.base_url_frontend
        config.advanced = response.data.advanced
        axios.defaults.baseURL = config.base_url
    })
}

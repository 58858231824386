<template>
  <svg width="36" height="36" viewBox="0 0 36 36" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 11.925C16.65 11.925 15.75 13.275 15.75 14.85C15.75 16.425 16.875 17.775 18 17.775C19.125 17.775 20.25 16.425 20.25 14.85C20.25 13.05 19.35 11.925 18 11.925Z"/>
    <path d="M18 2.25C9.225 2.25 2.25 9.225 2.25 18C2.25 26.775 9.225 33.75 18 33.75C26.775 33.75 33.75 26.775 33.75 18C33.75 9.225 26.775 2.25 18 2.25ZM23.625 25.2C22.725 25.2 22.275 24.525 22.275 23.85C22.275 21.825 20.25 20.475 18 20.475C15.75 20.475 13.725 21.825 13.725 23.625C13.725 24.525 13.05 24.975 12.375 24.975C11.475 24.975 11.025 24.3 11.025 23.625C11.025 21.375 12.375 19.575 14.4 18.45C13.5 17.325 13.05 16.2 13.05 14.625C12.825 11.475 15.075 9 18 9C20.925 9 23.175 11.475 23.175 14.85C23.175 16.425 22.725 17.775 21.825 18.675C23.85 19.8 25.2 21.6 25.2 23.85C25.2 24.525 24.525 25.2 23.625 25.2Z"/>
  </svg>
</template>
<script>
export default {
  name: 'profile-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

<template>
    <BaseModal>
        <b-row class="justify-content-center">
            <Title :titleText="`${$tc('admin.adminMngPage.modal.deleteAdmin.title')}`"/>
        </b-row>
        <b-row class="justify-content-center">
            <h6 class="mb-3 mt-3">{{ `${$tc('admin.adminMngPage.modal.deleteAdmin.body')}` }}</h6>
        </b-row>
        <b-row class="justify-content-between">
            <Button :text="`${$tc('admin.adminMngPage.modal.deleteAdmin.buttons.cancel')}`"
                    type="secondary"
                    :clickFunc="cancelOp"/>
            <Button :text=" `${$tc('admin.adminMngPage.modal.deleteAdmin.buttons.proceed')}`"
                    :clickFunc="performDelete"/>
        </b-row>
    </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import Button from "@/components/admin/subcomps/Button.vue";
import Title from './Title.vue';

export default {
    name: 'DeleteModal',
    components: {BaseModal, Button, Title},
    props: {

    },
    data(){
        return {
            show: false
        }
    },
    methods: {
        cancelOp(){
            this.$emit('cancel-delete')
        },
        performDelete(){
            this.$emit('perform-delete')
        }
    }
}
</script>

<style>

</style>
<template>
  <svg width="70" height="70" viewBox="0 0 70 70" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1001 27.6252H7.47502C5.77001 27.6252 4.375 26.2302 4.375 24.5251V12.9001C4.375 11.195 5.77001 9.80004 7.47502 9.80004C9.18003 9.80004 10.575 11.195 10.575 12.9001V21.4251H19.1001C20.8051 21.4251 22.2001 22.8201 22.2001 24.5251C22.2001 26.2302 20.8051 27.6252 19.1001 27.6252Z"/>
    <path d="M30.5076 66.3753C30.3216 66.3753 30.1356 66.3753 29.9806 66.3443C26.3846 65.6932 22.9435 64.4532 19.7815 62.6242C18.2935 61.7562 17.7975 59.8652 18.6655 58.3772C19.5335 56.8892 21.4245 56.3932 22.9125 57.2612C25.4236 58.7182 28.1826 59.7102 31.0656 60.2372C32.7396 60.5472 33.8866 62.1282 33.5766 63.8332C33.2976 65.3212 31.9956 66.3753 30.5076 66.3753Z"/>
    <path d="M13.8609 56.7656C12.9619 56.7656 12.0939 56.3936 11.4739 55.6496C9.14887 52.8906 7.31986 49.7286 6.07985 46.2566C5.49085 44.6446 6.32785 42.8775 7.93986 42.2885C9.55188 41.6995 11.3189 42.5365 11.9079 44.1486C12.9309 46.9386 14.3879 49.4806 16.2479 51.6816C17.3329 52.9836 17.1779 54.9366 15.8759 56.0526C15.2559 56.5486 14.5429 56.7656 13.8609 56.7656Z"/>
    <path d="M40.2728 66.3753C38.7848 66.3753 37.4828 65.3213 37.2348 63.8022C36.9248 62.1282 38.0718 60.5162 39.7458 60.2062C42.6289 59.7102 45.3879 58.6872 47.8989 57.2302C49.3869 56.3622 51.2779 56.8892 52.1459 58.3462C53.0139 59.8342 52.4869 61.7252 51.0299 62.5932C47.8679 64.4223 44.4579 65.6623 40.8308 66.3133C40.6138 66.3753 40.4588 66.3753 40.2728 66.3753Z"/>
    <path d="M56.9197 56.7656C56.2067 56.7656 55.5247 56.5176 54.9357 56.0526C53.6337 54.9366 53.4477 52.9836 54.5637 51.6816C56.4237 49.4496 57.8807 46.9386 58.9037 44.1486C59.4927 42.5365 61.2907 41.6995 62.8718 42.2885C64.4838 42.8775 65.3208 44.6446 64.7318 46.2566C63.4608 49.7286 61.6627 52.8906 59.3377 55.6496C58.6867 56.3936 57.8187 56.7656 56.9197 56.7656Z"/>
    <path d="M63.275 38.4752C61.57 38.4752 60.175 37.0802 60.175 35.3752C60.175 21.7041 49.0459 10.575 35.3748 10.575C25.7337 10.575 16.8986 16.2481 12.8686 25.0521C12.1556 26.6022 10.3266 27.2842 8.74559 26.5712C7.19558 25.8582 6.51357 24.0291 7.22658 22.4481C12.2796 11.4741 23.3467 4.375 35.3748 4.375C52.4559 4.375 66.375 18.2941 66.375 35.3752C66.375 37.0802 65.011 38.4752 63.275 38.4752Z"/>
  </svg>

</template>
<script>
export default {
  name: 'loading-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

<template>
  <div id="app" class="page_container d-flex">
    <element-loading :active="loading">
      <LoadingIcon class="align-content-center rotate faster" width="35" height="35" :color="color.primary"/>
    </element-loading>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <Header />
    <div class="main_page_container body-container">
        <router-view/>
    </div>
    <my-footer class="footer"/>
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import {isNil} from "lodash";
import Vue from "vue";
import Header from './fragments/Header.vue';


export default {
  components: { Header },
  name: 'App',
  metaInfo() {
    return {
      title: 'Self Test Tool',
      link: [
        {
          rel: "icon",
          href: `/${this.getFlavor()}_favicon.ico`
        },
      ]
    }
  },
  component: {VueElementLoading},
  data() {
    return {
      transition: 'slide-right',
      loading: false,
      isMobile: false,
      color: {
        primary: 'var(--primary)'
      },
    }
  },
  created() {
    let flavor = this.getFlavor()
    this.$store.commit('setFlavor', flavor)
    import(`@/assets/flavors/${flavor}/css/style.scss`)
    import('@/assets/css/common.scss')
    this.$root.$on('successNotification', (message, title) => {
      this.makeToast({variant: 'success', message: message, title: title})
    })
    this.$root.$on('errorNotification', (message, title) => {
      this.makeToast({variant: 'danger', message: message, title: title})
    })
    this.$root.$on('activeLoader', (active) => {
      this.loading = active
    })
    this.$root.$on('logAction', async (it, cellNumber, obuId, plateNumber, operationCode, description) => {
      await Vue.logOperation(it, cellNumber, obuId, plateNumber, operationCode, description)
    })
  },
  methods: {
    makeToast({variant, type, message, title}) {
      this.$root.$bvToast.toast(variant === 'success' && type === 'login'
          ? 'Accesso eseguito'
          : !isNil(message)
              ? message
              : variant === 'success' && isNil(message)
                  ? this.$tc('success.operationSuccess')
                  : variant === 'danger' && isNil(message)
                      ? this.$tc('errors.error')
                      : '', {
        title: variant === 'success' && isNil(title) ? `Ok` : !isNil(title) ? title : '',
        autoHideDelay: variant === 'danger' && type === 'delete' ? 23000 : 10000,
        variant: variant,
        solid: true,
      })
    },
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    getFlavor() {
      const url = window.location.href
      let flavor
      switch (true) {
          // case url.includes('localhost'):
          //   flavor = 'uta'
          //   break
        case url.includes('lumesia1'):
          flavor = 'eurotoll'
          break
        case url.includes('uta'):
          flavor = 'uta'
          break
        default:
          flavor = 'telepass'
          break
      }
      return flavor
    }
  },
  mounted() {
    this.checkWindowWidth()
  }
}
</script>

<style>

</style>

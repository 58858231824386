<template>
  <div class="col component-container d-flex align-items-center justify-content-center p-0">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
      <b-col cols="12" sm="12" md="10" lg="6" xl="5" class="p-0">
        <b-card class="login-card pl-5 pr-5 pt-4" style="border-radius: 10px">
          <b-row class="pt-2" style="justify-content: center" align-v="center">
            <h6 class="title-card-text" style="margin-top:0 !important;">
              {{ `${$tc('installSteps.title')}` }}
            </h6>
          </b-row>
          <b-row style="justify-content: space-between;" align-v="center">
            <h6 class="info-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('message.obuId')}: ` + obuId }}
            </h6>
            <h6 class="info-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('message.plate')}: ` + plateNumber }}
            </h6>
          </b-row>
          <b-row style="justify-content: center" align-v="center">
            <h6 class="info-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('installSteps.step1.message')}` }}
            </h6>
          </b-row>
          <b-row style="justify-content: center" align-v="center">
            <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('installSteps.step1.operationTitle')}` }}
            </h6>
          </b-row>
          <b-row style="justify-content: center" align-v="center">
            <h6 class="info-text step-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('installSteps.step1.instructions')}` }}
            </h6>
          </b-row>
          <b-row align-v="center">
            <b-col cols="11" class="p-2">
              <h6 class="step-text mt-4 mb-4 strong-font-weight">
                {{ `${$tc('installSteps.step2.instructions')}` }}
              </h6>
            </b-col>
            <b-col cols="1" class="p-0 text-center">
              <QuestionCircleIcon id="popover-obu" :color="color.primary"/>
              <b-popover custom-class="wide-popover" target="popover-obu" triggers="hover" placement="right">
                <template #title><h6 class="normal-text-field">
                  {{ `${$tc('installSteps.step2.obuPosition')}` }}</h6></template>
                <b-col>
                  <img src="@/assets/images/windscreen.svg" alt="Info"/>
                </b-col>
              </b-popover>
            </b-col>
          </b-row>
          <b-row>
            <h6 class="info-text step-text mx-2 my-4">
              {{ `${$tc('installSteps.step2.instructions1')}` }}
            </h6>
          </b-row>
          <b-row class="mb-4" style="justify-content: center" align-v="center">
            <b-button pill variant="secondary" class="custom-button secondary-button"
                      @click="$refs.confirmExitModal.show()">
              <div>{{ `${$tc('installSteps.buttonAbort')}` }}</div>
            </b-button>
            <b-button pill variant="primary" class="custom-button primary-button" @click="proceed()">
              {{ `${$tc('installSteps.buttonNext')}` }}
            </b-button>
          </b-row>
          <b-row style="justify-content: center" class="my-3">
            <h6 class="bottom-text mb-3 mt-2 mr-2">
              {{ `${$tc('installSteps.bottomMessage')}` }}
            </h6>
            <b-link class="bottom-link noselect mb-3 mt-2" @click="guides()">
              {{ `${$tc('installSteps.bottomLink')}` }}
            </b-link>
          </b-row>
        </b-card>
      </b-col>
      <b-modal
          ref="configurationInfoModal"
          id="configuration-info-modal"
          :title="`${$tc('installSteps.step2.configurationInformation')}`"
          size="lg"
          title-class="title-modal-text ml-auto"
          class="custom-modal"
      >
        <template #modal-footer="{ ok }">
          <b-button pill variant="primary" class="primary-button"
                    @click="ok();">
            {{ `${$tc('message.ok')}` }}
          </b-button>
        </template>
        <h6 class="custom-modal-text justify-content-center modal-body-text">
          {{ `${$tc('installSteps.step2.configurationInformationMessage')}` }}</h6>
      </b-modal>
      <b-modal
          ref="confirmExitModal"
          id="confirm-exit-modal"
          :title="`${$tc('info.stopInstallationQuestion')}`"
          title-class="title-modal-text ml-auto"
          size="lg"
          @ok="$refs.installationFailedModal.show()"
          @cancel="$refs.confirmExitModal.hide()"
          class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="ok()">
            {{ `${$tc('message.yes')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="cancel()">
            No
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.fullStopInstallationQuestion')}` }}</h6>
      </b-modal>
      <b-modal
          ref="installationFailedModal"
          id="installation-failed-modal"
          :title="`${$tc('message.failureInstallation')}`"
          title-class="title-modal-text ml-auto"
          size="lg"
          @ok="motivation !== 52 ? sendMotivation() : $refs.otherFailureMotivationModal.show()"
          @cancel="$refs.confirmExitModal.hide()"
          class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivation === '' || motivation === undefined">
            {{ `${$tc('message.go')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.selectReasonStopInstallation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="8" xl="8">
            <model-select :options="motivationList"
                          v-model="motivation"
                          class="info-text"
                          :placeholder="`${$tc('info.selectMotivation', 1)}`">
            </model-select>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          ref="otherFailureMotivationModal"
          id="other-motivation-failed-modal"
          :title="`${$tc('message.failureInstallation')}`"
          title-class="title-modal-text ml-auto"
          size="lg"
          @ok="sendMotivation()"
          @cancel="$refs.otherFailureMotivationModal.hide()"
          class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivationDescription.length === 0">
            {{ `${$tc('message.send')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center m-3">
          {{ `${$tc('info.insertOtherMotivation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="10" xl="10">
            <b-form-textarea
                class="custom-modal-text modal-txt-field"
                style="text-align: start !important; font-weight: bold !important;"
                v-model="motivationDescription"
                :placeholder="`${$tc('message.problem')}`"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          ref="returnOrSubsDevice"
          id="return-subst-device-modal"
          :title="`${$tc('info.returnORReplacementDevice')}`"
          title-class="title-modal-text ml-auto"
          size="lg"
          @ok="$router.push('/home')"
          class="custom-modal"
      >
        <template #modal-footer="{ ok }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button custom-button" @click="ok()">
            {{ `${$tc('message.ok')}` }}
          </b-button>
        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.returnReplaceDeviceMessage')}` }}</h6>
      </b-modal>
    </b-container>
  </div>
</template>


<script>
import Vue from "vue";
import {isNil} from "lodash";

export default {
  name: "first-step",
  data() {
    return {
      isMobile: false,
      obuId: Vue.getLocalStorage('obuId'),
      plateNumber: Vue.getLocalStorage('plateNumber'),
      motivation: '',
      motivationList: [],
      motivationDescription: '',
      telephoneNumber: Vue.getLocalStorage('telephoneNumber'),
      color: {
        primary: 'var(--primary)'
      },
    }
  },
  mounted() {
    this.checkWindowWidth()
    this.motivationList = Vue.getStopMotivations(this)
    this.$root.$on('change-lang-motivations', () => {
      this.motivationList = Vue.getStopMotivations(this)
    })
  },
  methods: {
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    proceed() {
      this.$router.push("/install/third-step")
      // IPS ok & Config Pending
      this.successLogging()
    },
    async successLogging() {
      await Vue.logOperation(this, this.telephoneNumber, this.obuId, this.plateNumber, 22, null, Vue.getLocalStorage('session_uuid'))
    },
    guides() {
      this.$router.push("/home/show")
    },
    async sendMotivation() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/operation', {
        cell_no: this.telephoneNumber,
        obu_id: this.obuId,
        plate_number: this.plateNumber,
        operation_code: this.motivation,
        description: this.motivationDescription.length > 0 ? this.motivationDescription : '',
        session_id: Vue.getLocalStorage('session_uuid'),
      }, {
        headers: {
          'Authorization': isNil(Vue.getLocalStorage('session').token) ? '' : Vue.getLocalStorage('session').token,
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else {
          this.$refs.returnOrSubsDevice.show()
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    }
  }
}
</script>


<style scoped>

</style>


const Authentication = {
    install(Vue) {
        let authenticated = false
        let administrator = false

        Vue.isAuthenticated = () => {
            return authenticated
        }

        Vue.authenticate = () => {
            authenticated = true
        }

        Vue.deAuth = () => {
            authenticated = false
        }

        Vue.isAdministrator = () =>{
            return administrator
        }

        Vue.setAdmin = () => {
            administrator = true
        }

        Vue.delAdmin = () => {
            administrator = false
        }

    }
}
export default Authentication
/*
For messages that has only a variant like:
      "warning: 'Warnings'"
use: "<template> <h1>{{ $t('message.warning') }}, it is danger!</h1> </template>" syntax to obtain:
Warning, it is danger!

Else if message contains multiple options divided by "|" like:
      "key: 'Key | Keys'"
use: "<template> <h1>There are: 5 {{ $tc('message.warning', 2) }}</h1> </template>" syntax to obtain:
There are: 5 Keys

File input "Browse" button changing by css, edit in App.vue .lang-en { content: "Browse"} or .lang-it { content: "Cerca"}

If need to change default language see prop/language.js
 */
const messages = {
  en: {
    message: {
      contact: 'Contact | Contacts',
      installer: 'Installer | Installers',
      access: 'Installer Login',
      newAccess: 'New Installer Login',
      phone: 'Phone',
      go: 'GO',
      retrieve: 'Retrieve',
      data: 'Data',
      pin: 'PIN',
      otp: 'OTP',
      new: 'New',
      registration: 'Registration',
      name: 'Name',
      surname: 'Surname',
      email: 'Email',
      confirm: 'Confirm',
      verify: 'Verify',
      signin: 'Sign in',
      ok: 'OK',
      completed: 'Completed',
      portal: 'Installer Portal',
      error: 'Error',
      guide: 'Guide',
      support: 'Installer Support',
      installK1: 'Install K1',
      download: 'Download',
      video: 'Video',
      userProfile: 'User Profile',
      changePassword: 'Change password',
      logout: 'Logout',
      update: 'Update',
      cancel: 'Cancel',
      stopInstallation: 'Stop installation',
      invalidPlate: 'Invalid plate number',
      obuId: 'OBU ID',
      plate: 'License Plate',
      yes: 'Yes',
      failureInstallation: 'Failure Installation',
      select: 'Select',
      reason: 'Reason',
      dismiss: 'Dismiss',
      placement: 'Placement',
      problem: 'Problem encountered...',
      send: 'Send',
      setting: 'Setting',
      set: 'Set',
      privacy: 'I have read and I accept the terms of use and privacy'
    },
    info: {
      insertTelephone: 'Insert your phone number.\nIf you already have a Telepass account, you can log-in using its associated credentials.',
      insertPIN: 'Insert the PIN to login',
      retrieveAccessData: 'Retrieve access data',
      insertInformation: 'Enter the information indicated in the fields below',
      accessFromNewDevice: 'You are signing in from a new device. To verify access enter the OTP code we sent to your mobile number:',
      insertOTP: 'Enter the OTP code sent to your mobile number',
      completeRegistrationEnteringPIN: 'Complete the registration by entering a 6-digit numeric PIN',
      otpNotReceivedQuestion: 'Didn\'t receive the OTP code?',
      resendOTP: 'Resend OTP code',
      OTPSent: 'A code to verify your identity (OTP) has been sent to your mobile number',
      unregisteredUser: 'Unregistered user',
      performRegistration: 'Register to access the portal',
      guideQuestion: 'Have you read the guidelines? Start a new K1 installation process',
      showGuideBeforeInstallation: 'Show the manual before each Installation procedure',
      downloadInstallationManual: 'Download the hardware installation and platform usage manual',
      playVideoGuide: 'Watch the step-by-step video-guide of the istallation process',
      updateInformation: 'Update the information indicated in the following fields',
      beforeProceeding: 'Before proceeding...',
      gsmAdvMessage: 'In order to be able to correctly install the K1 device it is necessary to have a good GSM coverage in the garage, or where you are installing it.',
      newObuInstallation: 'Installation of a new device',
      insertSerialAndPlateNumber: 'Insert the serial number of the device and the license plate number of the vehicle',
      gsmQuestion: 'Haven\'t you these information?',
      returnToGuidePage: 'Return to the guideline page',
      stopInstallationQuestion: 'Stop installation?',
      fullStopInstallationQuestion: 'Are you sure to stop installation process?',
      selectReasonStopInstallation: 'Please select the reason of failure of installation',
      associationPlateObuNotFoundMessage: 'Please make sure to have correctly typed data and the device you want to install matches with the correct customer vehicle.\n' +
          'If the error still remain, contact your Sales Partner',
      selectMotivation: 'Select motivation...',
      returnORReplacementDevice: 'Return / Replacement device',
      returnReplaceDeviceMessage: 'Please contact your service provider to start the return/replecement process for the device on which you had came upon issues during installation procedure',
      insertOtherMotivation: 'Enter a description of the problem encountered during installation of the device:',
      updatePIN: 'Enter a 6-digit numeric PIN',
      backLogin: 'Go back to the login page',
      otpError: 'The inserted OTP code is not valid or expired',
      pinError: 'The inserted PINs do not match'
    },
    success: {
      postSuccess: 'correctly added',
      putSuccess: 'correctly modified',
      sendMail: 'correctly sent',
      fileUpload: 'File uploaded correctly',
      verifiedAccess: 'Verified Access',
      verifiedOTP: 'OTP code verified',
      authorizedDevice: 'Your device has been authorized',
      canAccessToPortal: 'You can now access the installer platform',
      associationPlateObuFound: 'Association OBU ID-License Plate verified',
      pinChanged: 'New PIN set correctly',
      operationSuccess: 'Operation succeeded'
    },
    warnings: {
      phoneForm: 'The field must consist of an area code and a valid telephone number',
      pinForm: 'The field must consist of 6 digits',
      pinConfirm: 'The inserted PINs do not match'
    },
    errors: {
      associationPlateObuNotFound: 'Association OBU ID-License Plate not found',
      error: 'Error during comunication'
    },
    installSteps: {
      title: 'Installation of a NEW device',
      step1: {
        operationTitle: 'IPS Installation',
        message: 'Now you can proceed with device hardware installation',
        instructions: `Verify that the switchboard is off after then, start with the installation of the IPS unit.
                                \n Make sure you have correctly well connected the cables to the control unit and get ready with the USB cable in the cabin.`
      },
      step2: {
        advancedText: 'Advanced',
        datePrompt: 'Select the installation start date and time',
        operationTitle: 'Start device and configuration',
        instructions: 'Place the device in the windscreen as for the manual instruction.',
        instructions1: 'Connect the Usb cable to the OBU and then click on "proceed"',
        obuPosition: 'Correct OBU location',
        monitorMessage: 'Automatic procedure on going please wait...',
        configurationInformation: 'Configuration information',
        configurationInformationMessage: 'Description of the configuration procedure from the point of view feedback to installer from HMI',
        error1: {
          errorMain: 'Failure to communicate with the device',
          errorDesc: 'Please follow these steps:',
          first: 'Disconnect the usb cable from the device',
          second: 'Double-check electrical connections of the IPS unit',
          third: 'Connect again the USb cable to the OBU',
          fourth: 'Verify the device start-up:',
          subF1: 'start-up sound generation;',
          subF2: 'Status Red Led turned on.',
          footMessage: `Click on Try again to repeat the verification.
                    If the problem persists stop the installation`,
        },
        error2: {
          errorMain: 'An error occurred during the configuration process',
          errorDesc: 'It is not possible to configure the OBU.',
          footMessage: 'Stop the installation procedure'
        },
        error3: {
          errorMain: 'An error occurred during the configuration process',
          errorDesc: 'OBU personalization has not been completed.',
          footMessage: 'Stop the installation procedure'
        },
      },
      step3: {
        operationTitle: 'Verification',
        instructions: 'As a final step, it is necessary to check that the OBU correctly receives the activation signal from the vehicle\'s electrical panel.\n',
        instructions1: '\nSwitch on the switchboard and then click "Proceed" to start the remote verification. ',
        updating: ' Status update ongoing...',
        error: {
          errorMain: 'Key signal not present',
          errorDesc: 'Check the electrical connection of the key signal.\n' +
              '\n' +
              'Repeat the status OBu verification by clicking on "repeat" button.\n' +
              '\n' +
              'If is not possible to stop the verification please click on "Stop Installation".',
          batteryMain: 'Check cable connection',
          batteryDesc: 'The power cable might have been disconnected during the installation.\n' +
              '\nAlso, make sure you have not switched the power and ignition cables.'
        }
      },
      buttonNext: 'Proceed',
      buttonAbort: 'Stop Installation',
      bottomMessage: 'Have you met any problem?',
      bottomLink: 'Back to guides',
      retryButton: `Repeat`,
      successInstallation: 'OBU Configuration correctly completed',
      obuReady: 'The device works properly\n' +
          '\n' +
          'Installation complete',
      newInstallation: 'New installation'
    },
    admin: {
      success: 'Table Updated',
      nav: {
        title: 'Administrator Portal',
        eventsLink: 'Events',
        adminMng: 'Manage Admin'
      },
      eventsPage: {
        title: 'Installation events',
        search: 'Search Cell_no, OBU ID...',
        recordInfo: 'Shown Records',
        buttons: {
          next: 'Next',
          previous: 'Previous',
          autoRefresh: 'Auto-update'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Pending'
        },
        filters: {
          buttons: {
            clear: 'Clear Filters',
            collapse: 'Filters'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Operation Code',
              startTs: 'From:',
              startTime: 'Initial time:',
              endTs: 'To:',
              endTime: 'Final time:',
              plate: 'Plate number:',
              status: 'Status'
            },
            placeholders: {
              obu: 'Select OBU serial',
              opcode: 'Select Operation',
              startTs: 'Select initial date',
              endTs: 'Select final date',
              noObu: 'No available OBU ids',
              noOpcode: 'No available Opcodes',
              allOpts: 'All',
              plate: 'Select plate',
              searchPlate: 'Search plate...',
              noPlate: 'No available plate numbers',
              status: 'Select status'
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Pending'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Phone num',
          obu_id: 'OBU ID',
          plate_number: 'Plate Number',
          timestamp: 'Date-Time',
          opcode_desc: 'Opcode Descr.',
          description: 'Description',
          status: 'Status'
        },

        opcodes: {
          0: 'Select operation code',
          1: 'Login Pending',
          2: 'Login OK',
          3: 'Login KO',
          4: 'OTP Pending',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN Pending',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Registration Pending',
          11: 'Registration OK',
          12: 'Registration KO',
          13: 'Request new OTP OK',
          14: 'Request new OTP KO',
          15: 'Update user info Pending',
          16: 'Update user info OK',
          17: 'Update user info KO',
          18: 'OBU-Plate Pending',
          19: 'OBU-Plate match OK',
          20: 'OBU-Plate match KO',
          21: 'IPS install Pending',
          22: 'IPS install OK',
          23: 'Config K1 Pending',
          24: 'Config K1 OK',
          25: 'Config K1 - First Run: error on PM',
          26: 'Config K1 - First Run: Error no communication',
          27: 'Config K1 - First Run: Error wrong branding',
          28: 'Config K1 - Branding: Error on service',
          29: 'Config K1 - Branding: operation timed out',
          30: 'Config K1 - Vehicle Config: Error config',
          31: 'Config K1 - Vehicle Config: operation timed out',
          32: 'Config K1 - Tolling DSRC: Error on at least one service',
          33: 'Config K1 - Tolling DSRC: operation timed out',
          34: 'Config K1 - Tolling GNSS: Error on at least one service',
          35: 'Config K1 - Tolling GNSS: operation timed out',
          36: 'Config K1 - Service Activation: Error on at least one service',
          37: 'Config K1 - Service Activation: operation timed out',
          38: 'Config K1 - Reboot: reboot operation failed',
          39: 'Config K1 - Power Switch: PM switch failed',
          40: 'K1 Key Pending',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Install Pending',
          44: 'Install OK',
          45: 'OBU without light signals',
          46: 'USB cable not present',
          47: 'Dual Lock not present',
          48: 'USB cable too short',
          49: "The OBU does not communicate properly with leds and sounds",
          50: 'Configuration process failure',
          51: "OBU does not receive correctly the activation signal",
          52: 'Other failure scenario',
          53: 'Association start_time was NULL',
          54: 'Battery error',
        }
      },
      adminMngPage: {
        title: 'Admin Management',
        search: 'Search...',
        buttons: {
          add: 'Add'
        },
        table: {
          actions: 'Actions',
          phone: 'Phone',
          firstName: 'First Name',
          lastName: 'Last Name',
          company: 'Company',
          removed: 'Disabled',
        },
        modal: {
          dataLabels: {
            phone: 'Phone',
            confPhone: 'Confirm phone',
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            company: 'Company',
            toggle: 'Disable Account',
          },
          addAdmin: {
            title: 'Add Administrator Account',
            body: 'Insert the following account details:',
            buttons: {
              cancel: 'Cancel',
              proceed: 'Insert'
            }
          },
          modAdmin: {
            title: 'Modify Administrator Account',
            body: 'Modify the following account details:',
            buttons: {
              cancel: 'Cancel',
              proceed: 'Update'
            }
          },
          deleteAdmin: {
            title: 'Admin Account Deletion',
            body: 'Are you sure you want to remove this account from the administrator list?',
            buttons: {
              cancel: 'No',
              proceed: 'Yes'
            }
          },
          success: {
            title: 'Account Details Registered',
            body: 'Administrator was saved succesfully'
          },
          error: {
            title: 'Data is not valid',
            body: 'This Administrator is already registered'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filters',
            clear: 'Clear Filters'
          },
          dropdowns: {
            labels: {
              removed: 'Account Disabled',
              all: 'All'
            }
          }
        }
      }
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Incorrect PIN',
        1212: 'User not found',
        1226: 'Username already used',
        1231: 'Invalid phone number',
        1400: 'Parameter missing',
        1106: 'Incorrect OTP',
        1214: 'Wrong PIN (Last attempt available)'
      },
      403: {
        0: 'Access denied',
        1213: 'Blocked user',
      },
      404: {
        0: 'Resource not found',
        3: 'Resource not found'
      },
      409: {
        7: 'Conflict during removal'
      },
      413: 'Data size too large',
      422: {
        3: 'Error during communication'
      },
      423: 'The password has expired',
      440: 'The session has expired',
      500: {
        1500: 'Unexpected server side error',
        4001: 'Session rejected',
      },
      502: 'Error 502, communication error'
    },
    path: {
      '/home': 'Home',
      '/login': 'Login',
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  it: {
    message: {
      contact: 'Contatto | Contatti',
      installer: 'Installatore | Installatori',
      access: 'Accesso Installatore',
      newAccess: 'Nuovo Accesso Installatore',
      phone: 'Cellulare',
      go: 'Avanti',
      retrieve: 'Recupera',
      data: 'Dati',
      pin: 'PIN',
      new: 'Nuovo',
      registration: 'Registrazione',
      otp: 'OTP',
      name: 'Nome',
      surname: 'Cognome',
      email: 'Email',
      confirm: 'Conferma',
      verify: 'Verifica',
      signin: 'Registrati',
      ok: 'OK',
      completed: 'Completata',
      portal: 'Portale Installatori',
      error: 'Errore',
      guide: 'Guide',
      support: 'Supporto Installatori',
      installK1: 'Installa K1',
      download: 'Download',
      video: 'Video',
      userProfile: 'Profilo Utente',
      changePassword: 'Cambia password',
      logout: 'Logout',
      update: 'Aggiorna',
      cancel: 'Annulla',
      stopInstallation: 'Interrompi installazione',
      obuId: 'OBU ID',
      plate: 'Targa',
      yes: 'Sì',
      failureInstallation: 'Report Installazione Fallita',
      select: 'Seleziona',
      reason: 'Ragione',
      dismiss: 'Annulla',
      placement: 'Collocazione',
      problem: ' Problematica riscontrata...',
      send: 'Invia',
      setting: 'Impostazione',
      set: 'Imposta',
      privacy: 'Ho letto e accetto i termini di utilizzo e della privacy',
      invalidPlate: 'La targa inserita non è valida'
    },
    info: {
      insertTelephone: 'Inserisci il tuo numero di telefono.\nSe hai già un account Telepass, puoi accedere utilizzando le credenziali ad esso associate.',
      insertPIN: 'Inserisci il PIN per accedere',
      retrieveAccessData: 'Recupera i dati di accesso',
      insertInformation: 'Inserisci le informazioni indicate nei campi seguenti',
      accessFromNewDevice: 'Stai effettuando l\'accesso da un nuovo dispositivo. \n\n Per verificare l\'accesso inserisci il codice' +
          ' OTP che abbiamo inviato al tuo numero di cellulare:',
      insertOTP: 'Inserisci il codice OTP inviato al tuo numero di cellulare',
      completeRegistrationEnteringPIN: 'Completa la registrazione inserendo un PIN numerico di 6 cifre',
      otpNotReceivedQuestion: 'Non hai ricevuto il codice OTP?',
      resendOTP: 'Reinvia codice OTP',
      OTPSent: 'E\' stato inviato al tuo numero di cellulare un codice per verificare la tua identità (OTP)',
      unregisteredUser: 'Utente non registrato',
      performRegistration: 'Effettua la registrazione per accedere al portale',
      guideQuestion: 'Hai consultato le guide? Avvia un nuovo processo di installazione K1',
      showGuideBeforeInstallation: 'Mostra guide prima di ogni installazione',
      downloadInstallationManual: 'Scarica il manuale di installazione dell\'hardware e di utilizzo della piattaforma',
      playVideoGuide: 'Guarda la video-guida step-by-step del processo di installazione e monitoring del dispositivo',
      updateInformation: 'Aggiorna le informazioni indicate nei campi seguenti',
      beforeProceeding: 'Prima di procedere...',
      gsmAdvMessage: 'Per poter portare a termine correttamente il processo di installazione di un apparato K1 è necessario che sia presente, sul luogo di installazione, una buona ricezione del segnale cellulare GSM',
      newObuInstallation: 'Installazione nuovo apparato',
      insertSerialAndPlateNumber: 'Inserisci l\'identificativo dell\'apparato da installare e la targa del veicolo',
      gsmQuestion: 'Non disponi di queste informazioni?',
      returnToGuidePage: 'Torna alle guide',
      stopInstallationQuestion: 'Interrompere installazione?',
      fullStopInstallationQuestion: 'Sei sicuro di voler interrompere il processo di installazione?',
      selectReasonStopInstallation: 'Seleziona la ragione di interruzione installazione:',
      associationPlateObuNotFoundMessage: 'Assicurati di aver inserito correttamente i dati e che il dispositivo che ti appresti ' +
          'ad installare corrisponda al veicolo del cliente. Se il problema persiste, contatta il tuo Sales Partner',
      selectMotivation: 'Seleziona motivazione...',
      returnORReplacementDevice: 'Restituzione / Sostituzione apparato',
      returnReplaceDeviceMessage: 'Ti invitiamo a contattare il tuo Sales Partner per avviare la procedure ' +
          'di Restituzione o Sostituzione dell\'apparato su cui hai riscontrato problematiche durante il processo di installazione',
      insertOtherMotivation: 'Inserisci una descrizione del problema riscontrato durante l\'installazione dell\'apparato:',
      updatePIN: 'Inserisci un PIN numerico di 6 cifre',
      backLogin: 'Torna alla pagina di login',
      otpError: 'Codice OTP inserito non valido o scaduto',
      pinError: 'I PIN inseriti non coincidono'
    },
    success: {
      postSuccess: 'aggiunto con successo',
      putSuccess: 'aggiornato con successo',
      sendMail: 'inviata con successo',
      verifiedAccess: 'Accesso Verificato',
      verifiedOTP: 'Codice OTP verificato',
      authorizedDevice: 'Il tuo dispositivo è stato autorizzato',
      canAccessToPortal: 'Puoi ora accedere alla piattaforma installatori',
      associationPlateObuFound: 'Associazione ID-TARGA verificata',
      pinChanged: 'Nuovo PIN impostato correttamente',
      operationSuccess: 'Operazione eseguita con successo'
    },
    warnings: {
      accessDenied: 'Accesso negato',
      alreadyDisabled: 'L\'utente risulta già disabilitato',
      invalid: 'Non valido | Non valida',
      sureToDelete: 'Si è sicuri di voler eliminare l\'elemento?',
      continue: 'Prosegui',
      valid: 'Valido | Valida',
      phoneForm: 'Il campo deve essere composto da prefisso e numero di telefono valido',
      pinForm: 'Il campo deve essere costituito da 6 cifre',
      pinConfirm: 'I PIN inseriti non coincidono'
    },
    errors: {
      associationPlateObuNotFound: 'Associazione ID-TARGA non trovata',
      error: 'Si è verificato un errore'
    },
    installSteps: {
      title: 'Installazione nuovo apparato',
      step1: {
        operationTitle: 'Installazione IPS',
        message: 'Puoi ora procedere con la installazione dell\'Hardware',
        instructions: `Verifica che il quadro elettrico del veicolo sia spento ed effettua la installazione della unità IPS.
                                \n Assicurati di aver collegato correttamente i cavi alla centralina e predisponi il cavo USB all'interno dell'abitacolo.`
      },
      step2: {
        advancedText: 'Avanzate',
        datePrompt: 'Selezionare data e ora di avvio installazione',
        operationTitle: 'Avvio apparato e configurazione',
        message: '',
        instructions: 'Posiziona l\'OBU all\'interno dell\'abitacolo, sul parabrezza del mezzo, come mostrato sul manuale.',
        instructions1: 'Collega il connettore USB all\'OBU e fai click su procedi.',
        obuPosition: 'Collocazione corretta OBU',
        monitorMessage: 'Procedura di configurazione automatica in corso...',
        configDesc: '',
        configurationInformation: 'Informazioni sulla configurazione',
        configurationInformationMessage: 'Descrizione della procedura di configurazione dal punto di vista' +
            ' del feedback verso installatore da HMI',
        error1: {
          errorMain: 'Non è stato possibile comunicare con il dispositivo',
          errorDesc: 'Ti invitiamo a seguire i seguenti passaggi:',
          first: 'Scollega il cavo USB dall\'OBU',
          second: 'Ricontrolla i collegamenti elettrici della unità IPS',
          third: 'Ricollega il cavo USB all\'OBU',
          fourth: 'Verificare l\'avvio dell\'apparato:',
          subF1: 'Riproduzione suono di avvio;',
          subF2: 'Accensione led rosso di stato.',
          footMessage: `Fai click su riprova per ripetere la verifica.
                                    Se il problema persiste termina la installazione.`,
        },
        error2: {
          errorMain: 'Si è verificato un errore durante il processo di configurazione',
          errorDesc: 'Non è possibile configurare l\'OBU.',
          footMessage: 'Interrompi il processo di installazione dell\'apparato'
        },
        error3: {
          errorMain: 'Si è verificato un errore durante il processo di configurazione',
          errorDesc: 'La personalizzazione dell\'OBU non è stata completata.',
          footMessage: 'Interrompi il processo di installazione dell\'apparato'
        },
      },
      step3: {
        operationTitle: 'Verifica',
        instructions: 'Come ultimo passaggio è necessario verificare che l\'OBU riceva correttamente il segnale di “attivazione” del quadro elettrico del veicolo.\n',
        instructions1: '\nAccendi il quadro e fai click su procedi per avviare la verifica da remoto',
        updating: 'Aggiornamento status in corso...',
        error: {
          errorMain: 'Segnale di chiave non presente',
          errorDesc: 'Controlla il collegamento elettrico del segnale di chiave.\n' +
              '\n' +
              'Ripeti la verifica dello status dell\'OBU facendo click su riprova.\n' +
              '\n' +
              'Se non è possibile terminare la verifica fai click su Interrompi Installazione.',
          batteryMain: 'Verifica il collegamento cavi',
          batteryDesc: 'Il cavo di alimentazione potrebbe essere stato scollegato durante l\'installazione.\n' +
              '\nAssicurati inoltre di non aver invertito il cavo di alimentazione e sottochiave.'
        }
      },
      buttonNext: 'Procedi',
      buttonAbort: 'Interrompi installazione',
      bottomMessage: 'Hai incontrato qualche problema?',
      bottomLink: 'Torna alle guide',
      retryButton: `Riprova`,
      successInstallation: 'Configurazione OBU completata correttamente',
      obuReady: 'L\'OBU funziona correttamente\n' +
          '\n' +
          'Installazione completata',
      newInstallation: 'Nuova installazione'
    },
    admin: {
      success: 'Tabella Aggiornata',
      nav: {
        title: 'Portale Amministratore',
        eventsLink: 'Eventi',
        adminMng: 'Gestione Admin'
      },
      eventsPage: {
        title: 'Eventi installazioni',
        search: 'Ricerca Cell_no, OBU ID...',
        recordInfo: 'Record visualizzati',
        buttons: {
          next: 'Prossimo',
          previous: 'Precedente',
          autoRefresh: 'Auto-update'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'In corso'
        },
        filters: {
          buttons: {
            clear: 'Pulisci Filtri',
            collapse: 'Filtri'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Codice Operazione',
              startTs: 'Dal:',
              startTime: 'Tempo iniziale:',
              endTs: 'Al:',
              endTime: 'Tempo finale:',
              plate: 'Numero Targa:',
              status: 'Stato:'
            },
            placeholders: {
              obu: 'Seleziona seriale OBU',
              opcode: 'Seleziona operazione',
              startTs: 'Seleziona data iniziale',
              endTs: 'Seleziona data finale',
              noObu: 'Nessun OBU id disponibile',
              noOpcode: 'Nessun opcode disponibile',
              allOpts: 'Tutti',
              plate: 'Seleziona Targa',
              noPlate: 'Nessuna targa disponibile',
              searchPlate: 'Cerca Targa...',
              status: 'Seleziona Stato',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'In Corso'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Numero Tel.',
          obu_id: 'OBU ID',
          plate_number: 'Numero Targa',
          timestamp: 'Data-Ora',
          opcode_desc: 'Descr. Opcode',
          description: 'Descrizione',
          status: 'Stato'
        },
        opcodes: {
          0: 'Seleziona operation code',
          1: 'Login in corso',
          2: 'Login OK',
          3: 'Login KO',
          4: 'OTP in corso',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN in corso',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Registrazione in corso',
          11: 'Registrazione OK',
          12: 'Registrazione KO',
          13: 'Richiesta nuovo OTP OK',
          14: 'Richiesta nuovo OTP KO',
          15: 'Aggiornamento dati utente in corso',
          16: 'Aggiornamento dati utente OK',
          17: 'Aggiornamento dati utente KO',
          18: 'Associazione OBU-Targa in corso',
          19: 'Associazione OBU-Targa OK',
          20: 'Associazione OBU-Targa KO',
          21: 'Installazione IPS in corso',
          22: 'Installazione IPS OK',
          23: 'Configurazione K1 in corso',
          24: 'Configurazione K1 OK',
          25: 'Configurazione K1 - First Run: errore su PM',
          26: 'Configurazione K1 - First Run: nessuna comunicazione',
          27: 'Configurazione K1 - First Run: Branding errato',
          28: 'Configurazione K1 - Branding: errore su servizio',
          29: 'Configurazione K1 - Branding: operazione in time-out',
          30: 'Configurazione K1 - Vehicle Config: errore di configurazione',
          31: 'Configurazione K1 - Vehicle Config: operazione in time-out',
          32: 'Configurazione K1 - Tolling DSRC: errore su almeno un servizio',
          33: 'Configurazione K1 - Tolling DSRC: operazione in time-out',
          34: 'Configurazione K1 - Tolling GNSS: errore su almeno un servizio',
          35: 'Configurazione K1 - Tolling GNSS: operazione in time-out',
          36: 'Configurazione K1 - Service Activation: errore su almeno un servizio',
          37: 'Configurazione K1 - Service Activation: operazione in time-out',
          38: 'Configurazione K1 - Reboot: riavvio fallito',
          39: 'Configurazione K1 - Power Switch: variazione PM fallita',
          40: 'K1 Key in corso',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Installazione in corso',
          44: 'Installazione Completata',
          45: 'OBU senza segnali luminosi',
          46: 'Cavo USB non presente',
          47: 'Dual lock non presente',
          48: 'Cavo USB troppo corto',
          49: "L'obu non comunica correttamente con segnali luminosi e sonori",
          50: 'Fallimento del processo di configurazione',
          51: "L'OBU non riceve correttamente il segnale di attivazione",
          52: 'Altro scenario di fallimento',
          53: 'Lo start_time della associazione era NULL',
          54: 'Errore batteria',
        }
      },
      adminMngPage: {
        title: 'Gestione Amministratori',
        search: 'Ricerca...',
        buttons: {
          add: 'Aggiungi'
        },
        table: {
          actions: 'Azioni',
          phone: 'Telefono',
          firstName: 'Nome',
          lastName: 'Cognome',
          company: 'Compagnia',
          removed: 'Disabilitato',
        },
        modal: {
          dataLabels: {
            phone: 'Telefono',
            confPhone: 'Conferma telefono',
            firstName: 'Nome',
            lastName: 'Cognome',
            email: 'Email',
            company: 'Società',
            toggle: 'Disabilita Account',
          },
          addAdmin: {
            title: 'Aggiungi Account Amministratore',
            body: 'Inserisci le informazioni indicate nei campi seguenti:',
            buttons: {
              cancel: 'Annulla',
              proceed: 'Inserisci'
            }
          },
          modAdmin: {
            title: 'Modifica Account Amministratore',
            body: 'Modifica le informazioni dell\'account seguenti:',
            buttons: {
              cancel: 'Annulla',
              proceed: 'Aggiorna'
            }
          },
          deleteAdmin: {
            title: 'Cancellazione Account Amministratore',
            body: 'Sei sicuro di voler rimuovere questo account dalla lista amministratori?',
            buttons: {
              cancel: 'No',
              proceed: 'Si'
            }
          },
          success: {
            title: 'Dati Account Registrati',
            body: 'Account Amministratore salvato con successo'
          },
          error: {
            title: 'Dati non validi',
            body: 'Amministratore già presente in elenco'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtri',
            clear: 'Pulisci Filtri'
          },
          dropdowns: {
            labels: {
              removed: 'Account Disabilitato',
              all: 'Tutti'
            }
          }
        }
      },

    },
    months: {
      january: 'Gennaio',
      february: 'Febbraio',
      march: 'Marzo',
      april: 'Aprile',
      may: 'Maggio',
      june: 'Giugno',
      july: 'Luglio',
      august: 'Agosto',
      september: 'Settembre',
      october: 'Ottobre',
      november: 'Novembre',
      december: 'Dicembre'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PIN non corretto',
        1212: 'Utente non trovato',
        1226: 'Username già utilizzata',
        1231: 'Numero di telefono non valido',
        1400: 'Parametro mancante',
        1106: 'OTP inserito errato',
        1214: 'PIN errato (Ultimo tentativo disponibile)',
        4000: 'ID di sessione scaduto'
      },
      403: {
        0: 'Accesso negato',
        1213: 'Utente bloccato',
      },
      404: {
        0: 'Risorsa non trovata',
        3: 'Risorsa non trovata',
        5: 'Risorsa non trovata'
      },
      409: {
        7: 'Conflitto durante la rimozione'
      },
      413: 'Dimensione dei dati troppo grande',
      422: {
        3: 'Errore durante la comunicazione'
      },
      423: 'La password risulta scaduta',
      440: 'La sessione risulta scaduta',
      500: {
        1500: 'Errore imprevisto lato server',
        4001: 'Sessione rifiutata',
      },
      502: 'Errore 502, errore di comunicazione'
    },
    path: {
      '/home': 'Home',
      '/login': 'Accesso'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_it'
    },
  },
  pt: {
    message: {
      contact: 'Contacto | Contactos',
      installer: 'Instalador | Instaladores',
      access: 'Acesso de instalador',
      newAccess: 'Novo Acesso de instalador',
      phone: 'Telemóvel',
      go: 'Seguinte',
      retrieve: 'Recuperar',
      data: 'Dados',
      pin: 'PIN',
      new: 'Novo',
      registration: 'Registo',
      otp: 'OTP',
      name: 'Nome',
      surname: 'Apelido',
      email: 'E-mail',
      confirm: 'Confirmar',
      verify: 'Verificação',
      signin: 'Registe-se',
      ok: 'OK',
      completed: 'Concluída',
      portal: 'Portal de instaladores',
      error: 'Erro',
      guide: 'Guias',
      support: 'Apoio aos instaladores',
      installK1: 'Instalar K1',
      download: 'Descarregar',
      video: 'Vídeo',
      userProfile: 'Perfil de utilizador',
      changePassword: 'Alterar password',
      logout: 'Fim de sessão',
      update: 'Atualizar',
      cancel: 'Cancelar',
      stopInstallation: 'Interromper instalação',
      obuId: 'ID Unidade de bordo (OBU)',
      plate: 'Matrícula',
      yes: 'Sim',
      failureInstallation: 'Relatório de falha na instalação',
      select: 'Selecionar',
      reason: 'Motivo',
      dismiss: 'Cancelar',
      placement: 'Colocação',
      problem: ' Problema detetado...',
      send: 'Enviar',
      setting: 'Definição',
      set: 'Definir',
      privacy: 'Li e aceito os termos de utilização e da política de privacidade',
      invalidPlate: 'A matrícula que introduziu é inválida'
    },
    info: {
      insertTelephone: 'Introduza o seu número de telefone. Se já tem uma conta Telepass, pode iniciar a sessão utilizando as credenciais associadas.',
      insertPIN: 'Introduza o PIN para iniciar sessão',
      retrieveAccessData: 'Recuperar os dados de acesso',
      insertInformation: 'Introduza as informações indicadas nos campos seguintes',
      accessFromNewDevice: 'Está a fazer o acesso a partir de um novo dispositivo. \n\n Para verificar o acesso, introduza o código' +
          ' OTP que enviámos para o seu número de telemóvel:',
      insertOTP: 'Introduza o código OTP que enviámos para o seu número de telemóvel',
      completeRegistrationEnteringPIN: 'Conclua o processo de registo introduzindo um PIN numérico de 6 dígitos',
      otpNotReceivedQuestion: 'Não recebeu o código OTP?',
      resendOTP: 'Reenviar código OTP',
      OTPSent: 'Um código para verificar a sua identidade (OTP) foi enviado para o seu número de telemóvel',
      unregisteredUser: 'Utilizador não registado',
      performRegistration: 'Registe-se para aceder ao portal',
      guideQuestion: 'Consultou os guias? Iniciar novo processo de instalação K1',
      showGuideBeforeInstallation: 'Mostrar guias antes de cada instalação',
      downloadInstallationManual: 'Descarregar o manual de instalação do hardware e de utilização da plataforma',
      playVideoGuide: 'Ver o vídeo-guia passo-a-passo do processo de instalação e monitorização do dispositivo',
      updateInformation: 'Atualizar as informações indicadas nos campos seguintes',
      beforeProceeding: 'Antes de continuar...',
      gsmAdvMessage: 'Para poder terminar com êxito o processo de instalação de um aparelho K1 é necessário que, no local de instalação, haja boa receção do sinal de telemóvel GSM',
      newObuInstallation: 'Instalação de um novo aparelho',
      insertSerialAndPlateNumber: 'Introduza o identificador do aparelho que deseja instalar e a matrícula do veículo',
      gsmQuestion: 'Não tem estas informações?',
      returnToGuidePage: 'Regressar aos guias',
      stopInstallationQuestion: 'Interromper a instalação?',
      fullStopInstallationQuestion: 'Tem a certeza de que deseja interromper o processo de instalação?',
      selectReasonStopInstallation: 'Selecione o motivo de interrupção da instalação:',
      associationPlateObuNotFoundMessage: 'Certifique-se de que introduziu os dados corretamente e que o dispositivo que deseja ' +
          'instalar corresponde ao veículo do cliente. Se o problema persistir, contacte o seu Parceiro de Vendas',
      selectMotivation: 'Selecionar motivo...',
      returnORReplacementDevice: 'Devolução / Substituição do aparelho',
      returnReplaceDeviceMessage: 'Entre em contacte com o seu Parceiro de Vendas para iniciar o processo ' +
          'de Devolução ou Substituição do aparelho em que foram detetados problemas durante o processo de instalação',
      insertOtherMotivation: 'Introduza uma descrição do problema detetado durante a instalação do aparelho:',
      updatePIN: 'Introduza um PIN numérico de 6 dígitos',
      backLogin: 'Regressar à página de início sessão',
      otpError: 'O código OTP introduzido é inválido ou expirou',
      pinError: 'Os PINs introduzidos não coincidem'
    },
    success: {
      postSuccess: 'adicionado com êxito',
      putSuccess: 'atualizado com êxito',
      sendMail: 'enviado com êxito',
      verifiedAccess: 'Acesso verificado',
      verifiedOTP: 'Código OTP verificado',
      authorizedDevice: 'O seu dispositivo foi autorizado',
      canAccessToPortal: 'Pode agora aceder à plataforma de instaladores',
      associationPlateObuFound: 'Associação ID-MATRÍCULA verificada',
      pinChanged: 'Novo PIN definido corretamente',
      operationSuccess: 'Operação realizada com êxito'
    },
    warnings: {
      accessDenied: 'Acesso recusado',
      alreadyDisabled: 'O utilizador já foi desativado',
      invalid: 'Inválido | Inválida',
      sureToDelete: 'Tem a certeza de que deseja eliminar o elemento',
      continue: 'Continuar',
      valid: 'Válido | Válida',
      phoneForm: 'O campo deve ser constituído por um indicativo e um número de telefone válido',
      pinForm: 'O campo deve ser constituído por 6 dígitos',
      pinConfirm: 'Os PINs introduzidos não coincidem'
    },
    errors: {
      associationPlateObuNotFound: 'Associação ID-MATRÍCULA não encontrada',
      error: 'Ocorreu um erro'
    },
    installSteps: {
      title: 'Instalação de um novo aparelho',
      step1: {
        operationTitle: 'Instalação da unidade IPS',
        message: 'Pode agora proceder à instalação do hardware',
        instructions: `Verifique se o quadro de instrumentos do veículo está desligado e, neste caso, proceda à instalação da unidade IPS.
                                 \n Certifique-se de que os cabos estão corretamente ligados ao sistema elétrico e prepare o cabo USB dentro do habitáculo.`
      },
      step2: {
        advancedText: 'Avançadas',
        datePrompt: 'Selecionar data e hora de início da instalação',
        operationTitle: 'Iniciar aparelho e configuração',
        message: '',
        instructions: 'Posicione a OBU no habitáculo, no para-brisas do meio, como indicado no manual.',
        instructions1: 'Ligue o conector USB à OBU e clique em avançar.',
        obuPosition: 'Colocação correta da OBU',
        monitorMessage: 'Procedimento de configuração automática em curso...',
        configDesc: '',
        configurationInformation: 'Informações sobre a configuração',
        configurationInformationMessage: 'Descrição do procedimento de configuração do ponto de vista' +
            'do feedback dado pela IHM ao instalador',
        error1: {
          errorMain: 'Não foi possível comunicar com o dispositivo',
          errorDesc: 'Siga as indicações seguintes:',
          first: 'Desligue o cabo USB da OBU',
          second: 'Verifique de novo as ligações elétricas da unidade IPS',
          third: 'Ligue de novo o cabo USB à OBU',
          fourth: 'Verificar o arranque do aparelho:',
          subF1: 'Emissão do som de arranque;',
          subF2: 'O led vermelho de estado acende-se.',
          footMessage: `Clique em tentar novamente, para repetir a verificação.
        Se o problema persistir, terminar a instalação.`,
        },
        error2: {
          errorMain: 'Ocorreu um erro durante o processo de configuração',
          errorDesc: 'Não é possível configurar a OBU.',
          footMessage: 'Interromper o processo de instalação do aparelho'
        },
        error3: {
          errorMain: 'Ocorreu um erro durante o processo de configuração',
          errorDesc: 'A personalização da OBU não foi concluída.',
          footMessage: 'Interromper o processo de instalação do aparelho'
        },
      },
      step3: {
        operationTitle: 'Verificação',
        instructions: 'Para terminar, é necessário verificar se a OBU recebe corretamente o sinal de “ativação” do quadro de instrumentos do veículo.\n',
        instructions1: '\nLigue o quadro e clique em avançar, para iniciar a verificação remota',
        updating: 'Atualização do estado em curso...',
        error: {
          errorMain: 'Sem sinal de chave',
          errorDesc: 'Verifique a ligação elétrica do sinal de chave.\n' +
              '\n' +
              'Repita a verificação de estado da OBU e clique em tentar novamente.\n' +
              '\n' +
              'Se não for possível terminar a verificação, clique em Interromper instalação.',
          batteryMain: 'Verifique a conexão do cabo',
          batteryDesc: 'O cabo de alimentação pode ter sido desconectado durante a instalação.\n' +
              '\nCertifique-se também de não ter trocado os cabos de energia e ignição.'
        }
      },
      buttonNext: 'Avançar',
      buttonAbort: 'Interromper instalação',
      bottomMessage: 'Encontrou algum problema?',
      bottomLink: 'Regressar aos guias',
      retryButton: 'Tentar novamente',
      successInstallation: 'Configuração de OBU concluída com êxito',
      obuReady: 'A OBU funciona corretamente\n' +
          '\n' +
          'Instalação terminada',
      newInstallation: 'Nova instalação'
    },
    admin: {
      success: 'Tabela atualizada',
      nav: {
        title: 'Portal de administrador',
        eventsLink: 'Eventos',
        adminMng: 'Gestão Admin'
      },
      eventsPage: {
        title: 'Eventos instalações',
        search: 'Procurar Cell_no, OBU ID...',
        recordInfo: 'Registos mostrados',
        buttons: {
          next: 'Seguinte',
          previous: 'Anterior',
          autoRefresh: 'Atualização automática'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Em curso'
        },
        filters: {
          buttons: {
            clear: 'Limpar filtros',
            collapse: 'Filtros'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Código de operação',
              startTs: 'De:',
              startTime: 'Tempo inicial:',
              endTs: 'A:',
              endTime: 'Tempo final:',
              plate: 'Matrícula:',
              status: 'Estado:'
            },
            placeholders: {
              obu: 'Selecionar ID da unidade de bordo',
              opcode: 'Selecionar operação',
              startTs: 'Selecionar a data de início',
              endTs: 'Selecionar a data de fim',
              noObu: 'Nenhum id OBU disponível',
              noOpcode: 'Nenhum opcode disponível',
              allOpts: 'Todos',
              plate: 'Seleção de matrícula',
              noPlate: 'Nenhuma matrícula disponível',
              searchPlate: 'Procurar matrícula...',
              status: 'Seleção de estado',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Em curso'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Número tel.',
          obu_id: 'ID Unidade de bordo (OBU)',
          plate_number: 'Matrícula',
          timestamp: 'Data-Hora',
          opcode_desc: 'Descr. Opcode',
          description: 'Descrição',
          status: 'Estado'
        },
        opcodes: {
          0: 'Selecionar operation code',
          1: 'A iniciar sessão...',
          2: 'Início de sessão OK',
          3: 'Início de sessão falhou',
          4: 'OTP em curso',
          5: 'OTP OK',
          6: 'OTP falhou',
          7: 'PIN em curso',
          8: 'PIN OK',
          9: 'PIN falhou',
          10: 'Registo em curso',
          11: 'Registo OK',
          12: 'Registo falhou',
          13: 'Pedido de novo OTP OK',
          14: 'Pedido de novo OTP falhou',
          15: 'Atualização dos dados do utilizador em curso',
          16: 'Atualização dos dados do utilizador OK',
          17: 'Atualização dos dados do utilizador falhou',
          18: 'Associação OBU-Matrícula em curso',
          19: 'Associação OBU-Matrícula OK',
          20: 'Associação OBU-Matrícula falhou',
          21: 'Instalação da unidade IPS em curso',
          22: 'Instalação da unidade IPS OK',
          23: 'Configuração K1 em curso',
          24: 'Configuração K1 OK',
          25: 'Configuração K1 - First Run: erro no PM',
          26: 'Configuração K1 - First Run: sem comunicação',
          27: 'Configuração K1 - First Run: Branding incorreto',
          28: 'Configuração K1 - Branding: erro no serviço',
          29: 'Configuração K1 - Branding: tempo limite de operação excedido',
          30: 'Configuração K1 - Vehicle Config: erro de configuração',
          31: 'Configuração K1 - Vehicle Config: tempo limite de operação excedido',
          32: 'Configuração K1 - Tolling DSRC: erro em pelo menos um serviço',
          33: 'Configuração K1 - Tolling DSRC: tempo limite de operação excedido',
          34: 'Configuração K1 - Tolling GNSS: erro em pelo menos um serviço',
          35: 'Configuração K1 - Tolling GNSS: tempo limite de operação excedido',
          36: 'Configuração K1 - Service Activation: erro em pelo menos um serviço',
          37: 'Configuração K1 - Service Activation: tempo limite de operação excedido',
          38: 'Configuração K1 - Reboot: reinício falhou',
          39: 'Configuração K1 - Power Switch: variação de PM falhou',
          40: 'K1 Key em curso',
          41: 'K1 Key OK',
          42: 'K1 Key falhou',
          43: 'Instalação em curso',
          44: 'Instalação terminada',
          45: 'OBU sem sinais luminosos',
          46: 'Cabo USB ausente',
          47: 'Dual lock ausente',
          48: 'Cabo USB muito curto',
          49: 'A obu não comunica devidamente com sinais luminosos e sonoros',
          50: 'Falha no processo de configuração',
          51: 'A OBU não recebe corretamente o sinal de ativação',
          52: 'Outro cenário de falha',
          53: 'O start_time da associação era NULL',
        }
      },
      adminMngPage: {
        title: 'Gestão de administradores',
        search: 'Procurar...',
        buttons: {
          add: 'Adicionar'
        },
        table: {
          actions: 'Ações',
          phone: 'Telefone',
          firstName: 'Nome',
          lastName: 'Apelido',
          company: 'Companhia',
          removed: 'Desativado',
        },
        modal: {
          dataLabels: {
            phone: 'Telefone',
            confPhone: 'Confirmar telefone',
            firstName: 'Nome',
            lastName: 'Apelido',
            email: 'E-mail',
            company: 'Empresa',
            toggle: 'Desativar conta',
          },
          addAdmin: {
            title: 'Adicionar conta de administrador',
            body: 'Introduza as informações indicadas nos campos seguintes:',
            buttons: {
              cancel: 'Cancelar',
              proceed: 'Introduzir'
            }
          },
          modAdmin: {
            title: 'Editar conta de administrador',
            body: 'Editar as seguintes informações da conta:',
            buttons: {
              cancel: 'Cancelar',
              proceed: 'Atualizar'
            }
          },
          deleteAdmin: {
            title: 'Eliminar conta de administrador',
            body: 'Tem a certeza de que pretende remover esta conta da lista de administradores?',
            buttons: {
              cancel: 'Não',
              proceed: 'Sim'
            }
          },
          success: {
            title: 'Dados da conta registados',
            body: 'Conta de administrador guardada com êxito'
          },
          error: {
            title: 'Dados inválidos',
            body: 'Administrador já presente na lista'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtros',
            clear: 'Limpar filtros'
          },
          dropdowns: {
            labels: {
              removed: 'Conta desativada',
              all: 'Todos'
            }
          }
        }
      },

    },
    months: {
      january: 'Janeiro',
      february: 'Fevereiro',
      march: 'Março',
      april: 'Abril',
      may: 'Maio',
      june: 'Junho',
      july: 'Julho',
      august: 'Agosto',
      september: 'Setembro',
      october: 'Outubro',
      november: 'Novembro',
      december: 'Dezembro'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PIN incorreto',
        1212: 'Utilizador não encontrado',
        1226: 'Nome de utilizador já utilizado',
        1231: 'Número de telefone inválido',
        1400: 'Falta parâmetro',
        1106: 'OTP introduzida incorreta',
        1214: 'PIN incorreto (última tentativa disponível)',
        4000: 'ID de sessão expirou'

      },
      403: {
        0: 'Acesso recusado',
        1213: 'Utilizador bloqueado',
      },
      404: {
        0: 'Recurso não encontrado',
        3: 'Recurso não encontrado',
        5: 'Recurso não encontrado'
      },
      409: {
        7: 'Conflito durante a remoção'
      },
      413: 'Tamanho dos dados demasiado grande',
      422: {
        3: 'Erro durante a comunicação'
      },
      423: 'A password expirou',
      440: 'A sessão expirou',
      500: {
        1500: 'Erro imprevisto do servidor'
      },
      502: 'Erro 502, erro de comunicação'
    },
    path: {
      '/home': 'Home',
      '/login': 'Acesso'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  pl: {
    message: {
      contact: 'Kontakt | Kontakty',
      installer: 'Instalator | Instalatorzy',
      access: 'Logowanie - Instalator',
      newAccess: 'Nowy Logowanie - Instalator',
      phone: 'Telefon komórkowy',
      go: 'Dalej',
      retrieve: 'Odzyskaj',
      data: 'Dane',
      pin: 'PIN',
      new: 'Nowy',
      registration: 'Rejestracja',
      otp: 'OTP',
      name: 'Imię',
      surname: 'Nazwisko',
      email: 'Adres e-mail',
      confirm: 'Potwierdź',
      verify: 'Weryfikacja',
      signin: 'Zarejestruj się',
      ok: 'OK',
      completed: 'Ukończona',
      portal: 'Portal Instalatorów',
      error: 'Błąd',
      guide: 'Instrukcje',
      support: 'Wsparcie dla Instalatorów',
      installK1: 'Zainstaluj K1',
      download: 'Pobieranie',
      video: 'Wideo',
      userProfile: 'Profil Użytkownika',
      changePassword: 'Zmień hasło',
      logout: 'Wyloguj',
      update: 'Aktualizuj',
      cancel: 'Anuluj',
      stopInstallation: 'Przerwij instalację',
      obuId: 'IDENTYFIKATOR OBU',
      plate: 'Numer rejestracyjny',
      yes: 'Tak',
      failureInstallation: 'Raport o nieudanej instalacji',
      select: 'Wybierz',
      reason: 'Powód',
      dismiss: 'Anuluj',
      placement: 'Umiejscowienie',
      problem: ' Napotkany problem...',
      send: 'Wyślij',
      setting: 'Ustawienie',
      set: 'Ustaw',
      privacy: 'Zapoznałem się i akceptuję warunki użytkowania oraz politykę prywatności',
      invalidPlate: 'Wprowadzony numer tablicy rejestracyjnej jest nieprawidłowy'
    },
    info: {
      insertTelephone: 'Wprowadź swój numer telefonu. Jeśli masz już konto Telepass, możesz zalogować się przy użyciu powiązanych z nim danych uwierzytelniających.',
      insertPIN: 'Wprowadź kod PIN, aby się zalogować',
      retrieveAccessData: 'Odzyskaj dane do logowania',
      insertInformation: 'Wprowadź wskazane informacje w polach poniżej',
      accessFromNewDevice: 'Logujesz się z nowego urządzenia. \n\n Aby zweryfikować dostęp, wprowadź kod' +
          'OTP, który wysłaliśmy na Twój numer telefonu komórkowego:',
      insertOTP: 'Wprowadź kod OTP wysłany na Twój numer telefonu komórkowego',
      completeRegistrationEnteringPIN: 'Dokończ rejestrację, wprowadzając 6-cyfrowy kod PIN',
      otpNotReceivedQuestion: 'Nie otrzymałeś kodu OTP?',
      resendOTP: 'Wyślij ponownie kod OTP',
      OTPSent: 'Kod do weryfikacji Twojej tożsamości (OTP) został wysłany na Twój numer telefonu komórkowego',
      unregisteredUser: 'Użytkownik niezarejestrowany',
      performRegistration: 'Zarejestruj się, aby uzyskać dostęp do portalu',
      guideQuestion: 'Czy zapoznałeś się z instrukcjami? Rozpocznij nowy proces instalacji K1',
      showGuideBeforeInstallation: 'Pokaż instrukcje przed każdą instalacją',
      downloadInstallationManual: 'Pobierz instrukcję instalacji sprzętu i korzystania z platformy',
      playVideoGuide: 'Obejrzyj instrukcję wideo krok po kroku, przedstawiającą proces instalacji i monitorowania urządzenia',
      updateInformation: 'Zaktualizuj informacje wskazane w polach poniżej',
      beforeProceeding: 'Zanim przejdziesz dalej...',
      gsmAdvMessage: 'Aby poprawnie zakończyć proces instalacji urządzenia K1, w miejscu instalacji musi być zapewniony dobry odbiór sygnału komórkowego GSM',
      newObuInstallation: 'Instalacja nowego urządzenia',
      insertSerialAndPlateNumber: 'Wprowadź numer identyfikacyjny instalowanego urządzenia oraz numer rejestracyjny pojazdu',
      gsmQuestion: 'Nie masz tych informacji?',
      returnToGuidePage: 'Wróć do strony z instrukcjami',
      stopInstallationQuestion: 'Czy przerwać instalację?',
      fullStopInstallationQuestion: 'Czy na pewno chcesz przerwać proces instalacji?',
      selectReasonStopInstallation: 'Wybierz powód przerwania instalacji:',
      associationPlateObuNotFoundMessage: 'Upewnij się, że wprowadzone dane są poprawne, a urządzenie, które chcesz ' +
          'zainstalować, odpowiada pojazdowi klienta. Jeśli problem nie ustępuje, skontaktuj się ze swoim Partnerem Handlowym',
      selectMotivation: 'Wybierz powód...',
      returnORReplacementDevice: 'Zwrot / Wymiana urządzenia',
      returnReplaceDeviceMessage: 'Skontaktuj się z Partnerem Handlowym w celu rozpoczęcia procedury ' +
          'Zwrotu lub Wymiany urządzenia, na którym wystąpiły problemy podczas procesu instalacji',
      insertOtherMotivation: 'Wprowadź opis problemu napotkanego podczas instalacji urządzenia:',
      updatePIN: 'Wprowadź 6-cyfrowy kod PIN',
      backLogin: 'Wróć na stronę logowania',
      otpError: 'Wprowadzono nieprawidłowy lub wygasły kod OTP',
      pinError: 'Wprowadzone kody PIN nie są zgodne'
    },
    success: {
      postSuccess: 'dodano pomyślnie',
      putSuccess: 'zaktualizowano pomyślnie',
      sendMail: 'wysłany pomyślnie',
      verifiedAccess: 'Dostęp Zweryfikowany',
      verifiedOTP: 'Kod OTP zweryfikowany',
      authorizedDevice: 'Twoje urządzenie zostało autoryzowane',
      canAccessToPortal: 'Możesz teraz zalogować się do platformy instalatorów',
      associationPlateObuFound: 'Powiązanie ID-TABLICA REJESTRACYJNA zweryfikowane',
      pinChanged: 'Nowy kod PIN ustawiony poprawnie',
      operationSuccess: 'Operacja wykonana pomyślnie'
    },
    warnings: {
      accessDenied: 'Odmowa dostępu',
      alreadyDisabled: 'Użytkownik jest już dezaktywowany',
      invalid: 'Nieprawidłowy',
      sureToDelete: 'Czy na pewno chcesz usunąć element',
      continue: 'Kontynuuj',
      valid: 'Prawidłowy',
      phoneForm: 'Pole musi zawierać prawidłowy numer kierunkowy i numer telefonu',
      pinForm: 'Pole musi składać się z 6 cyfr',
      pinConfirm: 'Wprowadzone kody PIN nie są zgodne'
    },
    errors: {
      associationPlateObuNotFound: 'Nie znaleziono powiązania ID-TABLICA REJESTRACYJNA',
      error: 'Wystąpił błąd'
    },
    installSteps: {
      title: 'Instalacja nowego urządzenia',
      step1: {
        operationTitle: 'Instalacja IPS',
        message: 'Możesz teraz przystąpić do instalacji sprzętu',
        instructions: `Upewnij się, że deska rozdzielcza pojazdu jest wyłączona i zainstaluj jednostkę IPS.
                                 \n Upewnij się, że prawidłowo podłączyłeś/ podłączyłaś przewody do instalacji elektrycznej i przygotuj kabel USB w kabinie.`
      },
      step2: {
        advancedText: 'Zaawansowane',
        datePrompt: 'Wybrać datę i godzinę rozpoczęcia instalacji',
        operationTitle: 'Uruchomienie urządzenia i konfiguracja',
        message: '',
        instructions: 'Umieść OBU w kabinie, na przedniej szybie pojazdu, jak pokazano w instrukcji.',
        instructions1: 'Podłącz złącze USB do OBU i kliknij Dalej.',
        obuPosition: 'Prawidłowe umiejscowienie OBU',
        monitorMessage: 'Procedura automatycznej konfiguracji w toku...',
        configDesc: '',
        configurationInformation: 'Informacje na temat konfiguracji',
        configurationInformationMessage: 'Opis procedury konfiguracji z punktu widzenia' +
            ' informacji zwrotnej dla instalatora z HMI',
        error1: {
          errorMain: 'Nie udało się nawiązać komunikacji z urządzeniem',
          errorDesc: 'Wykonaj poniższe czynności:',
          first: 'Odłącz kabel USB od OBU',
          second: 'Ponownie sprawdź połączenia elektryczne jednostki IPS',
          third: 'Podłącz ponownie kabel USB do OBU',
          fourth: 'Sprawdź uruchomienie urządzenia:',
          subF1: 'Odtworzenie dźwięku uruchamiania;',
          subF2: 'Zapalenie się czerwonej diody statusu.',
          footMessage: `Kliknij Ponów próbę, aby powtórzyć weryfikację.
    Jeśli problem nie ustępuje, zakończ instalację.`,
        },
        error2: {
          errorMain: 'Podczas procesu konfiguracji wystąpił błąd',
          errorDesc: 'Nie można skonfigurować OBU.',
          footMessage: 'Przerwij proces instalacji urządzenia'
        },
        error3: {
          errorMain: 'Podczas procesu konfiguracji wystąpił błąd',
          errorDesc: 'Personalizacja OBU nie została zakończona.',
          footMessage: 'Przerwij proces instalacji urządzenia'
        },
      },
      step3: {
        operationTitle: 'Weryfikacja',
        instructions: 'W ostatnim kroku należy sprawdzić, czy OBU odbiera prawidłowo sygnał „aktywacji” z tablicy rozdzielczej pojazdu.\n',
        instructions1: '\nWłącz tablicę rozdzielczą i kliknij Dalej, aby rozpocząć zdalną weryfikację',
        updating: 'Aktualizacja statusu w toku...',
        error: {
          errorMain: 'Brak sygnału klucza',
          errorDesc: 'Sprawdź połączenie elektryczne sygnału klucza.\n' +
              '\n' +
              'Powtórz weryfikację statusu OBU, klikając Ponów próbę.\n' +
              '\n' +
              'Jeżeli nie można ukończyć weryfikacji, kliknij Przerwij instalację.',
          batteryMain: 'Sprawdź połączenie kabla',
          batteryDesc: 'Podczas instalacji może zostać odłączony kabel zasilający.\n' +
              '\nUpewnij się również, że nie zamieniłeś miejscami kabli zasilającego i zapłonowego.'
        }
      },
      buttonNext: 'Dalej',
      buttonAbort: 'Przerwij instalację',
      bottomMessage: 'Czy napotkałeś jakiś problem?',
      bottomLink: 'Wróć do strony z instrukcjami',
      retryButton: 'Ponów próbę',
      successInstallation: 'Konfiguracja OBU zakończona pomyślnie',
      obuReady: 'Urządzenie OBU działa prawidłowo\n' +
          '\n' +
          'Instalacja zakończona',
      newInstallation: 'Nowa instalacja'
    },
    admin: {
      success: 'Tabela zaktualizowana',
      nav: {
        title: 'Portal Administratora',
        eventsLink: 'Zdarzenia',
        adminMng: 'Zarządzanie Admin'
      },
      eventsPage: {
        title: 'Zdarzenia instalacji',
        search: 'Wyszukiwanie Cell_no, OBU ID...',
        recordInfo: 'Wyświetlane zapisy',
        buttons: {
          next: 'Następne',
          previous: 'Poprzednie',
          autoRefresh: 'Automatyczna aktualizacja'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'W toku'
        },
        filters: {
          buttons: {
            clear: 'Wyczyść filtry',
            collapse: 'Filtry'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Kod operacji',
              startTs: 'Od:',
              startTime: 'Czas rozpoczęcia:',
              endTs: 'Do:',
              endTime: 'Czas zakończenia:',
              plate: 'Numer tablicy rejestracyjnej:',
              status: 'Status:'
            },
            placeholders: {
              obu: 'Wybierz numer seryjny OBU',
              opcode: 'Wybierz operację',
              startTs: 'Wybierz datę rozpoczęcia',
              endTs: 'Wybierz datę zakończenia',
              noObu: 'Brak dostępnego identyfikatora OBU',
              noOpcode: 'Brak dostępnego kodu operacji',
              allOpts: 'Wszystkie',
              plate: 'Wybierz tablicę rejestracyjną',
              noPlate: 'Brak dostępnych tablic rejestracyjnych',
              searchPlate: 'Wyszukaj tablicę rejestracyjną...',
              status: 'Wybierz status',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'W toku'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Numer Tel.',
          obu_id: 'IDENTYFIKATOR OBU',
          plate_number: 'Numer tablicy rejestracyjnej',
          timestamp: 'Data-Godzina',
          opcode_desc: 'Opis Kod operacji',
          description: 'Opis',
          status: 'Status'
        },
        opcodes: {
          0: 'Wybierz kod operacji',
          1: 'Logowanie w toku',
          2: 'Logowanie OK',
          3: 'Logowanie KO',
          4: 'OTP w toku',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN w toku',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Rejestracja w toku',
          11: 'Rejestracja OK',
          12: 'Rejestracja KO',
          13: 'Żądanie nowego OTP OK',
          14: 'Żądanie nowego OTP KO',
          15: 'Aktualizacja danych użytkownika w toku',
          16: 'Aktualizacja danych użytkownika OK',
          17: 'Aktualizacja danych użytkownika KO',
          18: 'Powiązanie OBU-Targa w toku',
          19: 'Powiązanie OBU-Targa OK',
          20: 'Powiązanie OBU-Targa KO',
          21: 'Instalacja IPS w toku',
          22: 'Instalacja IPS OK',
          23: 'Konfiguracja K1 w toku',
          24: 'Konfiguracja K1 OK',
          25: 'Konfiguracja K1 - First Run: błąd w PM',
          26: 'Konfiguracja K1 - First Run: brak komunikacji',
          27: 'Konfiguracja K1 - First Run: Nieprawidłowy branding',
          28: 'Konfiguracja K1 - Branding: błąd w usłudze',
          29: 'Konfiguracja K1 - Branding: upłynął limit czasu operacji',
          30: 'Konfiguracja K1 - Vehicle Config: błąd konfiguracji',
          31: 'Konfiguracja K1 - Vehicle Config: upłynął limit czasu operacji',
          32: 'Konfiguracja K1 - Tolling DSRC: błąd w co najmniej jednej usłudze',
          33: 'Konfiguracja K1 - Tolling DSRC: upłynął limit czasu operacji',
          34: 'Konfiguracja K1 - Tolling GNSS: błąd w co najmniej jednej usłudze',
          35: 'Konfiguracja K1 - Tolling GNSS: upłynął limit czasu operacji',
          36: 'Konfiguracja K1 - Service Activation: błąd w co najmniej jednej usłudze',
          37: 'Konfiguracja K1 - Service Activation: upłynął limit czasu operacji',
          38: 'Konfiguracja K1 - Reboot: ponowne uruchomienie nie powiodło się',
          39: 'Konfiguracja K1 - Power Switch: zmiana PM nie powiodła się',
          40: 'K1 Key w toku',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Instalacja w toku',
          44: 'Instalacja ukończona',
          45: 'OBU bez sygnałów świetlnych',
          46: 'Brak kabla USB',
          47: 'Brak dual lock',
          48: 'Zbyt krótki kabel USB',
          49: 'OBU nie komunikuje się prawidłowo za pomocą sygnałów świetlnych i dźwiękowych',
          50: 'Niepowodzenie procesu konfiguracji',
          51: 'OBU nie odbiera prawidłowo sygnału aktywacji',
          52: 'Inny scenariusz niepowodzenia',
          53: 'Start_time powiązania miał wartość NULL',
        }
      },
      adminMngPage: {
        title: 'Zarządzanie Administratorami',
        search: 'Wyszukaj...',
        buttons: {
          add: 'Dodaj'
        },
        table: {
          actions: 'Działania',
          phone: 'Telefon',
          firstName: 'Imię',
          lastName: 'Nazwisko',
          company: 'Firma',
          removed: 'Dezaktywowany',
        },
        modal: {
          dataLabels: {
            phone: 'Telefon',
            confPhone: 'Potwierdź telefon',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            email: 'Adres e-mail',
            company: 'Przedsiębiorstwo',
            toggle: 'Dezaktywuj konto',
          },
          addAdmin: {
            title: 'Dodaj Konto Administratora',
            body: 'Wprowadź wskazane informacje w polach poniżej:',
            buttons: {
              cancel: 'Anuluj',
              proceed: 'Wprowadź'
            }
          },
          modAdmin: {
            title: 'Edytuj Konto Administratora',
            body: 'Edytuj następujące informacje o koncie:',
            buttons: {
              cancel: 'Anuluj',
              proceed: 'Aktualizuj'
            }
          },
          deleteAdmin: {
            title: 'Usuwanie Konta Administratora',
            body: 'Czy na pewno chcesz usunąć to konto z listy administratorów?',
            buttons: {
              cancel: 'Nie',
              proceed: 'Tak'
            }
          },
          success: {
            title: 'Dane konta zarejestrowane',
            body: 'Konto Administratora zostało pomyślnie zapisane'
          },
          error: {
            title: 'Dane nieprawidłowe',
            body: 'Administrator jest już na liście'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtry',
            clear: 'Wyczyść filtry'
          },
          dropdowns: {
            labels: {
              removed: 'Konto dezaktywowane',
              all: 'Wszystkie'
            }
          }
        }
      },

    },
    months: {
      january: 'Styczeń',
      february: 'Luty',
      march: 'Marzec',
      april: 'Kwiecień',
      may: 'Maj',
      june: 'Czerwiec',
      july: 'Lipiec',
      august: 'Sierpień',
      september: 'Wrzesień',
      october: 'Październik',
      november: 'Listopad',
      december: 'Grudzień'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Nieprawidłowy PIN',
        1212: 'Nie znaleziono użytkownika',
        1226: 'Nazwa użytkownika jest już używana',
        1231: 'Nieprawidłowy numer telefonu',
        1400: 'Brak parametru',
        1106: 'Wprowadzono błędny OTP',
        1214: 'Błędny PIN (pozostała ostatnia próba)',
        4000: 'Wygasł ID sesji'

      },
      403: {
        0: 'Odmowa dostępu',
        1213: 'Użytkownik zablokowany',
      },
      404: {
        0: 'Nie znaleziono zasobu',
        3: 'Nie znaleziono zasobu',
        5: 'Nie znaleziono zasobu'
      },
      409: {
        7: 'Konflikt podczas usuwania'
      },
      413: 'Zbyt duży rozmiar danych',
      422: {
        3: 'Błąd podczas komunikacji'
      },
      423: 'Hasło wygasło',
      440: 'Sesja wygasła',
      500: {
        1500: 'Nieoczekiwany błąd po stronie serwera'
      },
      502: 'Błąd 502, błąd komunikacji'
    },
    path: {
      '/home': 'Strona główna',
      '/login': 'Logowanie'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  bg: {
    message: {
      contact: 'Контакт | Контакти',
      installer: 'Инсталатор | Инсталатори',
      access: 'Достъп на инсталатора',
      newAccess: 'Нов Достъп на инсталатора',
      phone: 'Мобилен телефон',
      go: 'Напред',
      retrieve: 'Възстанови',
      data: 'Данни',
      pin: 'PIN код',
      new: 'Нов',
      registration: 'Регистрация',
      otp: 'OTP',
      name: 'Име',
      surname: 'Фамилия',
      email: 'Имейл',
      confirm: 'Потвърждение',
      verify: 'Проверка',
      signin: 'Регистрирай се',
      ok: 'OK',
      completed: 'Завършена',
      portal: 'Портал за инсталатори',
      error: 'Грешка',
      guide: 'Ръководства',
      support: 'за инсталатори',
      installK1: 'Инсталирай K1',
      download: 'Изтегляне',
      video: 'Видео',
      userProfile: 'Профил на потребителя',
      changePassword: 'Промяна на парола',
      logout: 'Изход',
      update: 'Актуализиране',
      cancel: 'Отмяна',
      stopInstallation: 'Прекратяване на инсталирането',
      obuId: 'ID номер на бордовото устройство',
      plate: 'Рег. номер',
      yes: 'Да',
      failureInstallation: 'Съобщение за неуспешно инсталиране',
      select: 'Избиране',
      reason: 'Причина',
      dismiss: 'Отмяна',
      placement: 'Разположение',
      problem: ' Възникна проблем...',
      send: 'Изпращане',
      setting: 'Настройка',
      set: 'Задаване',
      privacy: 'Прочетох и приемам общите условия за ползване и поверителност',
      invalidPlate: 'Въведеният регистрационен номер не е валиден'
    },
    info: {
      insertTelephone: 'Въведи телефонния си номер. Ако вече имаш Telepass акаунт, можеш да влезеш с данните за достъп, свързани с него.',
      insertPIN: 'Въведи PIN кода, за да влезеш',
      retrieveAccessData: 'Възстанови данните за достъп',
      insertInformation: 'Въведи посочената информация в полетата по-долу',
      accessFromNewDevice: 'Осъществяваш достъп от ново устройство. \n\n За да потвърдиш достъпа, въведи кода' +
          ' OTP, който изпратихме на номера на мобилния ти телефон:',
      insertOTP: 'Въведи OTP кода, изпратен на мобилния ти номер',
      completeRegistrationEnteringPIN: 'Завърши регистрацията, като въведеш цифров PIN код от 6 цифри',
      otpNotReceivedQuestion: 'Не си получил OTP кода?',
      resendOTP: 'Повторно изпращане на OTP код',
      OTPSent: 'На номера на мобилния ти телефон е изпратен код за потвърждение на самоличността (OTP)',
      unregisteredUser: 'Нерегистриран потребител',
      performRegistration: 'Регистрирай се, за да получиш достъп до портала',
      guideQuestion: 'Прочете ли ръководствата? Започване на нов процес на инсталиране на K1',
      showGuideBeforeInstallation: 'Показване на ръководствата преди всяко инсталиране',
      downloadInstallationManual: 'Изтегли ръководството за инсталиране на хардуера и използване на платформата',
      playVideoGuide: 'Гледай видео ръководството стъпка по стъпка за процеса на инсталиране и наблюдение на устройството',
      updateInformation: 'Актуализирай посочената информация в полетата по-долу',
      beforeProceeding: 'Преди да продължиш...',
      gsmAdvMessage: 'За да можеш успешно да приключиш процеса на инсталиране на устройство K1, на мястото на инсталиране трябва да има добро приемане на клетъчния GSM сигнал',
      newObuInstallation: 'Инсталиране на ново устройство',
      insertSerialAndPlateNumber: 'Въведи идентификатора на устройството за инсталиране и регистрационния номер на автомобила',
      gsmQuestion: 'Не разполагаш с тези данни?',
      returnToGuidePage: 'Обратно към ръководствата',
      stopInstallationQuestion: 'Да се прекрати ли инсталирането?',
      fullStopInstallationQuestion: 'Сигурен ли си, че искаш да прекратиш процеса на инсталиране?',
      selectReasonStopInstallation: 'Избери причината за прекратяване на инсталирането:',
      associationPlateObuNotFoundMessage: 'Увери се, че си въвел правилно данните и че устройството, което смяташ ' +
          'да инсталираш, отговаря на автомобила на клиента. Ако проблемът продължава, свържи се с твоя търговски партньор',
      selectMotivation: 'Избиране на причина...',
      returnORReplacementDevice: 'Връщане / Подмяна на устройството',
      returnReplaceDeviceMessage: 'Каним те да се свържеш с твоя търговски партньор, за да започнеш процедура ' +
          'Sза Връщане или Подмяна на устройството, в което си установил проблеми при процеса на инсталиране',
      insertOtherMotivation: 'Въведи описание на проблема, възникнал по време на инсталиране на устройството:',
      updatePIN: 'Въведи цифров PIN код от 6 цифри',
      backLogin: 'Назад към страницата за вход',
      otpError: 'Въведен е невалиден или изтекъл OTP код',
      pinError: 'Въведените PIN кодове не съвпадат'
    },
    success: {
      postSuccess: 'добавен успешно',
      putSuccess: 'актуализиран успешно',
      sendMail: 'изпратен успешно',
      verifiedAccess: 'Достъпът е потвърден',
      verifiedOTP: 'OTP кодът е потвърден',
      authorizedDevice: 'Твоето устройство е оторизирано',
      canAccessToPortal: 'Вече имаш достъп до платформата за инсталиране',
      associationPlateObuFound: 'Свързването ID-РЕГ.НОМЕР е потвърдено',
      pinChanged: 'Новият PIN код е въведен правилно',
      operationSuccess: 'Операцията е извършена успешно'
    },
    warnings: {
      accessDenied: 'Отказан достъп',
      alreadyDisabled: 'Потребителят вече е деактивиран',
      invalid: 'Невалиден | Невалидна',
      sureToDelete: 'Сигурно ли е, че искаш да премахнеш елемента',
      continue: 'Продължи',
      valid: 'Валиден | Валидна',
      phoneForm: 'Полето трябва да е съставено от код за населено място и валиден телефонен номер',
      pinForm: 'Полето трябва да е съставено от 6 цифри',
      pinConfirm: 'Въведените PIN кодове не съвпадат'
    },
    errors: {
      associationPlateObuNotFound: 'Свързването ID-РЕГ.НОМЕР не е намерено',
      error: 'Възникна грешка'
    },
    installSteps: {
      title: 'Инсталиране на ново устройство',
      step1: {
        operationTitle: 'Инсталиране на IPS',
        message: 'Вече можеш да продължиш с инсталирането на хардуера',
        instructions: `Увери се, че приборния панел на автомобила е изключен и изпълни инсталирането на модула IPS. 
                                 \n Увери се, че си свързал правилно кабелите към блока за управление и подготви USB кабела вътре в купето.`
      },
      step2: {
        advancedText: 'Други',
        datePrompt: 'Избери дата и час на стартиране на инсталирането',
        operationTitle: 'Стартиране на устройството и конфигуриране',
        message: '',
        instructions: 'Разположи бордовото устройство в купето, на предното стъкло на превозното средство, както е посочено в ръководството.',
        instructions1: 'Свържи USB конектора към бордовото устройство и щракни на Продължи.',
        obuPosition: 'Правилно разположение на бордовото устройство',
        monitorMessage: 'Извършва се процес на автоматично конфигуриране...',
        configDesc: '',
        configurationInformation: 'Информация за конфигурацията',
        configurationInformationMessage: 'Описание на процеса на конфигурация от гледна точка' +
            ' на обратната връзка към инсталатора от HMI',
        error1: {
          errorMain: 'Не беше възможна комуникация с устройството',
          errorDesc: 'Моля, следвай стъпките по-долу:',
          first: 'Извади USB кабела от бордовото устройство',
          second: 'Провери отново електрическите връзки на модула IPS',
          third: 'Свържи отново USB кабела към бордовото устройство',
          fourth: 'Провери стартирането на устройството:',
          subF1: 'Издаване на сигнал за начало;',
          subF2: 'Светване на червения индикатор за състояние.',
          footMessage: `Щракни на Опитай отново, за да повториш проверката.
          Ако проблемът продължава, прекрати инсталирането.`,
        },
        error2: {
          errorMain: 'Възникна грешка по време на процеса на конфигуриране',
          errorDesc: 'Бордовото устройство не може да се конфигурира.',
          footMessage: 'Прекрати процеса на инсталиране на устройството'
        },
        error3: {
          errorMain: 'Възникна грешка по време на процеса на конфигуриране',
          errorDesc: 'Персонализирането на OBU не е завършено.',
          footMessage: 'Прекрати процеса на инсталиране на устройството'
        },
      },
      step3: {
        operationTitle: 'Проверка',
        instructions: 'Като последна стъпка трябва да провериш дали бордовото устройство приема правилно сигнала за „активиране“ на приборния панел на автомобила.\n',
        instructions1: '\nВключи таблото и щракни на Продължи, за да стартираш проверката дистанционно',
        updating: 'Състоянието се актуализира...',
        error: {
          errorMain: 'Няма ключов сигнал',
          errorDesc: 'Провери електрическото свързване на ключовия сигнал.\n' +
              '\n' +
              'Повтори проверката на състоянието на бордовото устройство, като щракнеш на Опитай отново.\n' +
              '\n' +
              'Ако завършването на проверката не е възможно, щракни на Прекрати инсталирането.',
          batteryMain: 'Проверете връзката на кабела',
          batteryDesc: 'Захранващият кабел може да е бил изключен по време на инсталацията.\n' +
              '\nУверете се също, че не сте разменили захранващия и запалващия кабели.'
        }
      },
      buttonNext: 'Продължи',
      buttonAbort: 'Прекратяване на инсталирането',
      bottomMessage: 'Срещна ли някакъв проблем?',
      bottomLink: 'Обратно към ръководствата',
      retryButton: 'Опитай отново',
      successInstallation: 'Конфигурирането на бордовото устройство е завършено успешно',
      obuReady: 'Бордовото устройство работи правилно\n' +
          '\n' +
          'Инсталирането е завършено',
      newInstallation: 'Ново инсталиране'
    },
    admin: {
      success: 'Актуализирана таблица',
      nav: {
        title: 'Администраторски портал',
        eventsLink: 'Събития',
        adminMng: 'Управление Admin'
      },
      eventsPage: {
        title: 'Инсталационни събития',
        search: 'Търсене Cell_no, OBU ID...',
        recordInfo: 'Изведени записи',
        buttons: {
          next: 'Следващ',
          previous: 'Предишен',
          autoRefresh: 'Автоматично актуализиране'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Изпълнява се'
        },
        filters: {
          buttons: {
            clear: 'Изчистване на филтри',
            collapse: 'Филтри'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Код на операция',
              startTs: 'От:',
              startTime: 'Начален час:',
              endTs: 'До:',
              endTime: 'Краен час:',
              plate: 'Рег. номер:',
              status: 'Състояние:'
            },
            placeholders: {
              obu: 'Избери сериен номер на бордово устройство',
              opcode: 'Избери операция',
              startTs: 'Избери начална дата',
              endTs: 'Избери крайна дата',
              noObu: 'Няма наличен id номер на бордово устройство',
              noOpcode: 'Няма наличен opcode',
              allOpts: 'Всички',
              plate: 'Избери регистрационен номер',
              noPlate: 'Няма наличен регистрационен номер',
              searchPlate: 'Търсене на Рег. номер...',
              status: 'Избери Състояние',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Изпълнява се'
            },
          }
        },
        table: {
          id: 'ID номер',
          cell_no: 'Тел. номер',
          obu_id: 'ID номер на бордовото устройство',
          plate_number: 'Рег. номер',
          timestamp: 'Дата-Час',
          opcode_desc: 'Описание на Opcode',
          description: 'Описание',
          status: 'Състояние'
        },
        opcodes: {
          0: 'Избери код на операция',
          1: 'Извършва се влизане',
          2: 'Login OK',
          3: 'Login KO',
          4: 'Изпълнява се OTP',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'Изпълнява се PIN',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Извършва се регистрация',
          11: 'Регистрация OK',
          12: 'Регистрация KO',
          13: 'Заявка на нов OTP OK',
          14: 'Заявка на нов OTP KO',
          15: 'Извършва се актуализация на потребителски данни',
          16: 'Актуализация на потребителски данни OK',
          17: 'Актуализация на потребителски данни KO',
          18: 'Извършва се свързване OBU-Рег.номер',
          19: 'Свързване OBU-Рег.номер OK',
          20: 'Свързване OBU-Рег.номер KO',
          21: 'Извършва се инсталиране на IPS',
          22: 'Инсталиране на IPS OK',
          23: 'Извършва се конфигуриране на K1',
          24: 'Конфигурация на K1 OK',
          25: 'Конфигурация K1 - First Run: грешка на PM',
          26: 'Конфигурация K1 - First Run: няма комуникация',
          27: 'Конфигурация K1 - First Run: Грешно брандиране',
          28: 'Конфигурация K1 - Branding: грешка при услугата',
          29: 'Конфигурация K1 - Branding: времето за операцията изтече',
          30: 'Конфигурация K1 - Vehicle Config: грешка в конфигурацията',
          31: 'Конфигурация K1 - Vehicle Config: времето за операцията изтече',
          32: 'Конфигурация K1 - Tolling DSRC: грешка при поне една услуга',
          33: 'Конфигурация K1 - Tolling DSRC: времето за операцията изтече',
          34: 'Конфигурация K1 - Tolling GNSS: грешка при поне една услуга',
          35: 'Конфигурация K1 - Tolling GNSS: времето за операцията изтече',
          36: 'Конфигурация K1 - Service Activation: грешка при поне една услуга',
          37: 'Конфигурация K1 - Service Activation: времето за операцията изтече',
          38: 'Конфигурация K1 - Reboot: рестартирането е неуспешно',
          39: 'Конфигурация K1 - Power Switch: промяната на PM е неуспешна',
          40: 'K1 Key се изпълнява',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Извършва се инсталиране',
          44: 'Инсталирането е завършено',
          45: 'OBU без светлинни сигнали',
          46: 'USB кабелът липсва',
          47: 'Няма двойно заключване',
          48: 'USB кабелът е твърде къс',
          49: 'Бордовото устройство не комуникира правилно със светлинни и звукови сигнали',
          50: 'Неуспешен процес на конфигуриране',
          51: 'Бордовото устройство не получава правилно сигнала за активиране',
          52: 'Друг сценарий за неуспех',
          53: 'start_time на свързването беше NULL',
        }
      },
      adminMngPage: {
        title: 'Управление на администратори',
        search: 'Търсене...',
        buttons: {
          add: 'Добави'
        },
        table: {
          actions: 'Действия',
          phone: 'Телефон',
          firstName: 'Име',
          lastName: 'Фамилия',
          company: 'Компания',
          removed: 'Деактивиран',
        },
        modal: {
          dataLabels: {
            phone: 'Телефон',
            confPhone: 'Потвърди телефон',
            firstName: 'Име',
            lastName: 'Фамилия',
            email: 'Имейл',
            company: 'Фирма',
            toggle: 'Деактивирай акаунта',
          },
          addAdmin: {
            title: 'Добави акаунт на администратор',
            body: 'Въведи посочената информация в полетата по-долу:',
            buttons: {
              cancel: 'Отмяна',
              proceed: 'Въвеждане'
            }
          },
          modAdmin: {
            title: 'Промени акаунта на администратор',
            body: 'Промени следните данни на акаунта:',
            buttons: {
              cancel: 'Отмяна',
              proceed: 'Актуализиране'
            }
          },
          deleteAdmin: {
            title: 'Изтриване на акаунт на администратор',
            body: 'Сигурен ли си, че искаш да изтриеш този акаунт от списъка на администраторите?',
            buttons: {
              cancel: 'Не',
              proceed: 'Да'
            }
          },
          success: {
            title: 'Регистрирани данни на акаунта',
            body: 'Акаунтът на администратор е запазен успешно'
          },
          error: {
            title: 'Невалидни данни',
            body: 'Администраторът вече присъства в списъка'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Филтри',
            clear: 'Изчистване на филтри'
          },
          dropdowns: {
            labels: {
              removed: 'Деактивиран акаунт',
              all: 'Всички'
            }
          }
        }
      },

    },
    months: {
      january: 'Януари',
      february: 'Февруари',
      march: 'Март',
      april: 'Април',
      may: 'Май',
      june: 'Юни',
      july: 'Юли',
      august: 'Август',
      september: 'Септември',
      october: 'Октомври',
      november: 'Ноември',
      december: 'Декември'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Неправилен PIN код',
        1212: 'Потребителят не е намерен',
        1226: 'Потребителското име вече е използвано',
        1231: 'Невалиден телефонен номер',
        1400: 'Липсва параметър',
        1106: 'OTP е въведен неправилно',
        1214: 'Грешен PIN код (остава последен опит)',
        4000: 'Изтекъл сесиен ID номер'

      },
      403: {
        0: 'Отказан достъп',
        1213: 'Блокиран потребител',
      },
      404: {
        0: 'Ресурсът не е намерен',
        3: 'Ресурсът не е намерен',
        5: 'Ресурсът не е намерен'
      },
      409: {
        7: 'Конфликт по време на премахване'
      },
      413: 'Размерът на данните е твърде голям',
      422: {
        3: 'Грешка по време на комуникация'
      },
      423: 'Паролата е изтекла',
      440: 'Сесията е изтекла',
      500: {
        1500: 'Неочаквана грешка от сървъра'
      },
      502: 'Грешка 502, комуникационна грешка'
    },
    path: {
      '/home': 'Начало',
      '/login': 'Достъп'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  cs: {
    message: {
      contact: 'Kontakt | Kontakty',
      installer: 'Technik | Technici',
      access: 'Přihlášení Technika',
      newAccess: 'Nový Přihlášení Technika',
      phone: 'Mobilní telefon',
      go: 'Dále',
      retrieve: 'Získat',
      data: 'Údaje',
      pin: 'PIN',
      new: 'Nový',
      registration: 'Registrace',
      otp: 'OTP',
      name: 'Jméno',
      surname: 'Příjmení',
      email: 'E-mail',
      confirm: 'Potvrdit',
      verify: 'Ověření',
      signin: 'Zaregistrovat se',
      ok: 'OK',
      completed: 'Dokončena',
      portal: 'Portál Techniků',
      error: 'Chyba',
      guide: 'Nápověda',
      support: 'Podpora pro Techniky',
      installK1: 'Nainstalovat K1',
      download: 'Stáhnout',
      video: 'Video',
      userProfile: 'Uživatelský Profil',
      changePassword: 'Změnit heslo',
      logout: 'Odhlášení',
      update: 'Aktualizovat',
      cancel: 'Zrušit',
      stopInstallation: 'Přerušit instalaci',
      obuId: 'ID jednotky OBU',
      plate: 'SPZ',
      yes: 'Ano',
      failureInstallation: 'Zpráva Instalace Selhala',
      select: 'Zvolit',
      reason: 'Důvod',
      dismiss: 'Zrušit',
      placement: 'Umístění',
      problem: ' Zjištěný problém...',
      send: 'Odeslat',
      setting: 'Nastavení',
      set: 'Nastavit',
      privacy: 'Přečetl(a) jsem si podmínky používání a ochrany osobních údajů a souhlasím s nimi',
      invalidPlate: 'Zadaná SPZ je neplatná'
    },
    info: {
      insertTelephone: 'Zadejte číslo svého telefonu. Pokud již máte účet Telepass, můžete se přihlásit pomocí s ním propojených přihlašovacích údajů.',
      insertPIN: 'Zadejte PIN pro přihlášení',
      retrieveAccessData: 'Získat nové přihlašovací údaje',
      insertInformation: 'Zadejte informace uvedené v následujících polích',
      accessFromNewDevice: 'Právě se přihlašujete k novému zařízení. \n\n Pro kontrolu přihlášení zadejte' +
          ' OTP kód, který jsme zaslali na vaše telefonní číslo:',
      insertOTP: 'Zadejte OTP kód, který byl zaslán na vaše telefonní číslo',
      completeRegistrationEnteringPIN: 'Dokončete registraci zadáním šestimístného identifikačního čísla PIN',
      otpNotReceivedQuestion: 'Neobdrželi jste OTP?',
      resendOTP: 'Poslat OTP znovu',
      OTPSent: 'Poslali jsme na vaše telefonní číslo kód pro ověření vaší totožnosti (OTP)',
      unregisteredUser: 'Neregistrovaný uživatel',
      performRegistration: 'Zaregistrujte se a získejte přístup k portálu',
      guideQuestion: 'Přečetl(a) jste si obsah nápovědy? Spusťte nový instalační proces K1',
      showGuideBeforeInstallation: 'Před každou instalací zobrazit nápovědu',
      downloadInstallationManual: 'Stáhněte si návod k instalaci softwaru a používání platformy',
      playVideoGuide: 'Podívejte se na video s detailním popisem instalačního postupu a monitorování zařízení',
      updateInformation: 'Aktualizujte informace uvedené v následujících polích',
      beforeProceeding: 'Před pokračováním...',
      gsmAdvMessage: 'Pro správné dokončení instalačního procesu zařízení K1 se na místě instalace vyžaduje dobrá kvalita mobilního signálu GSM',
      newObuInstallation: 'Instalace nového zařízení',
      insertSerialAndPlateNumber: 'Zadejte identifikační číslo instalovaného zařízení a státní poznávací značku vozidla',
      gsmQuestion: 'Nemáte tyto informace k dispozici?',
      returnToGuidePage: 'Vraťte se k nápovědě',
      stopInstallationQuestion: 'Přerušit instalaci?',
      fullStopInstallationQuestion: 'Určitě chcete přerušit instalační proces?',
      selectReasonStopInstallation: 'Vyberte důvod přerušení instalace:',
      associationPlateObuNotFoundMessage: 'Ujistěte se, že jste správně zadali údaje a že zařízení, které se chystáte ' +
          'nainstalovat, odpovídá vozidlu zákazníka. Pokud problém přetrvává, kontaktujte svého obchodního partnera',
      selectMotivation: 'Zvolit důvod...',
      returnORReplacementDevice: 'Vrácení / Výměna zařízení',
      returnReplaceDeviceMessage: 'Kontaktujte prosím svého obchodního partnera, který vám sdělí, jak postupovat ' +
          'při vrácení nebo výměně zařízení, u kterého byly během instalace zjištěny problémy',
      insertOtherMotivation: 'Uveďte popis problému zjištěného během instalace zařízení:',
      updatePIN: 'Zadejte šestimístný číselný kód (PIN)',
      backLogin: 'Vraťte se na přihlašovací stránku',
      otpError: 'Zadaný OTP kód je neplatný, nebo vypršela jeho platnost',
      pinError: 'Zadané PIN kódy nejsou shodné'
    },
    success: {
      postSuccess: 'přidáno úspěšně',
      putSuccess: 'aktualizováno úspěšně',
      sendMail: 'odeslán úspěšně',
      verifiedAccess: 'Přihlášení Ověřené',
      verifiedOTP: 'Kód OTP Ověřený',
      authorizedDevice: 'Vaše zařízení bylo autorizováno',
      canAccessToPortal: 'Nyní můžete přistoupit na platformu techniků',
      associationPlateObuFound: 'Propojení ID-SPZ ověřené',
      pinChanged: 'Nový PIN správně nastavený',
      operationSuccess: 'Operace byla provedena úspěšně'
    },
    warnings: {
      accessDenied: 'Přístup zamítnut',
      alreadyDisabled: 'Uživatel je již deaktivován',
      invalid: 'Neplatný | Neplatná',
      sureToDelete: 'Opravdu chcete odstranit tento prvek',
      continue: 'Pokračovat',
      valid: 'Platný | Platná',
      phoneForm: 'Pole musí být sestaveno z platného předčíslí a telefonního čísla',
      pinForm: 'Pole musí být sestaveno z 6 čísel',
      pinConfirm: 'Zadané PIN kódy nejsou shodné'
    },
    errors: {
      associationPlateObuNotFound: 'Nenalezeno propojení ID-SPZ',
      error: 'Došlo k chybě'
    },
    installSteps: {
      title: 'Instalace nového zařízení',
      step1: {
        operationTitle: 'Instalace IPS',
        message: 'Nyní můžete provést instalaci hardwaru',
        instructions: `Zkontrolujte, jestli je přístrojová deska vozidla vypnutá, a proveďte instalaci jednotky IPS.
                                \n Zkontrolujte správné zapojení kabelů k elektrickému rozvodu a umístěte USB kabel v kabině vozidla.`
      },
      step2: {
        advancedText: 'Pokročilé',
        datePrompt: 'Zvolte datum a čas spuštění instalace',
        operationTitle: 'Spuštění zařízení a konfigurace',
        message: '',
        instructions: 'Umístěte OBU do kabiny vozidla, na přední sklo, jak je to znázorněno v návodu.',
        instructions1: 'Připojte konektor USB k OBU a klikněte na pokračovat.',
        obuPosition: 'Správné umístění OBU',
        monitorMessage: 'Probíhá automatický konfigurační proces...',
        configDesc: '',
        configurationInformation: 'Informace o konfiguraci',
        configurationInformationMessage: 'Popis konfiguračního postupu z hlediska' +
            'zpětné vazby HMI pro technika',
        error1: {
          errorMain: 'Komunikace se zařízením nemožná',
          errorDesc: 'Proveďte následující postup:',
          first: 'Odpojte kabel USB od OBU',
          second: 'Překontrolujte elektrické zapojení jednotky IPS',
          third: 'Znovu připojte kabel USB k OBU',
          fourth: 'Zkontrolujte spuštění zařízení:',
          subF1: 'Reprodukce zvukového spouštěcího signálu;',
          subF2: 'Rozsvícení stavové červené LED diody.',
          footMessage: `Klikněte na zkusit znovu pro zopakování kontroly.
    Pokud problém přetrvává, ukončete instalaci.`,
        },
        error2: {
          errorMain: 'Došlo k chybě během konfiguračního procesu',
          errorDesc: 'Konfigurace OBU nemožná.',
          footMessage: 'Přerušte instalační proces zařízení'
        },
        error3: {
          errorMain: 'Došlo k chybě během konfiguračního procesu',
          errorDesc: 'Personalizace OBU nebyla dokončena.',
          footMessage: 'Přerušte instalační proces zařízení'
        },
      },
      step3: {
        operationTitle: 'Kontrola',
        instructions: 'Nakonec je třeba zkontrolovat, jestli OBU správně přijímá „aktivační“ signál přístrojové desky vozidla.\n',
        instructions1: '\nZapněte přístrojovou desku a klikněte na pokračovat pro spuštění kontroly ze vzdáleného zařízení',
        updating: 'Probíhá aktualizace stavu...',
        error: {
          errorMain: 'Signál klíče nepřítomen',
          errorDesc: 'Zkontrolujte elektrické zapojení signálu klíče.\n' +
              '\n' +
              'Zopakujte kontrolu stavu OBU kliknutím na zkusit znovu.\n' +
              '\n' +
              'Pokud není možné kontrolu dokončit, klikněte na Přerušit instalaci.',
          batteryMain: 'Zkontrolujte připojení kabelu',
          batteryDesc: 'Během instalace mohl být odpojen napájecí kabel.\n' +
              '\nUjistěte se také, že jste nezaměnili napájecí a zapalovací kabely.'
        }
      },
      buttonNext: 'Pokračovat',
      buttonAbort: 'Přerušit instalaci',
      bottomMessage: 'Narazili jste na nějaký problém?',
      bottomLink: 'Vraťte se k nápovědě',
      retryButton: 'Zkusit znovu',
      successInstallation: 'Konfigurace OBU správně dokončena',
      obuReady: 'OBU funguje správně\n' +
          '\n' +
          'Instalace dokončena',
      newInstallation: 'Nová instalace'
    },
    admin: {
      success: 'Aktualizovaná Tabulka',
      nav: {
        title: 'Portál Správce',
        eventsLink: 'Události',
        adminMng: 'Správa Správ'
      },
      eventsPage: {
        title: 'Události instalací',
        search: 'Hledat Cell_no, OBU ID...',
        recordInfo: 'Zobrazené záznamy',
        buttons: {
          next: 'Následující',
          previous: 'Předcházející',
          autoRefresh: 'Automatická aktualizace'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Probíhá'
        },
        filters: {
          buttons: {
            clear: 'Odebrat Filtry',
            collapse: 'Filtry'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Kód Operace',
              startTs: 'Od:',
              startTime: 'Počáteční čas:',
              endTs: 'Do:',
              endTime: 'Konečný čas:',
              plate: 'Číslo SPZ:',
              status: 'Stav:'
            },
            placeholders: {
              obu: 'Zvolit sériové č. OBU',
              opcode: 'Zvolit operaci',
              startTs: 'Zvolit počáteční datum',
              endTs: 'Zvolit konečné datum',
              noObu: 'Není k dispozici žádné ID jednotky OBU',
              noOpcode: 'Není k dispozici žádný kód operace',
              allOpts: 'Všechny',
              plate: 'Zvolit SPZ',
              noPlate: 'Není k dispozici žádná SPZ',
              searchPlate: 'Hledat SPZ...',
              status: 'Zvolit stav',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Probíhá'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Tel. Číslo',
          obu_id: 'ID jednotky OBU',
          plate_number: 'Číslo SPZ',
          timestamp: 'Datum-Čas',
          opcode_desc: 'Popis Kódu Operace',
          description: 'Popis',
          status: 'Stav'
        },
        opcodes: {
          0: 'Zvolit kód operace',
          1: 'Probíhá přihlašování',
          2: 'Přihlášení OK',
          3: 'Přihlášení KO',
          4: 'Probíhá OTP',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'Probíhá PIN',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Probíhá registrace',
          11: 'Registrace OK',
          12: 'Registrace KO',
          13: 'Žádost o nový OTP OK',
          14: 'Žádost o nový OTP KO',
          15: 'Probíhá aktualizace uživatelských údajů',
          16: 'Aktualizace uživatelských údajů OK',
          17: 'Aktualizace uživatelských údajů KO',
          18: 'Probíhá propojování OBU-SPZ',
          19: 'Propojení OBU-SPZ OK',
          20: 'Propojení OBU-SPZ KO',
          21: 'Probíhá instalace IPS',
          22: 'Instalace IPS OK',
          23: 'Probíhá konfigurace K1',
          24: 'Konfigurace K1 OK',
          25: 'Konfigurace K1 - First Run: chyba na PM',
          26: 'Konfigurace K1 - First Run: výpadek komunikace',
          27: 'Konfigurace K1 - First Run: chyba Branding',
          28: 'Konfigurace K1 - Branding: chyba u služby',
          29: 'Konfigurace K1 - Branding: časový limit operace vypršel',
          30: 'Konfigurace K1 - Vehicle Config: chyba konfigurace',
          31: 'Konfigurace K1 - Vehicle Config: časový limit operace vypršel',
          32: 'Konfigurace K1 - Tolling DSRC: chyba alespoň u jedné služby',
          33: 'Konfigurace K1 - Tolling DSRC: časový limit operace vypršel',
          34: 'Konfigurace K1 - Tolling GNSS: chyba alespoň u jedné služby',
          35: 'Konfigurace K1 - Tolling GNSS: časový limit operace vypršel',
          36: 'Konfigurace K1 - Service Activation: chyba alespoň u jedné služby',
          37: 'Konfigurace K1 - Service Activation: časový limit operace vypršel',
          38: 'Konfigurace K1 - Reboot: znovuspuštění neúspěšné',
          39: 'Konfigurace K1 - Power Switch: změna PM neúspěšná',
          40: 'K1 Probíhá Key',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Probíhá instalace',
          44: 'Instalace Dokončena',
          45: 'OBU bez světelných signálů',
          46: 'Kabel USB nepřítomný',
          47: 'Dual lock nepřítomný',
          48: 'Kabel USB příliš krátký',
          49: 'Nesprávná komunikace OBU se světelnými a zvukovými signály',
          50: 'Selhání konfiguračního procesu',
          51: 'OBU správně nepřijímá aktivační signál',
          52: 'Další scénář selhání',
          53: 'Start_time propojení byl NULL',
        }
      },
      adminMngPage: {
        title: 'Správa Správců',
        search: 'Hledat...',
        buttons: {
          add: 'Přidat'
        },
        table: {
          actions: 'Úkony',
          phone: 'Telefon',
          firstName: 'Jméno',
          lastName: 'Příjmení',
          company: 'Společnost',
          removed: 'Deaktivován',
        },
        modal: {
          dataLabels: {
            phone: 'Telefon',
            confPhone: 'Potvrdit telefon',
            firstName: 'Jméno',
            lastName: 'Příjmení',
            email: 'E-mail',
            company: 'Společnost',
            toggle: 'Deaktivovat Účet',
          },
          addAdmin: {
            title: 'Přidat Účet Správce',
            body: 'Zadejte informace uvedené v následujících polích:',
            buttons: {
              cancel: 'Zrušit',
              proceed: 'Zadat'
            }
          },
          modAdmin: {
            title: 'Změnit Účet Správce',
            body: 'Změnit následující údaje účtu:',
            buttons: {
              cancel: 'Zrušit',
              proceed: 'Aktualizovat'
            }
          },
          deleteAdmin: {
            title: 'Vymazání Účtu Správce',
            body: 'Určitě chcete odstranit tento účet ze seznamu správců?',
            buttons: {
              cancel: 'Ne',
              proceed: 'Ano'
            }
          },
          success: {
            title: 'Zaregistrované Údaje Účtu',
            body: 'Účet Správce uložen úspěšně'
          },
          error: {
            title: 'Neplatné údaje',
            body: 'Správce je již na seznamu'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtry',
            clear: 'Odebrat Filtry'
          },
          dropdowns: {
            labels: {
              removed: 'Účet Deaktivován',
              all: 'Všechny'
            }
          }
        }
      },

    },
    months: {
      january: 'Leden',
      february: 'Únor',
      march: 'Březen',
      april: 'Duben',
      may: 'Květen',
      june: 'Červen',
      july: 'Červenec',
      august: 'Srpen',
      september: 'Září',
      october: 'Říjen',
      november: 'Listopad',
      december: 'Prosinec'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PIN není správný',
        1212: 'Uživatel nenalezen',
        1226: 'Uživatelské jméno se již používá',
        1231: 'Neplatné telefonní číslo',
        1400: 'Chybějící parametr',
        1106: 'Zadaný OTP kód není správný',
        1214: 'Chybný PIN (poslední možný pokus)',
        4000: 'Platnost ID relace vypršela'

      },
      403: {
        0: 'Přístup zamítnut',
        1213: 'Uživatel zablokovaný',
      },
      404: {
        0: 'Zdroj nenalezen',
        3: 'Zdroj nenalezen',
        5: 'Zdroj nenalezen'
      },
      409: {
        7: 'Konflikt při odstraňování'
      },
      413: 'Příliš velká data',
      422: {
        3: 'Chyba během komunikace'
      },
      423: 'Platnost hesla vypršela',
      440: 'Platnost relace vypršela',
      500: {
        1500: 'Nečekaná chyba serveru'
      },
      502: 'Chyba 502, chyba komunikace'
    },
    path: {
      '/home': 'Home',
      '/login': 'Přihlášení'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  da: {
    message: {
      contact: 'Kontakt | Kontakter',
      installer: 'Installatør | Installatører',
      access: 'Installatøradgang',
      newAccess: 'Ny Installatøradgang',
      phone: 'Mobil',
      go: 'Frem',
      retrieve: 'Hent',
      data: 'Data',
      pin: 'PIN',
      new: 'Ny',
      registration: 'Registrering',
      otp: 'OTP-kode',
      name: 'Navn',
      surname: 'Efternavn',
      email: 'E-mail',
      confirm: 'Bekræft',
      verify: 'Kontrollér',
      signin: 'Registrér dig',
      ok: 'OK',
      completed: 'Fuldfør',
      portal: 'Installatørportal',
      error: 'Fejl',
      guide: 'Vejledninger',
      support: 'Installatørsupport',
      installK1: 'Installér K1',
      download: 'Download',
      video: 'Video',
      userProfile: 'Brugerprofil',
      changePassword: 'Skift adgangskode',
      logout: 'Log ud',
      update: 'Opdatér',
      cancel: 'Annuller',
      stopInstallation: 'Afbryd installation',
      obuId: 'OBU ID',
      plate: 'Nummerplade',
      yes: 'Ja',
      failureInstallation: 'Rapport installation mislykket',
      select: 'Vælg',
      reason: 'Årsag',
      dismiss: 'Annuller',
      placement: 'Placering',
      problem: ' Opstået problem...',
      send: 'Send',
      setting: 'Indstilling',
      set: 'Indstil',
      privacy: 'Jeg har læst og accepterer vilkårene for brug og persondatabeskyttelse',
      invalidPlate: 'Den indtastede nummerplade er ugyldig'
    },
    info: {
      insertTelephone: 'Indtast dit telefonnummer. Hvis du allerede har en Telepass-konto, kan du logge ind med de legitimationsoplysninger, der er knyttet til den.',
      insertPIN: 'Indtast din PIN-kode for at logge ind',
      retrieveAccessData: 'Gendan dine loginoplysninger',
      insertInformation: 'Indtast de oplysninger, der er angivet i felterne nedenfor',
      accessFromNewDevice: 'Du logger ind fra en ny enhed. \n\n For at bekræfte adgang skal du indtasteì' +
          ' OTP-koden, vi har sendt til dit mobilnummer:',
      insertOTP: 'Indtast OTP-koden sendt til dit mobilnummer',
      completeRegistrationEnteringPIN: 'Fuldfør registreringen ved at indtaste en 6-cifret numerisk PIN-kode',
      otpNotReceivedQuestion: 'Modtog du ikke OTP-koden?',
      resendOTP: 'Send OTP-kode igen',
      OTPSent: 'En kode til at bekræfte din identitet (OTP) er blevet sendt til dit mobilnummer',
      unregisteredUser: 'Ikke registreret bruger',
      performRegistration: 'Du skal registrere dig for at få adgang til portalen',
      guideQuestion: 'Har du læst vejledningerne? Start en ny K1 installationsproces',
      showGuideBeforeInstallation: 'Vis vejledninger før hver installation',
      downloadInstallationManual: 'Download manualen til hardwareinstallation og brug af platformen',
      playVideoGuide: 'Se trin-for-trin videoguiden af ​​processen til installation og overvågning af enheden',
      updateInformation: 'Opdatér de oplysninger, der er angivet i felterne nedenfor',
      beforeProceeding: 'Før du går videre...',
      gsmAdvMessage: 'For at fuldføre installationsprocessen for ​​en K1-enhed korrekt, skal der være god modtagelse af GSM-mobilsignalet på installationsstedet',
      newObuInstallation: 'Installation af ny enhed',
      insertSerialAndPlateNumber: 'Indtast id\'et på den enhed, der skal installeres, og køretøjets nummerplade',
      gsmQuestion: 'Har du ikke disse oplysninger?',
      returnToGuidePage: 'Gå tilbage til vejledningerne',
      stopInstallationQuestion: 'Afbryd installation?',
      fullStopInstallationQuestion: 'Er du sikker på, at du vil afbryde installationsprocessen?',
      selectReasonStopInstallation: 'Vælg årsagen til afbrydelse af installationen:',
      associationPlateObuNotFoundMessage: 'Sørg for, at du har indtastet dataene korrekt, og at den enhed, du ' +
          'installerer, svarer til kundens køretøj. Kontakt din salgspartner, hvis problemet fortsætter',
      selectMotivation: 'Vælg forklaring...',
      returnORReplacementDevice: 'Returnering / udskiftning af enhed',
      returnReplaceDeviceMessage: 'Du bedes kontakte din salgspartner for at starte returnerings- eller udskiftningsproceduren for ' +
          'den enhed, som du havde problemer med under installationsprocessen',
      insertOtherMotivation: 'Indtast en beskrivelse af problemet du stødte på under installationen af ​​enheden:',
      updatePIN: 'Indtast en 6-cifret numerisk PIN-kode',
      backLogin: 'Vend tilbage til log ind-siden',
      otpError: 'Ugyldig eller udløbet OTP-kode indtastet',
      pinError: 'De indtastede PIN-koder stemmer ikke overens'
    },
    success: {
      postSuccess: 'tilføjet korrekt',
      putSuccess: 'opdateret korrekt',
      sendMail: 'sendt korrekt',
      verifiedAccess: 'Adgang bekræftet',
      verifiedOTP: 'OTP-kode bekræftet',
      authorizedDevice: 'Din enhed er blevet godkendt',
      canAccessToPortal: 'Du kan nu få adgang til installationsplatformen',
      associationPlateObuFound: 'ID-NUMMERPLADE tilknytning bekræftet',
      pinChanged: 'Ny PIN-kode indstillet korrekt',
      operationSuccess: 'Handling udført korrekt'
    },
    warnings: {
      accessDenied: 'Adgang nægtet',
      alreadyDisabled: 'Brugeren er allerede deaktiveret',
      invalid: 'Ikke gyldig | Ikke gyldigt',
      sureToDelete: 'Er du sikker på, at du vil slette elementet',
      continue: 'Fortsæt',
      valid: 'Gyldig | Gyldigt',
      phoneForm: 'Feltet skal indeholde et områdenummer og et gyldigt telefonnummer',
      pinForm: 'Feltet skal indeholde 6 cifre',
      pinConfirm: 'De indtastede PIN-koder stemmer ikke overens'
    },
    errors: {
      associationPlateObuNotFound: 'ID-NUMMERPLADE tilknytning ikke fundet',
      error: 'Der opstod en fejl'
    },
    installSteps: {
      title: 'Installation af ny enhed',
      step1: {
        operationTitle: 'Installation IPS',
        message: 'Du kan nu fortsætte med hardwareinstallationen',
        instructions: `Kontrollér, at køretøjets instrumentbræt er slukket, og installér IPS-enheden.
                                \n Sørg for, at du har tilsluttet kablerne korrekt til el-installationen, og anbring USB-kablet inde i kabinen.`
      },
      step2: {
        advancedText: 'Gå frem',
        datePrompt: 'Vælg dato og klokkeslæt for start på installationen',
        operationTitle: 'Start enheden og konfigurér den',
        message: '',
        instructions: 'Placér OBU\'en inde i kabinen på køretøjets forrude, som vist i manualen.',
        instructions1: 'Tilslut USB-stikket til OBU\'en og klik på Fortsæt.',
        obuPosition: 'Korrekt placering af OBU',
        monitorMessage: 'Automatisk konfigurationsprocedure i gang...',
        configDesc: '',
        configurationInformation: 'Konfigurationsoplysninger',
        configurationInformationMessage: 'Beskrivelse af konfigurationsproceduren set ud fra' +
            ' feedback til installatøren fra HMI',
        error1: {
          errorMain: 'Det var ikke muligt at kommunikere med enheden',
          errorDesc: 'Du bedes følge nedenstående trin:',
          first: 'Kobl USB-kablet fra OBU\'en',
          second: 'Kontrollér de elektriske forbindelser på IPS-enheden igen',
          third: 'Slut USB-kablet til OBU\'en igen',
          fourth: 'Kontrollér start af enheden:',
          subF1: 'Afspilning af startlyd;',
          subF2: 'Den røde statusdiode tændes.',
          footMessage: `Klik på Prøv igen for at gentage bekræftelsen.
    Hvis problemet fortsætter, skal du afslutte installationen.`,
        },
        error2: {
          errorMain: 'Der opstod en fejl under konfigurationsprocessen',
          errorDesc: 'OBU\'en kan ikke konfigureres.',
          footMessage: 'Stop enhedens installationsproces'
        },
        error3: {
          errorMain: 'Der opstod en fejl under konfigurationsprocessen',
          errorDesc: 'OBU-tilpasning er ikke gennemført.',
          footMessage: 'Stop enhedens installationsproces'
        },
      },
      step3: {
        operationTitle: 'Kontrollér',
        instructions: 'Som et sidste trin er det nødvendigt at kontrollere, at OBU\'en korrekt modtager \'aktiverings\'-signalet fra køretøjets el-panel.\n',
        instructions1: '\nTænd for tændingen, og klik på Fortsæt for at starte fjernbekræftelsen',
        updating: 'Statusopdatering i gang...',
        error: {
          errorMain: 'Nøglesignal ikke til stede',
          errorDesc: 'Kontroller nøglesignalets elektriske forbindelse.\n' +
              '\n' +
              'Gentag OBU-statuskontrollen ved at klikke på prøv igen.\n' +
              '\n' +
              'Hvis det ikke er muligt at fuldføre kontrollen, skal du klikke på Afbryd installation.',
          batteryMain: 'Kontroller kabeltilslutningen',
          batteryDesc: 'Strømkablet kan have været afbrudt under installationen.\n' +
              '\nSørg også for, at du ikke har byttet om på strøm- og tændingskablerne.'
        }
      },
      buttonNext: 'Fortsæt',
      buttonAbort: 'Afbryd installation',
      bottomMessage: 'Er du stødt på et problem?',
      bottomLink: 'Gå tilbage til vejledningerne',
      retryButton: 'Prøv igen',
      successInstallation: 'OBU-konfigurationen er gennemført korrekt',
      obuReady: 'OBU\'en fungerer korrekt\n' +
          '\n' +
          'Installationen er fuldført',
      newInstallation: 'Ny installation'
    },
    admin: {
      success: 'Tabel opdateret',
      nav: {
        title: 'Administratorportal',
        eventsLink: 'Hændelser',
        adminMng: 'Admin. styring'
      },
      eventsPage: {
        title: 'Installationshændelser',
        search: 'Modtag Cell_no, OBU ID...',
        recordInfo: 'Viste poster',
        buttons: {
          next: 'Næste',
          previous: 'Forrige',
          autoRefresh: 'Opdatér automatisk'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'I gang'
        },
        filters: {
          buttons: {
            clear: 'Rens filtre',
            collapse: 'Filtre'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Handlingskode',
              startTs: 'Fra:',
              startTime: 'Starttidspunkt:',
              endTs: 'Til:',
              endTime: 'Sluttidspunkt:',
              plate: 'Nummerplade:',
              status: 'Status:'
            },
            placeholders: {
              obu: 'Vælg seriel OBU',
              opcode: 'Vælg handling',
              startTs: 'Vælg startdato',
              endTs: 'Vælg slutdato',
              noObu: 'Intet OBU-id tilgængeligt',
              noOpcode: 'Ingen handlingskode tilgængelig',
              allOpts: 'Alle',
              plate: 'Ingen nummerplade',
              noPlate: 'Ingen nummerplade tilgængelig',
              searchPlate: 'Søg nummerplade...',
              status: 'Vælg status',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'I gang'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Tlf.nr.',
          obu_id: 'OBU ID',
          plate_number: 'Nummerplade',
          timestamp: 'Dato-Tidspunkt',
          opcode_desc: 'Beskriv. handlingskode',
          description: 'Beskrivelse',
          status: 'Status'
        },
        opcodes: {
          0: 'Vælg handlingskode',
          1: 'Log ind i gang',
          2: 'Log ind OK',
          3: 'Log ind KO',
          4: 'OTP i gang',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN i gang',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Registrering i gang',
          11: 'Registrering OK',
          12: 'Registrering KO',
          13: 'Ny OTP-anmodning OK',
          14: 'Ny OTP-anmodning KO',
          15: 'Opdatering af brugerdata i gang',
          16: 'Opdatering af brugerdata OK',
          17: 'Opdatering af brugerdata KO',
          18: 'OBU-Nummerplade tilknytning i gang',
          19: 'OBU-Nummerplade tilknytning OK',
          20: 'OBU-Nummerplade tilknytning KO',
          21: 'Installation IPS i gang',
          22: 'Installation IPS OK',
          23: 'Konfiguration K1 i gang',
          24: 'Konfiguration K1 OK',
          25: 'Konfiguration K1 - First Run: fejl på PM',
          26: 'Konfiguration K1 - First Run: ingen kommunikation',
          27: 'Konfiguration K1 - First Run: Forkert branding',
          28: 'Konfiguration K1 - Branding: fejl på service',
          29: 'Konfiguration K1 - Branding: handling udløbet',
          30: 'Konfiguration K1 - Vehicle Config: konfigurationsfejl',
          31: 'Konfiguration K1 - Vehicle Config: handling udløbet',
          32: 'Konfiguration K1 - Tolling DSRC: fejl på mindst én service',
          33: 'Konfiguration K1 - Tolling DSRC: handling udløbet',
          34: 'Konfiguration K1 - Tolling GNSS: fejl på mindst én service',
          35: 'Konfiguration K1 - Tolling GNSS: handling udløbet',
          36: 'Konfiguration K1 - Service Activation: fejl på mindst én service',
          37: 'Konfiguration K1 - Service Activation: handling udløbet',
          38: 'Konfiguration K1 - Reboot: genstart mislykket',
          39: 'Konfiguration K1 - Power Switch: PM-ændring mislykket',
          40: 'K1 Key i gang',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Installation i gang',
          44: 'Installation fuldført',
          45: 'OBU uden lyssignaler',
          46: 'USB-kabel ikke til stede',
          47: 'Dual lock ikke til stede',
          48: 'USB-kabel for kort',
          49: 'Obu\'en kommunikerer ikke korrekt med lys- og lydsignaler',
          50: 'Konfigurationsprocessen mislykkedes',
          51: 'OBU\'en modtager ikke aktiveringssignalet korrekt',
          52: 'Andet fejlscenarie',
          53: 'start_time for tilknytningen var NULL',
        }
      },
      adminMngPage: {
        title: 'Styring af administratorer',
        search: 'Søg...',
        buttons: {
          add: 'Tilføj'
        },
        table: {
          actions: 'Handlinger',
          phone: 'Telefon',
          firstName: 'Navn',
          lastName: 'Efternavn',
          company: 'Virksomhed',
          removed: 'Deaktiveret',
        },
        modal: {
          dataLabels: {
            phone: 'Telefon',
            confPhone: 'Bekræft telefon',
            firstName: 'Navn',
            lastName: 'Efternavn',
            email: 'E-mail',
            company: 'Selskab',
            toggle: 'Deaktivér konto',
          },
          addAdmin: {
            title: 'Tilføj administratorkonto',
            body: 'Indtast de oplysninger, der er angivet i felterne nedenfor:',
            buttons: {
              cancel: 'Annuller',
              proceed: 'Indsæt'
            }
          },
          modAdmin: {
            title: 'Ændr administratorkonto',
            body: 'Ændr oplysningerne for følgende konti:',
            buttons: {
              cancel: 'Annuller',
              proceed: 'Opdatér'
            }
          },
          deleteAdmin: {
            title: 'Slet administratorkonto',
            body: 'Er du sikker på, at du vil fjerne denne konto fra administratorlisten?',
            buttons: {
              cancel: 'Nej',
              proceed: 'Ja'
            }
          },
          success: {
            title: 'Registrerede kontodata',
            body: 'Administratorkonto blev gemt korrekt'
          },
          error: {
            title: 'Ugyldige data',
            body: 'Administrator er allerede til stede på liste'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtre',
            clear: 'Rens filtre'
          },
          dropdowns: {
            labels: {
              removed: 'Deaktivér konto',
              all: 'Alle'
            }
          }
        }
      },

    },
    months: {
      january: 'Januar',
      february: 'Februar',
      march: 'Marts',
      april: 'April',
      may: 'Maj',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PIN er forkert',
        1212: 'Bruger ikke fundet',
        1226: 'Brugernavn allerede anvendt',
        1231: 'Ugyldigt telefonnummer',
        1400: 'Manglende parameter',
        1106: 'Indtastet OTP er forkert',
        1214: 'Forkert PIN (sidste mulige forsøg)',
        4000: 'Sessions-id udløbet'

      },
      403: {
        0: 'Adgang nægtet',
        1213: 'Bruger blokeret',
      },
      404: {
        0: 'Ressource ikke fundet',
        3: 'Ressource ikke fundet',
        5: 'Ressource ikke fundet'
      },
      409: {
        7: 'Konflikt under fjernelse'
      },
      413: 'Datamængden er for stor',
      422: {
        3: 'Fejl under kommunikation'
      },
      423: 'Adgangskoden er udløbet',
      440: 'Sessionen er udløbet',
      500: {
        1500: 'Uforudset fejl på serversiden'
      },
      502: 'Fejl 502, kommunikationsfejl'
    },
    path: {
      '/home': 'Start',
      '/login': 'Adgang'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  de: {
    message: {
      contact: 'Kontakt | Kontakt',
      installer: 'Monteur | Monteure',
      access: 'Zugang für Monteure',
      newAccess: 'Neu Zugang für Monteure',
      phone: 'Mobiltelefon',
      go: 'Weiter',
      retrieve: 'Wiederherstellen',
      data: 'Daten',
      pin: 'PIN',
      new: 'Neu',
      registration: 'Registrierung',
      otp: 'OTP',
      name: 'Vorname',
      surname: 'Familienname',
      email: 'E-Mail',
      confirm: 'Bestätigen',
      verify: 'Prüfen',
      signin: 'Registrieren',
      ok: 'OK',
      completed: 'Abgeschlossen',
      portal: 'Portal für Monteure',
      error: 'Fehler',
      guide: 'Leitfäden',
      support: 'Support für Monteure',
      installK1: 'K1 installieren',
      download: 'Download',
      video: 'Video',
      userProfile: 'Benutzerprofil',
      changePassword: 'Passwort ändern',
      logout: 'Abmelden',
      update: 'Aktualisieren',
      cancel: 'Abbrechen',
      stopInstallation: 'Installation abbrechen',
      obuId: 'OBU ID',
      plate: 'Kennzeichen',
      yes: 'Ja',
      failureInstallation: 'Installationsfehlerbericht',
      select: 'Auswählen',
      reason: 'Grund',
      dismiss: 'Abbrechen',
      placement: 'Positionierung',
      problem: ' Es ist ein Problem aufgetreten ...',
      send: 'Senden',
      setting: 'Einstellung',
      set: 'Festlegen',
      privacy: 'Ich habe die Nutzungsbedingungen und die Datenschutzerklärung gelesen und nehme diese an',
      invalidPlate: 'Das eingegebene Kennzeichen ist ungültig'
    },
    info: {
      insertTelephone: 'Geben Sie Ihre Telefonnummer ein. Wenn Sie bereits ein Telepass-Konto haben, können Sie sich mit den dazugehörigen Anmeldedaten anmelden.',
      insertPIN: 'Geben Sie die Zugangs-PIN ein',
      retrieveAccessData: 'Zugangsdaten wiederherstellen',
      insertInformation: 'Geben Sie die erforderlichen Informationen in die folgenden Felder ein',
      accessFromNewDevice: 'Sie melden sich gerade mit einem neuen Gerät an. \n\n Geben Sie zum Überprüfen des Zugriffs' +
          'den OTP-Code ein, den wir an Ihr Mobiltelefon gesendet haben:',
      insertOTP: 'Geben Sie den OTP-Code ein, der an Ihr Mobiltelefon gesendet wurde',
      completeRegistrationEnteringPIN: 'Schließen Sie die Registrierung durch Eingabe einer 6-stelligen PIN ab',
      otpNotReceivedQuestion: 'Haben Sie keinen OTP-Code erhalten?',
      resendOTP: 'OTP-Code erneut senden',
      OTPSent: 'Ein Code zur Überprüfung Ihrer Identität (OTP) wurde an Ihre Mobiltelefonnummer gesendet',
      unregisteredUser: 'Nicht registrierter Benutzer',
      performRegistration: 'Registrieren Sie sich, um auf das Portal zugreifen zu können',
      guideQuestion: 'Haben Sie die Leitfäden gelesen? Starten Sie einen neuen K1-Installationsprozess',
      showGuideBeforeInstallation: 'Vor jeder Installation die Leitfäden anzeigen',
      downloadInstallationManual: 'Laden Sie das Installationshandbuch für die Hardware und das Benutzerhandbuch für die Plattform herunter',
      playVideoGuide: 'Sehen Sie sich den Schritt-für-Schritt-Videoleitfaden für den Installationsprozess und die Geräteüberwachung an',
      updateInformation: 'Aktualisieren Sie die erforderlichen Informationen in den folgenden Feldern',
      beforeProceeding: 'Vor dem Fortfahren ...',
      gsmAdvMessage: 'Um den Installationsprozess für ein K1-Gerät ordnungsgemäß abschließen zu können, muss am Installationsort ein guter GSM-Empfang gewährleistet sein',
      newObuInstallation: 'Installation eines neuen Geräts',
      insertSerialAndPlateNumber: 'Geben Sie die Kennung des zu installierenden Geräts und das Fahrzeugkennzeichen ein',
      gsmQuestion: 'Sie verfügen nicht über diese Informationen?',
      returnToGuidePage: 'Zu den Leitfäden zurückkehren',
      stopInstallationQuestion: 'Möchten Sie die Installation abbrechen?',
      fullStopInstallationQuestion: 'Möchten Sie den Installationsprozess wirklich abbrechen?',
      selectReasonStopInstallation: 'Wählen Sie den Grund für den Abbruch der Installation aus:',
      associationPlateObuNotFoundMessage: 'Vergewissern Sie sich, dass Sie die Daten korrekt eingegeben haben und ' +
          'dass das zu installierende Gerät dem Kundenfahrzeug entspricht. Wenn das Problem weiterhin besteht, kontaktieren Sie Ihren Vertriebspartner',
      selectMotivation: 'Grund auswählen ...',
      returnORReplacementDevice: 'Rückgabe / Austausch des Geräts',
      returnReplaceDeviceMessage: 'Bitte kontaktieren Sie Ihren Vertriebspartner, um den Rückgabe- ' +
          'oder Austauschprozess für das Gerät zu starten, auf dem während der Installation Probleme aufgetreten sind',
      insertOtherMotivation: 'Geben Sie eine Beschreibung des während der Geräteinstallation aufgetretenen Problems ein:',
      updatePIN: 'Geben Sie eine 6-stellige PIN ein',
      backLogin: 'Zur Anmeldeseite zurückkehren',
      otpError: 'Der eingegebene OTP-Code ist ungültig oder abgelaufen',
      pinError: 'Die eingegebenen PINs stimmen nicht überein'
    },
    success: {
      postSuccess: 'erfolgreich hinzugefügt',
      putSuccess: 'erfolgreich aktualisiert',
      sendMail: 'erfolgreich gesendet',
      verifiedAccess: 'Zugriff überprüft',
      verifiedOTP: 'OTP-Code überprüft',
      authorizedDevice: 'Ihr Gerät wurde authentifiziert',
      canAccessToPortal: 'Sie können jetzt auf die Monteurplattform zugreifen',
      associationPlateObuFound: 'Die Zuordnung ID–KENNZEICHEN wurde geprüft',
      pinChanged: 'Die neue PIN wurde korrekt festgelegt',
      operationSuccess: 'Der Vorgang wurde erfolgreich ausgeführt'
    },
    warnings: {
      accessDenied: 'Zugriff verweigert',
      alreadyDisabled: 'Der Benutzer ist bereits deaktiviert',
      invalid: 'Ungültig | Ungültig',
      sureToDelete: 'Wollen Sie das Element wirklich löschen',
      continue: 'Fortfahren',
      valid: 'Gültig | Gültig',
      phoneForm: 'Das Feld muss aus einer Vorwahl und einer gültigen Telefonnummer bestehen',
      pinForm: 'Das Feld muss aus 6 Ziffern bestehen',
      pinConfirm: 'Die eingegebenen PINs stimmen nicht überein'
    },
    errors: {
      associationPlateObuNotFound: 'Die Zuordnung ID–KENNZEICHEN wurde nicht gefunden',
      error: 'Es ist ein Fehler aufgetreten'
    },
    installSteps: {
      title: 'Installation eines neuen Geräts',
      step1: {
        operationTitle: 'IPS-Installation',
        message: 'Sie können nun mit der Hardwareinstallation fortfahren',
        instructions: `Überprüfen Sie, ob die Instrumententafel des Fahrzeugs ausgeschaltet ist, und installieren Sie das IPS-Gerät.
                                 \n Vergewissern Sie sich, dass die Kabel korrekt an der Elektroanlage angeschlossen sind und bereiten Sie das USB-Kabel im Fahrzeuginnenraum vor.`
      },
      step2: {
        advancedText: 'Erweiterte Einstellungen',
        datePrompt: 'Wählen Sie Datum und Uhrzeit für den Start der Installation aus',
        operationTitle: 'Gerätestart und Konfiguration',
        message: '',
        instructions: 'Positionieren Sie die OBU im Fahrzeuginnenraum auf der Windschutzscheibe wie im Handbuch dargestellt.',
        instructions1: 'Schließen Sie das USB-Kabel an die OBU an und klicken Sie auf Weiter.',
        obuPosition: 'Ordnungsgemäße Positionierung der OBU',
        monitorMessage: 'Der automatische Konfigurationsprozess wird ausgeführt ...',
        configDesc: '',
        configurationInformation: 'Konfigurationsinformationen',
        configurationInformationMessage: 'Beschreibung des Konfigurationsprozesses' +
            'anhand der HMI-Rückmeldungen an den Monteur',
        error1: {
          errorMain: 'Es konnte keine Kommunikation mit dem Gerät hergestellt werden',
          errorDesc: 'Bitte führen Sie folgende Schritte aus:',
          first: 'Trennen Sie das USB-Kabel von der OBU',
          second: 'Überprüfen Sie nochmals die elektrischen Anschlüsse des IPS-Geräts',
          third: 'Schließen Sie das USB-Kabel wieder an die OBU an',
          fourth: 'Prüfen Sie, ob das Gerät startet:',
          subF1: 'Wiedergabe des Startsounds;',
          subF2: 'Aufleuchten der roten Status-LED.',
          footMessage: `Klicken Sie auf Erneut versuchen, um die Prüfung zu wiederholen.
    Wenn das Problem weiterhin besteht, beenden Sie die Installation.`,
        },
        error2: {
          errorMain: 'Während des Konfigurationsprozesses ist ein Fehler aufgetreten',
          errorDesc: 'Die OBU kann nicht konfiguriert werden.',
          footMessage: 'Brechen Sie den Installationsprozess des Geräts ab'
        },
        error3: {
          errorMain: 'Während des Konfigurationsprozesses ist ein Fehler aufgetreten',
          errorDesc: 'Die OBU-Personalisierung ist noch nicht abgeschlossen.',
          footMessage: 'Brechen Sie den Installationsprozess des Geräts ab'
        },
      },
      step3: {
        operationTitle: 'Prüfung',
        instructions: 'Als letzter Schritt muss geprüft werden, ob die OBU das „Aktivierungssignal“ ordnungsgemäß von der Instrumententafel des Fahrzeugs empfängt.\n',
        instructions1: '\nDie Instrumententafel einschalten und auf Weiter klicken, um die Fernprüfung zu starten',
        updating: 'Status wird aktualisiert ...',
        error: {
          errorMain: 'Schlüsselsignal nicht vorhanden',
          errorDesc: 'Prüfen Sie den elektrischen Anschluss des Schlüsselsignals.\n' +
              '\n' +
              'Klicken Sie auf Erneut versuchen, um die Prüfung des OBU-Status zu wiederholen.\n' +
              '\n' +
              'Wenn die Prüfung nicht abgeschlossen werden kann, klicken Sie auf Installation abbrechen.',
          batteryMain: 'Überprüfen Sie die Kabelverbindung',
          batteryDesc: 'Das Netzkabel wurde möglicherweise während der Installation abgezogen.\n' +
              '\nStellen Sie außerdem sicher, dass Sie nicht die Strom- und Zündkabel vertauscht haben.'
        }
      },
      buttonNext: 'Weiter',
      buttonAbort: 'Installation abbrechen',
      bottomMessage: 'Haben Sie ein Problem festgestellt?',
      bottomLink: 'Zu den Leitfäden zurückkehren',
      retryButton: 'Erneut versuchen',
      successInstallation: 'Die OBU-Konfiguration wurde ordnungsgemäß abgeschlossen',
      obuReady: 'Die OBU funktioniert ordnungsgemäß\n' +
          '\n' +
          'Installation abgeschlossen',
      newInstallation: 'Neue Installation'
    },
    admin: {
      success: 'Tabelle wurde aktualisiert',
      nav: {
        title: 'Administratorportal',
        eventsLink: 'Ereignisse',
        adminMng: 'Admin-Verwaltung'
      },
      eventsPage: {
        title: 'Installationsereignisse',
        search: 'Suche Cell_no, OBU ID ...',
        recordInfo: 'Angezeigte Datensätze',
        buttons: {
          next: 'Nächstes',
          previous: 'Vorheriges',
          autoRefresh: 'Automatische Aktualisierung'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Wird ausgeführt'
        },
        filters: {
          buttons: {
            clear: 'Filter löschen',
            collapse: 'Filter'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Vorgangscode',
              startTs: 'Von:',
              startTime: 'Startzeit:',
              endTs: 'Bis:',
              endTime: 'Endzeit:',
              plate: 'Kennzeichen:',
              status: 'Status:'
            },
            placeholders: {
              obu: 'OBU-Seriennummer auswählen',
              opcode: 'Vorgang auswählen',
              startTs: 'Startdatum auswählen',
              endTs: 'Enddatum auswählen',
              noObu: 'Keine OBU-ID verfügbar',
              noOpcode: 'Kein Vorgangscode verfügbar',
              allOpts: 'Alle',
              plate: 'Kennzeichen auswählen',
              noPlate: 'Kein Kennzeichen verfügbar',
              searchPlate: 'Kennzeichen suchen ...',
              status: 'Status auswählen',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Wird ausgeführt'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Tel.-Nummer',
          obu_id: 'OBU ID',
          plate_number: 'Kennzeichen',
          timestamp: 'Datum–Uhrzeit',
          opcode_desc: 'Beschreibung Vorgangscode',
          description: 'Beschreibung',
          status: 'Status'
        },
        opcodes: {
          0: 'Vorgangscode auswählen',
          1: 'Anmeldung wird ausgeführt',
          2: 'Anmeldung OK',
          3: 'Anmeldung KO',
          4: 'OTP wird ausgeführt',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN wird ausgeführt',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Registrierung wird ausgeführt',
          11: 'Registrierung OK',
          12: 'Registrierung KO',
          13: 'Anforderung neuer OTP OK',
          14: 'Anforderung neuer OTP KO',
          15: 'Aktualisierung der Benutzerdaten wird ausgeführt',
          16: 'Aktualisierung der Benutzerdaten OK',
          17: 'Aktualisierung der Benutzerdaten KO',
          18: 'Zuordnung OBU–Kennzeichen wird ausgeführt',
          19: 'Zuordnung OBU–Kennzeichen OK',
          20: 'Zuordnung OBU–Kennzeichen KO',
          21: 'IPS-Installation wird ausgeführt',
          22: 'IPS-Installation OK',
          23: 'K1-Konfiguration wird ausgeführt',
          24: 'K1-Konfiguration OK',
          25: 'K1-Konfiguration - First Run: Fehler bei PM',
          26: 'K1-Konfiguration - First Run: keine Kommunikation',
          27: 'K1-Konfiguration - First Run: Falsches Branding',
          28: 'K1-Konfiguration - Branding: Fehler bei Dienst',
          29: 'K1-Konfiguration - Branding: Zeitüberschreitung bei Vorgang',
          30: 'K1-Konfiguration - Vehicle Config: Konfigurationsfehler',
          31: 'K1-Konfiguration - Vehicle Config: Zeitüberschreitung bei Vorgang',
          32: 'K1-Konfiguration - Tolling DSRC: Fehler bei mindestens einem Dienst',
          33: 'K1-Konfiguration - Tolling DSRC: Zeitüberschreitung bei Vorgang',
          34: 'K1-Konfiguration - Tolling GNSS: Fehler bei mindestens einem Dienst',
          35: 'K1-Konfiguration - Tolling GNSS: Zeitüberschreitung bei Vorgang',
          36: 'K1-Konfiguration - Service Activation: Fehler bei mindestens einem Dienst',
          37: 'K1-Konfiguration - Service Activation: Zeitüberschreitung bei Vorgang',
          38: 'K1-Konfiguration - Reboot: Neustart fehlgeschlagen',
          39: 'K1-Konfiguration - Power Switch: PM-Änderung fehlgeschlagen',
          40: 'K1 Key wird ausgeführt',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Installation wird ausgeführt',
          44: 'Installation abgeschlossen',
          45: 'OBU ohne optische Signale',
          46: 'USB-Kabel nicht vorhanden',
          47: 'Dual Lock nicht vorhanden',
          48: 'USB-Kabel zu kurz',
          49: 'Die OBU kommuniziert nicht richtig mit optischen und akustischen Signalen',
          50: 'Konfigurationsprozess fehlgeschlagen',
          51: 'Die OBU empfängt das Aktivierungssignal nicht ordnungsgemäß',
          52: 'Anderer Fehlschlaggrund',
          53: 'Die start_time der Zuordnung war NULL',
        }
      },
      adminMngPage: {
        title: 'Administratorverwaltung',
        search: 'Suche ...',
        buttons: {
          add: 'Hinzufügen'
        },
        table: {
          actions: 'Aktionen',
          phone: 'Telefonnummer',
          firstName: 'Vorname',
          lastName: 'Familienname',
          company: 'Firma',
          removed: 'Deaktiviert',
        },
        modal: {
          dataLabels: {
            phone: 'Telefonnummer',
            confPhone: 'Telefonnummer bestätigen',
            firstName: 'Vorname',
            lastName: 'Familienname',
            email: 'E-Mail',
            company: 'Firma',
            toggle: 'Konto deaktivieren',
          },
          addAdmin: {
            title: 'Administratorkonto hinzufügen',
            body: 'Geben Sie die erforderlichen Informationen in die folgenden Felder ein:',
            buttons: {
              cancel: 'Abbrechen',
              proceed: 'Eingeben'
            }
          },
          modAdmin: {
            title: 'Administratorkonto bearbeiten',
            body: 'Bearbeiten Sie die folgenden Kontoinformationen:',
            buttons: {
              cancel: 'Abbrechen',
              proceed: 'Aktualisieren'
            }
          },
          deleteAdmin: {
            title: 'Administratorkonto löschen',
            body: 'Möchten Sie dieses Konto wirklich aus der Administratorliste entfernen?',
            buttons: {
              cancel: 'Nein',
              proceed: 'Ja'
            }
          },
          success: {
            title: 'Registrierte Kontodaten',
            body: 'Das Administratorkonto wurde erfolgreich gespeichert'
          },
          error: {
            title: 'Ungültige Daten',
            body: 'Der Administrator ist bereits in der Liste vorhanden'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filter',
            clear: 'Filter löschen'
          },
          dropdowns: {
            labels: {
              removed: 'Konto deaktiviert',
              all: 'Alle'
            }
          }
        }
      },

    },
    months: {
      january: 'Januar',
      february: 'Februar',
      march: 'März',
      april: 'April',
      may: 'Mai',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Dezember'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PIN nicht korrekt',
        1212: 'Benutzer nicht gefunden',
        1226: 'Benutzername bereits in Verwendung',
        1231: 'Telefonnummer ungültig',
        1400: 'Fehlender Parameter',
        1106: 'Falscher OTP eingegeben',
        1214: 'Falsche PIN (Letzter möglicher Versuch)',
        4000: 'Sitzungs-ID abgelaufen'

      },
      403: {
        0: 'Zugriff verweigert',
        1213: 'Benutzer gesperrt',
      },
      404: {
        0: 'Ressource nicht gefunden',
        3: 'Ressource nicht gefunden',
        5: 'Ressource nicht gefunden'
      },
      409: {
        7: 'Konflikt beim Entfernen'
      },
      413: 'Datengröße zu groß',
      422: {
        3: 'Fehler bei der Kommunikation'
      },
      423: 'Das Passwort ist abgelaufen',
      440: 'Die Sitzung ist abgelaufen',
      500: {
        1500: 'Unvorhergesehener serverseitiger Fehler'
      },
      502: 'Fehler 502, Kommunikationsfehler'
    },
    path: {
      '/home': 'Startseite',
      '/login': 'Zugang'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  es: {
    message: {
      contact: 'Contacto | Contactos',
      installer: 'Instalador | Instaladores',
      access: 'Acceso Instalador',
      newAccess: 'Nuevo Acceso Instalador',
      phone: 'Teléfono móvil',
      go: 'Adelante',
      retrieve: 'Recuperar',
      data: 'Datos',
      pin: 'PIN',
      new: 'Nuevo',
      registration: 'Registro',
      otp: 'OTP',
      name: 'Nombre',
      surname: 'Apellido',
      email: 'Correo electrónico',
      confirm: 'Confirmar',
      verify: 'Comprobación',
      signin: 'Regístrate',
      ok: 'OK',
      completed: 'Completado',
      portal: 'Portal Instaladores',
      error: 'Error',
      guide: 'Guías',
      support: 'Ayuda Instaladores',
      installK1: 'Instalar K1',
      download: 'Descargas',
      video: 'Vídeo',
      userProfile: 'Perfil de usuario',
      changePassword: 'Cambiar contraseña',
      logout: 'Cierre de sesión',
      update: 'Actualizar',
      cancel: 'Cancelar',
      stopInstallation: 'Interrumpir la instalación',
      obuId: 'OBU ID',
      plate: 'Matrícula',
      yes: 'Sí',
      failureInstallation: 'Informe de instalación fallida',
      select: 'Seleccionar',
      reason: 'Motivo',
      dismiss: 'Cancelar',
      placement: 'Colocación',
      problem: ' Problema detectado...',
      send: 'Enviar',
      setting: 'Configuración',
      set: 'Configurar',
      privacy: 'He leído y acepto los términos de uso y de privacidad',
      invalidPlate: 'La matrícula introducida no es válida'
    },
    info: {
      insertTelephone: 'Introduce tu número de teléfono. Si ya tienes una cuenta de Telepass, puedes acceder con las credenciales asociadas a dicha cuenta.',
      insertPIN: 'Introducir el PIN para acceder',
      retrieveAccessData: 'Recuperar los datos de acceso',
      insertInformation: 'Introducir las informaciones indicadas en los siguientes campos',
      accessFromNewDevice: 'Estás accediendo desde un nuevo dispositivo. \n\n Para comprobar el acceso, introduce el código' +
          ' OTP que hemos enviado a tu número de teléfono móvil:',
      insertOTP: 'Introduce el código OTP enviado a tu número de teléfono móvil',
      completeRegistrationEnteringPIN: 'Completa el registro introduciendo un PIN numérico de 6 dígitos',
      otpNotReceivedQuestion: '¿No has recibido el código OTP?',
      resendOTP: 'Enviar otro código OTP',
      OTPSent: 'Se ha enviado a tu número de teléfono móvil un código para comprobar tu identidad (OTP)',
      unregisteredUser: 'Usuario no registrado',
      performRegistration: 'Efectuar el registro para acceder al portal',
      guideQuestion: '¿Has consultado las guías? Iniciar un nuevo proceso de instalación K1',
      showGuideBeforeInstallation: 'Mostrar las guías antes de cada instalación',
      downloadInstallationManual: 'Descargar el manual de instalación del hardware y de uso de la plataforma',
      playVideoGuide: 'Mirar el vídeo de guía paso a paso sobre el proceso de instalación y monitorización del dispositivo',
      updateInformation: 'Actualizar las informaciones indicadas en los siguientes campos',
      beforeProceeding: 'Antes de continuar...',
      gsmAdvMessage: 'Para completar con éxito el proceso de instalación de un aparato K1, debe haber una buena cobertura móvil GSM en el lugar de instalación',
      newObuInstallation: 'Instalación de un nuevo aparato',
      insertSerialAndPlateNumber: 'Introducir el número de identificación del aparato que se va a instalar y la matrícula del vehículo',
      gsmQuestion: '¿No dispones de esta información?',
      returnToGuidePage: 'Volver a las guías',
      stopInstallationQuestion: '¿Interrumpir la instalación?',
      fullStopInstallationQuestion: '¿Seguro que deseas interrumpir el proceso de instalación?',
      selectReasonStopInstallation: 'Selecciona el motivo para interrumpir la instalación:',
      associationPlateObuNotFoundMessage: 'Asegúrate de haber introducido correctamente los datos y de que el dispositivo que vas a ' +
          'instalar se corresponda con el vehículo del cliente. Si el problema persiste, ponte en contacto con el distribuidor',
      selectMotivation: 'Seleccionar el motivo...',
      returnORReplacementDevice: 'Devolución / Sustitución aparato',
      returnReplaceDeviceMessage: 'Por favor, ponte en contacto con tu distribuidor para iniciar el trámite ' +
          'de Devolución o Sustitución del aparato en el que se han constatado problemas durante el proceso de instalación',
      insertOtherMotivation: 'Introducir una descripción del problema observado durante la instalación del aparato:',
      updatePIN: 'Introducir un PIN numérico de 6 dígitos',
      backLogin: 'Volver a la página de inicio de sesión',
      otpError: 'Código OTP introducido no válido o expirado',
      pinError: 'Los PIN introducidos no coinciden'
    },
    success: {
      postSuccess: 'agregado correctamente',
      putSuccess: 'actualizado correctamente',
      sendMail: 'enviado correctamente',
      verifiedAccess: 'Acceso comprobado',
      verifiedOTP: 'Código OTP comprobado',
      authorizedDevice: 'Tu dispositivo ha sido autorizado',
      canAccessToPortal: 'Ahora puedes acceder a la plataforma de instaladores',
      associationPlateObuFound: 'Asociación ID-MATRÍCULA comprobada',
      pinChanged: 'Nuevo PIN configurado correctamente',
      operationSuccess: 'Operación ejecutada correctamente'
    },
    warnings: {
      accessDenied: 'Acceso denegado',
      alreadyDisabled: 'El usuario ya está deshabilitado',
      invalid: 'No válido | No válida',
      sureToDelete: 'Se está seguro de querer eliminar el elemento',
      continue: 'Continuar',
      valid: 'Válido | Válida',
      phoneForm: 'El campo debe incluir un prefijo y un número de teléfono válido',
      pinForm: 'El campo debe contener 6 dígitos',
      pinConfirm: 'Los PIN introducidos no coinciden'
    },
    errors: {
      associationPlateObuNotFound: 'Asociación ID-MATRÍCULA no encontrada',
      error: 'Se ha producido un error'
    },
    installSteps: {
      title: 'Instalación de un nuevo aparato',
      step1: {
        operationTitle: 'Instalación IPS',
        message: 'Ahora puedes proceder con la instalación del hardware',
        instructions: `Comprueba que el tablero de instrumentos del vehículo esté apagado y efectúa la instalación de la unidad IPS.
                                \n Asegúrate de haber conectado correctamente los cables a la instalación eléctrica y deja preparado el cable USB en el interior del habitáculo.`
      },
      step2: {
        advancedText: 'Avanzadas',
        datePrompt: 'Seleccionar la fecha y hora de inicio de la instalación',
        operationTitle: 'Puesta en marcha del aparato y configuración',
        message: '',
        instructions: 'Coloca la OBU en el interior del habitáculo, en el parabrisas del vehículo, tal y como se muestra en el manual.',
        instructions1: 'Conecta el conector USB a la OBU y haz clic en Continuar.',
        obuPosition: 'Colocación correcta de la OBU',
        monitorMessage: 'Procedimiento de configuración automática en curso...',
        configDesc: '',
        configurationInformation: 'Información sobre la configuración',
        configurationInformationMessage: 'Descripción del procedimiento de configuración desde el punto de vista' +
            'de las indicaciones que la HMI envía al instalador',
        error1: {
          errorMain: 'No ha sido posible comunicar con el dispositivo',
          errorDesc: 'Te rogamos que sigas los pasos siguientes:',
          first: 'Desconecta el cable USB de la OBU',
          second: 'Controla de nuevo las conexiones eléctricas de la unidad IPS',
          third: 'Vuelve a conectar el cable USB a la OBU',
          fourth: 'Comprueba la puesta en marcha del aparato:',
          subF1: 'Reproducción del sonido de inicio;',
          subF2: 'Encendido del led rojo de estado.',
          footMessage: `Haz clic en Reintentar para repetir la comprobación.
    Si el problema persiste, finaliza la instalación.`,
        },
        error2: {
          errorMain: 'Se ha producido un error durante el proceso de configuración',
          errorDesc: 'No es posible configurar la OBU.',
          footMessage: 'Interrumpir el proceso de instalación del aparato'
        },
        error3: {
          errorMain: 'Se ha producido un error durante el proceso de configuración',
          errorDesc: 'La personalización de OBU no se ha completado.',
          footMessage: 'Interrumpir el proceso de instalación del aparato'
        },
      },
      step3: {
        operationTitle: 'Comprobación',
        instructions: 'Como último paso, es necesario comprobar que la OBU reciba correctamente la señal de “activación” del tablero de instrumentos del vehículo.\n',
        instructions1: '\nEnciende el tablero de instrumentos y haz clic en Continuar para iniciar la comprobación remota',
        updating: 'Actualización del estado en curso...',
        error: {
          errorMain: 'Señal de la llave no presente',
          errorDesc: 'Controla la conexión eléctrica de la señal de la llave.\n' +
              '\n' +
              'Repite la comprobación del estado de la OBU haciendo clic en Reintentar.\n' +
              '\n' +
              'Si no se puede completar la comprobación, haz clic en Interrumpir instalación.',
          batteryMain: 'Verifique la conexión del cable',
          batteryDesc: 'Es posible que el cable de alimentación se haya desconectado durante la instalación.\n' +
              '\nAsegúrese también de no haber intercambiado los cables de alimentación y encendido.'
        }
      },
      buttonNext: 'Continuar',
      buttonAbort: 'Interrumpir la instalación',
      bottomMessage: '¿Has encontrado algún problema?',
      bottomLink: 'Volver a las guías',
      retryButton: 'Reintentar',
      successInstallation: 'Configuración OBU completada correctamente',
      obuReady: 'La OBU funciona correctamente\n' +
          '\n' +
          'Instalación completada',
      newInstallation: 'Nueva instalación'
    },
    admin: {
      success: 'Tabla actualizada',
      nav: {
        title: 'Portal Administrador',
        eventsLink: 'Eventos',
        adminMng: 'Gestión Admin'
      },
      eventsPage: {
        title: 'Eventos instalaciones',
        search: 'Búsqueda Cell_no, OBU ID...',
        recordInfo: 'Informes visualizados',
        buttons: {
          next: 'Siguiente',
          previous: 'Anterior',
          autoRefresh: 'Actualización automática'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'En curso'
        },
        filters: {
          buttons: {
            clear: 'Borrar filtros',
            collapse: 'Filtros'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Código Operación',
              startTs: 'Del:',
              startTime: 'Tiempo inicial:',
              endTs: 'Al:',
              endTime: 'Tiempo final:',
              plate: 'Número de matrícula:',
              status: 'Estado:'
            },
            placeholders: {
              obu: 'Seleccionar número de serie OBU',
              opcode: 'Seleccionar operación',
              startTs: 'Seleccionar fecha de inicio',
              endTs: 'Seleccionar fecha de finalización',
              noObu: 'Ningún ID de OBU disponible',
              noOpcode: 'Ningún código de operación disponible',
              allOpts: 'Todos',
              plate: 'Seleccionar Matrícula',
              noPlate: 'Ninguna matrícula disponible',
              searchPlate: 'Buscar matrícula...',
              status: 'Seleccionar Estado',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'En curso'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Número Tel.',
          obu_id: 'OBU ID',
          plate_number: 'Número matrícula',
          timestamp: 'Fecha-Hora',
          opcode_desc: 'Descr. Código de operación',
          description: 'Descripción',
          status: 'Estado'
        },
        opcodes: {
          0: 'Seleccionar el código operación',
          1: 'Inicio de sesión en curso',
          2: 'Inicio de sesión OK',
          3: 'Inicio de sesión KO',
          4: 'OTP en curso',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN en curso',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Registro en curso',
          11: 'Registro OK',
          12: 'Registro KO',
          13: 'Solicitud nuevo OTP OK',
          14: 'Solicitud nuevo OTP KO',
          15: 'Actualización datos usuario en curso',
          16: 'Actualización datos usuario OK',
          17: 'Actualización datos usuario KO',
          18: 'Asociación OBU-Matrícula en curso',
          19: 'Asociación OBU- Matrícula OK',
          20: 'Asociación OBU- Matrícula KO',
          21: 'Instalación IPS en curso',
          22: 'Instalación IPS OK',
          23: 'Configuración K1 en curso',
          24: 'Configuración K1 OK',
          25: 'Configuración K1 - First Run: error en PM',
          26: 'Configuración K1 - First Run: ninguna comunicación',
          27: 'Configuración K1 - First Run: Branding incorrecto',
          28: 'Configuración K1 - Branding: error en servicio',
          29: 'Configuración K1 - Branding: operación expirada',
          30: 'Configuración K1 - Vehicle Config: error de configuración',
          31: 'Configuración K1 - Vehicle Config: operación expirada',
          32: 'Configuración K1 - Tolling DSRC: error en al menos un servicio',
          33: 'Configuración K1 - Tolling DSRC: operación expirada',
          34: 'Configuración K1 - Tolling GNSS: error en al menos un servicio',
          35: 'Configuración K1 - Tolling GNSS: operación expirada',
          36: 'Configuración K1 - Service Activation: error en al menos un servicio',
          37: 'Configuración K1 - Service Activation: operación expirada',
          38: 'Configuración K1 - Reboot: reinicio fallido',
          39: 'Configuración K1 - Power Switch: variación PM fallida',
          40: 'K1 Key en curso',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Instalación en curso',
          44: 'Instalación completada',
          45: 'OBU sin señales luminosas',
          46: 'Cable USB no presente',
          47: 'Dual lock no presente',
          48: 'Cable USB demasiado corto',
          49: 'La OBU no comunica correctamente con señales luminosas y acústicas',
          50: 'Proceso de configuración fallido',
          51: 'La OBU no recibe la señal de activación correctamente',
          52: 'Otro escenario de fallo',
          53: 'El start_time de la asociación era NULL',
        }
      },
      adminMngPage: {
        title: 'Gestión Administradores',
        search: 'Búsqueda...',
        buttons: {
          add: 'Añadir'
        },
        table: {
          actions: 'Acciones',
          phone: 'Teléfono',
          firstName: 'Nombre',
          lastName: 'Apellido',
          company: 'Compañía',
          removed: 'Deshabilitado',
        },
        modal: {
          dataLabels: {
            phone: 'Teléfono',
            confPhone: 'Confirmación teléfono',
            firstName: 'Nombre',
            lastName: 'Apellido',
            email: 'Correo electrónico',
            company: 'Empresa',
            toggle: 'Deshabilitar Cuenta',
          },
          addAdmin: {
            title: 'Añadir Cuenta Administrador',
            body: 'Introduce las informaciones indicadas en los siguientes campos:',
            buttons: {
              cancel: 'Cancelar',
              proceed: 'Introducir'
            }
          },
          modAdmin: {
            title: 'Modificar Cuenta Administrador',
            body: 'Modifica las siguientes informaciones de la cuenta:',
            buttons: {
              cancel: 'Cancelar',
              proceed: 'Actualizar'
            }
          },
          deleteAdmin: {
            title: 'Borrar Cuenta Administrador',
            body: '¿Estás seguro de querer eliminar esta cuenta de la lista de administradores?',
            buttons: {
              cancel: 'No',
              proceed: 'Sí'
            }
          },
          success: {
            title: 'Datos Cuenta Registrados',
            body: 'Cuenta Administrador guardada correctamente'
          },
          error: {
            title: 'Datos no válidos',
            body: 'Administrador ya presente en la lista'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtros',
            clear: 'Borrar filtros'
          },
          dropdowns: {
            labels: {
              removed: 'Cuenta deshabilitada',
              all: 'Todos'
            }
          }
        }
      },

    },
    months: {
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PIN incorrecto',
        1212: 'Usuario no encontrado',
        1226: 'Nombre de usuario ya utilizado',
        1231: 'Número de teléfono no válido',
        1400: 'Parámetro ausente',
        1106: 'OTP introducido incorrecto',
        1214: 'PIN incorrecto (Último intento disponible)',
        4000: 'ID de sesión expirado'

      },
      403: {
        0: 'Acceso denegado',
        1213: 'Usuario bloqueado',
      },
      404: {
        0: 'Recurso no encontrado',
        3: 'Recurso no encontrado',
        5: 'Recurso no encontrado'
      },
      409: {
        7: 'Conflicto durante la eliminación'
      },
      413: 'Tamaño de los datos demasiado grande',
      422: {
        3: 'Error durante la comunicación'
      },
      423: 'La contraseña ha expirado',
      440: 'La sesión ha expirado',
      500: {
        1500: 'Error inesperado del servidor'
      },
      502: 'Error 502, error de comunicación'
    },
    path: {
      '/home': 'Página inicial',
      '/login': 'Acceso'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  fr: {
    message: {
      contact: 'Contact | Contacts',
      installer: 'Installateur | Installateurs',
      access: 'Connexion installateur',
      newAccess: 'Nouvelle connexion installateur',
      phone: 'Portable',
      go: 'Suivant',
      retrieve: 'Récupérer',
      data: 'Données',
      pin: 'PIN',
      new: 'Nouveau',
      registration: 'Inscription',
      otp: 'OTP',
      name: 'Prénom',
      surname: 'Nom',
      email: 'E-mail',
      confirm: 'Confirmer',
      verify: 'Vérifier',
      signin: 'S\'inscrire',
      ok: 'OK',
      completed: 'Terminée',
      portal: 'Portail installateurs',
      error: 'Erreur',
      guide: 'Guides',
      support: 'Assistance installateurs',
      installK1: 'Installer K1',
      download: 'Télécharger',
      video: 'Vidéo',
      userProfile: 'Profil utilisateur',
      changePassword: 'Modifier le mot de passe',
      logout: 'Déconnexion',
      update: 'Mettre à jour',
      cancel: 'Annuler',
      stopInstallation: 'Interrompre l\'installation',
      obuId: 'ID EE',
      plate: 'Plaque d\'immatriculation',
      yes: 'Oui',
      failureInstallation: 'Rapport d\'échec d\'installation',
      select: 'Sélectionner',
      reason: 'Motif',
      dismiss: 'Annuler',
      placement: 'Emplacement',
      problem: ' Problème rencontré…',
      send: 'Envoyer',
      setting: 'Paramétrage',
      set: 'Paramétrer',
      privacy: 'J\'ai lu et j\'accepte les conditions d\'utilisation et la politique de confidentialité',
      invalidPlate: 'La plaque d\'immatriculation saisie n\'est pas valide'
    },
    info: {
      insertTelephone: 'Saisir son numéro de téléphone. Si vous avez déjà un compte Telepass, vous pouvez vous connecter en utilisant les identifiants associés au compte.',
      insertPIN: 'Saisir le code PIN pour se connecter',
      retrieveAccessData: 'Récupérer les données de connexion',
      insertInformation: 'Saisir les informations indiquées dans les champs suivants',
      accessFromNewDevice: 'Vous vous connectez à partir d\'un nouvel équipement. \n\n Pour vérifier la connexion, saisissez le mot de passe' +
          'à usage unique (OTP) envoyé à votre numéro de portable :',
      insertOTP: 'Saisissez le mot de passe à usage unique envoyé à votre numéro de portable',
      completeRegistrationEnteringPIN: 'Terminer l\'inscription en saisissant un code PIN numérique à 6 chiffres',
      otpNotReceivedQuestion: 'Vous n\'avez pas reçu le mot de passe à usage unique?',
      resendOTP: 'Renvoyer le mot de passe à usage unique',
      OTPSent: 'Un mot de passe à usage unique (OTP) a été envoyé à votre numéro de portable pour vérifier votre identité',
      unregisteredUser: 'Utilisateur non inscrit',
      performRegistration: 'Effectuer l\'inscription pour accéder au portail',
      guideQuestion: 'Avez-vous consulté les guides ? Démarrer un nouveau processus d\'installation K1',
      showGuideBeforeInstallation: 'Montrer les guides avant chaque installation',
      downloadInstallationManual: 'Télécharger la notice d\'installation du matériel et d\'utilisation de la plate-forme',
      playVideoGuide: 'Regarder le guide vidéo étape par étape du processus d\'installation et de surveillance de l\'équipement',
      updateInformation: 'Mettre à jour les informations dans les champs suivants',
      beforeProceeding: 'Avant de poursuivre…',
      gsmAdvMessage: 'Pour pouvoir achever correctement le processus d\'installation d\'un équipement K1, il est nécessaire d\'avoir une bonne réception du signal cellulaire GSM sur le lieu d\'installation',
      newObuInstallation: 'Installation d\'un nouvel équipement',
      insertSerialAndPlateNumber: 'Saisir l\'ID de l\'équipement à installer et le numéro d\'immatriculation du véhicule',
      gsmQuestion: 'Vous ne disposez pas de ces informations ?',
      returnToGuidePage: 'Retourner aux guides',
      stopInstallationQuestion: 'Interrompre l\'installation ?',
      fullStopInstallationQuestion: 'Êtes-vous sûr de vouloir interrompre le processus d\'installation ?',
      selectReasonStopInstallation: 'Sélectionner le motif de l\'interruption de l\'installation :',
      associationPlateObuNotFoundMessage: 'Vérifiez que vous avez correctement saisi les données et que le dispositif que vous êtes sur le point ' +
          'd\'installer correspond au véhicule du client. Si le problème persiste, contactez votre partenaire commercial',
      selectMotivation: 'Sélectionner un motif…',
      returnORReplacementDevice: 'Restitution / Remplacement de l\'équipement',
      returnReplaceDeviceMessage: 'Nous vous invitons à contacter votre partenaire commercial pour lancer la procédure ' +
          'de restitution ou de remplacement de l\'équipement sur lequel vous avez rencontré des problèmes durant le processus d\'installation',
      insertOtherMotivation: 'Décrire le problème rencontré durant l\'installation de l\'équipement :',
      updatePIN: 'Saisir un code PIN numérique à 6 chiffres',
      backLogin: 'Retourner à la page de connexion',
      otpError: 'Mot de passe à usage unique non valide ou expiré',
      pinError: 'Les codes PIN saisis ne coïncident pas'
    },
    success: {
      postSuccess: 'ajout réussi',
      putSuccess: 'mise à jour réussie',
      sendMail: 'envoi réussi',
      verifiedAccess: 'Connexion vérifiée',
      verifiedOTP: 'Mot de passe à usage unique vérifié',
      authorizedDevice: 'Votre équipement a été autorisé',
      canAccessToPortal: 'Vous pouvez maintenant accéder à la plate-forme installateurs',
      associationPlateObuFound: 'Association ID-IMMATRICULATION vérifiée',
      pinChanged: 'Nouveau code PIN correctement paramétré',
      operationSuccess: 'Opération réussie'
    },
    warnings: {
      accessDenied: 'Connexion refusée',
      alreadyDisabled: 'L\'utilisateur est déjà désactivé',
      invalid: 'Non valide | Non valide',
      sureToDelete: 'Êtes-vous sûr de vouloir supprimer l\'élément',
      continue: 'Continuer',
      valid: 'Valide | Valide',
      phoneForm: 'Le champ doit être composé d\'un indicatif et d\'un numéro de téléphone valides',
      pinForm: 'Le champ doit être composé de 6 chiffres',
      pinConfirm: 'Les codes PIN saisis ne coïncident pas'
    },
    errors: {
      associationPlateObuNotFound: 'Association ID-IMMATRICULATION introuvable',
      error: 'Une erreur s\'est produite'
    },
    installSteps: {
      title: 'Installation d\'un nouvel équipement',
      step1: {
        operationTitle: 'Installation IPS',
        message: 'Vous pouvez maintenant poursuivre l\'installation du matériel',
        instructions: `Vérifier que le tableau de bord du véhicule est hors tension et procéder à l'installation de l'IPS. 
                                 \n Vérifier que les cordons sont correctement connectés au circuit électrique et installer le cordon USB à l'intérieur de l'habitacle.`
      },
      step2: {
        advancedText: 'Avancées',
        datePrompt: 'Sélectionner la date et l\'heure du début de l\'installation',
        operationTitle: 'Mise en service et configuration de l\'équipement',
        message: '',
        instructions: 'Positionner l\'équipement embarqué à l\'intérieur de l\'habitacle, sur le pare-brise du véhicule, comme indiqué dans la notice.',
        instructions1: 'Raccorder le connecteur USB à l\'équipement embarqué et cliquer sur Continuer.',
        obuPosition: 'Emplacement correct EE',
        monitorMessage: 'Procédure de configuration automatique en cours…',
        configDesc: '',
        configurationInformation: 'Informations sur la configuration',
        configurationInformationMessage: 'Description de la procédure de configuration du point de vue' +
            'du retour vers l\'installateur de l\'IHM',
        error1: {
          errorMain: 'La communication avec l\'équipement n\'a pas été possible',
          errorDesc: 'Nous vous invitons à suivre les étapes suivantes :',
          first: 'Débrancher le cordon USB de l\'équipement embarqué',
          second: 'Revérifier les connexions électriques de l\'IPS',
          third: 'Rebrancher le cordon USB à l\'équipement embarqué',
          fourth: 'Vérifier que l\'équipement se met en marche:',
          subF1: 'Le son de démarrage est émis;',
          subF2: 'Le voyant d\'état rouge s\'allume.',
          footMessage: `Cliquer sur Réessayer pour répéter la vérification.
    Si le problème persiste, interrompre l'installation.`,
        },
        error2: {
          errorMain: 'Une erreur s\'est produite pendant le processus de configuration',
          errorDesc: 'Il n\'est pas possible de configurer l\'équipement embarqué.',
          footMessage: 'Interrompre le processus d\'installation de l\'équipement'
        },
        error3: {
          errorMain: 'Une erreur s\'est produite pendant le processus de configuration',
          errorDesc: 'La personnalisation de l\'OBU n\'est pas terminée.',
          footMessage: 'Interrompre le processus d\'installation de l\'équipement'
        },
      },
      step3: {
        operationTitle: 'Vérifier',
        instructions: 'La dernière étape consiste à vérifier que l\'équipement embarqué reçoit correctement le signal d\'activation du tableau de bord du véhicule.\n',
        instructions1: '\nMettre le contact et cliquer sur Continuer pour lancer la vérification à distance',
        updating: 'Mise à jour état en cours…',
        error: {
          errorMain: 'Signal de clé absent',
          errorDesc: 'Vérifier la connexion électrique du signal de clé.\n' +
              '\n' +
              'Répéter la vérification de l\'état de l\'équipement embarqué en cliquant sur Réessayer.\n' +
              '\n' +
              'S\'il n\'est pas possible de terminer la vérification, cliquer sur Interrompre l\'installation.',
          batteryMain: 'Vérifiez la connexion du câble',
          batteryDesc: 'Le câble d\'alimentation a peut-être été déconnecté lors de l\'installation.\n' +
              '\nAssurez-vous également de ne pas avoir inversé les câbles d\'alimentation et d\'allumage.'
        }
      },
      buttonNext: 'Continuer',
      buttonAbort: 'Interrompre l\'installation',
      bottomMessage: 'Avez-vous rencontré des problèmes?',
      bottomLink: 'Retourner aux guides',
      retryButton: 'Réessayer',
      successInstallation: 'Configuration de l\'équipement embarqué correctement terminée',
      obuReady: 'L\'équipement embarqué fonctionne correctement\n' +
          '\n' +
          'Installation terminée',
      newInstallation: 'Nouvelle installation'
    },
    admin: {
      success: 'Tableau mis à jour',
      nav: {
        title: 'Portail administrateur',
        eventsLink: 'Événements',
        adminMng: 'Gestion Admin'
      },
      eventsPage: {
        title: 'Événements installations',
        search: 'Rechercher Cell_no, OBU ID…',
        recordInfo: 'Enregistrements affichés',
        buttons: {
          next: 'Suivant',
          previous: 'Précédent',
          autoRefresh: 'Mise à jour automatique'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'En cours'
        },
        filters: {
          buttons: {
            clear: 'Nettoyer filtres',
            collapse: 'Filtres'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Code opération',
              startTs: 'Du:',
              startTime: 'Début:',
              endTs: 'Au:',
              endTime: 'Fin:',
              plate: 'Numéro d\'immatriculation:',
              status: 'État:'
            },
            placeholders: {
              obu: 'Sélectionner nº de série EE',
              opcode: 'Sélectionner opération',
              startTs: 'Sélectionner date de début',
              endTs: 'Sélectionner date de fin',
              noObu: 'Aucun ID EE disponible',
              noOpcode: 'Aucun code opération disponible',
              allOpts: 'Tous',
              plate: 'Sélectionner nº d\'immatriculation',
              noPlate: 'Aucun nº d\'immatriculation disponible',
              searchPlate: 'Recherche nº d\'immatriculation…',
              status: 'Sélectionner état',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'En cours'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Numéro de tél.',
          obu_id: 'ID EE',
          plate_number: 'Numéro d\'immatriculation',
          timestamp: 'Date/Heure',
          opcode_desc: 'Descr. Code opération',
          description: 'Description',
          status: 'État'
        },
        opcodes: {
          0: 'Sélectionner le code d\'opération',
          1: 'Connexion en cours',
          2: 'Connexion OK',
          3: 'Connexion KO',
          4: 'OTP en cours',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN en cours',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Inscription en cours',
          11: 'Inscription OK',
          12: 'Inscription KO',
          13: 'Nouvelle demande OTP OK',
          14: 'Nouvelle demande OTP KO',
          15: 'Mise à jour données utilisateur en cours',
          16: 'Mise à jour données utilisateur OK',
          17: 'Mise à jour données utilisateur KO',
          18: 'Association EE-Immatriculation en cours',
          19: 'Association EE-Immatriculation OK',
          20: 'Association EE-Immatriculation KO',
          21: 'Installation IPS en cours',
          22: 'Installation IPS OK',
          23: 'Configuration K1 en cours',
          24: 'Configuration K1 OK',
          25: 'Configuration K1 – First Run : erreur sur PM',
          26: 'Configuration K1 – First Run : aucune communication',
          27: 'Configuration K1 – First Run : Branding incorrect',
          28: 'Configuration K1 – Branding : erreur sur service',
          29: 'Configuration K1 – Branding : délai opération dépassé',
          30: 'Configuration K1 – Vehicle Config : erreur de configuration',
          31: 'Configuration K1 – Vehicle Config : délai opération dépassé',
          32: 'Configuration K1 – Tolling DSRC : erreur sur au moins un service',
          33: 'Configuration K1 – Tolling DSRC : délai opération dépassé',
          34: 'Configuration K1 – Tolling GNSS : erreur sur au moins un service',
          35: 'Configuration K1 – Tolling GNSS : délai opération dépassé',
          36: 'Configuration K1 – Service Activation : erreur sur au moins un service',
          37: 'Configuration K1 – Service Activation : délai opération dépassé',
          38: 'Configuration K1 – Reboot : échec redémarrage',
          39: 'Configuration K1 – Power Switch : échec variation PM',
          40: 'K1 Key en cours',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Installation en cours',
          44: 'Installation terminée',
          45: 'EE sans signaux lumineux',
          46: 'Cordon USB absent',
          47: 'Dual lock absent',
          48: 'Cordon USB trop court',
          49: 'L\'équipement embarqué ne communique pas correctement avec les signaux lumineux et sonores',
          50: 'Échec du processus de configuration',
          51: 'L\'équipement embarqué ne reçoit pas correctement le signal d\'activation',
          52: 'Autre scénario d\'échec',
          53: 'Le start_time de l\'association était NULL',
        }
      },
      adminMngPage: {
        title: 'Gestion Administrateurs',
        search: 'Recherche…',
        buttons: {
          add: 'Ajouter'
        },
        table: {
          actions: 'Actions',
          phone: 'Téléphone',
          firstName: 'Prénom',
          lastName: 'Nom',
          company: 'Entreprise',
          removed: 'Désactivé',
        },
        modal: {
          dataLabels: {
            phone: 'Téléphone',
            confPhone: 'Confirmation téléphone',
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'E-mail',
            company: 'Société',
            toggle: 'Désactiver le compte',
          },
          addAdmin: {
            title: 'Ajouter un compte administrateur',
            body: 'Saisir les informations indiquées dans les champs suivants :',
            buttons: {
              cancel: 'Annuler',
              proceed: 'Saisir'
            }
          },
          modAdmin: {
            title: 'Modifier le compte administrateur',
            body: 'Modifier les informations du compte suivantes :',
            buttons: {
              cancel: 'Annuler',
              proceed: 'Mettre à jour'
            }
          },
          deleteAdmin: {
            title: 'Suppression du compte administrateur',
            body: 'Êtes-vous sûr de vouloir supprimer ce compte de la liste des administrateurs ?',
            buttons: {
              cancel: 'Non',
              proceed: 'Oui'
            }
          },
          success: {
            title: 'Données du compte enregistrées',
            body: 'Enregistrement compte administrateur réussi'
          },
          error: {
            title: 'Données non valides',
            body: 'Administrateur déjà présent dans la liste'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtres',
            clear: 'Nettoyer filtres'
          },
          dropdowns: {
            labels: {
              removed: 'Compte désactivé',
              all: 'Tous'
            }
          }
        }
      },

    },
    months: {
      january: 'Janvier',
      february: 'Février',
      march: 'Mars',
      april: 'Avril',
      may: 'Mai',
      june: 'Juin',
      july: 'Juillet',
      august: 'Août',
      september: 'Septembre',
      october: 'Octobre',
      november: 'Novembre',
      december: 'Décembre'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Code PIN incorrect',
        1212: 'Utilisateur introuvable',
        1226: 'Nom d\'utilisateur déjà utilisé',
        1231: 'Numéro de téléphone non valide',
        1400: 'Paramètre manquant',
        1106: 'Saisie OTP incorrecte',
        1214: 'PIN incorrect (dernière tentative disponible)',
        4000: 'ID de session expiré'

      },
      403: {
        0: 'Connexion refusée',
        1213: 'Utilisateur bloqué',
      },
      404: {
        0: 'Ressource introuvable',
        3: 'Ressource introuvable',
        5: 'Ressource introuvable'
      },
      409: {
        7: 'Conflit durant la suppression'
      },
      413: 'Taille des données trop grande',
      422: {
        3: 'Erreur durant la communication'
      },
      423: 'Le mot de passe a expiré',
      440: 'La session a expiré',
      500: {
        1500: 'Erreur imprévue côté serveur'
      },
      502: 'Erreur 502, erreur de communication'
    },
    path: {
      '/home': 'Home',
      '/login': 'Connexion'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  hu: {
    message: {
      contact: 'Kapcsolat | Kapcsolatok',
      installer: 'Telepítő | Telepítők',
      access: 'Telepítői hozzáférés',
      newAccess: 'Új Telepítői hozzáférés',
      phone: 'Mobiltelefonszám',
      go: 'Tovább',
      retrieve: 'Visszanyerés',
      data: 'Adatok',
      pin: 'PIN',
      new: 'Új',
      registration: 'Regisztráció',
      otp: 'OTP',
      name: 'Keresztnév',
      surname: 'Vezetéknév',
      email: 'E-mail',
      confirm: 'Visszaigazolom',
      verify: 'Ellenőrzés',
      signin: 'Regisztráció',
      ok: 'OK',
      completed: 'Befejeződött',
      portal: 'Telepítői portál',
      error: 'Hiba',
      guide: 'Útmutatók',
      support: 'Telepítői támogatás',
      installK1: 'K1 telepítés',
      download: 'Letöltés',
      video: 'Videó',
      userProfile: 'Felhasználói profil',
      changePassword: 'Jelszó módosítás',
      logout: 'Kijelentkezés',
      update: 'Frissítés',
      cancel: 'Mégsem',
      stopInstallation: 'Telepítés megszakítása',
      obuId: 'OBU azonosító',
      plate: 'Rendszám',
      yes: 'Igen',
      failureInstallation: 'Sikertelen telepítési jelentés',
      select: 'Kiválasztás',
      reason: 'Ok',
      dismiss: 'Mégsem',
      placement: 'Elhelyezés',
      problem: ' Észlelt hibák...',
      send: 'Elküldés',
      setting: 'Beállítás',
      set: 'Beállítás',
      privacy: 'Elolvastam és elfogadom a használati és adatvédelmi feltételeket',
      invalidPlate: 'A megadott rendszám nem érvényes'
    },
    info: {
      insertTelephone: 'Írja be a telefonszámát. Ha már van Telepass-fiókja, akkor a hozzá tartozó hitelesítő adatokkal jelentkezhet be.',
      insertPIN: 'A bejelentkezéshez írja be a PIN-kódot',
      retrieveAccessData: 'Kérje le bejelentkezési adatait',
      insertInformation: 'Adja meg az alábbi mezőkben feltüntetett adatokat',
      accessFromNewDevice: 'Új eszközről jelentkezik be. \n\n A hozzáférés ellenőrzéséhez írja be az' +
          'OTP kódot, amelyet a mobilszámára küldtünk:',
      insertOTP: 'Írja be a mobilszámára küldött OTP kódot',
      completeRegistrationEnteringPIN: 'Fejezze be a regisztrációt egy 6 számjegyű numerikus PIN-kód megadásával',
      otpNotReceivedQuestion: 'Nem kapta meg az OTP kódot?',
      resendOTP: 'OTP kód újraküldése',
      OTPSent: 'A személyazonosságát igazoló kódot (OTP) elküldtük mobilszámára',
      unregisteredUser: 'Nem regisztrált felhasználó',
      performRegistration: 'Regisztráljon a portál eléréséhez',
      guideQuestion: 'Elolvasta az útmutatókat? Indítson el egy új K1 telepítési folyamatot',
      showGuideBeforeInstallation: 'Útmutató megjelenítése minden telepítés előtt',
      downloadInstallationManual: 'Töltse le a hardver telepítési és platform használati útmutatóját',
      playVideoGuide: 'Tekintse meg a lépésről lépésre bemutatott videós útmutatót az eszköz telepítési és felügyeleti folyamatáról',
      updateInformation: 'Frissítse a következő mezőkben feltüntetett információkat',
      beforeProceeding: 'Folytatás előtt...',
      gsmAdvMessage: 'A K1 készülék telepítési folyamatának megfelelő befejezéséhez szükséges, hogy a telepítés helyszínén jó GSM mobiljel vétel legyen',
      newObuInstallation: 'Új készülék telepítése',
      insertSerialAndPlateNumber: 'Adja meg a telepíteni kívánt eszköz azonosítóját és a jármű rendszámát',
      gsmQuestion: 'Nem ismeri ezt az információt?',
      returnToGuidePage: 'Vissza az útmutatókhoz',
      stopInstallationQuestion: 'Megszakítja a telepítést?',
      fullStopInstallationQuestion: 'Biztosan megszakítja a telepítési folyamatot?',
      selectReasonStopInstallation: 'Válassza ki a telepítés megszakításának okát:',
      associationPlateObuNotFoundMessage: 'Győződjön meg arról, hogy helyesen adta meg az adatokat, és hogy a telepített eszköz ' +
          'megfelel-e az ügyfél járművének. Ha a probléma továbbra is fennáll, forduljon értékesítési partneréhez',
      selectMotivation: 'Ok kiválasztása...',
      returnORReplacementDevice: 'A készülék visszaküldése / cseréje',
      returnReplaceDeviceMessage: 'Vegye fel a kapcsolatot értékesítési partnerével, hogy kicserélje vagy visszaküldje azt az eszközt, ' +
          'amelyen a telepítés során problémákat észlelt',
      insertOtherMotivation: 'Írja be az eszköz telepítése során tapasztalt problémát:',
      updatePIN: 'Adjon meg egy 6 számjegyű numerikus PIN-kódot',
      backLogin: 'Vissza a bejelentkezési oldalra',
      otpError: 'Érvénytelen vagy lejárt OTP kódot adott meg',
      pinError: 'A megadott PIN-kódok nem egyeznek'
    },
    success: {
      postSuccess: 'sikeresen hozzáadva',
      putSuccess: 'sikeresen frissítve',
      sendMail: 'sikeresen elküldve',
      verifiedAccess: 'Hozzáférés ellenőrizve',
      verifiedOTP: 'OTP kód ellenőrizve',
      authorizedDevice: 'A készüléket engedélyezték',
      canAccessToPortal: 'Most már hozzáférhet a telepítői platformhoz',
      associationPlateObuFound: 'AZONOSÍTÓ-RENDSZÁM társítás ellenőrizve',
      pinChanged: 'Az új PIN helyesen beállítva',
      operationSuccess: 'A művelet sikeresen végrehajtva'
    },
    warnings: {
      accessDenied: 'Hozzáférés megtagadva',
      alreadyDisabled: 'A felhasználó már le van tiltva',
      invalid: 'Érvénytelen',
      sureToDelete: 'Biztosan törli az elemet',
      continue: 'Folytatás',
      valid: 'Érvényes',
      phoneForm: 'A mezőben legyen egy körzetszám és egy érvényes telefonszám',
      pinForm: 'A mezőben 6 számjegy legyen',
      pinConfirm: 'A megadott PIN-kódok nem egyeznek'
    },
    errors: {
      associationPlateObuNotFound: 'Az AZONOSÍTÓ-RENDSZÁM társítás nem található',
      error: 'Hiba történt'
    },
    installSteps: {
      title: 'Új készülék telepítése',
      step1: {
        operationTitle: 'IPS telepítés',
        message: 'Most folytathatja a hardver telepítését',
        instructions: `Ellenőrizze, hogy a jármű elektromos panelje ki van-e kapcsolva, és telepítse az IPS egységet.
                                 \n Győződjön meg arról, hogy megfelelően csatlakoztatta a kábeleket az ECU-hoz, és helyezze el az USB-kábelt az autóban.`
      },
      step2: {
        advancedText: 'Haladó',
        datePrompt: 'Válassza ki a dátumot és az időt a telepítés megkezdéséhez',
        operationTitle: 'Az eszköz indítása és konfigurálása',
        message: '',
        instructions: 'Helyezze el az OBU-t az utastérben, a jármű szélvédőjén, a kézikönyvben látható módon.',
        instructions1: 'Csatlakoztassa az USB-csatlakozót az OBU-hoz, és kattintson a Folytatás gombra.',
        obuPosition: 'Az OBU megfelelő elhelyezése',
        monitorMessage: 'Az automatikus beállítás folyamatban...',
        configDesc: '',
        configurationInformation: 'Konfigurációs információk',
        configurationInformationMessage: 'A konfigurációs eljárás leírása a HMI-től a telepítőnek' +
            'küldött visszacsatolás szempontjából',
        error1: {
          errorMain: 'Nem lehetett kommunikálni a készülékkel',
          errorDesc: 'Kövesse az alábbi lépéseket:',
          first: 'Húzza ki az USB-kábelt az OBU-ból',
          second: 'Ellenőrizze újra az IPS egység elektromos csatlakozásait',
          third: 'Csatlakoztassa újra az USB-kábelt az OBU-hoz',
          fourth: 'Ellenőrizze az eszköz indítását:',
          subF1: 'Indítási hang lejátszása;',
          subF2: 'Piros állapotjelző világít.',
          footMessage: `Kattintson az újra gombra az ellenőrzés megismétléséhez.
    Ha a probléma továbbra is fennáll, állítsa le a telepítést.`,
        },
        error2: {
          errorMain: 'Hiba történt a beállítási folyamat során',
          errorDesc: 'Az OBU nem konfigurálható.',
          footMessage: 'Állítsa le az eszköz telepítési folyamatát'
        },
        error3: {
          errorMain: 'Hiba történt a beállítási folyamat során',
          errorDesc: 'Az OBU személyre szabása nem fejeződött be.',
          footMessage: 'Állítsa le az eszköz telepítési folyamatát'
        },
      },
      step3: {
        operationTitle: 'Ellenőrzés',
        instructions: 'Utolsó lépésként ellenőrizze, hogy az OBU megfelelően fogadja-e az „aktiválás” jelet a jármű elektromos paneljétől.\n',
        instructions1: '\nKapcsolja be a panelt, és kattintson a Folytatás gombra a távoli ellenőrzés elindításához',
        updating: 'Állapot frissítése...',
        error: {
          errorMain: 'Nincs kulcsjel',
          errorDesc: 'Ellenőrizze a kulcsjel elektromos csatlakozását.\n' +
              '\n' +
              'Ismételje meg az OBU állapotellenőrzését az újrapróbálkozás gombra kattintva.\n' +
              '\n' +
              'Ha nem tudja befejezni az ellenőrzést, kattintson a Telepítés megszakítása gombra.',
          batteryMain: 'Ellenőrizze a kábelcsatlakozást',
          batteryDesc: 'Az áramellátó kábel a telepítés során lecsatlakozhatott.\n' +
              '\nGyőződjön meg arról is, hogy nem cserélte fel az áram- és gyújtáskábeleket.'
        }
      },
      buttonNext: 'Folytatás',
      buttonAbort: 'Telepítés megszakítása',
      bottomMessage: 'Problémát észlelt?',
      bottomLink: 'Vissza az útmutatókhoz',
      retryButton: 'Újra',
      successInstallation: 'Az OBU konfigurálása sikeresen befejeződött',
      obuReady: 'Az OBU megfelelően működik\n' +
          '\n' +
          'A telepítés befejeződött',
      newInstallation: 'Új telepítés'
    },
    admin: {
      success: 'Frissített táblázat',
      nav: {
        title: 'Adminisztrátori portál',
        eventsLink: 'Események',
        adminMng: 'Admin kezelés'
      },
      eventsPage: {
        title: 'Telepítői események',
        search: 'Cell_no, OBU ID keresés...',
        recordInfo: 'Megjelenített adatok',
        buttons: {
          next: 'Következő',
          previous: 'Előző',
          autoRefresh: 'Auto-frissítés'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Folyamatban'
        },
        filters: {
          buttons: {
            clear: 'Szűrés törlése',
            collapse: 'Szűrők'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Működési kód',
              startTs: 'Kezdet:',
              startTime: 'Kezdés idő:',
              endTs: 'Befejezés:',
              endTime: 'Befejezési idő:',
              plate: 'Rendszám:',
              status: 'Állapot:'
            },
            placeholders: {
              obu: 'OBU sorozatszám kiválasztása',
              opcode: 'Művelet kiválasztása',
              startTs: 'Kezdődátum kiválasztása',
              endTs: 'Befejezési dátum kiválasztása',
              noObu: 'Nincs használható OBU azonosító',
              noOpcode: 'Nincs rendelkezésre álló opcode',
              allOpts: 'Összes',
              plate: 'Rendszám kiválasztása',
              noPlate: 'Nincs rendelkezésre álló rendszám',
              searchPlate: 'Rendszám keresése...',
              status: 'Állapot kiválasztása',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Folyamatban'
            },
          }
        },
        table: {
          id: 'Azonosító',
          cell_no: 'Telefonsz.',
          obu_id: 'OBU azonosító',
          plate_number: 'Rendszám',
          timestamp: 'Dátum-idő',
          opcode_desc: 'Opcode leírás',
          description: 'Leírás',
          status: 'Állapot'
        },
        opcodes: {
          0: 'Működési kód kiválasztása',
          1: 'Bejelentkezés folyamatban',
          2: 'Bejelentkezés rendben',
          3: 'Bejelentkezés hibás',
          4: 'OTP folyamatban',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN folyamatban',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Regisztráció folyamatban',
          11: 'Regisztráció OK',
          12: 'Regisztráció KO',
          13: 'Új OTP kérés OK',
          14: 'Új OTP kérés KO',
          15: 'Felhasználói adatok frissítése',
          16: 'Felhasználó frissítése OK',
          17: 'Felhasználó frissítése KO',
          18: 'OBU-rendszám társítás folyamatban',
          19: 'OBU-rendszám társítás OK',
          20: 'OBU-rendszám társítás KO',
          21: 'IPS telepítés folyamatban',
          22: 'IPS telepítés OK',
          23: 'K1 konfiguráció folyamatban',
          24: 'K1 konfiguráció OK',
          25: 'K1 konfiguráció - First run: PM hiba',
          26: 'K1 konfiguráció - First run: nincs kommunikáció',
          27: 'K1 konfiguráció - First run: hibás branding',
          28: 'K1 konfiguráció - Branding: szolgáltatási hiba',
          29: 'K1 konfiguráció - Branding: befejeződött művelet',
          30: 'K1 konfiguráció - Vehicle Config: konfigurációs hiba',
          31: 'K1 konfiguráció - Vehicle Config: befejeződött művelet',
          32: 'K1 konfiguráció - Tolling DSRC: hiba legalább egy szolgáltatáson',
          33: 'K1 konfiguráció - Tolling DSRC: befejeződött művelet',
          34: 'K1 konfiguráció - Tolling GNSS: hiba legalább egy szolgáltatáson',
          35: 'K1 konfiguráció - Tolling GNSS: befejeződött művelet',
          36: 'K1 konfiguráció - Service Activation: hiba legalább egy szolgáltatáson',
          37: 'K1 konfiguráció - Service Activation: befejeződött művelet',
          38: 'K1 konfiguráció - Reboot: sikertelen újraindítás',
          39: 'K1 konfiguráció - Power Switch: sikertelen PM változás',
          40: 'K1 Key folyamatban',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Telepítés folyamatban',
          44: 'A telepítés befejeződött',
          45: 'OBU fényjelzések nélkül',
          46: 'USB kábel nincs jelen',
          47: 'Dual lock nincs jelen',
          48: 'USB kábel túl rövid',
          49: 'Az OBU nem kommunikál megfelelően a fény- és hangjelzésekkel',
          50: 'A konfigurációs folyamat sikertelen',
          51: 'Az OBU nem fogadja megfelelően az aktiválási jelet',
          52: 'Újabb sikertelen esemény',
          53: 'A társítás start_time NULL volt',
        }
      },
      adminMngPage: {
        title: 'Adminisztrátor kezelés',
        search: 'Keresés...',
        buttons: {
          add: 'Hozzáadás'
        },
        table: {
          actions: 'Tevékenységek',
          phone: 'Telefon',
          firstName: 'Keresztnév',
          lastName: 'Vezetéknév',
          company: 'Cég',
          removed: 'Kikapcsolt',
        },
        modal: {
          dataLabels: {
            phone: 'Telefon',
            confPhone: 'Telefon megerősítés',
            firstName: 'Keresztnév',
            lastName: 'Vezetéknév',
            email: 'E-mail',
            company: 'Cég',
            toggle: 'Fiók kikapcsolás',
          },
          addAdmin: {
            title: 'Adminisztrátori fiók hozzáadása',
            body: 'Adja meg a következő mezőkben feltüntetett információkat:',
            buttons: {
              cancel: 'Mégsem',
              proceed: 'Beírás'
            }
          },
          modAdmin: {
            title: 'Adminisztrátori fiók szerkesztése',
            body: 'A következő fiókadatok szerkesztése:',
            buttons: {
              cancel: 'Mégsem',
              proceed: 'Frissítés'
            }
          },
          deleteAdmin: {
            title: 'Adminisztrátori fiók törlése',
            body: 'Biztosan eltávolítja ezt a fiókot az adminisztrátori listáról?',
            buttons: {
              cancel: 'Nem',
              proceed: 'Igen'
            }
          },
          success: {
            title: 'Regisztrált fiók adatai',
            body: 'A rendszergazdai fiók sikeresen mentve'
          },
          error: {
            title: 'Nem érvényes adatok',
            body: 'Az adminisztrátor már szerepel'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Szűrők',
            clear: 'Szűrés törlése'
          },
          dropdowns: {
            labels: {
              removed: 'Kikapcsolt fiók',
              all: 'Összes'
            }
          }
        }
      },

    },
    months: {
      january: 'Január',
      february: 'Február',
      march: 'Március',
      april: 'Április',
      may: 'Május',
      june: 'Június',
      july: 'Július',
      august: 'Augusztus',
      september: 'Szeptember',
      october: 'Október',
      november: 'November',
      december: 'December'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Helytelen PIN',
        1212: 'Nem találja a felhasználót',
        1226: 'Felhasználónév már használt',
        1231: 'Telefonszám érvénytelen',
        1400: 'Hiányos paraméter',
        1106: 'Hibásan megadott OTP',
        1214: 'Hibás PIN (utolsó lehetőség)',
        4000: 'Lejárt munkamenet-azonosító'

      },
      403: {
        0: 'Hozzáférés megtagadva',
        1213: 'Blokkolt felhasználó',
      },
      404: {
        0: 'Nincs forrás',
        3: 'Nincs forrás',
        5: 'Nincs forrás'
      },
      409: {
        7: 'Konfliktus az eltávolítás során'
      },
      413: 'Az adatméret túl nagy',
      422: {
        3: 'Hiba a kommunikáció során'
      },
      423: 'A jelszó lejárt',
      440: 'A munkamenet lejárt',
      500: {
        1500: 'Váratlan szerveroldali hiba'
      },
      502: '502. hiba, kommunikációs hiba'
    },
    path: {
      '/home': 'Kezdőlap',
      '/login': 'Hozzáférés'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  nl: {
    message: {
      contact: 'Contactpersoon | Contactpersonen',
      installer: 'Installateur | Installateurs',
      access: 'Toegang installateur',
      newAccess: 'Nieuw Toegang installateur',
      phone: 'Mobiele telefoon',
      go: 'Volgende',
      retrieve: 'Ophalen',
      data: 'Gegevens',
      pin: 'PINCODE',
      new: 'Nieuw',
      registration: 'Registratie',
      otp: 'OTP',
      name: 'Voornaam',
      surname: 'Achternaam',
      email: 'E-mail',
      confirm: 'Bevestig',
      verify: 'Verifiëren',
      signin: 'Maak een account aan',
      ok: 'OK',
      completed: 'Voltooid',
      portal: 'Installateursportaal',
      error: 'Fout',
      guide: 'Hulpgidsen',
      support: 'Ondersteuning installateurs',
      installK1: 'K1 installeren',
      download: 'Downloaden',
      video: 'Video',
      userProfile: 'Gebruikersprofiel',
      changePassword: 'Wachtwoord wijzigen',
      logout: 'Uitloggen',
      update: 'Bijwerken',
      cancel: 'Annuleren',
      stopInstallation: 'Installatie afbreken',
      obuId: 'OBU ID',
      plate: 'Kentekenplaat',
      yes: 'Ja',
      failureInstallation: 'Melding installatie mislukt',
      select: 'Selecteren',
      reason: 'Reden',
      dismiss: 'Annuleren',
      placement: 'Plaatsen',
      problem: ' Probleem opgetreden...',
      send: 'Verzenden',
      setting: 'Instelling',
      set: 'Instellen',
      privacy: 'Ik heb de gebruiksvoorwaarden en het privacybeleid gelezen en ga akkoord',
      invalidPlate: 'Het ingevulde kenteken is niet geldig'
    },
    info: {
      insertTelephone: 'Vul uw telefoonnummer in. Als u al een Telepass-account hebt, kunt u inloggen met de bijbehorende gegevens.',
      insertPIN: 'Vul uw PINCODE in om in te loggen',
      retrieveAccessData: 'Inloggegevens ophalen',
      insertInformation: 'Vul de gegevens in de volgende velden in',
      accessFromNewDevice: 'U logt in vanaf een nieuw apparaat. \n\n Vul de OTP-code in die wij naar uw mobiele' +
          'telefoonnummer hebben gestuurd om de toegang te verifiëren:',
      insertOTP: 'Vul de OTP-code in die naar uw mobiele nummer is verstuurd',
      completeRegistrationEnteringPIN: 'Vul een 6-cijferige numerieke PINCODE in om de registratie te voltooien',
      otpNotReceivedQuestion: 'Heeft u geen OTP-code ontvangen?',
      resendOTP: 'OTP-code opnieuw verzenden',
      OTPSent: 'Er is een code naar uw mobiele telefoonnummer gestuurd om uw identiteit te verifiëren (OTP)',
      unregisteredUser: 'Niet-geregistreerde gebruiker',
      performRegistration: 'Registreer om toegang te krijgen tot het portaal',
      guideQuestion: 'Heeft u de hulpgidsen geraadpleegd? Een nieuw proces voor de installatie van K1 starten',
      showGuideBeforeInstallation: 'Toon vóór elke installatie de hulpgidsen',
      downloadInstallationManual: 'Download de handleiding voor hardware-installatie en platformgebruik',
      playVideoGuide: 'Bekijk de stapsgewijze videogids van het installatie- en bewakingsproces van het apparaat',
      updateInformation: 'Werk in de volgende velden de gegevens bij',
      beforeProceeding: 'Voordat u verder gaat...',
      gsmAdvMessage: 'Om het installatieproces van een K1-apparaat succesvol af te ronden, moet er een goede ontvangst van het GSM-signaal zijn op de plaats van installatie',
      newObuInstallation: 'Installatie nieuw apparaat',
      insertSerialAndPlateNumber: 'Vul de identificatie van het te installeren apparaat en het kenteken van het voertuig in',
      gsmQuestion: 'Beschikt u niet over deze gegevens?',
      returnToGuidePage: 'Terug naar de hulpgidsen',
      stopInstallationQuestion: 'Installatie afbreken?',
      fullStopInstallationQuestion: 'Weet u zeker dat u het installatieproces wilt afbreken?',
      selectReasonStopInstallation: 'Selecteer de reden voor het afbreken van de installatie:',
      associationPlateObuNotFoundMessage: 'Controleer of u de gegevens correct heeft ingevuld en of het apparaat dat u wilt ' +
          'installeren overeenkomt met het voertuig van de klant. Neem contact op met uw verkooppartner als het probleem aanhoudt',
      selectMotivation: 'Selecteer de reden...',
      returnORReplacementDevice: 'Apparaat terugsturen/vervangen',
      returnReplaceDeviceMessage: 'Neem contact op met uw verkooppartner om de retour- of vervangingsprocedure ' +
          'te starten voor het apparaat waarmee u bij de installatie problemen heeft ondervonden',
      insertOtherMotivation: 'Geef een beschrijving van het probleem dat u tijdens de installatie van het apparaat heeft ondervonden:',
      updatePIN: 'Vul een 6-cijferige numerieke PINCODE in',
      backLogin: 'Terug naar de inlogpagina',
      otpError: 'Ingevulde OTP-code is niet geldig of verlopen',
      pinError: 'De ingevulde PINCODES komen niet overeen'
    },
    success: {
      postSuccess: 'met goed gevolg toegevoegd',
      putSuccess: 'met goed gevolg bijgewerkt',
      sendMail: 'met goed gevolg verzonden',
      verifiedAccess: 'Toegang geverifieerd',
      verifiedOTP: 'OTP-code geverifieerd',
      authorizedDevice: 'Uw apparaat is geautoriseerd',
      canAccessToPortal: 'U heeft nu toegang tot het installateursplatform',
      associationPlateObuFound: 'Koppeling ID-KENTEKEN geverifieerd',
      pinChanged: 'Nieuwe PINCODE correct ingesteld',
      operationSuccess: 'Bewerking met goed gevolg uitgevoerd'
    },
    warnings: {
      accessDenied: 'Toegang geweigerd',
      alreadyDisabled: 'Gebruiker is al gedeactiveerd',
      invalid: 'Niet geldig',
      sureToDelete: 'Het item moet worden verwijderd',
      continue: 'Doorgaan',
      valid: 'Geldig',
      phoneForm: 'Het veld moet een geldig netnummer en een geldig telefoonnummer bevatten',
      pinForm: 'Het veld moet 6 cijfers bevatten',
      pinConfirm: 'De ingevulde PINCODES komen niet overeen'
    },
    errors: {
      associationPlateObuNotFound: 'Koppeling ID-KENTEKEN niet gevonden',
      error: 'Er is een fout geconstateerd'
    },
    installSteps: {
      title: 'Installatie nieuw apparaat',
      step1: {
        operationTitle: 'Installatie IPS',
        message: 'U kunt nu overgaan op het installeren van de hardware',
        instructions: `Controleer of het elektrische paneel van het voertuig is uitgeschakeld en installeer de IPS-unit.
                                 \n Controleer of de kabels correct op het elektrische systeem zijn aangesloten en sluit de USB-kabel in het voertuig aan.`
      },
      step2: {
        advancedText: 'Geavanceerd',
        datePrompt: 'Selecteer datum en tijd waarop de installatie moet starten',
        operationTitle: 'Apparaat opstarten en configureren',
        message: '',
        instructions: 'Plaats de OBU in het voertuig, op de voorruit, zoals aangegeven in de handleiding.',
        instructions1: 'Sluit de USB-connector aan op de OBU en klik op \'Doorgaan\'.',
        obuPosition: 'OBU is correct geplaatst',
        monitorMessage: 'Automatische configuratieprocedure bezig ...',
        configDesc: '',
        configurationInformation: 'Configuratie-informatie',
        configurationInformationMessage: 'Beschrijving van de configuratieprocedure vanuit het oogpunt van' +
            ' feedback aan de installateur vanuit de HMI',
        error1: {
          errorMain: 'Communicatie met het apparaat was niet mogelijk',
          errorDesc: 'Voer de volgende stappen uit:',
          first: 'Koppel de USB-kabel los van de OBU',
          second: 'Controleer nogmaals de elektrische aansluitingen van de IPS-unit',
          third: 'Sluit de USB-kabel weer op de OBU aan',
          fourth: 'Controleer het opstarten van het apparaat:',
          subF1: 'klinken van opstartgeluid;',
          subF2: 'branden van rode status-led.',
          footMessage: `Klik op 'Opnieuw proberen' om de verificatie te herhalen.
    Beëindig de installatie als het probleem aanhoudt.`,
        },
        error2: {
          errorMain: 'Er is een fout opgetreden tijdens het configuratieproces',
          errorDesc: 'De OBU kan niet worden geconfigureerd.',
          footMessage: 'Breek het installatieproces van het apparaat af'
        },
        error3: {
          errorMain: 'Er is een fout opgetreden tijdens het configuratieproces',
          errorDesc: 'Az OBU személyre szabása nem fejeződött be.',
          footMessage: 'Breek het installatieproces van het apparaat af'
        },
      },
      step3: {
        operationTitle: 'Verifiëren',
        instructions: 'Als laatste stap moet worden gecontroleerd of de OBU het \'activeringssignaal\' van het elektrische paneel van het voertuig correct ontvangt.\n',
        instructions1: '\nSchakel het paneel in en klik op \'Doorgaan\' om de verificatie op afstand te starten',
        updating: 'Status wordt bijgewerkt...',
        error: {
          errorMain: 'Sleutelsignaal niet aanwezig',
          errorDesc: 'Controleer de elektrische aansluiting van het sleutelsignaal.\n' +
              '\n' +
              'Klik op \'Opnieuw proberen\' om de OBU-statuscontrole te herhalen.\n' +
              '\n' +
              'Klik op \'Installatie afbreken\' als de controle niet kan worden voltooid.',
          batteryMain: 'Controleer de kabelverbinding',
          batteryDesc: 'De voedingskabel kan tijdens de installatie zijn losgekoppeld.\n' +
              '\nZorg er ook voor dat u de stroom- en ontstekingskabels niet heeft omgewisseld.'
        }
      },
      buttonNext: 'Doorgaan',
      buttonAbort: 'Installatie afbreken',
      bottomMessage: 'Heeft u problemen ondervonden?',
      bottomLink: 'Terug naar de hulpgidsen',
      retryButton: 'Opnieuw proberen',
      successInstallation: 'OBU is met goed gevolg geconfigureerd',
      obuReady: 'De OBU werkt correct\n' +
          '\n' +
          'Installatie is voltooid',
      newInstallation: 'Nieuwe installatie'
    },
    admin: {
      success: 'Bijgewerkte tabel',
      nav: {
        title: 'Beheerportaal',
        eventsLink: 'Gebeurtenissen',
        adminMng: 'Beheer admin'
      },
      eventsPage: {
        title: 'Installatie gebeurtenissen',
        search: 'Zoeken naar Cell_no, OBU ID...',
        recordInfo: 'Weergegeven records',
        buttons: {
          next: 'Volgende',
          previous: 'Vorige',
          autoRefresh: 'Automatisch bijwerken'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Bezig'
        },
        filters: {
          buttons: {
            clear: 'Filters reinigen',
            collapse: 'Filters'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Bewerkingscode',
              startTs: 'Van:',
              startTime: 'Begintijd:',
              endTs: 'Tot:',
              endTime: 'Eindtijd:',
              plate: 'Kenteken:',
              status: 'Status:'
            },
            placeholders: {
              obu: 'OBU serieel selecteren',
              opcode: 'Bewerking selecteren',
              startTs: 'Startdatum selecteren',
              endTs: 'Einddatum selecteren',
              noObu: 'Geen OBU-id beschikbaar',
              noOpcode: 'Geen bewerkingscode beschikbaar',
              allOpts: 'Alle',
              plate: 'Kenteken selecteren',
              noPlate: 'Geen kenteken beschikbaar',
              searchPlate: 'Kenteken zoeken...',
              status: 'Status selecteren',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Bezig'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Telefoonnummer',
          obu_id: 'OBU ID',
          plate_number: 'Kenteken',
          timestamp: 'Datum-Tijd',
          opcode_desc: 'Beschr. bewerkingscode',
          description: 'Beschrijving',
          status: 'Status'
        },
        opcodes: {
          0: 'Bewerkingscode selecteren',
          1: 'Inloggen bezig',
          2: 'Inloggen OK',
          3: 'Inloggen KO',
          4: 'OTP bezig',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PINCODE bezig',
          8: 'PINCODE OK',
          9: 'PINCODE KO',
          10: 'Registratie bezig',
          11: 'Registratie OK',
          12: 'Registratie KO',
          13: 'Nieuw OTP-verzoek OK',
          14: 'Nieuw OTP-verzoek KO',
          15: 'Gebruikersgegevens worden bijgewerkt',
          16: 'Gebruikersgegevens bijwerken OK',
          17: 'Gebruikersgegevens bijwerken KO',
          18: 'Koppeling OBU-kenteken bezig',
          19: 'Koppeling OBU-kenteken OK',
          20: 'Koppeling OBU-kenteken KO',
          21: 'Installatie IPS bezig',
          22: 'Installatie IPS OK',
          23: 'Configuratie K1 bezig',
          24: 'Configuratie K1 OK',
          25: 'Configuratie K1 - First Run: fout op PM',
          26: 'Configuratie K1 - First Run: geen communicatie',
          27: 'Configuratie K1 - First Run: branding fout',
          28: 'Configuratie K1 - Branding: fout bij service',
          29: 'Configuratie K1 - Branding: bewerking in time-out',
          30: 'Configuratie K1 - Vehicle Config: fout in configuratie',
          31: 'Configuratie K1 - Vehicle Config: bewerking in time-out',
          32: 'Configuratie K1 - Tolling DSRC: fout bij minimaal één service',
          33: 'Configuratie K1 - Tolling DSRC: bewerking in time-out',
          34: 'Configuratie K1 - Tolling GNSS: fout bij minimaal één service',
          35: 'Configuratie K1 - Tolling GNSS: bewerking in time-out',
          36: 'Configuratie K1 - Service Activation: fout bij minimaal één service',
          37: 'Configuratie K1 - Service Activation: bewerking in time-out',
          38: 'Configuratie K1 - Reboot: opnieuw opstarten mislukt',
          39: 'Configuratie K1 - Power Switch: PM-variatie mislukt',
          40: 'K1 Key bezig',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Installatie bezig',
          44: 'Installatie voltooid',
          45: 'OBU zonder lichtsignalen',
          46: 'USB-kabel niet aanwezig',
          47: 'Dual lock niet aanwezig',
          48: 'USB-kabel te kort',
          49: 'De obu communiceert niet correct met licht- en geluidssignalen',
          50: 'Configuratieproces is mislukt',
          51: 'OBU ontvangt activeringssignaal niet correct',
          52: 'Een ander storingsscenario',
          53: 'De start_time van de koppeling was NULL',
        }
      },
      adminMngPage: {
        title: 'Beheerdersbeheer',
        search: 'Zoeken ...',
        buttons: {
          add: 'Toevoegen'
        },
        table: {
          actions: 'Acties',
          phone: 'Telefoonnummer',
          firstName: 'Voornaam',
          lastName: 'Achternaam',
          company: 'Bedrijf',
          removed: 'Gedeactiveerd',
        },
        modal: {
          dataLabels: {
            phone: 'Telefoonnummer',
            confPhone: 'Telefoonnummer bevestigen',
            firstName: 'Voornaam',
            lastName: 'Achternaam',
            email: 'E-mail',
            company: 'Bedrijf',
            toggle: 'Account deactiveren',
          },
          addAdmin: {
            title: 'Beheerdersaccount toevoegen',
            body: 'Vul de gegevens in de volgende velden in:',
            buttons: {
              cancel: 'Annuleren',
              proceed: 'Invullen'
            }
          },
          modAdmin: {
            title: 'Beheerdersaccount wijzigen',
            body: 'Wijzig de volgende accountgegevens:',
            buttons: {
              cancel: 'Annuleren',
              proceed: 'Bijwerken'
            }
          },
          deleteAdmin: {
            title: 'Beheerdersaccount verwijderen',
            body: 'Weet u zeker dat u dit account wilt verwijderen van de beheerderslijst?',
            buttons: {
              cancel: 'Nee',
              proceed: 'Ja'
            }
          },
          success: {
            title: 'Geregistreerde accountgegevens',
            body: 'Beheerdersaccount met goed gevolg opgeslagen'
          },
          error: {
            title: 'Gegevens niet geldig',
            body: 'Beheerder staat al in de lijst'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filters',
            clear: 'Filters reinigen'
          },
          dropdowns: {
            labels: {
              removed: 'Account gedeactiveerd',
              all: 'Alle'
            }
          }
        }
      },

    },
    months: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PINCODE onjuist',
        1212: 'Gebruiker niet gevonden',
        1226: 'Gebruikersnaam al in gebruik',
        1231: 'Telefoonnummer niet geldig',
        1400: 'Ontbrekende parameter',
        1106: 'Verkeerde OTP ingevuld',
        1214: 'Verkeerde PIN (laatste beschikbare poging)',
        4000: 'Sessie-ID verlopen'

      },
      403: {
        0: 'Toegang geweigerd',
        1213: 'Gebruiker geblokkeerd',
      },
      404: {
        0: 'Bron niet gevonden',
        3: 'Bron niet gevonden',
        5: 'Bron niet gevonden'
      },
      409: {
        7: 'Conflict bij verwijderen'
      },
      413: 'Gegevensformaat te groot',
      422: {
        3: 'Fout tijdens communicatie'
      },
      423: 'Het wachtwoord is verlopen',
      440: 'De sessie is verlopen',
      500: {
        1500: 'Onverwachte fout serverzijde'
      },
      502: 'Fout 502, communicatiefout'
    },
    path: {
      '/home': 'Home',
      '/login': 'Toegang'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  ro: {
    message: {
      contact: 'Contact | Contacte',
      installer: 'Instalator | Instalatori',
      access: 'Acces instalator',
      newAccess: 'Nou Acces instalator',
      phone: 'Telefon mobil',
      go: 'Înainte',
      retrieve: 'Recuperează',
      data: 'Date',
      pin: 'PIN',
      new: 'Nou',
      registration: 'Înregistrare',
      otp: 'OTP',
      name: 'Prenume',
      surname: 'Nume',
      email: 'E-mail',
      confirm: 'Confirmă',
      verify: 'Verificare',
      signin: 'Înregistrează-te',
      ok: 'OK',
      completed: 'Finalizată',
      portal: 'Portal Instalatori',
      error: 'Eroare',
      guide: 'Ghiduri',
      support: 'Asistență pentru Instalatori',
      installK1: 'Instalează K1',
      download: 'Descarcă',
      video: 'Video',
      userProfile: 'Profil utilizator',
      changePassword: 'Modifică parola',
      logout: 'Deconectează-te',
      update: 'Actualizează',
      cancel: 'Anulează',
      stopInstallation: 'Anulează instalarea',
      obuId: 'ID unitate de bord',
      plate: 'Plăcuță de înmatriculare',
      yes: 'Da',
      failureInstallation: 'Raport Instalare nereușită',
      select: 'Selectează',
      reason: 'Motiv',
      dismiss: 'Anulează',
      placement: 'Plasare',
      problem: ' Problema întâmpinată...',
      send: 'Trimite',
      setting: 'Setare',
      set: 'Setează',
      privacy: 'Am citit și accept condițiile de utilizare și politica de confidențialitate',
      invalidPlate: 'Plăcuța de înmatriculare introdusă nu este valabilă'
    },
    info: {
      insertTelephone: 'Introdu numărul tău de telefon. Dacă ai deja un cont Telepass, te poți conecta folosind acreditările asociate acestuia.',
      insertPIN: 'Introdu codul PIN pentru a te conecta',
      retrieveAccessData: 'Recuperează datele de conectare',
      insertInformation: 'Introdu informațiile indicate în câmpurile de mai jos',
      accessFromNewDevice: 'Te conectezi de pe un nou dispozitiv. \n\n Pentru a verifica conectarea, introdu codul' +
          'OTP pe care l-am trimis la numărul tău de telefon mobil:',
      insertOTP: 'Introdu codul OTP trimis la numărul tău de telefon mobil',
      completeRegistrationEnteringPIN: 'Finalizează înregistrarea introducând un cod PIN numeric din 6 cifre',
      otpNotReceivedQuestion: 'Nu ai primit codul OTP?',
      resendOTP: 'Retrimite codul OTP',
      OTPSent: 'S-a trimis un cod pentru a-ți verifica identitatea (OTP) la numărul tău de mobil',
      unregisteredUser: 'Utilizator neînregistrat',
      performRegistration: 'Înregistrează-te pentru a te conecta la portal',
      guideQuestion: 'Ai consultat ghidurile? Începe un nou proces de instalare K1',
      showGuideBeforeInstallation: 'Afișează ghidurile înainte de fiecare instalare',
      downloadInstallationManual: 'Descarcă manualul de instalare hardware și de utilizare a platformei',
      playVideoGuide: 'Vizionează ghidul video pas cu pas al procesului de instalare și monitorizare a dispozitivului',
      updateInformation: 'Actualizează informațiile indicate în câmpurile de mai jos',
      beforeProceeding: 'Înainte de a continua...',
      gsmAdvMessage: 'Pentru a finaliza corect procesul de instalare a unui dispozitiv K1, trebuie să existe o recepție bună a semnalului celular GSM la locul de instalare',
      newObuInstallation: 'Instalare dispozitiv nou',
      insertSerialAndPlateNumber: 'Introdu identificatorul dispozitivului de instalat și plăcuța de înmatriculare a vehiculului',
      gsmQuestion: 'Nu ai aceste informații?',
      returnToGuidePage: 'Înapoi la ghiduri',
      stopInstallationQuestion: 'Anulezi instalarea?',
      fullStopInstallationQuestion: 'Sigur dorești să anulezi procesul de instalare?',
      selectReasonStopInstallation: 'Selectează motivul pentru care anulezi instalarea:',
      associationPlateObuNotFoundMessage: 'Verifică dacă ai introdus datele corect și dacă dispozitivul pe care urmează să-l ' +
          'instalezi corespunde vehiculului clientului. Dacă problema persistă, contactează-ți partenerul de vânzări',
      selectMotivation: 'Selectează motivul...',
      returnORReplacementDevice: 'Restituire/Înlocuire dispozitiv',
      returnReplaceDeviceMessage: 'Contactează-ți partenerul de vânzări pentru a începe procedura ' +
          'de restituire sau de înlocuire a dispozitivului cu privire la care ai avut probleme în timpul procesului de instalare',
      insertOtherMotivation: 'Introdu o descriere a problemei întâlnite în timpul instalării dispozitivului:',
      updatePIN: 'Introdu un cod PIN numeric din 6 cifre',
      backLogin: 'Înapoi la pagina de conectare',
      otpError: 'Codul OTP introdus nu este valabil sau a expirat',
      pinError: 'Codurile PIN introduse nu coincid'
    },
    success: {
      postSuccess: 'adăugat cu succes',
      putSuccess: 'actualizat cu succes',
      sendMail: 'trimis cu succes',
      verifiedAccess: 'Acces verificat',
      verifiedOTP: 'Cod OTP verificat',
      authorizedDevice: 'Dispozitivul tău a fost autorizat',
      canAccessToPortal: 'Te poți conecta acum la platforma dedicată instalatorilor',
      associationPlateObuFound: 'Asocierea IDENTIFICATOR-PLĂCUȚĂ DE ÎNMATRICULARE verificată',
      pinChanged: 'PIN nou setat corect',
      operationSuccess: 'Operațiune efectuată cu succes'
    },
    warnings: {
      accessDenied: 'Acces refuzat',
      alreadyDisabled: 'Utilizatorul este deja dezactivat',
      invalid: 'Nevalid | Nevalidă',
      sureToDelete: 'Sigur dorești să ștergi elementul',
      continue: 'Continuă',
      valid: 'Valid | Validă',
      phoneForm: 'Câmpul trebuie să fie compus dintr-un prefix și un număr de telefon valabil',
      pinForm: 'Câmpul trebuie să fie format din 6 cifre',
      pinConfirm: 'Codurile PIN introduse nu coincid'
    },
    errors: {
      associationPlateObuNotFound: 'Asocierea IDENTIFICATOR-PLĂCUȚĂ DE ÎNMATRICULARE nu a fost găsită',
      error: 'S-a înregistrat o eroare'
    },
    installSteps: {
      title: 'Instalare dispozitiv nou',
      step1: {
        operationTitle: 'Instalare IPS',
        message: 'Poți continua acum cu instalarea hardware-ului',
        instructions: `Verifică dacă tabloul de bord al vehiculului este oprit și instalează unitatea IPS.
                                 \n Verifică dacă ai conectat corect cablurile la instalația electrică și dirijează cablul USB în interiorul habitaclului.`
      },
      step2: {
        advancedText: 'Avansate',
        datePrompt: 'Selectează data și ora pentru a începe instalarea',
        operationTitle: 'Pornire dispozitiv și configurare',
        message: '',
        instructions: 'Poziționează unitatea de bord în interiorul habitaclului, pe parbrizul vehiculului, așa cum este indicat în manual.',
        instructions1: 'Conectează conectorul USB la unitatea de bord și fă clic pe Continuă.',
        obuPosition: 'Plasare corectă a unității de bord',
        monitorMessage: 'Procedură de configurare automată în curs...',
        configDesc: '',
        configurationInformation: 'Informații despre configurare',
        configurationInformationMessage: 'Descrierea procedurii de configurare din punctul de vedere' +
            'al feedback-ului oferit instalatorului de HMI',
        error1: {
          errorMain: 'Nu a fost posibilă comunicarea cu dispozitivul',
          errorDesc: 'Urmează pașii de mai jos:',
          first: 'Deconectează cablul USB de la unitatea de bord',
          second: 'Verifică din nou conexiunile electrice ale unității IPS',
          third: 'Reconectează cablul USB la unitatea de bord',
          fourth: 'Verifică pornirea dispozitivului:',
          subF1: 'Redare sunet de pornire;',
          subF2: 'Aprindere led roșu de stare.',
          footMessage: `Fă clic pe Reîncearcă pentru a repeta verificarea.
    Dacă problema persistă, oprește instalarea.`,
        },
        error2: {
          errorMain: 'A apărut o eroare în timpul procesului de configurare',
          errorDesc: 'Unitatea de bord nu poate fi configurată.',
          footMessage: 'Anulează procesul de instalare a dispozitivului'
        },
        error3: {
          errorMain: 'A apărut o eroare în timpul procesului de configurare',
          errorDesc: 'Personalizarea OBU nu a fost finalizată.',
          footMessage: 'Anulează procesul de instalare a dispozitivului'
        },
      },
      step3: {
        operationTitle: 'Verificare',
        instructions: 'Ca ultim pas, este necesar să verifici dacă unitatea de bord primește corect semnalul de „activare” de la sistemul electric al vehiculului.\n',
        instructions1: '\nConectează-te la panou și fă clic pe Continuă pentru a începe verificarea de la distanță',
        updating: 'Actualizarea stării este în curs...',
        error: {
          errorMain: 'Semnalul cheii nu este prezent',
          errorDesc: 'Verifică conexiunea electrică a semnalului cheii.\n' +
              '\n' +
              'Repetă verificarea stării unității de bord făcând clic pe Reîncearcă.\n' +
              '\n' +
              'Dacă nu este posibil să finalizezi verificarea, fă clic pe Anulează instalarea.',
          batteryMain: 'Verificați conexiunea cablului',
          batteryDesc: 'Cablu de alimentare ar fi putut fi deconectat în timpul instalării.\n' +
              '\nAsigurați-vă de asemenea că nu ați inversat cablurile de alimentare și aprindere.'
        }
      },
      buttonNext: 'Continuă',
      buttonAbort: 'Anulează instalarea',
      bottomMessage: 'Ai întâmpinat vreo problema?',
      bottomLink: 'Înapoi la ghiduri',
      retryButton: 'Reîncearcă',
      successInstallation: 'Configurarea unității de bord a fost finalizată cu succes',
      obuReady: 'Unitatea de bord funcționează corect\n' +
          '\n' +
          'Instalare finalizată',
      newInstallation: 'Instalare nouă'
    },
    admin: {
      success: 'Tabel actualizat',
      nav: {
        title: 'Portal administrator',
        eventsLink: 'Evenimente',
        adminMng: 'Gestionare admin'
      },
      eventsPage: {
        title: 'Evenimente instalări',
        search: 'Căutare Cell_no, OBU ID...',
        recordInfo: 'Înregistrări afișate',
        buttons: {
          next: 'Următor',
          previous: 'Anterior',
          autoRefresh: 'Actualizare automată'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'În curs'
        },
        filters: {
          buttons: {
            clear: 'Elimină filtre',
            collapse: 'Filtre'
          },
          dropdowns: {
            labels: {
              obu: 'ID unitate de bord',
              opcode: 'Cod operațiune',
              startTs: 'De la:',
              startTime: 'Ora inițială:',
              endTs: 'Până la:',
              endTime: 'Ora finală:',
              plate: 'Număr plăcuță de înmatriculare:',
              status: 'Stare:'
            },
            placeholders: {
              obu: 'Selectează numărul de serie al unității de bord',
              opcode: 'Selectează operațiunea',
              startTs: 'Selectează data inițială',
              endTs: 'Selectează data finală',
              noObu: 'Niciun ID unitate de bord disponibil',
              noOpcode: 'Niciun opcode disponibil',
              allOpts: 'Toate',
              plate: 'Selectează plăcuța de înmatriculare',
              noPlate: 'Nicio plăcuță de înmatriculare disponibilă',
              searchPlate: 'Caută plăcuță de înmatriculare...',
              status: 'Selectează stare',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'În curs'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Număr tel.',
          obu_id: 'ID unitate de bord',
          plate_number: 'Număr plăcuță de înmatriculare',
          timestamp: 'Data-Ora',
          opcode_desc: 'Descr. Opcode',
          description: 'Descriere',
          status: 'Stare'
        },
        opcodes: {
          0: 'Selectează cod operațiune',
          1: 'Conectare în curs',
          2: 'Conectare OK',
          3: 'Conectare KO',
          4: 'OTP în curs',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'PIN în curs',
          8: 'PIN OK',
          9: 'PIN KO',
          10: 'Înregistrare în curs',
          11: 'Înregistrare OK',
          12: 'Înregistrare KO',
          13: 'Solicitare OTP nou OK',
          14: 'Solicitare OTP nou KO',
          15: 'Actualizare date utilizator în curs',
          16: 'Actualizare date utilizator OK',
          17: 'Actualizare date utilizator KO',
          18: 'Asociere unitate de bord - plăcuță de înmatriculare în curs',
          19: 'Asociere unitate de bord - plăcuță de înmatriculare OK',
          20: 'Asociere unitate de bord - plăcuță de înmatriculare KO',
          21: 'Instalare IPS în curs',
          22: 'Instalare IPS OK',
          23: 'Configurare K1 în curs',
          24: 'Configurare K1 OK',
          25: 'Configurare K1 - First Run: eroare PM',
          26: 'Configurare K1 - First Run: nicio comunicare',
          27: 'Configurare K1 - First Run: Branding greșit',
          28: 'Configurare K1 - Branding: eroare serviciu',
          29: 'Configurare K1 - Branding: timp operațiune expirat',
          30: 'Configurare K1 - Vehicle Config: eroare de configurare',
          31: 'Configurare K1 - Vehicle Config: timp operațiune expirat',
          32: 'Configurare K1 - Tolling DSRC: eroare la cel puțin un serviciu',
          33: 'Configurare K1 - Tolling DSRC: timp operațiune expirat',
          34: 'Configurare K1 - Tolling GNSS: eroare la cel puțin un serviciu',
          35: 'Configurare K1 - Tolling GNSS: timp operațiune expirat',
          36: 'Configurare K1 - Service Activation: eroare la cel puțin un serviciu',
          37: 'Configurare K1 - Service Activation: timp operațiune expirat',
          38: 'Configurare K1 - Reboot: repornire eșuată',
          39: 'Configurare K1 - Power Switch: variație PM eșuată',
          40: 'K1 Key în curs',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Instalare în curs',
          44: 'Instalare finalizată',
          45: 'Unitate de bord fără semnale luminoase',
          46: 'Cablu USB absent',
          47: 'Dual lock absentă',
          48: 'Cablu USB prea scurt',
          49: 'Unitatea de bord nu comunică corect prin semnale luminoase și sonore',
          50: 'Proces de configurare eșuat',
          51: 'Unitatea de bord nu primește corect semnalul de activare',
          52: 'Alt scenariu de eșec',
          53: 'Start_time al asocierii era NULL',
        }
      },
      adminMngPage: {
        title: 'Gestionare administratori',
        search: 'Căutare...',
        buttons: {
          add: 'Adaugă'
        },
        table: {
          actions: 'Acțiuni',
          phone: 'Telefon',
          firstName: 'Prenume',
          lastName: 'Nume',
          company: 'Companie',
          removed: 'Dezactivat',
        },
        modal: {
          dataLabels: {
            phone: 'Telefon',
            confPhone: 'Confirmă nr. de telefon',
            firstName: 'Prenume',
            lastName: 'Nume',
            email: 'E-mail',
            company: 'Societate',
            toggle: 'Dezactivează contul',
          },
          addAdmin: {
            title: 'Adaugă cont administrator',
            body: 'Introdu informațiile indicate în câmpurile de mai jos:',
            buttons: {
              cancel: 'Anulează',
              proceed: 'Introdu'
            }
          },
          modAdmin: {
            title: 'Modifică cont administrator',
            body: 'Modifică informațiile contului de mai jos:',
            buttons: {
              cancel: 'Anulează',
              proceed: 'Actualizează'
            }
          },
          deleteAdmin: {
            title: 'Ștergere cont administrator',
            body: 'Sigur dorești să elimini acest cont din lista de administratori?',
            buttons: {
              cancel: 'Nu',
              proceed: 'Da'
            }
          },
          success: {
            title: 'Date cont înregistrate',
            body: 'Cont administrator salvat cu succes'
          },
          error: {
            title: 'Date nevalide',
            body: 'Administrator deja prezent în listă'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtre',
            clear: 'Elimină filtre'
          },
          dropdowns: {
            labels: {
              removed: 'Cont dezactivat',
              all: 'Toate'
            }
          }
        }
      },

    },
    months: {
      january: 'Ianuarie',
      february: 'Februarie',
      march: 'Martie',
      april: 'Aprilie',
      may: 'Mai',
      june: 'Iunie',
      july: 'Iulie',
      august: 'August',
      september: 'Septembrie',
      october: 'Octombrie',
      november: 'Noiembrie',
      december: 'Decembrie'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'PIN incorect',
        1212: 'Utilizatorul nu a fost găsit',
        1226: 'Nume utilizator deja utilizat',
        1231: 'Număr de telefon nevalid',
        1400: 'Parametru lipsă',
        1106: 'OTP introdus greșit',
        1214: 'PIN incorect (ultima încercare disponibilă)',
        4000: 'ID sesiune expirat'

      },
      403: {
        0: 'Acces refuzat',
        1213: 'Utilizator blocat',
      },
      404: {
        0: 'Resursa nu a fost găsită',
        3: 'Resursa nu a fost găsită',
        5: 'Resursa nu a fost găsită'
      },
      409: {
        7: 'Conflict în timpul eliminării'
      },
      413: 'Dimensiunea datelor este prea mare',
      422: {
        3: 'Eroare în timpul comunicării'
      },
      423: 'Parolă expirată',
      440: 'Sesiune expirată',
      500: {
        1500: 'Eroare server neașteptată'
      },
      502: 'Eroare 502, eroare de comunicare'
    },
    path: {
      '/home': 'Home',
      '/login': 'Conectare'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  ru: {
    message: {
      contact: 'Контакт | Контакты',
      installer: 'Лицо, выполняющее установку | Лица, выполняющие установку',
      access: 'Вход для выполняющего установку',
      newAccess: 'Новый Вход для выполняющего установку',
      phone: 'Мобильный',
      go: 'Далее',
      retrieve: 'Восстановить',
      data: 'Данные',
      pin: 'PIN',
      new: 'Новый',
      registration: 'Регистрация',
      otp: 'OTP',
      name: 'Имя',
      surname: 'Фамилия',
      email: 'Электронная почта',
      confirm: 'Подтвердить',
      verify: 'Проверить',
      signin: 'Зарегистрироваться',
      ok: 'OK',
      completed: 'Завершено',
      portal: 'Портал для выполняющих установку',
      error: 'Ошибка',
      guide: 'Руководства',
      support: 'Средства поддержки для выполняющих установку',
      installK1: 'Установить K1',
      download: 'Загрузить',
      video: 'Видео',
      userProfile: 'Профиль пользователя',
      changePassword: 'Изменить пароль',
      logout: 'Выход из системы',
      update: 'Обновить',
      cancel: 'Отмена',
      stopInstallation: 'Отменить установку',
      obuId: 'ID OBU',
      plate: 'Номерной знак',
      yes: 'Да',
      failureInstallation: 'Отчет о сбое установки',
      select: 'Выбрать',
      reason: 'Причина',
      dismiss: 'Отмена',
      placement: 'Местоположение',
      problem: ' Возникшие проблемы...',
      send: 'Отправить',
      setting: 'Настройка',
      set: 'Задать',
      privacy: 'Я прочитал(а) и принимаю условия использования и политику конфиденциальности',
      invalidPlate: 'Введенный номерной знак неверен'
    },
    info: {
      insertTelephone: 'Введите номер своего телефона. Если у вас уже есть учетная запись Telepass, то можно использовать для входа учетные данные, связанные с этой записью.',
      insertPIN: 'Введите PIN-код для входа в систему',
      retrieveAccessData: 'Восстановить учетные данные',
      insertInformation: 'Введите в полях ниже нужную информацию',
      accessFromNewDevice: 'Вы выполняете вход с нового устройства. \n\n Для проверки разрешения на вход введите код' +
          'OTP, который был отправлен на ваш номер телефона:',
      insertOTP: 'Введите код OTP, который был отправлен на ваш номер телефона',
      completeRegistrationEnteringPIN: 'Завершите регистрацию, введя PIN-код, содержащий 6 цифр',
      otpNotReceivedQuestion: 'Не получили код OTP?',
      resendOTP: 'Отправить код OTP повторно',
      OTPSent: 'Для подтверждения подлинности ваших данных на ваш номер телефона отправлен код (OTP)',
      unregisteredUser: 'Пользователь не зарегистрирован',
      performRegistration: 'Выполните регистрацию для получения доступа к порталу',
      guideQuestion: 'Ознакомились с руководствами? Начать новую процедуру установки K1',
      showGuideBeforeInstallation: 'Показывать руководства перед каждой установкой',
      downloadInstallationManual: 'Загрузить руководство по установке оборудования и пользованию платформой',
      playVideoGuide: 'Просмотреть пошаговое видеоруководство по порядку установки и мониторинга устройства',
      updateInformation: 'Обновите в полях ниже требуемую информацию',
      beforeProceeding: 'Прежде чем продолжить...',
      gsmAdvMessage: 'Для того чтобы правильно выполнить весь процесс установки устройства K1, необходимо, чтобы в месте установки обеспечивался надежный прием сигнала сети GSM',
      newObuInstallation: 'Установка нового устройства',
      insertSerialAndPlateNumber: 'Введите идентификатор устанавливаемого устройства и номерной знак транспортного средства',
      gsmQuestion: 'У вас нет этих данных?',
      returnToGuidePage: 'Вернуться к руководствам',
      stopInstallationQuestion: 'Отменить установку?',
      fullStopInstallationQuestion: 'Вы действительно хотите отменить установку?',
      selectReasonStopInstallation: 'Выберите причину отмены установки',
      associationPlateObuNotFoundMessage: 'Проверьте, правильно ли введены данные и соответствует ли устройство, ' +
          'которое вы собираетесь установить, транспортному средству клиента. Если проблема не устранена, обратитесь к нашему партнеру по продажам',
      selectMotivation: 'Выберите причину...',
      returnORReplacementDevice: 'Возврат/замена устройства',
      returnReplaceDeviceMessage: 'Свяжитесь с нашим партнером по продажам, чтобы начать процедуру ' +
          'возврата или замены устройства, с которым возникли проблемы при установке',
      insertOtherMotivation: 'Введите описание проблемы, возникшей во время установки',
      updatePIN: 'Введите PIN-код, содержащий 6 цифр',
      backLogin: 'Вернуться на страницу входа в систему',
      otpError: 'Введенный код OTP неверен или истек срок его действия',
      pinError: 'Введенные PIN-коды не совпадают'
    },
    success: {
      postSuccess: 'добавлен успешно',
      putSuccess: 'обновлен успешно',
      sendMail: 'отправлено успешно',
      verifiedAccess: 'Подлинность учетных данных подтверждена',
      verifiedOTP: 'Код OTP подтвержден',
      authorizedDevice: 'Ваше устройство авторизовано',
      canAccessToPortal: 'Теперь можно выполнить доступ к платформе для специалистов, выполняющих установку',
      associationPlateObuFound: 'Связь ID OBU с НОМЕРНЫМ ЗНАКОМ подтверждена',
      pinChanged: 'Новый PIN-код задан правильно',
      operationSuccess: 'Операция выполнена успешно'
    },
    warnings: {
      accessDenied: 'Доступ запрещен',
      alreadyDisabled: 'Этот пользователь уже отключен',
      invalid: 'Non valido | Non valida',
      sureToDelete: 'Хочу удалить этот элемент',
      continue: 'Продолжить',
      valid: 'Valido | Valida',
      phoneForm: 'Это поле должно содержать телефонный код и правильный номер телефона',
      pinForm: 'Это поле должно содержать 6 цифр',
      pinConfirm: 'Введенные PIN-коды не совпадают'
    },
    errors: {
      associationPlateObuNotFound: 'Связь ID OBU с НОМЕРНЫМ ЗНАКОМ не найдена',
      error: 'Произошла ошибка'
    },
    installSteps: {
      title: 'Установка нового устройства',
      step1: {
        operationTitle: 'установка выключателя IPS',
        message: 'Теперь можно приступить к установке оборудования',
        instructions: `Проверьте, выключена ли приборная панель транспортного средства, и выполните установку блока IPS.
                                \n Проверьте правильность подсоединения кабелей к системе электрооборудования и имейте при себе в кабине/салоне кабель USB.`
      },
      step2: {
        advancedText: 'Дополнительно',
        datePrompt: 'Выбрать дату и время начала установки',
        operationTitle: 'включение устройства и задание конфигурации',
        message: '',
        instructions: 'Поместите бортовое устройство OBU внутри салона/кабины на ветровое стекло, как показано в руководстве.',
        instructions1: 'Подсоедините разъем USB к устройству OBU и нажмите \'Продолжить\'.',
        obuPosition: 'Правильное расположение OBU',
        monitorMessage: 'Идет автоматическая настройка конфигурации...',
        configDesc: '',
        configurationInformation: 'Сведения о конфигурации',
        configurationInformationMessage: 'Описание процесса настройки конфигурации,' +
            'предоставляемое человеко-машинным интерфейсом (HMI)',
        error1: {
          errorMain: 'Не удалось установить связь с устройством',
          errorDesc: 'Выполните следующие действия:',
          first: 'Отсоедините кабель USB от устройства OBU',
          second: 'Проверьте снова электрические подсоединения блока IPS',
          third: 'Подсоедините снова кабель USB к устройству OBU',
          fourth: 'Проверьте включение устройства:',
          subF1: 'подачу звукового сигнала при включении;',
          subF2: 'включение индикатора состояния красным цветом.',
          footMessage: `Нажмите кнопку 'Повторить' для повторения проверки.
    Если проблема не устранена, остановите установку.`,
        },
        error2: {
          errorMain: 'Произошла ошибка в процессе настройки конфигурации',
          errorDesc: 'Невозможно задать конфигурацию OBU.',
          footMessage: 'Отмените процесс установки устройства'
        },
        error3: {
          errorMain: 'Произошла ошибка в процессе настройки конфигурации',
          errorDesc: 'Персонализация OBU не завершена.',
          footMessage: 'Отмените процесс установки устройства'
        },
      },
      step3: {
        operationTitle: 'Проверка',
        instructions: 'На последнем этапе необходимо проверить, правильно ли устройство OBU принимает сигнал \'включения\' от приборной панели транспортного средства.\n',
        instructions1: '\nВключите приборную панель и нажмите \'Продолжить\' для запуска процедуры дистанционной проверки',
        updating: 'Идет обновление состояния...',
        error: {
          errorMain: 'Нет сигнала от ключа зажигания',
          errorDesc: 'Проверьте электрическое соединение для сигнала ключа зажигания.\n' +
              '\n' +
              'Повторите проверку состояния устройства OBU, нажав \'Повторить\'.\n' +
              '\n' +
              'Если оказывается невозможным завершить проверку, нажмите кнопку \'Отменить установку\'.',
          batteryMain: 'Проверьте подключение кабеля',
          batteryDesc: 'Во время установки могло произойти отключение питающего кабеля.\n' +
              '\nТакже убедитесь, что вы не перепутали кабели питания и зажигания.'
        }
      },
      buttonNext: 'Продолжить',
      buttonAbort: 'Отменить установку',
      bottomMessage: 'Возникли проблемы?',
      bottomLink: 'Вернуться к руководствам',
      retryButton: 'Повторить',
      successInstallation: 'Настройка конфигурации устройства OBU завершена успешно',
      obuReady: 'Устройство OBU работает должным образом\n' +
          '\n' +
          'Установка завершена',
      newInstallation: 'Новая установка'
    },
    admin: {
      success: 'Обновленная таблица',
      nav: {
        title: 'Портал администратора',
        eventsLink: 'События',
        adminMng: 'Управление админ.'
      },
      eventsPage: {
        title: 'События, связанные с установкой',
        search: 'Поиск по Cell_no, ID OBU...',
        recordInfo: 'Показанные записи',
        buttons: {
          next: 'Следующее',
          previous: 'Предыдущее',
          autoRefresh: 'Авт. обновление'
        },
        statusPopovers: {
          ko: 'СБОЙ',
          ok: 'OK',
          pending: 'Идет выполнение'
        },
        filters: {
          buttons: {
            clear: 'Очистить фильтры',
            collapse: 'Фильтры'
          },
          dropdowns: {
            labels: {
              obu: 'ID OBU',
              opcode: 'Код операции',
              startTs: 'С:',
              startTime: 'Время начала:',
              endTs: 'До:',
              endTime: 'Время окончания:',
              plate: 'Номерной знак:',
              status: 'Состояние:'
            },
            placeholders: {
              obu: 'Выбрать идентификатор (ID) OBU',
              opcode: 'Выбрать операцию',
              startTs: 'Выбрать дату начала',
              endTs: 'Выбрать дату окончания',
              noObu: 'Нет доступных идентификаторов (ID) OBU',
              noOpcode: 'Нет доступных кодов операций',
              allOpts: 'Все',
              plate: 'Выбрать номерной знак',
              noPlate: 'Нет доступных номерных знаков',
              searchPlate: 'Искать номерной знак...',
              status: 'Выбрать состояние',
            },
            opStatus: {
              true: 'OK',
              false: 'СБОЙ',
              null: 'Идет выполнение'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Телефон',
          obu_id: 'ID OBU',
          plate_number: 'Номерной знак',
          timestamp: 'Дата-время',
          opcode_desc: 'Описание Код операций',
          description: 'Описание',
          status: 'Состояние'
        },
        opcodes: {
          0: 'Выбрать код операции',
          1: 'Выполняется вход в систему',
          2: 'Вход выполнен',
          3: 'Сбой при входе',
          4: 'Идет проверка OTP',
          5: 'OTP OK',
          6: 'Сбой OTP',
          7: 'Идет проверка PIN',
          8: 'PIN OK',
          9: 'Сбой PIN',
          10: 'Идет регистрация',
          11: 'Регистрация выполнена',
          12: 'Сбой регистрации',
          13: 'Запрос нового OTP выполнен',
          14: 'Сбой запроса нового OTP',
          15: 'Идет обновление данных пользователя',
          16: 'Обновление данных пользователя выполнено',
          17: 'Сбой обновления данных пользователя',
          18: 'Идет связывание OBU с номерным знаком',
          19: 'Связь OBU с номерным знаком подтверждена',
          20: 'Сбой связывания OBU с номерным знаком',
          21: 'Идет установка IPS',
          22: 'Установка IPS выполнена',
          23: 'Идет задание конфигурации K1',
          24: 'Конфигурация K1 задана',
          25: 'Конфигурация K1 - First Run: ошибка в PM',
          26: 'Конфигурация K1 - First Run: нет связи',
          27: 'Конфигурация K1 - First Run: Ошибочный Branding',
          28: 'Конфигурация K1 - Branding: ошибка службы',
          29: 'Конфигурация K1 - Branding: время выполнения операции истекло',
          30: 'Конфигурация K1 - Vehicle Config: ошибка конфигурации',
          31: 'Конфигурация K1 - Vehicle Config: время выполнения операции истекло',
          32: 'Конфигурация K1 - Tolling DSRC: ошибка не менее чем в одной службе',
          33: 'Конфигурация K1 - Tolling DSRC: время выполнения операции истекло',
          34: 'Конфигурация K1 - Tolling GNSS: ошибка не менее чем в одной службе',
          35: 'Конфигурация K1 - Tolling GNSS: время выполнения операции истекло',
          36: 'Конфигурация K1 - Service Activation: ошибка не менее чем в одной службе',
          37: 'Конфигурация K1 - Service Activation: время выполнения операции истекло',
          38: 'Конфигурация K1 - Reboot: сбой перезагрузки',
          39: 'Конфигурация K1 - Power Switch: сбой при изменении PM',
          40: 'Идет проверка K1 Key',
          41: 'K1 Key OK',
          42: 'Сбой K1 Key',
          43: 'Идет установка',
          44: 'Установка завершена',
          45: 'Нет световых сигналов на OBU',
          46: 'Отсутствует кабель USB',
          47: 'Отсутствуют Dual lock (клейкие элементы)',
          48: 'Слишком короткий кабель USB',
          49: 'Некорректная работа световой и звуковой сигнализации бортового устройства OBU',
          50: 'Ошибка в процессе задания конфигурации',
          51: 'Ошибка при приеме устройством OBU сигнала включения',
          52: 'Другая причина ошибки',
          53: 'Значение start_time для связывания было NULL',
        }
      },
      adminMngPage: {
        title: 'Управление администраторами',
        search: 'Поиск...',
        buttons: {
          add: 'Добавить'
        },
        table: {
          actions: 'Действия',
          phone: 'Телефон',
          firstName: 'Имя',
          lastName: 'Фамилия',
          company: 'Компания',
          removed: 'Отключено',
        },
        modal: {
          dataLabels: {
            phone: 'Телефон',
            confPhone: 'Подтвердить телефон',
            firstName: 'Имя',
            lastName: 'Фамилия',
            email: 'Электронная почта',
            company: 'Компания',
            toggle: 'Отключить учетную запись',
          },
          addAdmin: {
            title: 'Добавить учетную запись администратора',
            body: 'Введите в полях ниже нужную информацию',
            buttons: {
              cancel: 'Отмена',
              proceed: 'Введите'
            }
          },
          modAdmin: {
            title: 'Изменить учетную запись администратора',
            body: 'Изменить следующие данные учетной записи:',
            buttons: {
              cancel: 'Отмена',
              proceed: 'Обновить'
            }
          },
          deleteAdmin: {
            title: 'Удалить учетную запись администратора',
            body: 'Вы уверены, что хотите удалить эту учетную запись из списка администраторов?',
            buttons: {
              cancel: 'Нет',
              proceed: 'Да'
            }
          },
          success: {
            title: 'Данные учетной записи зарегистрированы',
            body: 'Учетная запись администратора сохранена успешно'
          },
          error: {
            title: 'Неверные данные',
            body: 'Этот администратор уже есть в списке'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Фильтры',
            clear: 'Очистить фильтры'
          },
          dropdowns: {
            labels: {
              removed: 'Учетная запись отключена',
              all: 'Все'
            }
          }
        }
      },

    },
    months: {
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Неверный PIN-код',
        1212: 'Пользователь не найден',
        1226: 'Это имя пользователя уже используется',
        1231: 'Неверный номер телефона',
        1400: 'Отсутствует параметр',
        1106: 'Введенный код OTP неверен',
        1214: 'Неверный PIN-код (осталась одна попытка)',
        4000: 'Время сеанса истекло'

      },
      403: {
        0: 'Доступ запрещен',
        1213: 'Пользователь блокирован',
      },
      404: {
        0: 'Ресурс не найден',
        3: 'Ресурс не найден',
        5: 'Ресурс не найден'
      },
      409: {
        7: 'Конфликт при удалении'
      },
      413: 'Слишком большой объем данных',
      422: {
        3: 'Ошибка связи'
      },
      423: 'Срок действия пароля истек',
      440: 'Время сеанса истекло',
      500: {
        1500: 'Непредвиденная ошибка сервера'
      },
      502: 'Ошибка 502, ошибка связи'
    },
    path: {
      '/home': 'Главная',
      '/login': 'Вход'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  sk: {
    message: {
      contact: 'Kontakt | Kontakty',
      installer: 'Inštalatér | Inštalatéri',
      access: 'Prihlásenie Inštalatéra',
      newAccess: 'Nový Prihlásenie Inštalatéra',
      phone: 'Číslo mobilného telefónu',
      go: 'Ďalej',
      retrieve: 'Obnoviť',
      data: 'Údaje',
      pin: 'PIN kód',
      new: 'Nový',
      registration: 'Registrácia',
      otp: 'OTP',
      name: 'Meno',
      surname: 'Priezvisko',
      email: 'E-mail',
      confirm: 'Potvrdiť',
      verify: 'Overenie',
      signin: 'Zaregistrujte sa',
      ok: 'OK',
      completed: 'Dokončená',
      portal: 'Portál Inštalatéri',
      error: 'Chyba',
      guide: 'Príručky',
      support: 'Podpora Inštalatérom',
      installK1: 'Inštalovať K1',
      download: 'Stiahnuť',
      video: 'Video',
      userProfile: 'Profil používateľa',
      changePassword: 'Zmeniť heslo',
      logout: 'Odhlásiť',
      update: 'Aktualizovať',
      cancel: 'Zrušiť',
      stopInstallation: 'Prerušiť inštaláciu',
      obuId: 'ID jednotky PJ',
      plate: 'ŠPZ',
      yes: 'Áno',
      failureInstallation: 'Záznam o inštalácii zlyhal',
      select: 'Vybrať',
      reason: 'Dôvod',
      dismiss: 'Zrušiť',
      placement: 'Umiestnenie',
      problem: ' Zistený problém...',
      send: 'Odoslať',
      setting: 'Nastavenie',
      set: 'Nastaviť',
      privacy: 'Prečítal/-a som si a súhlasím s podmienkami o používaní a ochrane osobných údajov',
      invalidPlate: 'Zadaná ŠPZ nie je platná'
    },
    info: {
      insertTelephone: 'Zadajte svoje číslo mobilného telefónu. Ak máte účet Telepass, prihlásiť sa môžete pomocou prihlasovacích údajov, ktoré sú k nemu priradené.',
      insertPIN: 'Pre prihlásenie zadajte PIN kód',
      retrieveAccessData: 'Obnoviť prihlasovacie údaje',
      insertInformation: 'Zadajte informácie uvedené v nasledujúcich poliach',
      accessFromNewDevice: 'Prihlasujete sa z nového zariadenia. \n\n Na overenie prihlásenia zadajte' +
          'OTP kód, ktorý sme zaslali na Vaše číslo mobilného telefónu:',
      insertOTP: 'Zadajte OTP kód zaslaný na Vaše číslo mobilného telefónu',
      completeRegistrationEnteringPIN: 'Dokončite registráciu zadaním 6 číselného PIN kódu',
      otpNotReceivedQuestion: 'Neprišiel Vám OTP kód?',
      resendOTP: 'Preposlať OTP kód',
      OTPSent: 'Na Vaše číslo mobilného telefónu bol odoslaný kód na overenie Vašej totožnosti (OTP)',
      unregisteredUser: 'Neregistrovaný používateľ',
      performRegistration: 'Pre prihlásenie do portálu sa zaregistrujte',
      guideQuestion: 'Prečítali ste si príručky? Spustiť nový proces inštalácie K1',
      showGuideBeforeInstallation: 'Ukázať príručky pred každou inštaláciou',
      downloadInstallationManual: 'Stiahnuť návod na inštaláciu hardvéru a používanie platformy',
      playVideoGuide: 'Pozrieť video, ktoré podobne uvádza proces inštalácie a monitorovania zariadenia',
      updateInformation: 'Aktualizovať informácie uvedené v nasledujúcich poliach',
      beforeProceeding: 'Skôr ako budete pokračovať...',
      gsmAdvMessage: 'Aby ste mohli správne dokončiť proces inštalácie zariadenia K1, je nevyhnutné, aby bol na mieste inštalácie dobrý príjem signálu mobilného telefónu GSM',
      newObuInstallation: 'Inštalácia nového zariadenia',
      insertSerialAndPlateNumber: 'Zadajte identifikačný údaj zariadenia určeného na inštaláciu a ŠPZ vozidla',
      gsmQuestion: 'Nemáte tieto informácie?',
      returnToGuidePage: 'Vrátiť sa na príručky',
      stopInstallationQuestion: 'Prerušiť inštaláciu?',
      fullStopInstallationQuestion: 'Ste si istý/-á, že chcete prerušiť proces inštalácie?',
      selectReasonStopInstallation: 'Vybrať dôvod prerušenia inštalácie:',
      associationPlateObuNotFoundMessage: 'Uistite sa o správnom zadaní údajov a či zariadenie, ktoré chcete ' +
          'nainštalovať, zodpovedá vozidlu zákazníka. Ak problém pretrváva, kontaktujte svojho predajného partnera',
      selectMotivation: 'Vybrať dôvod...',
      returnORReplacementDevice: 'Vrátenie / Výmena zariadenia',
      returnReplaceDeviceMessage: 'Kontaktujte svojho predajného partnera pre zahájenie postupu, ktorý sa týka ' +
          'Vrátenia alebo Výmeny zariadenia, na ktorom ste zaznamenali problémy počas procesu inštalácie',
      insertOtherMotivation: 'Popíšte problém zistený počas inštalácie zariadenia:',
      updatePIN: 'Zadajte 6 číselný PIN kód',
      backLogin: 'Vráťte sa na stránku prihlásenia',
      otpError: 'Zadaný OTP kód je neplatný alebo jeho platnosť vypršala',
      pinError: 'Zadané PIN kódy sa nezhodujú'
    },
    success: {
      postSuccess: 'úspešne pridané',
      putSuccess: 'úspešne aktualizované',
      sendMail: 'úspešne odoslaný',
      verifiedAccess: 'Prihlásenie bolo overené',
      verifiedOTP: 'OTP kód bol overený',
      authorizedDevice: 'Vaše zariadenie bolo schválené',
      canAccessToPortal: 'Teraz môžete prejsť na platformu inštalatérov',
      associationPlateObuFound: 'Priradenie ID-ŠPZ overené',
      pinChanged: 'Nový PIN kód bol nastavený správne',
      operationSuccess: 'Operácia bola úspešne vykonaná'
    },
    warnings: {
      accessDenied: 'Prihlásenie zamietnuté',
      alreadyDisabled: 'Používateľ už je deaktivovaný',
      invalid: 'Neplatný | Neplatná',
      sureToDelete: 'Určite chcete prvok odstrániť',
      continue: 'Pokračovať',
      valid: 'Platný | Platná',
      phoneForm: 'Pole sa musí skladať z platnej predvoľby a telefónneho čísla',
      pinForm: 'Pole musí tvoriť 6 čísel',
      pinConfirm: 'Zadané PIN kódy sa nezhodujú'
    },
    errors: {
      associationPlateObuNotFound: 'Priradenie ID-ŠPZ nebolo nájdené',
      error: 'Vyskytla sa chyba'
    },
    installSteps: {
      title: 'Inštalácia nového zariadenia',
      step1: {
        operationTitle: 'Inštalácia IPS',
        message: 'Teraz môžete pokračovať v inštalácii hardvéru',
        instructions: `Overte, či elektrický panel vozidla je vypnutý a inštalujte jednotku IPS.
                                 \n Uistite sa o správnom pripojení káblov ku skrinke a pripravte si USB kábel v kabíne.`
      },
      step2: {
        advancedText: 'Rozšírené',
        datePrompt: 'Vyberte si dátum a čas zahájenia inštalácie',
        operationTitle: 'Zapnutie zariadenia a konfigurácia',
        message: '',
        instructions: 'Umiestnite jednotku PJ do kabíny, na čelné sklo vozidla tak, ako je to uvedené v návode.',
        instructions1: 'Pripojte konektor USB k jednotke PJ a kliknite na pokračovať.',
        obuPosition: 'Správne umiestnenie jednotky PJ',
        monitorMessage: 'Prebieha automatický proces konfigurácie...',
        configDesc: '',
        configurationInformation: 'Informácie o konfigurácii',
        configurationInformationMessage: 'Popis procesu konfigurácie z pohľadu' +
            'spätnej väzby smerom k inštalatérovi od HMI',
        error1: {
          errorMain: 'So zariadením nebolo možné komunikovať',
          errorDesc: 'Vykonajte nasledujúce kroky:',
          first: 'Odpojte USB kábel od jednotky PJ',
          second: 'Znovu skontrolujte elektrické pripojenia jednotky IPS',
          third: 'Znovu zapojte USB kábel k jednotke PJ',
          fourth: 'Overte zapnutie zariadenia:',
          subF1: 'Vydanie zvuku zapnutia;',
          subF2: 'Zapnutie červenej stavovej kontrolky.',
          footMessage: `Kliknite na skúsiť znovu pre zopakovanie overenia.
    Ak problém pretrváva, inštaláciu prerušte.`,
        },
        error2: {
          errorMain: 'Počas procesu konfigurácie sa vyskytla chyba',
          errorDesc: 'Jednotku PJ nie je možné nakonfigurovať.',
          footMessage: 'Prerušiť proces inštalácie zariadenia'
        },
        error3: {
          errorMain: 'Počas procesu konfigurácie sa vyskytla chyba',
          errorDesc: 'Personalizácia OBU nebola dokončená.',
          footMessage: 'Prerušiť proces inštalácie zariadenia'
        },
      },
      step3: {
        operationTitle: 'Overenie',
        instructions: 'Ako posledný krok je nevyhnutné overiť, či jednotka PJ správne prijíma signál „aktivácie“ elektrického panelu vozidla.\n',
        instructions1: '\nZapnite panel a kliknite na pokračovať pre zahájenie diaľkového overenia',
        updating: 'Prebieha aktualizácia stavu...',
        error: {
          errorMain: 'Signál kľúča neprítomný',
          errorDesc: 'Skontrolujte elektrické pripojenie signálu kľúča.\n' +
              '\n' +
              'Zopakujte overenie stavu jednotky PJ kliknutím na skúsiť znovu.\n' +
              '\n' +
              'Ak overenie nie je možné dokončiť, kliknite na Prerušiť inštaláciu.',
          batteryMain: 'Skontrolujte pripojenie kábla',
          batteryDesc: 'Počas inštalácie mohol byť odpojený napájací kábel.\n' +
              '\nUistite sa tiež, že ste nezamenili napájací a zapalovací kábel.'
        }
      },
      buttonNext: 'Pokračovať',
      buttonAbort: 'Prerušiť inštaláciu',
      bottomMessage: 'Zistili ste nejaký problém?',
      bottomLink: 'Vrátiť sa na príručky',
      retryButton: 'Skúsiť znovu',
      successInstallation: 'Konfigurácia jednotky PJ bola dokončená správne',
      obuReady: 'Jednotka PJ funguje správne\n' +
          '\n' +
          'Inštalácia bola dokončená',
      newInstallation: 'Nová inštalácia'
    },
    admin: {
      success: 'Tabuľka bola aktualizovaná',
      nav: {
        title: 'Portál správcu',
        eventsLink: 'Udalosti',
        adminMng: 'Správa Admin'
      },
      eventsPage: {
        title: 'Udalosti inštalácií',
        search: 'Hľadať Cell_no, OBU ID...',
        recordInfo: 'Zobrazené záznamy',
        buttons: {
          next: 'Nasledujúca',
          previous: 'Predchádzajúca',
          autoRefresh: 'Automatická aktualizácia'
        },
        statusPopovers: {
          ko: 'KO',
          ok: 'OK',
          pending: 'Prebieha'
        },
        filters: {
          buttons: {
            clear: 'Vymazať filtre',
            collapse: 'Filtre'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Kód operácie',
              startTs: 'Od:',
              startTime: 'Čas začiatku:',
              endTs: 'Do:',
              endTime: 'Čas konca:',
              plate: 'Číslo ŠPZ:',
              status: 'Stav:'
            },
            placeholders: {
              obu: 'Vybrať sériové číslo jednotky PJ',
              opcode: 'Vybrať operáciu',
              startTs: 'Vybrať dátum začiatku',
              endTs: 'Vybrať dátum konca',
              noObu: 'Žiadne id jednotky PJ nie je k dispozícii',
              noOpcode: 'Žiadny kód operácie nie je k dispozícii',
              allOpts: 'Všetky',
              plate: 'Vybrať ŠPZ',
              noPlate: 'Žiadna ŠPZ nie je k dispozícii',
              searchPlate: 'Hľadať ŠPZ...',
              status: 'Vybrať štát',
            },
            opStatus: {
              true: 'OK',
              false: 'KO',
              null: 'Prebieha'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Tel. číslo',
          obu_id: 'ID jednotky PJ',
          plate_number: 'Číslo ŠPZ',
          timestamp: 'Dátum-Čas',
          opcode_desc: 'Popis Kód operácie',
          description: 'Popis',
          status: 'Stav'
        },
        opcodes: {
          0: 'Vybrať kód operácie',
          1: 'Prebieha prihlasovanie',
          2: 'Prihlásenie OK',
          3: 'Prihlásenie KO',
          4: 'Prebieha OTP kód',
          5: 'OTP OK',
          6: 'OTP KO',
          7: 'Prebieha PIN kód',
          8: 'PIN kód OK',
          9: 'PIN kód KO',
          10: 'Prebieha registrácia',
          11: 'Registrácia OK',
          12: 'Registrácia KO',
          13: 'Žiadosť o nový OTP kód OK',
          14: 'Žiadosť o nový OTP kód KO',
          15: 'Prebieha aktualizácia údajov používateľa',
          16: 'Aktualizácia údajov používateľa OK',
          17: 'Aktualizácia údajov používateľa KO',
          18: 'Prebieha priradenie jednotky PJ-ŠPZ',
          19: 'Priradenie jednotky PJ-ŠPZ OK',
          20: 'Priradenie jednotky PJ-ŠPZ KO',
          21: 'Prebieha inštalácia IPS',
          22: 'Inštalácia IPS OK',
          23: 'Prebieha konfigurácia K1',
          24: 'Konfigurácia K1 OK',
          25: 'Konfigurácia K1 - First Run: chyba na PM',
          26: 'Konfigurácia K1 - First Run: žiadna komunikácia',
          27: 'Konfigurácia K1 - First Run: Chybný Branding',
          28: 'Konfigurácia K1 - Branding: chyba služby',
          29: 'Konfigurácia K1 - Branding: čas operácie ukončený',
          30: 'Konfigurácia K1 - Vehicle Config: chyba konfigurácie',
          31: 'Konfigurácia K1 - Vehicle Config: čas operácie ukončený',
          32: 'Konfigurácia K1 - Tolling DSRC: chyba na aspoň jednej službe',
          33: 'Konfigurácia K1 - Tolling DSRC: čas operácie ukončený',
          34: 'Konfigurácia K1 - Tolling GNSS: chyba na aspoň jednej službe',
          35: 'Konfigurácia K1 - Tolling GNSS: čas operácie ukončený',
          36: 'Konfigurácia K1 - Service Activation: chyba na aspoň jednej službe',
          37: 'Konfigurácia K1 - Service Activation: čas operácie ukončený',
          38: 'Konfigurácia K1 - Reboot: reštartovanie zlyhalo',
          39: 'Konfigurácia K1 - Power Switch: zmena PM zlyhala',
          40: 'Prebieha kontrola signálu kľúča K1 Key',
          41: 'K1 Key OK',
          42: 'K1 Key KO',
          43: 'Prebieha inštalácia',
          44: 'Inštalácia dokončená',
          45: 'Jednotka PJ bez svetelných signálov',
          46: 'USB kábel neprítomný',
          47: 'Dual lock neprítomný',
          48: 'Príliš krátky USB kábel',
          49: 'Jednotka PJ nekomunikuje správne pomocou svetelných a zvukových signálov',
          50: 'Zlyhanie procesu konfigurácie',
          51: 'Jednotka PJ neprijíma správne signál aktivácie',
          52: 'Iný scenár zlyhania',
          53: 'start_time priradenia bol NULL',
        }
      },
      adminMngPage: {
        title: 'Správa Správcov',
        search: 'Hľadať...',
        buttons: {
          add: 'Pridať'
        },
        table: {
          actions: 'Úkony',
          phone: 'Telefón',
          firstName: 'Meno',
          lastName: 'Priezvisko',
          company: 'Spoločnosť',
          removed: 'Deaktivovaný',
        },
        modal: {
          dataLabels: {
            phone: 'Telefón',
            confPhone: 'Potvrdiť telefón',
            firstName: 'Meno',
            lastName: 'Priezvisko',
            email: 'E-mail',
            company: 'Spoločnosť',
            toggle: 'Deaktivovať účet',
          },
          addAdmin: {
            title: 'Pridať účet správcu',
            body: 'Zadajte informácie uvedené v nasledujúcich poliach:',
            buttons: {
              cancel: 'Zrušiť',
              proceed: 'Zadať'
            }
          },
          modAdmin: {
            title: 'Upraviť účet správcu',
            body: 'Upraviť informácie nasledujúcich účtov:',
            buttons: {
              cancel: 'Zrušiť',
              proceed: 'Aktualizovať'
            }
          },
          deleteAdmin: {
            title: 'Vymazať účet správcu',
            body: 'Ste si istý/-á, že chcete odstrániť tento účet zo zoznamu správcov?',
            buttons: {
              cancel: 'Nie',
              proceed: 'Áno'
            }
          },
          success: {
            title: 'Údaje účtu zaregistrované',
            body: 'Účet správcu bol úspešne uložený'
          },
          error: {
            title: 'Neplatné údaje',
            body: 'Správca sa už v zozname nachádza'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtre',
            clear: 'Vymazať filtre'
          },
          dropdowns: {
            labels: {
              removed: 'Účet deaktivovaný',
              all: 'Všetky'
            }
          }
        }
      },

    },
    months: {
      january: 'Január',
      february: 'Február',
      march: 'Marec',
      april: 'Apríl',
      may: 'Máj',
      june: 'Jún',
      july: 'Júl',
      august: 'August',
      september: 'September',
      october: 'Október',
      november: 'November',
      december: 'December'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Nesprávny PIN kód',
        1212: 'Používateľ nebol nájdený',
        1226: 'Meno používateľa sa už používa',
        1231: 'Neplatné telefónne číslo',
        1400: 'Chýbajúci parameter',
        1106: 'Nesprávny zadaný OTP kód',
        1214: 'Nesprávny PIN kód (Posledný možný pokus)',
        4000: 'Platnosť ID relácie vypršala'

      },
      403: {
        0: 'Prihlásenie zamietnuté',
        1213: 'Zablokovaný používateľ',
      },
      404: {
        0: 'Zdroj nebol nájdený',
        3: 'Zdroj nebol nájdený',
        5: 'Zdroj nebol nájdený'
      },
      409: {
        7: 'Konflikt počas odstraňovania'
      },
      413: 'Príliš veľký rozmer údajov',
      422: {
        3: 'Chyba počas komunikácie'
      },
      423: 'Platnosť hesla vypršala',
      440: 'Platnosť relácie vypršala',
      500: {
        1500: 'Neočakávaná chyba servera'
      },
      502: 'Chyba 502, chyba komunikácie'
    },
    path: {
      '/home': 'Domov',
      '/login': 'Prihlásenie'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  },
  sl: {
    message: {
      contact: 'Kontakt | Kontakti',
      installer: 'Inštalater | Inštalaterji',
      access: 'Dostop inštalaterja',
      newAccess: 'Novo Dostop inštalaterja',
      phone: 'Mobitel',
      go: 'Naprej',
      retrieve: 'Obnovi',
      data: 'Podatki',
      pin: 'PIN',
      new: 'Novo',
      registration: 'Registracija',
      otp: 'OTP',
      name: 'Ime',
      surname: 'Priimek',
      email: 'E-pošta',
      confirm: 'Potrdi',
      verify: 'Preveri',
      signin: 'Registracija',
      ok: 'V redu',
      completed: 'Zaključena',
      portal: 'Portal za inštalaterje',
      error: 'Napaka',
      guide: 'Vodnik',
      support: 'Podpora za inštalaterje',
      installK1: 'Namesti K1',
      download: 'Prenos',
      video: 'Video',
      userProfile: 'Uporabniški profil',
      changePassword: 'Spremeni geslo',
      logout: 'Odjava',
      update: 'Posodobi',
      cancel: 'Prekliči',
      stopInstallation: 'Prekini namestitev',
      obuId: 'OBU ID',
      plate: 'Reg. tablica',
      yes: 'Da',
      failureInstallation: 'Poročilo o neuspeli namestitvi',
      select: 'Izberi',
      reason: 'Razlog',
      dismiss: 'Prekliči',
      placement: 'Lokacija',
      problem: ' Ugotovljena težava ...',
      send: 'Pošlji',
      setting: 'Nastavitev',
      set: 'Nastavi',
      privacy: 'Prebral(-a) sem in sprejemam pogoje uporabe ter zasebnosti',
      invalidPlate: 'Vpisana reg. tablica ni veljavna'
    },
    info: {
      insertTelephone: 'Vnesite svojo tel. številko. Če že imate račun Telepass, se lahko prijavite s poverilnicami, ki so povezane z njim.',
      insertPIN: 'Za dostop vnesite PIN',
      retrieveAccessData: 'Pridobite podatke za dostop',
      insertInformation: 'Vnesite podatke v spodnja polja',
      accessFromNewDevice: 'Dostopate z nove naprave. \n\n Za preverjanje dostopa vnesite kodo' +
          'OTP, ki smo jo poslali na vašo št. mobilnega telefona:',
      insertOTP: 'Vnesite kodo OTP, ki ste prejeli na št. vašega mob. telefona',
      completeRegistrationEnteringPIN: 'Zaključite prijavo z vnosom 6-mestne PIN kode',
      otpNotReceivedQuestion: 'Niste prejeli kode OTP?',
      resendOTP: 'Ponovno pošlji kodo OTP',
      OTPSent: 'Na številko vašega mob. tel. je bila poslana koda za preverjanje identitete (OTP)',
      unregisteredUser: 'Uporabnik ni registriran',
      performRegistration: 'Za dostop do portala se registrirajte',
      guideQuestion: 'Ste prebrali vodnike? Začnite nov postopek namestitve K1',
      showGuideBeforeInstallation: 'Pred vsako namestitvijo prikaži vodnike',
      downloadInstallationManual: 'Prenesite navodila za namestitev strojne opreme in uporabo platforme',
      playVideoGuide: 'Oglejte si video z navodili za namestitev in nadzor naprave',
      updateInformation: 'Posodobite podatke v naslednjih poljih',
      beforeProceeding: 'Preden nadaljujete ...',
      gsmAdvMessage: 'Za pravilno dokončanje postopka namestitve naprave K1 mora biti na mestu namestitve zagotovljen dober sprejem signala mobilnega telefona GSM',
      newObuInstallation: 'Namestitev nove naprave',
      insertSerialAndPlateNumber: 'Vnesite ident. oznako naprave, ki jo želite namestiti, in reg. tablico vozila',
      gsmQuestion: 'Nimate teh podatkov?',
      returnToGuidePage: 'Vrnite se na vodnike',
      stopInstallationQuestion: 'Prekinem namestitev?',
      fullStopInstallationQuestion: 'Ste prepričani, da želite prekiniti postopek namestitve?',
      selectReasonStopInstallation: 'Izberite razlog za prekinitev namestitve:',
      associationPlateObuNotFoundMessage: 'Preverite, ali ste pravilno vnesli podatke in ali naprava, ki jo želite ' +
          'namestiti, ustreza vozilu stranke. Če težave niste odpravili, se obrnite na prodajnega partnerja',
      selectMotivation: 'Izberite razlog ...',
      returnORReplacementDevice: 'Vračilo / Zamenjava naprave',
      returnReplaceDeviceMessage: 'Obrnite se na svojega prodajnega partnerja, da začnete postopek' +
          'vračila ali zamenjave naprave, pri kateri ste med postopkom namestitve naleteli na težave',
      insertOtherMotivation: 'Vnesite opis težave, ugotovljene pri namestitvi naprave:',
      updatePIN: 'Vnesite 6-mestno številčno PIN kodo',
      backLogin: 'Vrnitev na stran za prijavo',
      otpError: 'Vnesena koda OTP ni veljavna ali je potekla',
      pinError: 'Vnesene PIN kode se ne ujemajo'
    },
    success: {
      postSuccess: 'uspešno dodana',
      putSuccess: 'uspešno posodobljena',
      sendMail: 'uspešno poslana',
      verifiedAccess: 'Preverjen dostop',
      verifiedOTP: 'Preverjena koda OTP',
      authorizedDevice: 'Vaša naprava je potrjena',
      canAccessToPortal: 'Zdaj lahko dostopate do platforme za inštalaterje',
      associationPlateObuFound: 'Povezava ID - REG. TABL. preverjena',
      pinChanged: 'Nova PIN koda je pravilno nastavljena',
      operationSuccess: 'Postopek je uspešno izveden'
    },
    warnings: {
      accessDenied: 'Zavrnjen dostop',
      alreadyDisabled: 'Uporabnik je že onemogočen',
      invalid: 'Neveljaven | Neveljavna',
      sureToDelete: 'Ste prepričani, da želite izbrisati element',
      continue: 'Nadaljuj',
      valid: 'Veljaven | Veljavna',
      phoneForm: 'Polje mora vsebovati veljavno območno kodo in številko telefona',
      pinForm: 'Polje mora vsebovati 6 števk',
      pinConfirm: 'Vnesene PIN kode se ne ujemajo'
    },
    errors: {
      associationPlateObuNotFound: 'Povezava ID - REG. TABL. ni najdena',
      error: 'Prišlo je do napake'
    },
    installSteps: {
      title: 'Namestitev nove naprave',
      step1: {
        operationTitle: 'Namestitev IPS',
        message: 'Zdaj lahko namestite strojno opremo',
        instructions: `Preverite, ali je armaturna plošča vozila izklopljena, in namestite enoto IPS.
                                 \n Preverite, ali ste pravilno priključili kable na električni sistem vozila in speljite kabel USB po notranjosti vozila.`
      },
      step2: {
        advancedText: 'Napredne',
        datePrompt: 'Izberite datum in uro začetka namestitve',
        operationTitle: 'Zagon in konfiguracija naprave',
        message: '',
        instructions: 'Postavite enoto OBU na armaturno ploščo vozila, kot je prikazano v priročniku.',
        instructions1: 'Priključite kabel USB na enoto OBU in kliknite Nadaljuj.',
        obuPosition: 'Pravilen položaj enote OBU',
        monitorMessage: 'Postopek samodejne konfiguracije v teku ...',
        configDesc: '',
        configurationInformation: 'Informacije o konfiguraciji',
        configurationInformationMessage: 'Opis postopka konfiguracije z vidika' +
            'povratnih informacij, ki jih vmesnik HMI pošlje inštalaterju',
        error1: {
          errorMain: 'Komunikacija z napravo ni mogoča',
          errorDesc: 'Sledite spodnjim korakom:',
          first: 'Odklopite kabel USB z enote OBU',
          second: 'Ponovno preverite električne priključke enote IPS',
          third: 'Ponovno priključite kabel USB na enoto OBU',
          fourth: 'Preverite zagon naprave:',
          subF1: 'Zasliši se zvočni signal zagona;',
          subF2: 'Zasveti rdeča LED dioda stanja.',
          footMessage: `Za ponovitev preverjanja kliknite na Poskusi znova.
    Če se težava nadaljuje, zaključite namestitev.`,
        },
        error2: {
          errorMain: 'Med postopkom konfiguracije je prišlo do napake',
          errorDesc: 'Enote OBU ni mogoče konfigurirati.',
          footMessage: 'Prekinite postopek namestitve naprave'
        },
        error3: {
          errorMain: 'Med postopkom konfiguracije je prišlo do napake',
          errorDesc: 'Personalizacija OBU ni dokončana.',
          footMessage: 'Prekinite postopek namestitve naprave'
        },
      },
      step3: {
        operationTitle: 'Preverjanje',
        instructions: 'V zadnjem koraku je treba preveriti, ali enota OBU pravilno prejme signal za aktiviranje\' električne plošče vozila.\n',
        instructions1: '\nZa začetek preverjanja na daljavo vklopite ploščo in kliknite na Nadaljuj',
        updating: 'Posodobitev stanja v teku ...',
        error: {
          errorMain: 'Signal ključa ni prisoten',
          errorDesc: 'Preverite električni priključek signala ključa.\n' +
              '\n' +
              'Ponovite preverjanje stanja enote OBU s klikom na Poskusi znova.\n' +
              '\n' +
              'Če preverjanja ni mogoče zaključiti, kliknite na Prekini namestitev.',
          batteryMain: 'Preverite povezavo kabla',
          batteryDesc: 'Med namestitvijo je bil morda odklopljen napajalni kabel.\n' +
              '\nPreverite tudi, ali ste preklopili napajalne in vžigalne kable.'
        }
      },
      buttonNext: 'Nadaljuj',
      buttonAbort: 'Prekini namestitev',
      bottomMessage: 'Ste naleteli na težave?',
      bottomLink: 'Vrnite se na vodnike',
      retryButton: 'Poskusite znova',
      successInstallation: 'Konfiguracija enote OBU je uspešno končana',
      obuReady: 'Enota OBU deluje pravilno\n' +
          '\n' +
          'Namestitev je končana',
      newInstallation: 'Nova namestitev'
    },
    admin: {
      success: 'Posodoblj. tabela',
      nav: {
        title: 'Portal za skrbnika',
        eventsLink: 'Dogodki',
        adminMng: 'Upravlj. skrbnika'
      },
      eventsPage: {
        title: 'Dogodki namestitev',
        search: 'Iskanje Cell_no, OBU ID ...',
        recordInfo: 'Prikazani zapisi',
        buttons: {
          next: 'Naslednji',
          previous: 'Prejšnji',
          autoRefresh: 'Samoposodobi'
        },
        statusPopovers: {
          ko: 'NI OK',
          ok: 'OK',
          pending: 'V teku'
        },
        filters: {
          buttons: {
            clear: 'Počisti filtre',
            collapse: 'Filtri'
          },
          dropdowns: {
            labels: {
              obu: 'OBU ID',
              opcode: 'Koda postopka',
              startTs: 'Od:',
              startTime: 'Čas začetka:',
              endTs: 'Do:',
              endTime: 'Čas konca:',
              plate: 'Reg. tablica:',
              status: 'Stanje:'
            },
            placeholders: {
              obu: 'Izberite ID številko OBU',
              opcode: 'Izberite postopek',
              startTs: 'Izberite datum začetka',
              endTs: 'Izberite datum konca',
              noObu: 'Id enote OBU ni na voljo',
              noOpcode: 'Koda postopka ni na voljo',
              allOpts: 'Vsi',
              plate: 'Izberi reg. tablico',
              noPlate: 'Reg. tablica ni na voljo',
              searchPlate: 'Iskanje reg. tabl. ...',
              status: 'Izberite državo',
            },
            opStatus: {
              true: 'V redu',
              false: 'NI OK',
              null: 'V teku'
            },
          }
        },
        table: {
          id: 'ID',
          cell_no: 'Tel. številka',
          obu_id: 'OBU ID',
          plate_number: 'Reg. tablica',
          timestamp: 'Datum-ura',
          opcode_desc: 'Opis koda postopka',
          description: 'Opis',
          status: 'Stanje'
        },
        opcodes: {
          0: 'Izberite kodo postopka',
          1: 'Prijava v teku',
          2: 'Prijava OK',
          3: 'Prijava NI OK',
          4: 'OTP v teku',
          5: 'OTP OK',
          6: 'OTP NI OK',
          7: 'PIN v teku',
          8: 'PIN OK',
          9: 'PIN NI OK',
          10: 'Registracija v teku',
          11: 'Registracija OK',
          12: 'Registracija NI OK',
          13: 'Zahteva za novo OTP OK',
          14: 'Zahteva za novo OTP NI OK',
          15: 'Posodobitev uporabn. podatkov v teku',
          16: 'Posodobitev uporabn. podatkov OK',
          17: 'Posodobitev uporabn. podatkov NI OK',
          18: 'Povezava OBU - reg. tabl. v teku',
          19: 'Povezava OBU - reg. tabl. OK',
          20: 'Povezava OBU - reg. tabl. NI OK',
          21: 'Namestitev IPS v teku',
          22: 'Namestitev IPS OK',
          23: 'Konfiguracija K1 v teku',
          24: 'Konfiguracija K1 OK',
          25: 'Konfiguracija K1 - First Run: napaka na PM',
          26: 'Konfiguracija K1 - First Run: ni komunikacije',
          27: 'Konfiguracija K1 - First Run: Napačen branding',
          28: 'Konfiguracija K1 - Branding: napaka pri storitvi',
          29: 'Konfiguracija K1 - Branding: čas za postopek je potekel',
          30: 'Konfiguracija K1 - Vehicle Config: napaka pri konfiguraciji',
          31: 'Konfiguracija K1 - Vehicle Config: čas za postopek je potekel',
          32: 'Konfiguracija K1 - Tolling DSRC: napaka pri vsaj eni storitvi',
          33: 'Konfiguracija K1 - Tolling DSRC: čas za postopek je potekel',
          34: 'Konfiguracija K1 - Tolling GNSS: napaka pri vsaj eni storitvi',
          35: 'Konfiguracija K1 - Tolling GNSS: čas za postopek je potekel',
          36: 'Konfiguracija K1 - Service Activation: napaka pri vsaj eni storitvi',
          37: 'Konfiguracija K1 - Service Activation: čas za postopek je potekel',
          38: 'Konfiguracija K1 - Reboot: ponovni zagon ni uspel',
          39: 'Konfiguracija K1 - Power Switch: sprememba PM ni uspela',
          40: 'K1 Key v teku',
          41: 'K1 Key OK',
          42: 'K1 Key NI OK',
          43: 'Namestitev v teku',
          44: 'Namestitev je končana',
          45: 'OBU brez svetlobnih signalov',
          46: 'Ni kabla USB',
          47: 'Dual lock ni prisoten',
          48: 'Prekratek kabel USB',
          49: 'OBU ne komunicira pravilno s svetlobnimi in zvočnimi signali',
          50: 'Postopek konfiguracije ni uspel',
          51: 'OBU ne prejema pravilno signala za aktiviranje',
          52: 'Drug scenarij okvare',
          53: 'Start_time povezave je bil NULL',
        }
      },
      adminMngPage: {
        title: 'Upravljanje skrbnikov',
        search: 'Iskanje ...',
        buttons: {
          add: 'Dodaj'
        },
        table: {
          actions: 'Ukrepi',
          phone: 'Telefon',
          firstName: 'Ime',
          lastName: 'Priimek',
          company: 'Podjetje',
          removed: 'Onemogočen',
        },
        modal: {
          dataLabels: {
            phone: 'Telefon',
            confPhone: 'Potrdi telefon',
            firstName: 'Ime',
            lastName: 'Priimek',
            email: 'E-pošta',
            company: 'Družba',
            toggle: 'Onemogoči račun',
          },
          addAdmin: {
            title: 'Dodaj skrbniški račun',
            body: 'Vnesite podatke v spodnja polja:',
            buttons: {
              cancel: 'Prekliči',
              proceed: 'Vnesi'
            }
          },
          modAdmin: {
            title: 'Spremenite skrbniški račun',
            body: 'Spremenite naslednje podatke o računu:',
            buttons: {
              cancel: 'Prekliči',
              proceed: 'Posodobi'
            }
          },
          deleteAdmin: {
            title: 'Izbris skrbniškega računa',
            body: 'Ste prepričani, da želite odstraniti ta račun s seznama skrbnikov?',
            buttons: {
              cancel: 'Ne',
              proceed: 'Da'
            }
          },
          success: {
            title: 'Registr. podatki o računu',
            body: 'Skrbniški račun je uspešno shranjen'
          },
          error: {
            title: 'Neveljavni podatki',
            body: 'Skrbnik je že prisoten na seznamu'
          },
          warning: {}
        },
        filters: {
          buttons: {
            collapse: 'Filtri',
            clear: 'Počisti filtre'
          },
          dropdowns: {
            labels: {
              removed: 'Račun je onemogočen',
              all: 'Vsi'
            }
          }
        }
      },

    },
    months: {
      january: 'Januar',
      february: 'Februar',
      march: 'Marec',
      april: 'April',
      may: 'Maj',
      june: 'Junij',
      july: 'Julij',
      august: 'Avgust',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December'
    },
    constraints: {},
    httpCode: {
      400: {
        1215: 'Napačna PIN koda',
        1212: 'Uporabnik ni najden',
        1226: 'Že uporabljeno uporabn. ime',
        1231: 'Neveljavna tel. številka',
        1400: 'Manjka parameter',
        1106: 'Vnesena napačna OTP',
        1214: 'Napačna PIN koda (zadnji možen poskus)',
        4000: 'ID seje je potekla'

      },
      403: {
        0: 'Zavrnjen dostop',
        1213: 'Blokiran uporabnik',
      },
      404: {
        0: 'Vir ni najden',
        3: 'Vir ni najden',
        5: 'Vir ni najden'
      },
      409: {
        7: 'Konflikt med odstranitvijo'
      },
      413: 'Prevelika velikost podatkov',
      422: {
        3: 'Napaka pri komunikaciji'
      },
      423: 'Geslo je poteklo',
      440: 'Seja je potekla',
      500: {
        1500: 'Nepričakovana napaka strežnika'
      },
      502: 'Napaka 502, napaka v komunikaciji'
    },
    path: {
      '/home': 'Domov',
      '/login': 'Dostop'
    },
    media: {
      manualUrl: 'https://selftesttool.telepass.com/installation_manual',
      videoUrl: 'https://selftesttool.telepass.com/video_tutorial_en'
    },
  }
}

export default messages

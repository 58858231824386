<template>
  <div id="login-div" class="col component-container d-flex align-items-center justify-content-center p-0">
    <b-container class="col-12 d-flex justify-content-center rounded-circle p-0">
      <b-col class="p-0" cols="12" sm="12" md="10" :lg="(showRegistration || showFullRegistration) ? '6' : '6'"
             :xl="(showRegistration || showFullRegistration) ? '6' : '5'">
        <b-card class="text-center main-card">
          <b-row style="justify-content: center" v-if="!showInsertOTP && !showRegistration">
            <UserIcon :color="color.primary"/>
          </b-row>
          <b-row style="justify-content: center" class="pt-2">
            <h6 class="title-card-text mb-3" v-if="!showInsertOTP && !isNewRegistration">
              {{ `${$tc('message.access')}` }}
            </h6>
            <h6 class="title-card-text mb-3" v-else-if="showInsertOTP && !isNewRegistration">
              {{ `${$tc('message.newAccess')}` }}
            </h6>
            <h6 class="title-card-text mb-3" v-else-if="isNewRegistration">
              {{ `${$tc('message.registration')} ${$tc('message.installer', 1)}` }}
            </h6>
          </b-row>

          <b-row style="justify-content: center">
            <h6 class="info-text" v-if="showInsertPIN">
              {{ `${$tc('info.insertPIN')}` }}
            </h6>
            <h6 class="info-text m-4 text-align-start"
                v-else-if="!telephoneNumberExistsOnDevice && !showInsertOTP && isNewRegistration && !showFullRegistration">
              {{ `${$tc('info.insertInformation')}` }}
            </h6>
            <h6 class="info-text" v-else-if="!telephoneNumberExistsOnDevice && !showInsertOTP && !showFullRegistration">
              {{ `${$tc('info.insertTelephone')}` }}
            </h6>
            <h6 class="info-text m-4 text-align-start"
                v-else-if="!telephoneNumberExistsOnDevice && showInsertOTP && !isNewRegistration">
              {{ `${$tc('info.accessFromNewDevice')}` }}
            </h6>
            <h6 class="info-text m-4 text-align-start"
                v-else-if="!telephoneNumberExistsOnDevice && showInsertOTP && isNewRegistration">
              {{ `${$tc('info.insertOTP')}` }}
            </h6>
            <h6 class="info-text m-4 text-align-start"
                v-else-if="!telephoneNumberExistsOnDevice && isNewRegistration && showFullRegistration">
              {{ `${$tc('info.completeRegistrationEnteringPIN')}` }}
            </h6>
          </b-row>
          <b-row align-h="center" class="ml-2 mr-2 mt-5 mb-5" v-if="!showRegistration && !showFullRegistration">
            <b-col cols="12" sm="9" md="12" lg="12" xl="12">
              <!-- Telephone -->
              <!-- Always showing at first -->
              <vue-tel-input v-model="displayedPhone" v-show="!showInsertOTP || showInsertPIN"
                             :disabled="telephoneNumberExistsOnDevice || showInsertPIN"
                             defaultCountry="IT"
                             mode="international"
                             aria-describedby="input-live-feedback"
                             :state="isValidPhoneNumber"
                             v-on:validate="prova($event)"
                             v-on:focus="onFocus = true"
                             v-on:input="buildPhoneString"
                             :dropdownOptions="{disabled:false,showDialCodeInList:true,showDialCodeInSelection:true, showFlags:true, showSearchBox:false, tabindex:0, width: '390px'}"
                             :validCharactersOnly="true"
                             class="mb-2"
                             :class="(telephoneNumberExistsOnDevice || showInsertPIN) ? 'disable-bck' : ''"
                             :inputOptions="{placeholder: `${$tc('message.phone')}`, styleClasses: 'telephone-text-field box-style form-control border-0'}"
                             :invalidMsg="`${$tc('warnings.phoneForm')}`"
              >
                <template v-slot:icon-right>
                  <span v-if="isValidPhoneNumber" class="text-success">
                    <img class="ml-3 mr-2 mt-2 mb-1" src="@/assets/images/check_mark.svg" alt="Success"/>
                  </span>
                  <span v-else class="text-danger">
                    <img class="ml-3 mr-2 mt-2 mb-1" src="@/assets/images/cross.svg" alt="Error"/>
                  </span>
                </template>
              </vue-tel-input>
              <h6 class="info-text err-feedback" v-if="(!isValidPhoneNumber) && onFocus">
                {{ `${$tc('warnings.phoneForm')}` }}
              </h6>

            </b-col>
            <!-- Showing only if number is registered in the system and is not the first access with this device -->
            <b-col cols="12" sm="9" md="12" lg="12" xl="12">
              <!-- PIN -->
              <b-input-group v-show="telephoneNumberExistsOnDevice || showInsertPIN">

                <b-form-input v-model="input.PIN"
                              class="normal-text-field box-style border-right-0 pl-2"
                              id="input-password"
                              @keypress="isNumber($event)"
                              aria-describedby="pin-live-feedback"
                              :state="input.PIN.length > 0 ? PINState : null"
                              :type="!showPassword ? 'password' : 'text'"
                              maxlength="6"
                              :placeholder="$tc('message.pin')">
                </b-form-input>
                <b-input-group-append>
                  <b-input-group-text class="bg-transparent">
                    <img :src="showPassword ? hideIconSrc : showIconSrc"
                         @click="showPassword = !showPassword" alt="Eye"/>
                  </b-input-group-text>
                </b-input-group-append>
                <!-- Da capire come non rompere il layout del box PIN -->
                <b-form-invalid-feedback id="pin-live-feedback"
                                         v-if="!PINState">
                  {{ `${$tc('warnings.pinForm')}` }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
            <b-col cols="12" sm="9" md="12" lg="12" xl="12" class="mb-2">
              <!-- OTP -->
              <b-input-group v-show="!telephoneNumberExistsOnDevice && showInsertOTP">
                <b-form-input v-model="input.OTP"
                              class="normal-text-field box-style"
                              id="input-OTP"
                              @keypress="isNumber($event)"
                              autocomplete="off"
                              type="tel"
                              :placeholder="`${$tc('message.otp')}`" maxlength="6">
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- Registation -->
          <b-row align-h="center" class="m-5" v-else-if="showRegistration || showFullRegistration">
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4">
              <!-- Name -->
              <b-form-group>
                <b-form-input v-model="newRegistration.name"
                              class="normal-text-field box-style"
                              :disabled="showFullRegistration"
                              id="nw-name"
                              type="text"
                              :placeholder="`${$tc('message.name')}`">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4">
              <!-- Surname -->
              <b-input-group>
                <b-form-input v-model="newRegistration.surname"
                              class="normal-text-field box-style"
                              id="nw-surname"
                              :disabled="showFullRegistration"
                              type="text"
                              :placeholder="`${$tc('message.surname')}`">
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4">
              <!-- Telephone -->
              <vue-tel-input v-model="displayedPhone"
                            :disabled="true"
                            defaultCountry="IT"
                            mode="international"
                            v-on:validate="prova($event)"
                            v-on:input="buildPhoneString()"
                            :dropdownOptions="{disabled:false,showDialCodeInList:true,showDialCodeInSelection:true, showFlags:true, showSearchBox:false, tabindex:0, width: '390px'}"
                            :validCharactersOnly="true"
                            :styleClasses="'telephone-text-field box-style '"
                            :inputOptions="{placeholder: `${$tc('message.phone')}`, styleClasses: 'telephone-text-field box-style form-control border-0'}"
                            class="disable-bck"
              >
                <template v-slot:icon-right>
                  <span v-if="isValidPhoneNumber" class="text-success">
                    <img class="ml-3 mr-2 mt-2 mb-1" src="@/assets/images/check_mark.svg" alt="Success"/>
                  </span>
                  <span v-else class="text-danger">
                    <img class="ml-3 mr-2 mt-2 mb-1" src="@/assets/images/cross.svg" alt="Error"/>
                  </span>
                </template>
              </vue-tel-input>

            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4">
              <!-- Email -->
              <b-input-group>
                <b-form-input v-model="newRegistration.email"
                              class="normal-text-field box-style"
                              id="nw-email"
                              :disabled="showFullRegistration"
                              type="email"
                              :placeholder="`${$tc('message.email')}`"
                >
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4 mt-2">
              <!-- PIN -->
              <b-input-group v-show="showFullRegistration">
                <b-form-input v-model="newRegistration.PIN"
                              class="normal-text-field box-style"
                              id="nw-pin"
                              autocomplete="off"
                              @keypress="isNumber($event)"
                              :type="!newRegistration.showPIN ? 'password' : 'text'"
                              maxlength="6"
                              aria-describedby="new-pin-live-feedback"
                              :state="newRegistration.PIN.length > 0 ? newPinCheck : null"
                              :placeholder="`${$tc('message.pin')}`">
                </b-form-input>
                <b-input-group-text class="bg-transparent">
                  <img :src="newRegistration.showPIN ? hideIconSrc : showIconSrc"
                       @click="newRegistration.showPIN = !newRegistration.showPIN" alt="Eye"/>
                </b-input-group-text>
                <b-form-invalid-feedback id="new-pin-live-feedback"
                                         v-if="!newPinCheck">
                  {{ `${$tc('warnings.pinForm')}` }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mt-2 mb-4">
              <!-- PIN -->
              <b-input-group v-show="showFullRegistration">
                <b-form-input v-model="newRegistration.confirmPIN"
                              class="normal-text-field box-style"
                              :disabled="!newPinCheck"
                              id="nw-confirm-pin"
                              @keypress="isNumber($event)"
                              autocomplete="off"
                              :type="!newRegistration.showConfirmPIN ? 'password' : 'text'"
                              maxlength="6"
                              aria-describedby="pin-confirm-live"
                              :state="newRegistration.confirmPIN.length > 0 ? MatchingPins : null"
                              :placeholder="`${$tc('message.confirm')} ${$tc('message.pin')}`">
                </b-form-input>
                <b-input-group-text class="bg-transparent">
                  <img :src="newRegistration.showConfirmPIN ? hideIconSrc : showIconSrc"
                       @click="newRegistration.showConfirmPIN = !newRegistration.showConfirmPIN" alt="Eye"/>
                </b-input-group-text>
                <b-form-invalid-feedback id="pin-confirm-live"
                                         v-if="!MatchingPins">
                  {{ `${$tc('warnings.pinConfirm')}` }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
          </b-row>
          <!-- Privacy Checkbox -->
          <b-col class="justify-content-center" align-v="center"
                 v-if="showRegistration || (showFullRegistration && (privacy === 'false'))">
            <b-row class="align-items-center justify-content-center" align-v="center">
              <b-form-checkbox
                      id="checkbox-privacy"
                      v-model="privacy"
                      name="checkbox-privacy"
                      value="true"
                      unchecked-value="false">
              </b-form-checkbox>
              <b-link class="bottom-link" href="https://selftesttool.telepass.com/privacy_policy" target="_blank">
                {{ `${$tc('message.privacy')}` }}
              </b-link>
            </b-row>
          </b-col>

          <b-row align-h="center">
            <b-col cols="12" sm="9" md="12" lg="12" xl="12">
              <b-button id="login-button"
                        :disabled="!telephoneNumberExistsOnDevice ? !(telephoneNumberState && isValidPhoneNumber) : !PINState"
                        @click="!showInsertPIN ? checkLogin() : verifyPIN(input.telephoneNumber)"
                        pill
                        variant="primary"
                        class="pr-5 pl-5 custom-button primary-button"
                        v-if="!showInsertOTP && !showRegistration && !showFullRegistration">
                {{ `${$tc('message.go')}` }}
              </b-button>
              <b-button id="verify-OTP-button"
                        @click="verifyOTP()"
                        :disabled="!OTPState"
                        pill
                        variant="primary"
                        class="pr-5 pl-5 custom-button primary-button"
                        v-else-if="showInsertOTP"
              >
                {{ `${$tc('message.verify')}` }}
              </b-button>

              <b-button id="registration-button"
                        :disabled="!registrationFormState || !newPINState || privacy ==='false'"
                        @click="showRegistration ? checkRegistration() : verifyPIN(newRegistration.telephoneNumber)"
                        pill
                        variant="primary"
                        class="custom-button my-3 primary-button"
                        v-else-if="showRegistration || showFullRegistration">
                {{ `${$tc('message.signin')}` }}
              </b-button>
              <b-row style="justify-content: center" class="mb-5" v-if="showRegistration || showFullRegistration">
                <b-link @click="resetEnv()" class="bottom-link mb-3">
                  {{ `${$tc('info.backLogin')}` }}
                </b-link>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="justify-content: center; align-items: center;" class="my-3">
            <b-col class="md-6" style="text-align: center" v-if="!showInsertOTP && !showRegistration && showInsertPIN">
              <b-row style="justify-content: center; align-items: center;">
              <b-link class="bottom-link mb-3 mt-2" @click="resetPIN()">
                {{ `${$tc('info.retrieveAccessData')}` }}
              </b-link>
            </b-row>
            <b-row style="justify-content: center; align-items: center;">
              <b-link class="bottom-link mb-3 mt-2" @click="resetEnv()">
                      {{ `${$tc('info.backLogin')}` }}
              </b-link>
            </b-row>
            </b-col>
            <b-row style="justify-content: space-evenly" v-else-if="showInsertOTP">
              <h6 class="info-text mb-3 mt-2 mr-2 fs-12">
                {{ `${$tc('info.otpNotReceivedQuestion')}` }}
              </h6>
              <b-link @click="sendOTP()" class="bottom-link mb-3 mt-2">
                {{ `${$tc('info.resendOTP')}` }}
              </b-link>
            </b-row>
          </b-row>
        </b-card>
      </b-col>
    </b-container>
  </div>

</template>
<script>
import Vue from "vue";
import {isNil} from "lodash";
import axios from "axios";
import HideIcon from '@/assets/images/hide.svg';
import ShowIcon from '@/assets/images/show.svg';

export default {
  name: 'Login',
  data() {
    return {
      deviceID: Vue.getLocalStorage('deviceID'),
      isActive: false,
      input: {
        telephoneNumber: "",
        PIN: "",
        OTP: ""
      },
      newRegistration: {
        name: "",
        surname: "",
        telephoneNumber: "",
        email: "",
        PIN: "",
        confirmPIN: "",
        showPIN: false,
        showConfirmPIN: false,
      },
      privacy: "false",
      telephoneNumberExistsOnDevice: false,
      showPassword: false,
      hideIconSrc: HideIcon,
      showIconSrc: ShowIcon,
      displayedPhone: '',
      onFocus: false,
      isMobile: false,
      isNewDevice: false,
      isNewRegistration: false,
      showInsertOTP: false,
      showInsertPIN: false,
      showRegistration: false,
      showFullRegistration: false,
      sessionId: '',
      assertionId: '',
      challenge: '',
      isValidPhoneNumber: false,
      color: {
        primary: 'var(--primary)'
      },
    }
  },
  computed: {
    telephoneNumberState() {
      return this.input.telephoneNumber.length >= 9
    },
    PINState() {
      return this.input.PIN.length >= 6
    },
    registrationFormState() {
      return this.newRegistration.name.length >= 2 && this.newRegistration.surname.length >= 2
          && this.newRegistration.name.length >= 2 && this.isEmail(this.newRegistration.email)
    },
    newPINState() {
      if (!this.showFullRegistration) {
        return true
      } else {
        return this.newRegistration.PIN.length >= 6 && this.newRegistration.confirmPIN.length >= 6
            && this.newRegistration.PIN.length === this.newRegistration.confirmPIN.length
      }
    },
    newPinCheck() {
      return this.newRegistration.PIN.length === 6
    },
    MatchingPins() {
      return this.newRegistration.PIN === this.newRegistration.confirmPIN
    },
    OTPState() {
      return this.input.OTP.length >= 6
    }
  },
  created() {
    this.deviceID = Vue.getDeviceID()
    if (!isNil(Vue.getLocalStorage('session'))) {
      Vue.setLocalStorage('session', null)
    }
  },
  methods: {
    buildPhoneString() {
      let value = ''
      value = this.displayedPhone.replace('+', '')
      value = value.replace(/ /g, '')
      this.input.telephoneNumber = value
    },
    prova(event) {
      this.isValidPhoneNumber = event.valid
    },
    resetEnv() {
      this.privacy = "false"
      this.telephoneNumberExistsOnDevice = false
      this.showPassword = false
      this.isNewDevice = false
      this.isNewRegistration = false
      this.showInsertOTP = false
      this.showFullRegistration = false
      this.showRegistration = false
      this.showInsertPIN = false
    },
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isEmail(email) {
      return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(email);
    },
    registrationContinue() {
      this.showInsertOTP = false;
      this.showFullRegistration = true;
    },
    checkLogin() {
      this.$root.$emit('activeLoader', true)
      Vue.myLogin(this.input.telephoneNumber).then(async (response) => {
        await this.checkRegFailure()
        if (response.status === 404) {
          this.$root.$emit('successNotification', `${this.$tc('info.performRegistration')}`,`${this.$tc('info.unregisteredUser')}`)
          this.registrationStart()
        } else {
          this.sessionId = response.data.session_id
          this.assertionId = response.data.assertion_id
          this.challenge = response.data.challenge
          if (response.data.step === 'INSERT_PIN') {
            this.showInsertPIN = true;
          } else if (response.data.step === 'SMS_OTP') {
            // MODAL REPLACEMENT
            this.$root.$emit('successNotification', `${this.$tc('info.OTPSent')}`)
            this.showInsertOTP = true; this.showRegistration = false;
          }
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        this.$root.$emit('activeLoader', false)
        if (error.response == undefined) {
          error.status = 502
          console.log("here")
          console.log(error)
          Vue.manageErrors(this, error)
          return
        }
        if (error.response.status !== 404) {
          Vue.manageErrors(this, error)
        }
      })
    },
    async checkRegistration() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/register/' + this.newRegistration.telephoneNumber, {
        device_id: this.deviceID,
        firstName: this.newRegistration.name,
        lastName: this.newRegistration.surname,
        email: this.newRegistration.email
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else {
          this.sessionId = response.data.session_id
          this.assertionId = response.data.assertion_id
          this.challenge = response.data.challenge
          this.$root.$emit('successNotification', `${this.$tc('info.OTPSent')}`,"OTP")
          this.showInsertOTP = true; this.showRegistration = false;
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    async sendOTP() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/newotp/' + this.input.telephoneNumber + '/' + this.sessionId, {
        assertion_id: this.assertionId,
        fch: this.challenge
      }).then((response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else {
          this.$root.$emit('successNotification', `${this.$tc('info.OTPSent')}`,"OTP")
          console.log("inviato msg otp")
          this.showInsertOTP = true; this.showRegistration = false;
        }
        this.assertionId = response.data.assertion_id
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    async verifyOTP() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/otp/' + this.input.telephoneNumber + '/' + this.sessionId, {
        OTP: this.input.OTP,
        assertion_id: this.assertionId,
        fch: this.challenge
      }).then(async (response) => {
        if (response.status !== 200) {
          this.$root.$emit('errorNotification', `${this.$tc('info.otpError')}`,"OTP")
          this.showInsertOTP = true; this.showRegistration = false;
        } else {
          this.assertionId = response.data.assertion_id
          this.$root.$emit('successNotification',
            this.isNewRegistration ? `${this.$tc('success.verifiedOTP')}` : `${this.$tc('success.authorizedDevice')}`,
            (this.isNewRegistration && !this.showInsertOTP && !this.telephoneNumberExistsOnDevice) ? `${this.$tc('info.unregisteredUser')}` : `${this.$tc('success.verifiedAccess')}`
            )
          if (this.isNewRegistration){
            this.registrationContinue()
          } else {
            this.telephoneNumberExistsOnDevice = true
            this.showInsertOTP = false
            this.showInsertPIN = true
          }
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        if (error.response.status !== 400) {
          Vue.manageErrors(this, error)
        }
        this.$root.$emit('activeLoader', false)
        // this.$root.$emit('errorNotification', `${this.$tc('info.otpError')}`,"OTP")
          this.showInsertOTP = true; this.showRegistration = false;
      })
    },
    async verifyPIN(telephone) {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/pin/' + telephone + '/' + this.sessionId, {
        PIN: this.isNewRegistration ? this.newRegistration.PIN : this.input.PIN,
        assertion_id: this.assertionId,
        fch: this.challenge
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else if (!isNil(response.data.access_token)) {
          if (isNil(response.data.email) || isNil(response.data.firstName) || isNil(response.data.lastName)) {
            const session = {
              token: response.data.access_token,
            }
            Vue.setLocalStorage('session', session)
          } else {
            const session = {
              firstName: this.isNewRegistration ? this.newRegistration.name : response.data.firstName,
              lastName: this.isNewRegistration ? this.newRegistration.name : response.data.lastName,
              email: this.isNewRegistration ? this.newRegistration.email : response.data.email,
              token: response.data.access_token,
            }
            Vue.setLocalStorage('session', session)
          }
          Vue.setLocalStorage('telephoneNumber', this.input.telephoneNumber)
          this.$root.$emit('changeLocalStorage')
          if (this.isNewRegistration) {
            this.$root.$emit('successNotification', `${this.$tc('success.canAccessToPortal')}`,`${this.$tc('message.registration')} ${this.$tc('message.completed')}`)
            this.$router.go()
          } else {
            if (isNil(response.data.email) || isNil(response.data.firstName) || isNil(response.data.lastName)) {
              await this.$router.push('/user_profile')
            } else {
              // check if the logging user is Admin
              await axios.get('/admin', {
                headers: {
                  'Authorization': Vue.getLocalStorage('session').token,
                }
              })
                  .then(async (response) => {
                    if (response.status === 200) {
                      Vue.setAdmin()
                    }
                  }).catch((error) => {
                    if (error.response.status !== 401) {
                      Vue.manageErrors(this, error)
                    }
                  })
              Vue.authenticate()
              if (Vue.isAdministrator()) {
                this.$router.push('/events')
              } else {
                this.$router.push('/home')
              }
              // Login Complete Log
              if (!isNil(Vue.getLocalStorage('reg'))) {
                Vue.deleteLocalStorage('reg')
              }
            }
          }
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    async resetPIN() {
      Vue.setLocalStorage('telephoneNumber', this.input.telephoneNumber)
      this.$router.push('/change_pin')
    },
    async registrationStart() {
      this.showRegistration = true;
      this.isNewRegistration = true;
      this.newRegistration.telephoneNumber = this.input.telephoneNumber
      Vue.setLocalStorage('reg', this.input.telephoneNumber)
    },
    async checkRegFailure() {
      if (!isNil(Vue.getLocalStorage('reg'))) {
        console.log(Vue.getLocalStorage('reg'))
        Vue.deleteLocalStorage('reg')
      }
    }
  },
  mounted() {
    this.checkWindowWidth()
  }
}
</script>

<style>

</style>

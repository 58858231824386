import Vuex from 'vuex';
import Vue from "vue";


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    flavor: 'telepass'
  },
  mutations: {
    setFlavor(state, flavor) {
      state.flavor = flavor;
    }
  }
});

export default store;

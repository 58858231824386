<template>
    <h6 class="title-card-text" style="margin-top: 0 !important;">{{ titleText }}</h6>
  </template>
  
  <script>
  export default {
    name: 'Title',
    props: {
      titleText: {
        type: String,
        required: true
      }
    }
  }
  </script>

<template>
    <b-col class="justify-content-center align-items-center h-100 p-0">
        <h6 :class="'nav-element prevent-select header-text '+getClass+' ml-1 mr-1 p-0' +(isLink ? ' primary-color':'')">{{ text }}</h6>
        <div :class="active ? 'active-bar':'invis'"></div>
    </b-col>
</template>

<script>
export default {
    props:{
        text:{
            type:String,
            default:""
        },
        active:{
            type:Boolean,
            default:false
        },
        isLink: {
            type: Boolean,
            default: false
        },
        textClass:{
            type:String,
            default:""
        }
    },
    data() {
        return {
            color: {
                primary: 'var(--primary)'
            },
        }
    },
    computed:{
        getClass(){
            return this.textClass
        }
    }
}
</script>

<style scoped>

</style>
import Router from 'vue-router'
import Login from "@/components/Login.vue"
import MyGetWindowSize from '@/plugin/my-get-window-size'
import MyLocalStorage from "@/plugin/my-local-storage";
import MyServerInteraction from "@/plugin/my-server-interaction";
import MyGetDeviceID from "@/plugin/my-get-device-id";
import MyGetStopMotivations from "@/plugin/my-get-stop-motivations";
import Authentication from '@/plugin/auth';
import Vue from "vue";
import Home from "@/components/Home";
import UserProfile from "@/components/UserProfile";
import DeviceInstallation from "@/components/registry/DeviceInstallation";
import FirstStep from "@/components/install/FirstStep"
import SecondStep from "@/components/install/SecondStep"
import ChangePIN from "@/components/ChangePIN"
import ThirdStep from "@/components/install/ThirdStep";
import Admins from "@/components/admin/Admins"
import Events from  "@/components/admin/Events"

Vue.use(Router)
Vue.use(MyGetWindowSize)
Vue.use(MyLocalStorage)
Vue.use(MyServerInteraction)
Vue.use(MyGetDeviceID)
Vue.use(MyGetStopMotivations)
Vue.use(Authentication)

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "login",
            component: Login,
            meta: {
                authOnly: false
            }
        },
        {
            path: "/home/:force",
            name: "home-forced",
            component: Home,
            meta: {
                authOnly: true
            }
        },
        {
            path: "/home",
            name: "home",
            component: Home,
            meta: {
                authOnly: true
            }
        },
        {
            path: "/user_profile",
            name: "userProfile",
            component: UserProfile,
            meta: {
                authOnly: false
            }
        },
        {
            path: "/registry/obu_plate",
            name: "obu_plate",
            component: DeviceInstallation,
            meta: {
                authOnly: true
            }
        },
        {
            path: "/install/first-step",
            name: "first-step",
            component: FirstStep,
            meta: {
                authOnly: true
            }
        },
        {
            path: "/install/second-step",
            name: "second-step",
            component: SecondStep,
            meta: {
                authOnly: true
            }
        },
        {
            path: "/install/third-step",
            name: "third-step",
            component: ThirdStep,
            meta: {
                authOnly: true
            }
        },
        {
            path: "/change_pin",
            name: "change_pin",
            component: ChangePIN,
            meta: {
                authOnly: false
            }
        },
        {
            path: "/events",
            name: "events",
            component: Events,
            meta: {
                authOnly: true,
                adminOnly: true
            }
        },
        {
            path: "/admins",
            name: "admins",
            component: Admins,
            meta: {
                authOnly: true,
                adminOnly: true
            }
        }
    ]
})

// Authenticated Route Check
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authOnly)) {
        if (!Vue.isAuthenticated()) {
            next({path: '/'})
        } else if (to.matched.some(record => record.meta.adminOnly) && !Vue.isAdministrator()){
            next({path: '/'})
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router

<template>

<div class="d-flex align-items-center justify-content-center p-0">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
        <b-col :cols=cols :sm=sm :md=md :lg=lg :xl=xl class="p-0">
            <b-card class="main-card pl-3 pr-3 main-panel">
                <slot></slot>
            </b-card>
        </b-col>
    </b-container>
</div>

</template>

<script>
import Vue from "vue";

export default {
    name: 'MainPanel',
    props: {
      cols:{
        type: String,
        default: '12',
      },
      sm:{
        type: String,
        default: '12',
      },
      md:{
        type: String,
        default: '10',
      },
      lg:{
        type: String,
        default: '9',
      },
      xl:{
        type: String,
        default: '9',
      },

    },
    data() {
    return {
      isMobile: false,
    }
  },
  methods: {
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
  },
  mounted() {
    this.checkWindowWidth()
  }
}
</script>

<style>

</style>

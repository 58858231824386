/* eslint-disable no-param-reassign */

const MyGetWindowSize = {
  install(Vue) {
    Vue.myGetWindowSize = () => {
      const innerWidth = window.innerWidth
      let size = 'cols'
      switch (true) {
        case innerWidth < 576:
          size = 'cols';
          break;
        case innerWidth >= 576 && innerWidth < 768:
          size = 'sm';
          break;
        case innerWidth >= 768 && innerWidth < 992:
          size = 'md';
          break;
        case innerWidth >= 992 && innerWidth < 1200:
          size = 'lg';
          break;
        case innerWidth >= 1200:
          size = 'xl';
          break;
      }
      return size
    }
  }
}

export default MyGetWindowSize

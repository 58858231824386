<template>
  <div>
    <b-row class="justify-content-between">
        <Button :text="`${$tc('admin.eventsPage.filters.buttons.collapse')}`" :clickFunc="toggleFilterVisibility"/>
        <Button :text="`${$tc('admin.eventsPage.filters.buttons.clear')}`" :clickFunc="clearFilters"/>
    </b-row>
    <b-collapse v-if="toggleFilters" :visible="true" class="text-style">
        <b-row class="mt-2 mb-3">
            <b-col >
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.obu')}` }}</label>
                <b-input-group class="custom-dropdown-container">
                    <b-dropdown id="custom-dropdown" :text="obuId" v-model="obuId" @show="onShowObuDropHandler" @hide="onHideObuDropHandler">
                        <b-dropdown-header v-if="showObuSearch && !noObuAvailable"><SearchBox placeholder="OBU-ID..." v-on:search-changed="filterDropDown($event)"/></b-dropdown-header>
                        <b-dropdown-text v-if="noObuAvailable">{{ `${$tc('admin.eventsPage.filters.dropdowns.placeholders.noObu')}` }}</b-dropdown-text>
                        <b-dropdown-item v-if="obuId !== `${$tc('admin.eventsPage.filters.dropdowns.placeholders.obu')}`" @click="obuId = `${$tc('admin.eventsPage.filters.dropdowns.placeholders.obu')}`">
                            {{`${$tc('admin.eventsPage.filters.dropdowns.placeholders.allOpts')}`}}
                        </b-dropdown-item>
                        <b-dropdown-item id="item.index" v-for="item in search!=='' ? matchedObuIds : availObuIds" :key="item" @click="obuId = item === '' ? '-': item " class="dropdown-items">
                            {{ item }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-input-group>
            </b-col>
            <b-col >
                <div>
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.opcode')}` }}</label>
                <b-input-group class="custom-dropdown-container">
                    <b-dropdown id="custom-dropdown" :text="$tc('admin.eventsPage.opcodes.'+operationCode)" v-model="operationCode" @show="onShowHandler" @hide="onHideHandler">
                        <b-dropdown-header v-if="showOpCodeSearch && !noOpcodeAvailable"><SearchBox placeholder="Operation Code..." v-on:search-changed="filterDropDown($event)"/></b-dropdown-header>
                        <b-dropdown-text v-if="noOpcodeAvailable">{{ `${$tc('admin.eventsPage.filters.dropdowns.placeholders.noOpcode')}` }}</b-dropdown-text>
                        <b-dropdown-item v-if="operationCode !== 0" @click="operationCode = 0">
                            {{`${$tc('admin.eventsPage.filters.dropdowns.placeholders.allOpts')}`}}
                        </b-dropdown-item>
                        <b-dropdown-item id="item.index" name="item.index" v-for="item in search !== '' ? matchedOpCodes : availOpCodes" :key="item" 
                                @click="operationCode = item;">
                            {{ `${$tc('admin.eventsPage.opcodes.'+item)}` }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-input-group>
            </div>
            </b-col>
        </b-row>

        <b-row class="mt-2 mb-3">
            <b-col >
                <div>
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.plate')}` }}</label>
                <b-input-group class="custom-dropdown-container">
                    <b-dropdown id="custom-dropdown" :text="plateNumber" v-model="plateNumber" @show="onPlateShowHandler" @hide="onPlateHideHandler">
                        <b-dropdown-header v-if="showPlateSearch && !noPlateAvailable"><SearchBox :placeholder="`${$tc('admin.eventsPage.filters.dropdowns.placeholders.searchPlate')}`" v-on:search-changed="filterDropDown($event)"/></b-dropdown-header>
                        <b-dropdown-text v-if="noPlateAvailable">{{ `${$tc('admin.eventsPage.filters.dropdowns.placeholders.noPlate')}` }}</b-dropdown-text>
                        <b-dropdown-item v-if="plateNumber !== `${$tc('admin.eventsPage.filters.dropdowns.placeholders.plate')}`" @click="plateNumber = `${$tc('admin.eventsPage.filters.dropdowns.placeholders.plate')}`">
                            {{`${$tc('admin.eventsPage.filters.dropdowns.placeholders.allOpts')}`}}
                        </b-dropdown-item>
                        <b-dropdown-item id="item.index" name="item.index" v-for="item in search !== '' ? matchedPlates : availPlates" :key="item" 
                                @click="plateNumber = item === '' ? 'EMPTY': item ">
                                {{ item }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-input-group>
            </div>
            </b-col>
            <b-col >
                <div>
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.status')}` }}</label>
                <b-input-group class="custom-dropdown-container">
                    <b-dropdown id="custom-dropdown" :text="operationStatus !== null ? $tc('admin.eventsPage.filters.dropdowns.opStatus.'+operationStatus) : $tc('admin.eventsPage.filters.dropdowns.placeholders.status')" v-model="operationStatus">
                        <b-dropdown-item v-if="operationStatus !== null" id="item.index" name="item.index" @click="operationStatus = null">{{ `${$tc('admin.eventsPage.filters.dropdowns.placeholders.allOpts')}` }}</b-dropdown-item>
                        <b-dropdown-item id="item.index" name="item.index" @click="operationStatus = 'true'">{{ `${$tc('admin.eventsPage.filters.dropdowns.opStatus.true')}` }}</b-dropdown-item>
                        <b-dropdown-item id="item.index" name="item.index" @click="operationStatus = 'false'">{{ `${$tc('admin.eventsPage.filters.dropdowns.opStatus.false')}` }}</b-dropdown-item>
                        <b-dropdown-item id="item.index" name="item.index" @click="operationStatus = 'null'"> {{ `${$tc('admin.eventsPage.filters.dropdowns.opStatus.null')}` }}</b-dropdown-item>
                    </b-dropdown>
                </b-input-group>
            </div>
            </b-col>
        </b-row>

        <b-row class="mt-2 mb-3">
            <b-col>
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.startTs')}` }}</label>
                <b-input-group :state="isValidTimeDelta">
                <b-form-datepicker id="start-date-picker"
                                    v-model="startDate"
                                    :state="isValidStartDate ? isValidTimeDelta : null"
                                    :max="maxDate"
                                    :placeholder="`${$tc('admin.eventsPage.filters.dropdowns.placeholders.startTs')}`"
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                <div class="input-group-append">
                    <b-button
                        @click="startDate = null; startTime = '00:00:00'">Reset
                    </b-button>
                </div>
                </b-input-group>
            </b-col>
            <b-col>
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.startTime')}` }}</label>
                <b-form-input v-model="startTime"
                                id="input-start-time"
                                type="time"
                                :state="isValidStartDate ? isValidTimeDelta : null"
                                value="HH:mm:ss"
                                :placeholder="`${$tc('admin.eventsPage.filters.dropdowns.labels.startTime')}`"
                                step="2"
                                required
                    ></b-form-input>
            </b-col>
        </b-row>
        <b-row class="mt-2 mb-3">
            <b-col>
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.endTs')}` }}</label>
                <b-input-group :state="isValidTimeDelta">
                <b-form-datepicker id="end-date-picker"
                                    v-model="endDate"
                                    :state="isValidEndDate ? isValidTimeDelta : null"
                                    :max="maxDate"
                                    :placeholder="`${$tc('admin.eventsPage.filters.dropdowns.placeholders.endTs')}`" 
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                <div class="input-group-append">
                    <b-button
                            @click="endDate = null; endTime = '00:00:00'">Reset
                    </b-button>
                    </div>
                </b-input-group>
            </b-col>
            <b-col>
                <label>{{ `${$tc('admin.eventsPage.filters.dropdowns.labels.endTime')}` }}</label>
                <b-form-input v-model="endTime"
                                id="input-start-time"
                                type="time"
                                value="HH:mm:ss"
                                :state="isValidEndDate ? isValidTimeDelta : null"
                                placeholder="Seleziona orario di fine"
                                step="2"
                                required
                    ></b-form-input>
            </b-col>
        </b-row>
    </b-collapse>
  </div>
</template>

<script>
import Button from "@/components/admin/subcomps/Button.vue";
import axios from 'axios';
import {isNil} from 'lodash';
import SearchBox from '@/components/admin/subcomps/SearchBox';
import Vue from 'vue';

export default {
    components: {Button, SearchBox},
    name: 'EventFilters',
    data(){
        return{
            maxDate: this.getMaxDate(),
            startDate: null,
            endDate: null,
            startTime: '00:00:00',
            endTime: '00:00:00',
            obuId: this.$root.$tc('admin.eventsPage.filters.dropdowns.placeholders.obu'),
            plateNumber: this.$root.$tc('admin.eventsPage.filters.dropdowns.placeholders.plate'),
            obuDisplay: null,
            operationCode: 0,
            toggleFilters: false,
            availObuIds: [],
            availOpCodes: [],
            availPlates: [],
            matches: [],
            operationStatus: null,
            search: '',
            inputTimeout: null,
            showOpCodeSearch: false,
            showObuSearch: false,
            showPlateSearch: false,
        }
    },
    methods: {
        getTranslation(idArr){
            this.matchedOpCodes = idArr
        },

        redrawMenu() {
        this.$nextTick(() => {
            this.$refs.dropdown.forceUpdate()
            })
        },
        getMaxDate(){
            const now = new Date()
            const max = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            return max
        },

        toggleFilterVisibility() {
            this.toggleFilters = !this.toggleFilters
        },

        clearFilters(){
            this.startDate = null
            this.endDate = null
            this.startTime = '00:00:00'
            this.endTime = '00:00:00'
            this.obuId = this.$root.$tc('admin.eventsPage.filters.dropdowns.placeholders.obu')
            this.operationCode = 0
            this.plateNumber = this.$root.$tc('admin.eventsPage.filters.dropdowns.placeholders.plate')
            this.operationStatus = null
            this.$emit('reset-filters')
        },

        async fetchObus(){
            await axios.get("/operation/avail", {
                headers: {
                    'Authorization': Vue.getLocalStorage('session').token,
                },
                params: {
                kind: "obuid"
            }}).then(async (response) => {
                if (response.status !== 200) {
                    console.log("response error from backend")
                    return
                } else {
                    this.availObuIds = response.data
                }
            }).catch((error) => {
                console.log("Error during obu id fetch")
                console.log(error)
            })
        },
        async fetchOpCodes(){
            await axios.get("/operation/avail", {
                headers: {
                    'Authorization': Vue.getLocalStorage('session').token,
                },
                params: {
                kind: "opcode"
            }}).then(async (response) => {
                if (response.status !== 200) {
                    console.log("response error from backend")
                    return
                } else {
                    this.availOpCodes = response.data
                }
            }).catch((error) => {
                console.log("Error during opcodes fetch")
                console.log(error)
            })
        },

        async fetchPlates(){
            await axios.get("/operation/avail", {
                headers: {
                    'Authorization': Vue.getLocalStorage('session').token,
                },
                params: {
                kind: "plate"
            }}).then(async (response) => {
                if (response.status !== 200) {
                    console.log("response error from backend")
                    return
                } else {
                    this.availPlates = response.data
                }
            }).catch((error) => {
                console.log("Error during plate fetch")
                console.log(error)
            })
        },

        onShowObuDropHandler(){
            this.showObuSearch = true
            this.fetchObus()
        },

        onHideObuDropHandler(){
            this.showObuSearch = false
            this.search = ''
        },

        onShowHandler(){
            this.showOpCodeSearch = true
            this.fetchOpCodes()
        },

        onHideHandler() {
            this.showOpCodeSearch = false
            this.search = ''
        },

        onPlateShowHandler(){
            this.showPlateSearch = true
            this.fetchPlates()
        },

        onPlateHideHandler(){
            this.showPlateSearch = false
        },

        checkKey(event){
            const validChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ";
            if (validChars.indexOf(event.key) === -1) {
                // key is not a valid character
                return;
            }
            this.onKeydown(event)
        },

        filterDropDown(value){
            this.search = value
        },

        onKeydown(event) {
            if (this.resetSearch){
                this.search = ''
                this.resetSearch = false
            }
            this.search += event.key
            clearTimeout(this.inputTimeout)
            this.inputTimeout = setTimeout(() => {
                this.resetSearch = true
            }, 3000)
        },
    },
    mounted(){
        // this.fetchObus()
        // this.fetchOpCodes()
    },
    watch: {
        '$root.$i18n.locale': function(){
            this.clearFilters()
        },

        obuId(newValue) {
            this.$emit('obufilter-changed', newValue)
        },

        operationCode(newValue) {
            this.$emit('opcode-changed', newValue)
        },

        startDate(newValue) {
            if (this.isValidTimeDelta)
                this.$emit('start-date-changed', newValue)
        },

        endDate(newValue) {
            if (this.isValidTimeDelta)
                this.$emit('end-date-changed', newValue)
        },

        startTime(newValue) {
            this.$emit('start-time-changed', newValue)
        },

        endTime(newValue){
            this.$emit('end-time-changed', newValue)
        },

        plateNumber(newValue){
            this.$emit('plate-changed', newValue)
        },

        operationStatus(newValue){
            this.$emit('status-changed', newValue)
        }


    },
    beforeUnmount() {
        //window.removeEventListener("keyup", this.checkKey);
    },
    computed:{
        
        noObuAvailable(){
            return this.availObuIds.length === 0
        },

        noOpcodeAvailable(){
            return this.availOpCodes.length === 0
        },

        noPlateAvailable(){
            return this.availPlates.length === 0
        },

        isValidStartDate() {
            if (isNil(this.startDate))
                return null
            if (Date.parse(this.startDate))
                return true
            else return false
        },
        isValidEndDate() {
            if (isNil(this.endDate))
                return null
            if (Date.parse(this.endDate))
                return true
            else return false
        },
        isValidStartTime() {
            if (isNil(this.startTime))
                return null
            if (Date.parse('1970-01-01T'+this.startTime))
                return true
            else return false
        },
        isValidEndTime() {
            if (isNil(this.endTime))
                return null
            if (Date.parse('1970-01-01T'+this.endTime))
                return true
            else return false
        },

        matchedOpCodes() {
            // return this.availOpCodes.filter(item => item.toLowerCase().includes(this.search.toLowerCase()))
            return this.availOpCodes.filter(item => this.$root.$tc('admin.eventsPage.opcodes.'+item).toLowerCase().includes(this.search.toLowerCase()))
        },

        matchedObuIds() {
            return this.availObuIds.filter(item => item.toLowerCase().includes(this.search.toLowerCase()))
        },

        matchedPlates() {
            return this.availPlates.filter(item => item.toLowerCase().includes(this.search.toLocaleLowerCase()))
        },

        isValidTimeDelta() {
            if (isNil(this.endDate) || isNil(this.startDate))
                return true
            else {
                return Date.parse(this.endDate+'T'+this.endTime) > Date.parse(this.startDate+'T'+this.startTime)
            }
        }
    }

}
</script>

<style>

</style>

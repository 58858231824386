<template>
  <div class="col p-0">
    <MainPanel>
      <b-row class="justify-content-between mt-3 mb-5" align-v="center">
        <Title :titleText=" `${$tc('admin.adminMngPage.title')}` "/>
        <Button :text="''" :clickFunc="getAdminChunk" :customStyle="'width: 3.25rem !important;'">
          <img class="ref-icon" src="@/assets/images/refresh.svg" alt="Refresh"/>
        </Button>
        <b-row align-v="center">
          <SearchBox :placeholder="`${$tc('admin.adminMngPage.search')}`"
                     v-on:search-changed="search = $event"></SearchBox>
          <Button :text="`${$tc('admin.adminMngPage.buttons.add')}`" :clickFunc="addAdminModalFunc"></Button>
        </b-row>
      </b-row>
      <AdminFilters v-on:removed-changed="filterVars.removed = $event; getAdminChunk()"
                    v-on:reset-filters="filterVars.removed = null; getAdminChunk()"/>

      <b-table ref="mainTable" striped responsive :items="tableItems" :fields="tableFields">
        <template v-slot:cell(actions)="data">
          <b-row class="justify-content-between">
            <div @click="handleActionClick(data.item, 'delete')">
              <DeleteIcon class="mt-1 mb-2 ml-4 mr-4" :color="color.primary"></DeleteIcon>
            </div>
            <div @click="handleActionClick(data.item, 'edit')">
              <EditIcon class="mt-1 mb-2 ml-4 mr-4" :color="color.primary"></EditIcon>
            </div>
          </b-row>
        </template>
      </b-table>
    </MainPanel>

    <AdminDataModal v-if="openModal"
                    :enable="openModal"
                    :retrieved-admin="addAdmin ? null : retrievedAdmin"
                    v-on:admin-modal-data="performAction($event)"
    />
    <DeleteModal v-if="delModal"
                 v-on:cancel-delete="delModal = false"
                 v-on:perform-delete="performDelete()"
    />
  </div>
</template>

<script>
import MainPanel from '@/components/admin/subcomps/MainPanel.vue';
import Title from '@/components/admin/subcomps/Title.vue';
import SearchBox from '@/components/admin/subcomps/SearchBox.vue';
import Button from '@/components/admin/subcomps/Button.vue';
import AdminDataModal from './subcomps/AdminDataModal.vue';
import DeleteModal from './subcomps/DeleteModal.vue';
import AdminFilters from './subcomps/AdminFilters.vue';
import axios from "axios";
import Vue from 'vue';
import DeleteIcon from '../icons/DeleteIcon.vue';
import EditIcon from '../icons/EditIcon.vue';

export default {
  components: {MainPanel, Title, SearchBox, Button, AdminDataModal, DeleteModal, AdminFilters, DeleteIcon, EditIcon},
  name: 'admins',
  data() {
    return {
      tableFields: [],
      tableItems: [],
      search: '',
      filterVars: {
        removed: null
      },
      openModal: false,
      delModal: false,
      addAdmin: false,
      retrievedAdmin: null,
      toDelete: null,
      color: {
        primary: 'var(--primary)'
      },
    }
  },
  methods: {
    async performDelete() {
      await axios.delete("/admin/" + this.toDelete.toString(),
          {
            headers: {
              'Authorization': Vue.getLocalStorage('session').token,
            },
          }).then(async (response) => {
        if (response.status == 204) {
          console.log("deletion successfull")
          return
        }
      }).catch((error) => {
        console.log(error)
        Vue.manageErrors(this, error)
      })
      this.getAdminChunk()
      this.toDelete = null
      this.delModal = false
    },
    async performAction(event) {
      if (event !== null) {
        if (event.id == null) {
          await axios.post("/admin", event, {
            headers: {
              'Authorization': Vue.getLocalStorage('session').token,
            },
          }).then(async (response) => {
            if (response.status == 200) {
              console.log("admin successfully added")
            }
          }).catch((error) => {
            console.log(error)
            Vue.manageErrors(this, error)
          })
        } else {
          await axios.put("/admin/" + event.id.toString(), event, {
            headers: {
              'Authorization': Vue.getLocalStorage('session').token,
            },
          }).then(async (response) => {
            if (response.status == 204) {
              console.log("admin successfully updated")
            }
          }).catch((error) => {
            console.log(error)
            Vue.manageErrors(this, error)
          })
        }
      }
      this.getAdminChunk()
      this.addAdmin = false
      this.openModal = false
    },
    addAdminModalFunc() {
      this.addAdmin = true
      this.openModal = true
    },
    decreaseOffset() {
      this.currentOffset -= this.currentChunkSize
    },
    increaseOffset() {
      this.currentOffset += this.currentChunkSize
    },

    buildParams() {
      let params = {}
      if (this.search !== '') {
        params.query = this.search
      }
      if (this.filterVars.removed !== null) {
        params.removed = this.filterVars.removed.toString()
      }

      return params
    },

    async getAdminChunk() {
      await axios.get("/admin", {
        headers: {
          'Authorization': Vue.getLocalStorage('session').token,
        },
        params: this.buildParams()
      }).then(async (response) => {
        if (response.status != 200) {
          console.log(response.status)
        }
        this.tableItems = response.data
      }).catch((error) => {
        if (error.response.status === 404) {
          console.log(error)
          Vue.manageErrors(this, error)
        }
      })
    },

    async handleActionClick(item, action) {
      if (action == 'delete') {
        this.toDelete = item.id
        this.delModal = true
      }
      if (action == 'edit') {
        await axios.get("/admin/" + item.id.toString(),
            {
              headers: {
                'Authorization': Vue.getLocalStorage('session').token,
              },
            }).then(
            async (response) => {
              this.retrievedAdmin = response.data
            }
        ).catch((error) => {
          Vue.manageErrors(this, error)
        })
        this.addAdmin = false
        this.openModal = true
      }
    },
  },
  mounted() {
    this.tableFields = this.getLabels
    this.getAdminChunk()
  },
  watch: {
    '$root.$i18n.locale': function () {
      this.tableFields = this.getLabels
      //this.$refs.mainTable.$forceUpdate()
    },
    search: function () {
      this.getAdminChunk()
    }
  },
  computed: {
    getLabels() {
      return [
        {
          key: 'actions',
          sortable: false,
          label: this.$root.$tc('admin.adminMngPage.table.actions')
        },
        {
          key: 'username',
          sortable: true,
          label: this.$root.$tc('admin.adminMngPage.table.phone')
        },
        {
          key: 'first_name',
          sortable: true,
          label: this.$root.$tc('admin.adminMngPage.table.firstName')
        },
        {
          key: 'last_name',
          sortable: true,
          label: this.$root.$tc('admin.adminMngPage.table.lastName')
        },
        {
          key: 'email',
          sortable: false
        },
        {
          key: 'company',
          sortable: false,
          label: this.$root.$tc('admin.adminMngPage.table.company')
        },
        {
          key: 'removed',
          sortable: true,
          label: this.$root.$tc('admin.adminMngPage.table.removed')
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>

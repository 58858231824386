<template>
  <div class="col component-container d-flex align-items-center justify-content-center p-0">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
      <b-col cols="12" sm="12" md="10" lg="6" xl="5" class="p-0">
        <b-card class="login-card pl-5 pr-5 pt-4" style="border-radius: 10px">
          <b-row class="pt-2" style="justify-content: center" align-v="center">
            <h6 class="title-card-text mt-4 mb-4" style="margin-top:0 !important;">
              {{ `${$tc('installSteps.title')}` }}
            </h6>
          </b-row>
          <b-row style="justify-content: space-between;" align-v="center">
            <h6 class="info-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('message.obuId')}: ` + obuId }}
            </h6>
            <h6 class="info-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('message.plate')}: ` + plateNumber }}
            </h6>
          </b-row>
          <b-row style="justify-content: center" align-v="center">
            <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('installSteps.step3.operationTitle')}` }}
            </h6>
          </b-row>
          <div v-if="error"><!--Errors-->
            <div>
              <b-row style="justify-content: center" align-v="center">
                <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4 error-color">
                  {{
                    battery ? `${$tc('installSteps.step3.error.batteryMain')}` : `${$tc('installSteps.step3.error.errorMain')}`
                  }}
                </h6>
              </b-row>
              <b-row style="justify-content: center" align-v="center">
                <img class="align-content-center" src="@/assets/images/error.svg" alt="Error"/>
              </b-row>
              <b-row style="justify-content: center" align-v="center">
                <h6 class="step-text mr-2 ml-2 mt-4 mb-4 strong-font-weight">
                  {{
                    battery ? `${$tc('installSteps.step3.error.batteryDesc')}` : `${$tc('installSteps.step3.error.errorDesc')}`
                  }}
                </h6>
              </b-row>
            </div>

          </div>
          <div v-else-if="success">
            <b-row style="justify-content: center" align-v="center">
              <h6 class="info-text sub-title mr-2 ml-2 mt-4 success-color step-text">
                {{ `${$tc('installSteps.obuReady')}` }}
              </h6>
            </b-row>
            <b-row style="justify-content: center" align-v="center" class="my-5">
              <img class="align-content-center" src="@/assets/images/success.svg" alt="Success"/>
            </b-row>
          </div>
          <div v-else>
            <b-row align-v="center">
              <h6 class="step-text mt-4 mb-4 strong-font-weight">
                {{ `${$tc('installSteps.step3.instructions')}` }}
              </h6>
              <h6 class="step-text mt-4 mb-4 strong-font-weight" v-if="!updating">
                {{ `${$tc('installSteps.step3.instructions1')}` }}
              </h6>
            </b-row>
            <div v-if="updating">
              <b-row style="justify-content: center" align-v="center">
                <label class="info-text">{{ `${$tc('installSteps.step3.updating')}` }}</label>
              </b-row>
              <b-row style="justify-content: center" align-v="center" class="mb-5">
                <LoadingIcon class="align-content-center rotate" :color="color.primary"/>
              </b-row>
            </div>
          </div>
          <b-row class="mb-4" style="justify-content: center" align-v="center" v-if="!updating || error || success">
            <b-button v-if="!success" pill variant="secondary" class="custom-button secondary-button"
                      @click="$refs.confirmExitModal.show()">
              <div>{{ `${$tc('installSteps.buttonAbort')}` }}</div>
            </b-button>
            <b-button pill variant="primary" class="custom-button primary-button"
                      @click="!error && !updating ? updateStatus() : error ? tryAgain() : success ? completeInstall() : ''">
              {{
                success ? `${$tc('installSteps.newInstallation')}` : !error ? `${$tc('installSteps.buttonNext')}` : `${$tc('installSteps.retryButton')}`
              }}
            </b-button>
          </b-row>
          <div v-if="error">
            <b-row style="justify-content: center" class="mt-2">
              <h6 class="bottom-text mb-3 mt-2 mr-2">
                {{ `${$tc('installSteps.bottomMessage')}` }}
              </h6>
              <b-link class=" bottom-link noselect mb-3 mt-2" @click="backToGuides()">
                {{ `${$tc('installSteps.bottomLink')}` }}
              </b-link>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-modal
              ref="configurationInfoModal"
              id="configuration-info-modal"
              :title="`${$tc('installSteps.step2.configurationInformation')}`"
              size="lg"
              title-class="title-modal-text ml-auto"
              class="custom-modal"
      >
        <template #modal-footer="{ ok }">
          <b-button pill variant="primary" class="primary-button"
                    @click="ok();">
            {{ `${$tc('message.ok')}` }}
          </b-button>
        </template>
        <h6 class="custom-modal-text justify-content-center modal-body-text">
          {{ `${$tc('installSteps.step2.configurationInformationMessage')}` }}</h6>
      </b-modal>
      <b-modal
              ref="confirmExitModal"
              id="confirm-exit-modal"
              :title="`${$tc('info.stopInstallationQuestion')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="$refs.installationFailedModal.show()"
              @cancel="$refs.confirmExitModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="ok()">
            {{ `${$tc('message.yes')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="cancel()">
            No
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.fullStopInstallationQuestion')}` }}</h6>
      </b-modal>
      <b-modal
              ref="installationFailedModal"
              id="installation-failed-modal"
              :title="`${$tc('message.failureInstallation')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="motivation !== 52 ? sendMotivation() : $refs.otherFailureMotivationModal.show()"
              @cancel="$refs.confirmExitModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivation === '' || motivation === undefined">
            {{ `${$tc('message.go')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.selectReasonStopInstallation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="8" xl="8">
            <model-select :options="motivationList"
                          v-model="motivation"
                          class="info-text"
                          :placeholder="`${$tc('info.selectMotivation', 1)}`">
            </model-select>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
              ref="otherFailureMotivationModal"
              id="other-motivation-failed-modal"
              :title="`${$tc('message.failureInstallation')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="sendMotivation()"
              @cancel="$refs.otherFailureMotivationModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivationDescription.length === 0">
            {{ `${$tc('message.send')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center m-3">
          {{ `${$tc('info.insertOtherMotivation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="10" xl="10">
            <b-form-textarea
                    class="custom-modal-text modal-txt-field"
                    style="text-align: start !important; font-weight: bold !important;"
                    v-model="motivationDescription"
                    :placeholder="`${$tc('message.problem')}`"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
              ref="returnOrSubsDevice"
              id="return-subst-device-modal"
              :title="`${$tc('info.returnORReplacementDevice')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="$router.push('/home')"
              class="custom-modal"
      >
        <template #modal-footer="{ ok }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button custom-button" @click="ok()">
            {{ `${$tc('message.ok')}` }}
          </b-button>
        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.returnReplaceDeviceMessage')}` }}</h6>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {isNil} from "lodash";

export default {
  name: "ThirdStep",
  data() {
    return {
      isMobile: false,
      obuId: Vue.getLocalStorage('obuId'),
      plateNumber: Vue.getLocalStorage('plateNumber'),
      instStartTime: Vue.getLocalStorage('instStartTime'),
      updating: false,
      motivation: '',
      motivationList: [],
      motivationDescription: '',
      telephoneNumber: Vue.getLocalStorage('telephoneNumber'),
      error: false,
      battery: false,
      success: false,
      color: {
        primary: 'var(--primary)'
      },
    }
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.interval);
    next();
  },
  mounted() {
    this.checkWindowWidth()
    this.motivationList = Vue.getStopMotivations(this)
    this.$root.$on('change-lang-motivations', () => {
      this.motivationList = Vue.getStopMotivations(this)
    })
  },
  methods: {
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    backToGuides() {
      clearTimeout(this.interval)
      this.updating = false
      this.$router.push("/home/show")
    },
    tryAgain() {
      if (this.battery) {  // Restart the installation process
        this.$router.push("/install/first-step")
      } else {  // Retry KeyOnFetch
        this.error = false
        this.battery = false
        this.updateStatus()
      }
    },
    async sendMotivation() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/operation', {
        cell_no: this.telephoneNumber,
        obu_id: this.obuId,
        plate_number: this.plateNumber,
        operation_code: this.motivation,
        description: this.motivationDescription.length > 0 ? this.motivationDescription : '',
        session_id: Vue.getLocalStorage('session_uuid'),
      }, {
        headers: {
          'Authorization': isNil(Vue.getLocalStorage('session').token) ? '' : Vue.getLocalStorage('session').token,
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else {
          this.$refs.returnOrSubsDevice.show()
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, "Backend request error: " + error.response.errMsg)
        this.$root.$emit('activeLoader', false)
      })
    },
    updateStatus() {
      this.updating = true
      this.breakPolling = false
      var checkTime = Math.floor(Date.now() / 1000)
      this.statusPolling(checkTime)
    },
    async statusPolling(checkTime) {
      this.interval = setTimeout(async () => {
        try {
          if (!this.updating) {
            console.log("Monitor was stopped")
            return
          }
          const response = await axios.get('/proxy/obu_key/' + this.obuId + '?time=' + this.instStartTime + '&checktime=' + checkTime, {
            headers: {
              'Authorization': Vue.getLocalStorage('session').token,
              'Session_UUID': Vue.getLocalStorage('session_uuid'),
              'Plate': this.plateNumber,
            },
          })
          if (response.data.operationStatus === 'BATTERY') {
            // Show battery failure text
            this.battery = true
            this.error = true
            clearTimeout(this.interval)
            // Vue.logOperation(this, this.telephoneNumber, this.obuId, this.plateNumber, 54, null)
            return
          }
          if (response.data.operationStatus === 'OK') {
            console.log("KeyOn signal found, install successful")
            this.success = true
            // await Vue.logOperation(this, this.telephoneNumber, this.obuId, this.plateNumber, 41, null)
            // await Vue.logOperation(this, this.telephoneNumber, this.obuId, this.plateNumber, 44, null)
            return
          } else {
            this.statusPolling(checkTime)
          }
        } catch (error) {  // TODO: Rewrite all requests to manage response codes != 200 in the cath directive

          if (error.response.status !== 404) {
            // 404 is an expexted status code
            Vue.manageErrors(this, "Backend request error: " + error.response.errMsg)
          }
          this.error = true
          clearTimeout(this.interval)
          // Vue.logOperation(this, this.telephoneNumber, this.obuId, this.plateNumber, 42, null)
          return
        }
      }, 2000)
    },
    completeInstall() {
      // Clean Installation vars
      Vue.deleteLocalStorage('instStartTime')
      Vue.deleteLocalStorage('obuId')
      Vue.deleteLocalStorage('plateNumber')
      // Restart entire process
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <svg width="70" height="70" viewBox="0 0 70 70" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.1526 6.85449C19.6336 6.85449 7.00708 19.481 7.00708 35C7.00708 50.5205 19.6336 63.147 35.1526 63.147C50.6731 63.147 63.2996 50.5205 63.2996 35C63.2996 19.481 50.6731 6.85449 35.1526 6.85449ZM35.1526 59.3531C21.7243 59.3531 10.7982 48.4298 10.7982 35C10.7982 21.5717 21.7243 10.6456 35.1526 10.6456C48.5824 10.6456 59.5057 21.5717 59.5057 35C59.5057 48.4298 48.5824 59.3531 35.1526 59.3531ZM35.1526 35.4373C37.4128 35.4373 39.5362 34.5571 41.1341 32.9607C42.732 31.3628 43.6121 29.2393 43.6121 26.9792C43.6121 24.7205 42.732 22.5957 41.1341 20.9992C41.1341 20.9992 41.1341 20.9992 41.1341 20.9978C37.9369 17.8034 32.3627 17.8034 29.1726 20.9978C27.5747 22.5957 26.6946 24.7191 26.6946 26.9792C26.6946 29.2393 27.5747 31.3628 29.1712 32.9592C30.7691 34.5571 32.8925 35.4373 35.1526 35.4373ZM31.8543 23.6794C32.7344 22.7979 33.9065 22.3123 35.1526 22.3123C36.3988 22.3123 37.5708 22.7979 38.451 23.6794C38.451 23.6794 38.451 23.6794 38.451 23.6809C39.3339 24.561 39.8182 25.7331 39.8182 26.9792C39.8182 28.2253 39.3339 29.3974 38.451 30.279C36.6907 32.0421 33.616 32.0435 31.8529 30.2775C30.9713 29.3974 30.4857 28.2253 30.4857 26.9792C30.4857 25.7331 30.9713 24.561 31.8543 23.6794ZM39.3995 39.1927H30.9058C25.786 39.1927 21.4836 42.8756 20.6775 47.9484C20.5138 48.9824 21.2187 49.9537 22.2526 50.116C23.288 50.2898 24.2593 49.5777 24.4231 48.5409C24.9343 45.3223 27.6616 42.9867 30.9058 42.9867H39.3995C42.6437 42.9867 45.3695 45.3223 45.8851 48.5437C46.0332 49.4751 46.8392 50.1416 47.7535 50.1416C47.8532 50.1416 47.9529 50.1331 48.0555 50.116C49.0894 49.9508 49.7929 48.9795 49.6277 47.9456C48.8188 42.8756 44.5179 39.1927 39.3995 39.1927Z"/>
  </svg>
</template>
<script>
export default {
  name: 'user-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

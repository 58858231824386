<template>
    <svg width="24" height="24" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.64767 1.61658H12.6218C13.0694 1.61658 13.4301 1.25596 13.4301 0.80829C13.4301 0.360622 13.0694 0 12.6218 0H7.64767C7.2 0 6.83938 0.360622 6.83938 0.80829C6.83938 1.25596 7.2 1.61658 7.64767 1.61658ZM19.4611 5.34715H18.4041V20.3938C18.4041 22.3834 16.7751 24 14.7731 24H5.44663C3.46943 24 1.86529 22.3834 1.86529 20.3938V5.34715H0.80829C0.360622 5.34715 0 4.98653 0 4.53886C0 4.09119 0.360622 3.73057 0.80829 3.73057H2.67358H17.5959H19.4611C19.9088 3.73057 20.2694 4.09119 20.2694 4.53886C20.2694 4.98653 19.9088 5.34715 19.4611 5.34715ZM3.48187 5.34715H16.7876V20.3938C16.7876 21.4881 15.8798 22.3834 14.7731 22.3834H5.44663C4.36477 22.3834 3.48187 21.4881 3.48187 20.3938V5.34715ZM12.6218 19.0259C12.1741 19.0259 11.8135 18.6653 11.8135 18.2176V9.51295C11.8135 9.06529 12.1741 8.70466 12.6218 8.70466C13.0694 8.70466 13.4301 9.06529 13.4301 9.51295V18.2176C13.4301 18.6653 13.0694 19.0259 12.6218 19.0259ZM6.83938 18.2176C6.83938 18.6653 7.2 19.0259 7.64767 19.0259C8.09534 19.0259 8.45596 18.6653 8.45596 18.2176V9.51295C8.45596 9.06529 8.09534 8.70466 7.64767 8.70466C7.2 8.70466 6.83938 9.06529 6.83938 9.51295V18.2176Z"/>
    </svg>

</template>

<script>
export default {
  name: 'delete-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>
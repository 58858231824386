<template>
  <div class="col component-container d-flex align-items-center justify-content-center p-0">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
      <b-col cols="12" sm="12" md="10" lg="7" xl="5" class="p-0">
        <b-card class="text-center main-card" :class="showAdv ? 'pl-3 pr-3' : ''">
          <div v-if="showAdv">
            <b-row style="justify-content: center" class="gsm-margin">
              <h6 class="title-card-text">
                {{ `${$tc('info.beforeProceeding')}` }}
              </h6>
            </b-row>
            <b-row style="justify-content: center" class="gsm-margin mr-3 ml-3">
              <h6 class="info-text">
                {{ `${$tc('info.gsmAdvMessage')}` }}
              </h6>
            </b-row>
            <b-row align-h="center" class="mb-5 pt-3">
              <b-col cols="12" sm="9" md="12" lg="12" xl="12">
                <b-button id="ok-button"
                          pill
                          variant="primary"
                          class="custom-button font-weight-bold primary-button"
                          @click="startInstall()">
                  {{ `${$tc('message.ok')}` }}
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row style="justify-content: center">
              <h6 class="title-card-text">
                {{ `${$tc('info.newObuInstallation')}` }}
              </h6>
            </b-row>
            <b-row style="justify-content: center" class="my-3 mx-3">
              <h6 class="info-text">
                {{ `${$tc('info.insertSerialAndPlateNumber')}` }}
              </h6>
            </b-row>

            <b-row align-h="center" class="m-4">
              <b-col cols="12" sm="12" md="12" lg="12" xl="9" >
                <!-- Obuid -->
                <b-input-group>
                  <template #append>
                    <b-input-group-text class="bg-transparent white-background">
                      <img src="@/assets/images/question_circle_placeholder.svg" id="popover-obu" alt="Question"/>
                      <b-popover custom-class="wide-popover" target="popover-obu" triggers="hover" placement="top">
                        <template #title><h6 class="normal-text-field">
                          {{ `${$tc('message.placement')} ${$tc('message.obuId')}` }}</h6></template>
                        <b-col>
                          <img src="@/assets/images/obu1.png" alt="Info"/>
                          <div class="text-center">
                            <img class="align-content-center" src="@/assets/images/obu_label.svg" alt="Info"/>
                          </div>
                        </b-col>
                      </b-popover>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="input.obuId"
                                class="normal-text-field box-style border-right-0 pl-2"
                                id="nw-obu-id"
                                type="text"
                                @keypress="isNumber($event)"
                                maxlength="15"
                                :placeholder="`${$tc('message.obuId')}`">
                  </b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row align-h="center" class="m-4">
              <b-col cols="12" sm="12" md="12" lg="12" xl="9" >
                <!-- Plate -->
                <b-form ref="form"
                        @submit.prevent="() => {return !(input.obuId.length < 15 || input.plate.length === 0);}">
                  <b-form-group>
                    <b-form-input v-model="input.plate"
                                  class="normal-text-field box-style pl-2"
                                  id="nw-plate"
                                  type="text"
                                  @input="convPlateToUpper"
                                  maxlength="14"
                                  aria-describedby="input-live-feedback"
                                  :placeholder="`${$tc('message.plate')}`">
                    </b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{ `${$tc('message.invalidPlate')}` }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-form>
              </b-col>
              <b-row style="justify-content: space-between" class="pt-3">
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-button id="stop-installation-button"
                            pill
                            variant="secondary"
                            @click="$refs.confirmExitModal.show()"
                            class="custom-button secondary-button">
                    {{ `${$tc('message.stopInstallation')}` }}
                  </b-button>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-button id="verify-button"
                            pill
                            variant="primary"
                            class="custom-button primary-button"
                            :disabled="input.obuId.length < 15 || input.plate.length === 0"
                            @click="checkAssociation()">
                    {{ `${$tc('message.verify')}` }}
                  </b-button>
                </b-col>
              </b-row>
            </b-row>
            <b-row style="justify-content: center" class="m-3">
              <b-row style="justify-content: space-evenly">
                <h6 class="bottom-text mt-2">
                  {{ `${$tc('info.gsmQuestion')} ` }}
                </h6>
                <b-link @click="$router.push('/home/show')" class="bottom-link m-2">
                  {{ `  ${$tc('info.returnToGuidePage')}` }}
                </b-link>
              </b-row>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-modal
              ref="confirmExitModal"
              id="confirm-exit-modal"
              :title="`${$tc('info.stopInstallationQuestion')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="$refs.installationFailedModal.show()"
              @cancel="$refs.confirmExitModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="ok()">
            {{ `${$tc('message.yes')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="cancel()">
            No
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text  justify-content-center">
          {{ `${$tc('info.fullStopInstallationQuestion')}` }}</h6>
      </b-modal>

      <b-modal
              ref="installationFailedModal"
              id="installation-failed-modal"
              :title="`${$tc('message.failureInstallation')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="motivation !== 52 ? sendMotivation() : $refs.otherFailureMotivationModal.show()"
              @cancel="$refs.confirmExitModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivation === '' || motivation === undefined">
            {{ `${$tc('message.go')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.selectReasonStopInstallation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="8" xl="8">
            <model-select :options="motivationList"
                          v-model="motivation"
                          class="info-text"
                          :placeholder="`${$tc('info.selectMotivation', 1)}`">
            </model-select>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
              ref="returnOrSubsDevice"
              id="return-subst-device-modal"
              :title="`${$tc('info.returnORReplacementDevice')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="$router.push('/home')"
              class="custom-modal"
      >
        <template #modal-footer="{ ok }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button custom-button" @click="ok()">
            {{ `${$tc('message.ok')}` }}
          </b-button>
        </template>
        <h6 class="custom-modal-text info-text  justify-content-center">
          {{ `${$tc('info.returnReplaceDeviceMessage')}` }}</h6>
      </b-modal>

      <b-modal
              ref="otherFailureMotivationModal"
              id="other-motivation-failed-modal"
              :title="`${$tc('message.failureInstallation')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="sendMotivation()"
              @cancel="$refs.otherFailureMotivationModal.hide()"
              class="custom-modal modal-dialog-centered"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivationDescription.length === 0">
            {{ `${$tc('message.send')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center m-3">
          {{ `${$tc('info.insertOtherMotivation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="10" xl="10">
            <b-form-textarea
                    class="custom-modal-text modal-txt-field"
                    style="text-align: start !important; font-weight: bold !important;"
                    v-model="motivationDescription"
                    :placeholder="`${$tc('message.problem')}`"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "DeviceInstallation",
  data() {
    return {
      telephoneNumber: Vue.getLocalStorage('telephoneNumber'),
      isMobile: false,
      showAdv: true,
      input: {
        plate: '',
        obuId: '00059'
      },
      motivation: '',
      motivationList: [],
      motivationDescription: ''
    }
  },
  computed: {
    areEqual() {
      return this.motivation === '' || this.motivation === undefined
    },
    plateNumberState() {
      const regex = /^[a-zA-Z0-9]+([- ][a-zA-Z0-9]+)*$/g
      return this.input.plate.match(regex) != null;
    }
  },
  methods: {
    startInstall() {
      this.showAdv = false
    },
    convPlateToUpper() {
      this.input.plate = this.input.plate.toUpperCase()
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    preventSpecialCharacters(event) {
      // Regular expression to allow alphabetic characters, number, space and minus between words
      const regex = /^[a-zA-Z0-9]+([- ][a-zA-Z0-9]+)*$/g
      // Get the character that was typed
      const char = String.fromCharCode(event.which)
      // Test the character against the regular expression
      if (!regex.test(char)) {
        // Prevent the character from being entered if it is a special character
        event.preventDefault()
      }
    },
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
      if (!_.isNil(Vue.getLocalStorage('plateNumber'))) {
        this.showAdv = false
      }
    },
    async checkAssociation() {
      this.$root.$emit('activeLoader', true)
      await axios.get('/proxy/obu_plate/' + this.input.obuId + '/' + this.input.plate, {
        headers: {
          'Authorization': Vue.getLocalStorage('session').token,
          'Session_UUID': Vue.getLocalStorage('session_uuid'),
        },
      }).then(async (response) => {
        if (response.status === 200) {
          // Success Toast
          this.$root.$emit('successNotification', this.$tc('success.associationPlateObuFound'))
          Vue.setLocalStorage('obuId', this.input.obuId)
          Vue.setLocalStorage('plateNumber', this.input.plate)
          await this.associationTsCheck(response.data.start_date)
          await this.$router.push('/install/first-step')
        } else {
          // Error toast
          this.$root.$emit('errorNotification', this.$tc('info.associationPlateObuNotFoundMessage'), this.$tc('errors.associationPlateObuNotFound'))
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        if (error.response.status === 404) {
          // Error Toast
          this.$root.$emit('errorNotification', this.$tc('info.associationPlateObuNotFoundMessage'), this.$tc('errors.associationPlateObuNotFound'))
        } else {
          Vue.manageErrors(this, error.response)
          Vue.logOperation(this, this.telephoneNumber, this.input.obuId, this.input.plate, 20, "error "+error.response.status+" on plate check",null)
        }
        this.$root.$emit('activeLoader', false)
      })
    },
    resetFields() {
      this.input = {
        obuId: '',
        plate: ''
      }
    },
    async sendMotivation() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/operation', {
        cell_no: this.telephoneNumber,
        obu_id: this.input.obuId,
        plate_number: this.input.plate,
        operation_code: this.motivation,
        description: this.motivationDescription.length > 0 ? this.motivationDescription : '',
        session_id: Vue.getLocalStorage('session_uuid'),
      }, {
        headers: {
          'Authorization': _.isNil(Vue.getLocalStorage('session').token) ? '' : Vue.getLocalStorage('session').token,
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else {
          this.$refs.returnOrSubsDevice.show()
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    async associationTsCheck(assTimestamp) {
      if (_.isNil(assTimestamp) || _.isUndefined(assTimestamp)) {
        await Vue.logOperation(this, this.telephoneNumber, this.input.obuId, this.input.plate, 53, null, null)
        let yesterday = (Date.now() - (24 * 60 * 60 * 1000))
        yesterday = new Date(yesterday)
        yesterday.setHours(0, 0, 0, 0)
        Vue.setLocalStorage('instStartTime', Math.floor(yesterday / 1000))
      } else {  // Compute ts in seconds
        Vue.setLocalStorage('instStartTime', Math.floor(new Date(assTimestamp) / 1000))
      }
      return
    },
  },
  mounted() {
    this.checkWindowWidth()
    this.motivationList = Vue.getStopMotivations(this)
    this.$root.$on('change-lang-motivations', () => {
      this.motivationList = Vue.getStopMotivations(this)
    })
    Vue.setLocalStorage('session_uuid', uuidv4())
  }
}
</script>

<style scoped>
.gsm-margin {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.modal-footer {
    display: none !important;
}

</style>

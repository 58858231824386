<template>
  <svg width="32" height="32" viewBox="0 0 32 32" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 3.13346C8.90559 3.13346 3.13345 8.9056 3.13345 16C3.13345 23.095 8.90559 28.8672 16 28.8672C23.095 28.8672 28.8672 23.095 28.8672 16C28.8672 8.9056 23.095 3.13346 16 3.13346ZM16 27.1328C9.86132 27.1328 4.86653 22.1393 4.86653 16C4.86653 9.86133 9.86132 4.86653 16 4.86653C22.1393 4.86653 27.1328 9.86133 27.1328 16C27.1328 22.1393 22.1393 27.1328 16 27.1328ZM15.9987 20.4661C15.6764 20.4661 15.3743 20.5924 15.1478 20.8203C14.9212 21.0495 14.7975 21.3516 14.8001 21.6667C14.8001 22.3281 15.3385 22.8672 16 22.8672C16.6614 22.8672 17.1999 22.3281 17.1999 21.6667C17.1999 21.0052 16.6608 20.4661 15.9987 20.4661ZM16 9.57747C14.0514 9.57747 12.4668 11.1621 12.4668 13.1107C12.4668 13.5892 12.8548 13.9772 13.3333 13.9772C13.8118 13.9772 14.1999 13.5892 14.1999 13.1107C14.1999 12.1178 15.0072 11.3105 16 11.3105C16.9928 11.3105 17.7995 12.1178 17.7995 13.1107C17.7995 13.6562 17.5547 14.015 16.8639 14.4805C16.0579 15.0228 15.1335 15.8372 15.1335 17.3333V17.6667C15.1335 18.1458 15.5215 18.5339 16 18.5339C16.4785 18.5339 16.8665 18.1458 16.8665 17.6667V17.3333C16.8665 16.7682 17.1191 16.3984 17.832 15.9186C18.6237 15.3848 19.5338 14.5833 19.5338 13.1107C19.5338 11.1621 17.9479 9.57747 16 9.57747Z"/>
  </svg>
</template>
<script>
export default {
  name: 'question-circle-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

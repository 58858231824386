import { v4 as uuidv4 } from 'uuid';

const MyGetDeviceID = {
    install(Vue) {
        Vue.getDeviceID = () => {
            // Vue.deleteLocalStorage('deviceID')
            let deviceID = Vue.getLocalStorage('deviceID');

            if (!deviceID) {
                deviceID = uuidv4();
                Vue.setLocalStorage('deviceID', deviceID);
            }

            return deviceID;
        }
    }
}
export default MyGetDeviceID

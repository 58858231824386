const Locale = {
  supportedLocales: [
    { country: 'en', flag: 'gb' },
    { country: 'it', flag: 'it' },
    { country: 'fr', flag: 'fr' },
    { country: 'de', flag: 'de' },
    { country: 'es', flag: 'es' },
    { country: 'pt', flag: 'pt' },
    { country: 'ru', flag: 'ru' },
    { country: 'ro', flag: 'ro' },
    { country: 'pl', flag: 'pl' },
    { country: 'cs', flag: 'cz' },
    { country: 'hu', flag: 'hu' },
    { country: 'bg', flag: 'bg' },
    { country: 'da', flag: 'dk' },
    { country: 'sk', flag: 'sk' },
    { country: 'sl', flag: 'si' },
    { country: 'nl', flag: 'nl' }
  ],
  getLocale(language) {
    let locale
    switch (language) {
      case 'it-IT':
        locale = 'it'
        break
      case 'fr-FR':
        locale = 'fr'
        break
      case 'de-DE':
        locale = 'de'
        break
      case 'es-ES':
        locale = 'es'
        break
      case 'pt-PT':
        locale = 'pt'
        break
      case 'ru-RU':
        locale = 'ru'
        break
      case 'ro-RO':
        locale = 'ro'
        break
      case 'pl-PL':
        locale = 'pl'
        break
      case 'nl-NL':
        locale = 'nl'
        break
      case 'cs-CZ':
        locale = 'cs'
        break
      case 'hu-HU':
        locale = 'hu'
        break
      case 'bg-BG':
        locale = 'bg'
        break
      case 'sk-SK':
        locale = 'sk'
        break
      case 'sl-SI':
        locale = 'sl'
        break
      case 'da-DK':
        locale = 'da'
        break
      default:
        locale = 'en'
    }
    return locale
  }
}

export default Locale

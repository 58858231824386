<template>
    <div class="col p-0">
        <MainPanel>
            <b-row class="justify-content-between mt-3 mb-5" align-v="center">
                <Title :titleText=" `${$tc('admin.eventsPage.title')}` "/>
                <b-row align-v="center">
                    <Button :disable="autoRefresh" :text="''" :clickFunc="refreshTable"
                            :customStyle="'width: 3.25rem !important;'">
                        <img class="ref-icon" src="@/assets/images/refresh.svg"/>
                    </Button>
                    <b-form-checkbox name="check-button" switch class="toggle-text mr-3" v-model="autoRefresh">
                        {{ `${$tc('admin.eventsPage.buttons.autoRefresh')}` }}
                    </b-form-checkbox>
                </b-row>
                <SearchBox :placeholder="`${$tc('admin.eventsPage.search')}`"
                           v-on:search-changed="search = $event"></SearchBox>
            </b-row>
            <EventFilters
                    v-on:obufilter-changed="filterVars.obuFilter = $event"
                    v-on:opcode-changed="filterVars.codeFilter = $event"
                    v-on:start-date-changed="filterVars.startDate = $event"
                    v-on:end-date-changed="filterVars.endDate = $event"
                    v-on:start-time-changed="filterVars.startTime = $event"
                    v-on:end-time-changed="filterVars.endTime = $event"
                    v-on:reset-filters="resetFilterVars"
                    v-on:plate-changed="filterVars.plateNumber = $event"
                    v-on:status-changed="filterVars.operationStatus = $event"
            />
            <b-table ref="mainTable" class="text-center" striped responsive :items="tableItems" :fields="tableFields">
                <template v-slot:cell(status)="data">
                    <img v-if="(data.item.status) === null" class="led-status" src="@/assets/images/yellow_led.svg"
                         v-b-popover.hover.top="$root.$tc('admin.eventsPage.statusPopovers.pending')" alt="Yellow Led">
                    <img v-if="data.item.status === true" class="led-status" src="@/assets/images/green_led.svg"
                         v-b-popover.hover.top="$root.$tc('admin.eventsPage.statusPopovers.ok')" alt="Green Led">
                    <img v-if="data.item.status === false" class="led-status" src="@/assets/images/red_led.svg"
                         v-b-popover.hover.top="$root.$tc('admin.eventsPage.statusPopovers.ko')" alt="Red Led">
                </template>
            </b-table>

            <b-row class="justify-content-between mt-5">
                <Button :text="`${$tc('admin.eventsPage.buttons.previous')}`" :clickFunc="decreaseOffset"
                        :disable="btnPrevDisable"/>
                <b-row align-v="center">
                    <h6 class="mr-3">{{ `${$tc('admin.eventsPage.recordInfo')}` }}</h6>
                    <b-dropdown variant="outline primary" id="chunk-dropdown" :text="currentChunkSize.toString()"
                                class="m-md-2">
                        <b-dropdown-item @click="currentChunkSize = 20">20</b-dropdown-item>
                        <b-dropdown-item @click="currentChunkSize = 30">30</b-dropdown-item>
                        <b-dropdown-item @click="currentChunkSize = 40">40</b-dropdown-item>
                        <b-dropdown-item @click="currentChunkSize = 50">50</b-dropdown-item>
                        <b-dropdown-item @click="currentChunkSize = 75">75</b-dropdown-item>
                        <b-dropdown-item @click="currentChunkSize = 100">100</b-dropdown-item>
                    </b-dropdown>
                </b-row>
                <Button :text="`${$tc('admin.eventsPage.buttons.next')}`" :clickFunc="increaseOffset"
                        :disable="btnNextDisable"/>
            </b-row>
        </MainPanel>
    </div>
</template>

<script>
import MainPanel from '@/components/admin/subcomps/MainPanel.vue';
import Title from '@/components/admin/subcomps/Title.vue';
import SearchBox from '@/components/admin/subcomps/SearchBox.vue';
import Button from '@/components/admin/subcomps/Button.vue';
import EventFilters from '@/components/admin/subcomps/EventFilters.vue';
import Vue from 'vue';
import axios from "axios";
import {isNil} from "lodash";


export default {
  components: {MainPanel, Title, SearchBox, Button, EventFilters},
  name: 'Events',
  methods: {
    resetFilterVars() {
      this.filterVars = {
        obuFilter: null,
        codeFilter: null,
        startDate: null,
        endDate: null,
        startTime: '00:00:00',
        endTime: '00:00:00',
        plateNumber: null,
        operationStatus: null,
      }
    },
    async refreshTable() {
      await this.getOpChunk()
      this.btnNextDisable = this.tableItems.length < this.currentChunkSize
      this.$root.$emit('successNotification', this.$root.$tc('admin.success'))
    },
    atLeastOneEmpty(item) {
      return item.obu_id === '-' || item.plate_number === '-'
    },
    decreaseOffset() {
      this.currentOffset -= this.currentChunkSize
    },
    increaseOffset() {
      this.currentOffset += this.currentChunkSize
    },
    buildParams() {
      let params = {}
      if (this.filterVars.obuFilter !== undefined && !isNil(this.filterVars.obuFilter) && this.filterVars.obuFilter !== this.$root.$tc('admin.eventsPage.filters.dropdowns.placeholders.obu')) {
        params.obuId = this.filterVars.obuFilter
      }
      if (this.filterVars.codeFilter !== undefined && !isNil(this.filterVars.codeFilter) && this.filterVars.codeFilter !== 0) {
        params.opcode = this.filterVars.codeFilter
      }

      if (this.filterVars.plateNumber !== undefined && !isNil(this.filterVars.plateNumber) && this.filterVars.plateNumber !== this.$root.$tc('admin.eventsPage.filters.dropdowns.placeholders.plate')) {
        params.plate = this.filterVars.plateNumber
      }

      if (this.filterVars.operationStatus !== undefined && !isNil(this.filterVars.operationStatus)) {
        params.status = this.filterVars.operationStatus
      }

      let temp, aux
      if (this.filterVars.startDate !== '' && this.filterVars.startDate !== null) {
        temp = new Date("1970-01-01T" + this.filterVars.startTime)
        aux = new Date(this.filterVars.startDate)
        if (temp.toString() !== "Invalid Date" && aux.toString() !== "Invalid Date") {
          let dateTime = new Date(this.filterVars.startDate + "T" + this.filterVars.startTime)
          params.startts = Math.floor(dateTime / 1000)
        }
      }

      if (this.filterVars.endDate !== '' && this.filterVars.endDate !== null) {
        temp = new Date("1970-01-01T" + this.filterVars.endTime)
        aux = new Date(this.filterVars.endDate)

        if (temp.toString() !== "Invalid Date" && aux.toString() !== "Invalid Date") {
          let dateTime = new Date(this.filterVars.endDate + "T" + this.filterVars.endTime)
          params.endts = Math.floor(dateTime / 1000)
        }
      }

      if (this.search !== '') {
        params.query = this.search
      }

      params.chunk_size = this.currentChunkSize
      params.offset = this.currentOffset

      return params

    },
    async getOpChunk() {
      await axios.get("/operation_filter", {
        headers: {
          'Authorization': Vue.getLocalStorage('session').token,
        },
        params: this.buildParams()
      }).then(async (response) => {
        if (response.status !== 200) {
          console.log(response.status)
        }
        this.tableItems = response.data
        this.tableItems.forEach((item, index) => {
          this.tableItems[index].opcode_desc = this.$root.$tc('admin.eventsPage.opcodes.' + item.operation_code)
          if (this.atLeastOneEmpty(item))
            this.tableItems[index]._rowVariant = 'row-special'
        })
      }).catch((error) => {
        if (error.response.status === 404) {
          console.log(error)
          Vue.manageErrors(this, error)
        }
      })
    },
    setNewRefTimeout() {
      this.refreshTable()
      this.refreshTimer = setTimeout(this.setNewRefTimeout, 10000)
    }
  },
  data() {
    return {
      tableFields: [],
      tableItems: [],
      currentPage: 0,
      currentOffset: 0,
      currentChunkSize: 20,
      btnPrevDisable: true,
      btnNextDisable: false,
      search: '',
      filterVars: {
        obuFilter: null,
        codeFilter: null,
        startDate: null,
        endDate: null,
        startTime: '00:00:00',
        endTime: '00:00:00',
        plateNumber: null,
        operationStatus: null,
      },
      autoRefresh: false,
      refreshTimer: null,
    }
  },
  async mounted() {
    this.tableFields = this.getLabels
    await this.getOpChunk()
    this.btnNextDisable = this.tableItems.length < this.currentChunkSize
  },
  watch: {
    '$root.$i18n.locale': function () {
      console.log("language changed")
      this.tableFields = this.getLabels
      this.$refs.mainTable.$forceUpdate()
    },
    currentChunkSize: function () {
      this.getOpChunk()
    },
    search: function () {
      this.currentOffset = 0
      this.getOpChunk()
    },
    currentOffset: function (newValue, oldValue) {
      if (newValue <= 0) {
        this.currentOffset = 0
        this.btnPrevDisable = true
      }
      if (newValue > oldValue) {
        this.btnPrevDisable = false
        if (this.tableItems.length < this.currentChunkSize) {
          this.currentOffset = oldValue
        }
      }
      this.getOpChunk()
    },
    filterVars: {
      handler: function () {
        this.currentOffset = 0
        this.getOpChunk()
      },
      deep: true
    },
    tableItems: {
      handler: function () {
        this.btnNextDisable = this.tableItems.length < this.currentChunkSize;
      },
      deep: true
    },
    autoRefresh: function () {
      if (this.autoRefresh) {
        this.setNewRefTimeout()
      } else {
        clearTimeout(this.refreshTimer)
      }
    },
  },
  beforeUnmount() {
    clearTimeout(this.refreshTimer)
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.refreshTimer);
    next();
  },
  computed: {
    getLabels() {
      return [
        {
          key: 'id',
          sortable: true,
          label: this.$root.$tc('admin.eventsPage.table.id')
        },
        {
          key: 'cell_no',
          sortable: true,
          label: this.$root.$tc('admin.eventsPage.table.cell_no')
        },
        {
          key: 'obu_id',
          sortable: true,
          label: this.$root.$tc('admin.eventsPage.table.obu_id')
        },
        {
          key: 'plate_number',
          sortable: true,
          label: this.$root.$tc('admin.eventsPage.table.plate_number')
        },
        {
          key: 'timestamp',
          sortable: true,
          label: this.$root.$tc('admin.eventsPage.table.timestamp')
        },
        {
          key: 'opcode_desc',
          sortable: false,
          label: this.$root.$tc('admin.eventsPage.table.opcode_desc')
        },
        {
          key: 'description',
          sortable: false,
          label: this.$root.$tc('admin.eventsPage.table.description')
        },
        {
          key: 'status',
          sortable: false,
          label: this.$root.$tc('admin.eventsPage.table.status')
        }
      ]
    }
  }
}
</script>

<style>

</style>

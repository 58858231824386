<template>
    <svg width="24" height="24" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2021 20H2.6943C1.1399 20 0 18.7565 0 17.2021V2.6943C0 1.24352 1.24352 0 2.6943 0H9.94819C10.3627 0 10.5699 0.310881 10.5699 0.621762C10.5699 0.932643 10.3627 1.34715 9.94819 1.34715H2.6943C1.96891 1.34715 1.24352 1.96891 1.24352 2.79793V17.3057C1.24352 18.0311 1.86528 18.6528 2.6943 18.6528H17.2021C17.9275 18.6528 18.5492 18.0311 18.5492 17.3057V9.01554C18.6528 8.60104 18.9637 8.29016 19.2746 8.29016C19.5855 8.29016 20 8.60104 20 8.91192V17.2021C20 18.7565 18.7565 20 17.2021 20Z"/>
        <path d="M6.83941 13.7819C6.63216 13.7819 6.52853 13.6783 6.32128 13.5747C6.21765 13.471 6.21765 13.2638 6.21765 13.0565L6.73579 9.74047C6.73579 9.32596 6.94304 8.91146 7.15029 8.7042L15.0259 0.828555C16.1658 -0.311341 18.0311 -0.311341 19.171 0.828555C20.3109 1.96845 20.3109 3.83374 19.171 4.97363L11.2954 12.8493C10.9845 13.1602 10.6736 13.2638 10.2591 13.3674L6.83941 13.7819C6.94304 13.7819 6.94304 13.7819 6.83941 13.7819ZM17.0985 1.34669C16.684 1.34669 16.2695 1.45032 15.9586 1.7612L8.18656 9.63684C8.08294 9.74047 8.08294 9.74047 8.08294 9.8441L7.77206 12.3311L10.1555 12.0203C10.2591 12.0203 10.3627 12.0203 10.3627 11.9166L18.2384 4.04099C18.8601 3.41923 18.8601 2.38296 18.2384 1.86482C17.9275 1.45032 17.513 1.34669 17.0985 1.34669Z"/>
    </svg>
</template>

<script>
export default {
  name: 'edit-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>
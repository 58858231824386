<template>
  <svg width="16" height="16" viewBox="0 0 16 16" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 6.40741C14 6.16872 13.9108 5.93827 13.7405 5.76543C13.5703 5.59259 13.3432 5.50206 13.1081 5.50206C12.873 5.50206 12.6459 5.60082 12.4757 5.76543L8.01622 10.3086L3.52432 5.76543C3.17568 5.41152 2.60811 5.41152 2.25946 5.76543C2.08919 5.93827 2 6.16872 2 6.40741C2 6.65432 2.08919 6.87654 2.25946 7.04938L7.38378 12.2346C7.73243 12.5885 8.3 12.5885 8.64865 12.2346L13.7405 7.04938C13.9108 6.87654 14 6.64609 14 6.40741Z"/>
  </svg>
</template>
<script>
export default {
  name: 'arrow-down-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

<template>
    <div>
        <b-button
            pill
            :disabled="disable"
            :class="'custom-button ' + getClass(type)"
            @click="clickFunc"
            :style="customStyle"
            :variant=getVariant
            >
            {{ text }}
            <slot></slot>
        </b-button>
    </div>
</template>

<script>
import Vue from 'vue';
export default{
    name: 'Button',
    props: {
        text: {
            type: String,
            default: 'Button',
        },
        clickFunc: {
            type: Function,
            default: () => {
                console.log('clicked')
            }
        },
        type: {
            type: String,
            default: 'primary'
        },
        disable: {
          type: Boolean,
          default: false
        },
        customStyle: {
          type: String,
          default: ''
        }
    },
    data(){
        return {
            
        }
    },
    methods:{
        getClass(type){
            if (type === 'primary'){
                return 'primary-button'
            }
            if (type === 'secondary'){
                return 'secondary-button'
            }
        }
    },
    computed: {
        getVariant(){
            return this.type
        },
        getSize(){
            return Vue.myGetWindowSize()
        }
    }

}
</script>

<style scoped>

</style>
<template>
  <div class="col component-container d-flex align-items-center justify-content-center p-0">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
      <b-col cols="12" sm="12" md="10" lg="6" xl="5" class="p-0">
        <b-card class="text-center main-card pt-3">
          <b-row style="justify-content: center">
            <h6 class="title-card-text m-3 pb-5">
              {{
                `${$tc('message.setting')} ${$tc('message.new').toLowerCase()} ${$tc('message.pin')} ${$tc('message.access').toLowerCase()}`
              }}
            </h6>
          </b-row>
          <b-row style="justify-content: center" class="mt-4 ">
            <h6 class="info-text m-4 text-align-start"
                v-if="showInsertOTP">
              {{ `${$tc('info.insertOTP')}` }}
            </h6>
            <h6 v-else class="info-text">
              {{ `${$tc('info.updatePIN')}` }}
            </h6>
          </b-row>
          <b-row class="m-5" style="justify-content: center" v-if="showInsertOTP">
            <b-col cols="12" sm="9" md="12" lg="9" xl="9" class="mb-2">
              <!-- OTP -->
              <b-input-group>
                <b-form-input v-model="OTP"
                              class="normal-text-field box-style"
                              id="input-OTP"
                              autocomplete="off"
                              type="text"
                              @keypress="isNumber($event)"
                              maxlength="6"
                              :placeholder="`${$tc('message.otp')}`">
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="m-5" style="justify-content: center" v-else>
            <b-col cols="12" sm="12" md="12" lg="9" xl="9" class="mb-4">
              <!-- PIN -->
              <b-input-group>
                <template #append>
                  <b-input-group-text class="bg-transparent">
                    <img :src="showPassword ? hideIconSrc : showIconSrc"
                        @click="showPassword = !showPassword" alt="Eye"/>
                  </b-input-group-text>
                </template>
                <b-form-input v-model="pin"
                              class="normal-text-field box-style p-6"
                              id="nw-pin"
                              :type="!showPassword ? 'password' : 'text'"
                              @keypress="isNumber($event)"
                              maxlength="6"
                              :placeholder="`${$tc('message.pin')}`">
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="9" xl="9" class="mb-4 mt-4">
              <!-- Confirm PIN -->
              <b-input-group>
                <template #append>
                  <b-input-group-text class="bg-transparent">
                    <img :src="showConfirmPassword ? hideIconSrc : showIconSrc"
                        @click="showConfirmPassword = !showConfirmPassword" alt="Eye"/>
                  </b-input-group-text>
                </template>
                <b-form-input v-model="confirmPin"
                              class="normal-text-field box-style p-6"
                              id="nw-confirm-pin"
                              :type="!showConfirmPassword ? 'password' : 'text'"
                              @keypress="isNumber($event)"
                              maxlength="6"
                              :placeholder="`${$tc('message.confirm')} ${$tc('message.pin')}`">
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row align-h="center" class=" pb-5">
            <b-col cols="12" sm="9" md="6" lg="6" xl="6">
              <b-button id="verify-OTP-button"
                        @click="verifyOTP()"
                        :disabled="!OTPState"
                        variant="primary"
                        pill
                        class="pr-5 pl-5 custom-button"
                        v-if="showInsertOTP"
              >
                {{ `${$tc('message.verify')}` }}
              </b-button>
              <b-button id="update-button"
                        v-else
                        variant="primary"
                        pill
                        :disabled="!newPINState"
                        @click="verifyPIN(telephoneNumber)"
                        class="custom-button">
                {{ `${$tc('message.set')} ${$tc('message.pin')}` }}
              </b-button>
            </b-col>
          </b-row>
          <b-row style="justify-content: center" class="mb-5">
            <b-link @click="$router.push('/')" class="bottom-link mb-3 mt-2">
              {{ `${$tc('info.backLogin')}` }}
            </b-link>
          </b-row>
        </b-card>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import {isNil} from "lodash";
import HideIcon from '@/assets/images/hide.svg';
import ShowIcon from '@/assets/images/show.svg';

export default {
  name: "ChangePIN",
  data() {
    const session = Vue.getLocalStorage('session')
    const telephoneNumber = Vue.getLocalStorage('telephoneNumber')
    const deviceId = Vue.getLocalStorage('deviceID');
    return {
      isMobile: false,
      user: session,
      showConfirmPassword: false,
      showPassword: false,
      hideIconSrc: HideIcon,
      showIconSrc: ShowIcon,
      pin: '',
      confirmPin: '',
      telephoneNumber: telephoneNumber,
      deviceId: deviceId,
      changed: false,
      challenge: '',
      assertionId: '',
      sessionId: '',
      showInsertOTP: true,
      OTP: ''
    }
  },
  async mounted() {
    this.checkWindowWidth()
    this.user = Vue.getLocalStorage('session')
    this.telephoneNumber = Vue.getLocalStorage('telephoneNumber')
    this.deviceId = Vue.getLocalStorage('deviceID')
    await this.resetPIN()
  },
  methods: {
    async pinChangedRedir() {
      Vue.deAuth()
      this.$router.push('/')
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    async resetPIN() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/reset/' + this.telephoneNumber, {
        device_id: this.deviceId,
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else {
          this.sessionId = response.data.session_id
          this.assertionId = response.data.assertion_id
          this.challenge = response.data.challenge
          Vue.setLocalStorage('session', this.sessionId)
          this.showInsertOTP = true
          this.$root.$emit('successNotification', this.$tc('info.OTPSent'), 'OTP')
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    async verifyOTP() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/otp/' + this.telephoneNumber + '/' + this.sessionId, {
        OTP: this.OTP,
        assertion_id: this.assertionId,
        fch: this.challenge
      }).then((response) => {
        if (response.status !== 200) {
          this.$root.$emit('errorNotification', this.$tc('info.otpError'), 'OTP')
        } else {
          this.assertionId = response.data.assertion_id
          this.showInsertOTP = false
          this.$root.$emit('successNotification', this.$tc('success.verifiedOTP'), 'OTP')
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        if (error.response.status !== 400)
          Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    async verifyPIN(telephone) {
      this.$root.$emit('activeLoader', true)
      if (!this.matchingPINs()) {
        this.$root.$emit('activeLoader', false)
        this.$root.$emit('errorNotification', this.$tc('info.pinError'), 'PIN')
        return
      }
      await Vue.myPost('/pin/' + telephone + '/' + this.sessionId, {
        PIN: this.pin,
        assertion_id: this.assertionId,
        fch: this.challenge
      }).then((response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else if (!isNil(response.data.access_token)) {
          const session = {
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            token: response.data.access_token,
          }
          Vue.setLocalStorage('session', session)
          this.$root.$emit('successNotification', this.$tc('success.canAccessToPortal'),this.$tc('success.pinChanged'))
        }
        this.$root.$emit('activeLoader', false)
        this.pinChangedRedir()
      }).catch((error) => {
        if (error.response.status !== 400)
          Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    matchingPINs() {
      return this.pin === this.confirmPin
    }
  },
  computed: {
    newPINState() {
      return this.pin.length === 6 && this.confirmPin.length === 6
    },
    OTPState() {
      return this.OTP.length >= 6
    },
    isAdmin() {
      return Vue.isAdministrator()
    }
  }
}
</script>

<style scoped>

</style>

<template>
    <div class="overlay-container">
            <b-container class="col d-flex justify-content-center rounded-circle " :class="{'slide-comp': show}">
                <b-col cols="12" sm="12" md="10" lg="6" xl="6">
                    <b-card class="main-card pl-5 pr-5 main-panel container-style" :class="getStyle">
                        <slot></slot>
                    </b-card>
                </b-col>
            </b-container>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: 'BaseModal',
    props:{
        kind: {
            type: String,
            default: 'default'
        }
    },
    data() {
        return {
            isMobile: false,
            show: false,
        }
    },
    methods: {
        checkWindowWidth() {
            const size = Vue.myGetWindowSize()
            this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
        },
    },
    mounted() {
        this.checkWindowWidth()
        this.show = true
    },
    computed:{
        getStyle(){
            if (this.kind == 'success'){
                return ' success-text success-panel'
            }
            if (this.kind == 'error'){
                return ' error-text error-panel'
            }
            if (this.kind == 'warning'){
                return ' warning-text warning-panel'
            }
            return ''
        }
    }

}
</script>

<style>

</style>
<template>
    <svg width="16" height="16" viewBox="0 0 16 16" :fill="color" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7926 13.7861L12.1505 11.1439C12.9997 10.0745 13.5187 8.72196 13.5187 7.25149C13.5187 3.80727 10.7114 1 7.25935 1C3.80727 1 1 3.80727 1 7.25935C1 10.7114 3.80727 13.5187 7.25935 13.5187C8.72983 13.5187 10.0823 13.0076 11.1518 12.1505L13.7939 14.7926C13.9355 14.9341 14.1163 14.997 14.2972 14.997C14.478 14.997 14.6589 14.9263 14.8005 14.7926C15.0678 14.5174 15.0678 14.0613 14.7926 13.7861ZM2.41543 7.25935C2.41543 4.58576 4.58576 2.41543 7.25935 2.41543C9.93294 2.41543 12.1033 4.58576 12.1033 7.25935C12.1033 9.93294 9.93294 12.1033 7.25935 12.1033C4.58576 12.1033 2.41543 9.92508 2.41543 7.25935Z" fill="#0057B7"/>
    </svg>
</template>

<script>
export default {
  name: 'search-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>
<template>
  <div class="col component-container d-flex align-items-center justify-content-center p-0">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
      <b-col cols="12" sm="12" md="10" lg="9" xl="9" class="p-0">
        <b-card class="main-card pl-5 pr-3">
          <b-row class="pt-2">
            <h6 class="title-card-text" style="margin-top: 0 !important;">
              {{ `${$tc('message.support')}` }}
            </h6>
          </b-row>
          <b-row class="mb-5 home-row-style" align-v="center">
            <h6 class="title-card-text mt-0" style="font-size: 19px !important;">
              {{ `${$tc('info.guideQuestion')}` }}
            </h6>
            <b-button
                    @click="proceed"
                    variant="primary"
                    pill
                    class="custom-button primary-button">
              {{ `${$tc('message.installK1')}` }}
            </b-button>
          </b-row>

          <b-row class="mb-5" align-v="center">
            <b-form-checkbox name="check-button" switch class="toggle-text custom-checkbox mr-5" v-model="alwaysShow">
              {{ `${$tc('info.showGuideBeforeInstallation')}` }}
            </b-form-checkbox>
          </b-row>
          <b-row class="mb-4 home-row-style" align-v="center">
            <h6 class="title-card-text" style="margin-top: 0 !important; font-size: 19px !important;">
              {{ `${$tc('info.downloadInstallationManual')}` }}
            </h6>
            <b-button
                    @click="downloadManual"
                    pill
                    variant="secondary"
                    class="custom-button secondary-button">
              {{ `${$tc('message.download')}` }}
            </b-button>
          </b-row>

          <b-row class="home-row-style" align-v="center">
            <h6 class="title-card-text" style="margin-top: 0 !important; font-size: 19px !important;">
              {{ `${$tc('info.playVideoGuide')}` }}
            </h6>
            <b-button
                    @click="toggleVideo"
                    pill
                    variant="secondary"
                    class="custom-button secondary-button">
              {{ `${$tc('message.video')}` }}
            </b-button>
          </b-row>
        </b-card>
      </b-col>

      <div class="overlay-container" v-if="video" @click="toggleVideo">
        <iframe class="player" :src="this.$root.$tc('media.videoUrl')" title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
        </iframe>
      </div>

    </b-container>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Home",
  data() {
    const session = Vue.getLocalStorage('session')
    return {
      isMobile: false,
      session: session,
      alwaysShow: Vue.getLocalStorage('alwaysShow') == null ? true : Vue.getLocalStorage('alwaysShow'),
      force: this.$route.params.force === "show" ? true : false,
      video: false
    }
  },
  mounted() {
    window.addEventListener("keyup", this.checkKey);
    this.checkWindowWidth()
    console.log(this.$route.params.force)
    console.log(this.$route.params)
    // go immediatly next page
    if (!this.alwaysShow && !this.force) {
      this.$router.push('/registry/obu_plate')
    }
  },
  methods: {
    proceed() {
      console.log('I wrote alwatshow: ' + this.alwaysShow)
      Vue.setLocalStorage('alwaysShow', this.alwaysShow)
      this.$router.push('/registry/obu_plate')
    },
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    downloadManual() {
      window.open(this.$root.$tc('media.manualUrl'), '_blank')
    },
    toggleVideo() {
      this.video = !this.video
    },
    checkKey(event) {
      if (event.key === 'Escape' && this.video) {
        this.toggleVideo()
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.checkKey);
  }
}
</script>

<style scoped>

</style>

<template>
  <b-dropdown id="lang-dropdown" dropup no-caret variant="link" class="show">
    <template v-slot:button-content>
      <div class="d-flex flex-wrap">
      <flag class="m-auto" :country='Locale.supportedLocales.filter(l => l.country === currentLang)[0]?.flag' size="normal"/>
      </div>
    </template>

    <b-dropdown-item
        v-for="locale in Locale.supportedLocales.filter(l => l.country !== currentLang)"
        :key="locale.country"
        @click="changeLang(locale.country)">
        <div class="d-flex flex-wrap">
          <flag class="my-auto" :country="locale.flag" size="normal"/>
        </div>
    </b-dropdown-item>

  </b-dropdown>
</template>

<script>
import Vue from 'vue'
import {localize} from 'vee-validate'
import Locale from "@/plugin/locale";

export default {
  name: 'changeLanguage',
  computed: {
    Locale() {
      return Locale
    }
  },
  data() {
    const sessionLang = Vue.getLocalStorage('lang')
    return {
      currentLang: sessionLang || this.$i18n.locale
    }
  },
  mounted() {
    const browserLang = Locale.getLocale(navigator.language)
    this.changeLang(Vue.getLocalStorage('lang') || browserLang || this.$i18n.locale)
  },
  methods: {
    changeLang(locale) {
      console.log(`Changed language to ${locale}`)
      localize(locale)
      this.$i18n.locale = locale
      this.currentLang = locale
      Vue.setLocalStorage('lang', locale)
      this.$nextTick(() => {
        this.$root.$emit('change-lang', locale)
        this.$root.$emit('change-lang-motivations')
      })
    }
  }
}
</script>

<style>

</style>

<template>
    <div>
        <b-row class="justify-content-between">
            <Button :text="`${$tc('admin.adminMngPage.filters.buttons.collapse')}`" :clickFunc="toggleFilterVisibility"/>
            <Button :text="`${$tc('admin.adminMngPage.filters.buttons.clear')}`" :clickFunc="clearFilters"/>
        </b-row>
        <b-collapse v-if="toggleFilters" :visible="true" class="text-style">
            <b-row class="mt-2 mb-3">
                <b-col>
                    <label>{{ `${$tc('admin.adminMngPage.filters.dropdowns.labels.removed')}` }}</label>
                    <b-input-group class="custom-dropdown-container">
                        <b-dropdown id="removed-dropdown" :text="textRemoved" v-model="removed">
                            <b-dropdown-item v-if="removed !== null" @click="removed = null; textRemoved = `${$tc('admin.adminMngPage.filters.dropdowns.labels.all')}`">
                                {{ `${$tc('admin.adminMngPage.filters.dropdowns.labels.all')}` }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="removed = true; textRemoved='True'">
                                True
                            </b-dropdown-item>
                            <b-dropdown-item @click="removed = false; textRemoved='False'">
                                False
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-collapse>
    </div>
</template>

<script>
import Button from "@/components/admin/subcomps/Button.vue";

export default {
    name: 'AdminFilters',
    components: { Button },
    data() {
        return {
            removed: null,
            textRemoved: this.$root.$tc('admin.adminMngPage.filters.dropdowns.labels.all'),
            toggleFilters: false
        }
    },
    methods: {
        toggleFilterVisibility() {
            this.toggleFilters = !this.toggleFilters
        },
        clearFilters(){
            console.log("filters cleared")
            this.removed = null
            this.textRemoved = this.$root.$tc('admin.adminMngPage.filters.dropdowns.labels.all')
            this.$emit('reset-filters')
        },
    },
    watch: {
        '$root.$i18n.locale': function(){
            this.clearFilters()
        },
        removed: function() {
            this.$emit('removed-changed', this.removed)
        }
    }
}
</script>


<style scoped>

</style>
<template>
  <img :src="require(`@/assets/flavors/${flavor}/images/logo.svg`)" alt="Company Logo">
</template>
<script>
export default {
  name: 'company-logo',
  computed: {
    flavor() {
      return this.$store.state.flavor
    }
  }
}
</script>

<template>
  <b-container class="b-c-black mw-100" ref="footer">
    <b-row align-h="center" v-on:click="showButtons = true">
      <b-col cols="5" class="text-left">
        <div class="d-flex flex-wrap">
          <changeLanguage class="my-auto"/>
      </div>
      </b-col>
      <b-col cols="7" class="text-right align-items-center">
        <b-container class="d-flex color-background h-100 align-items-center">
          <div class="ml-auto">K1 Self Test Tool</div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import routeViews from '@/router-views'
import changeLanguage from '@/components/vue-plugins/ChangeLanguage'

export default {
  name: 'Footer',
  components: {
    changeLanguage
  },
  mounted() {
    this.$root.$on('change-lang', () => {
      this.getButtons(this)
    })
    this.$root.$on('resize', (size) => {
      this.checkSize(size)
    })
    this.$nextTick(() => {
      window.onscroll = () => this.handleScroll()
    })

    this.handleScroll()
    this.checkSize(Vue.myGetWindowSize())
    this.getButtons(this)
  },
  watch: {
    '$route'(to) {
      const session = Vue.getLocalStorage('session')
      const showFooter = Vue.getLocalStorage('showFooter')
      this.logged = !!(session && session.token)
      if (to.path === '/home') {
        this.showFooter = false
      } else {
        this.showFooter = showFooter
      }
    }
  },
  data() {
    const hasScrollbar = window.scrollY > 0
    const session = Vue.getLocalStorage('session')
    const showFooter = Vue.getLocalStorage('showFooter')
    return {
      hasScrollbar,
      buttons: [],
      isMD: false,
      isMobile: true,
      showFooter: showFooter,
      showButtons: false,
      logged: !!(session && session.token)
    }
  },
  methods: {
    handleScroll() {
      this.hasScrollbar = window.scrollY > 0
    },
    getButtons(it) {
      let routes = Vue.getLocalStorage('routes')
      routes = routes ? JSON.parse(atob(routes)) : null
      const buttons = routeViews.footerButtons(this, routes)
      const editedButtons = []
      if (buttons && buttons.length !== 0) {
        buttons.forEach((button) => {
          let name = ''
          if (button.title && Array.isArray(button.title)) {
            button.title.forEach((nameChunk, index) => {
              if (it) {
                if (!isNaN(nameChunk.num) && index === 0) {
                  name += `${it.$tc(nameChunk.option, nameChunk.num)} `
                } else if (!isNaN(nameChunk.num) && index !== 0) {
                  name += `${it.$tc(nameChunk.option, nameChunk.num).toLowerCase()} `
                } else if ((nameChunk.num === null || nameChunk.num === undefined) && index !== 0) {
                  name += `${it.$t(nameChunk.option).toLowerCase()} `
                } else {
                  name += `${it.$t(nameChunk.option)} `
                }
              }
            })
          } else {
            name = button.title
          }
          editedButtons.push({href: button.href, title: name})
        })
      }
      this.buttons = editedButtons
    },
    checkSize(size) {
      this.isMD = size === 'md'
      this.isMobile = size !== 'lg' && size !== 'xl'
    }
  }
}
</script>

<style scoped>

</style>

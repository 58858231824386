<template>
  <svg width="36" height="36" viewBox="0 0 36 36" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.2609 17.55H22.6391C21.9518 17.55 21.2645 16.875 21.2645 16.2C21.2645 15.525 21.9518 14.85 22.6391 14.85H32.2609C32.9482 14.85 33.6354 15.525 33.6354 16.2C33.6354 16.875 32.9482 17.55 32.2609 17.55Z"/>
    <path d="M29.0536 20.7C28.5955 20.7 28.3664 20.475 28.1373 20.25C27.6791 19.8 27.6791 18.9 28.1373 18.225L30.1991 16.2L28.1373 14.175C27.45 13.5 27.45 12.6 28.1373 11.925C28.5955 11.475 29.5118 11.475 30.1991 11.925L33.4064 15.075C33.8646 15.525 33.8646 16.425 33.4064 17.1L30.1991 20.25C29.7409 20.475 29.5118 20.7 29.0536 20.7Z"/>
    <path d="M25.8464 9.675C26.7627 9.675 27.45 9 27.45 8.325V6.75C27.45 4.275 25.3882 2.25 22.8682 2.25H7.06091C5.91545 2.25 5.22818 2.475 4.54091 2.925C3.16636 3.6 2.25 5.175 2.25 6.75V25.2C2.25 27 3.16636 28.35 4.31182 29.25L10.7264 33.075C11.4136 33.525 12.33 33.75 13.2464 33.75C13.9336 33.75 14.85 33.525 15.5373 33.075C16.6827 32.4 17.5991 31.275 17.8282 29.925H22.8682C25.3882 29.925 27.45 27.9 27.45 25.425V23.85C27.45 23.175 26.7627 22.5 25.8464 22.5C25.1591 22.5 24.4718 23.175 24.4718 23.85V25.425C24.4718 26.325 23.7845 27.225 22.6391 27.225H17.8282V10.575C17.8282 9 16.9118 7.65 15.5373 6.75L12.7882 4.95H22.8682C23.7845 4.95 24.7009 5.625 24.7009 6.75V8.325C24.4718 9 25.1591 9.675 25.8464 9.675Z"/>
  </svg>
</template>
<script>
export default {
  name: 'logout-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

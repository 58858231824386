import Vue from 'vue'

import App from './App.vue'
import router from "@/router/router";
import store from "@/plugin/store";
import footer from "@/fragments/Footer";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {BootstrapVue} from "bootstrap-vue";
import ElementLoading from 'vue-element-loading'
import VueI18n from 'vue-i18n'
import messages from './i18nMessages'
import CountryFlag from 'vue-country-flag';
import {ModelSelect} from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import {setAxiosBaseURL} from "@/plugin/axios";
import UserIcon from "@/components/icons/UserIcon";
import ProfileIcon from "@/components/icons/ProfileIcon";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon";
import QuestionCircleIcon from "@/components/icons/QuestionCircleIcon";
import EditPswIcon from "@/components/icons/EditPswIcon";
import LogoutIcon from "@/components/icons/LogoutIcon";
import LoadingIcon from "@/components/icons/LoadingIcon";
import CompanyLogo from "@/components/images/CompanyLogo";
import VueMeta from "vue-meta";
import Locale from "@/plugin/locale";

Vue.component('flag', CountryFlag)
Vue.component('myFooter', footer)
Vue.component('ElementLoading', ElementLoading)
Vue.component('ModelSelect', ModelSelect)
Vue.component('UserIcon', UserIcon)
Vue.component('ProfileIcon', ProfileIcon)
Vue.component('ArrowDownIcon', ArrowDownIcon)
Vue.component('QuestionCircleIcon', QuestionCircleIcon)
Vue.component('EditPswIcon', EditPswIcon)
Vue.component('LogoutIcon', LogoutIcon)
Vue.component('LoadingIcon', LoadingIcon)
Vue.component('CompanyLogo', CompanyLogo)

Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(VueTelInput)
Vue.use(VueMeta)

const i18n = new VueI18n({
  locale: Locale.getLocale(navigator.language),
  messages,
  preserveDirectiveContent: true
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

setAxiosBaseURL()

const MyGetStopMotivations = {
    install(Vue) {
        Vue.getStopMotivations = (it) => {
            const list = []
            for (let index = 45; index < 53; index++){
                const txt = it.$tc('admin.eventsPage.opcodes.'+index)
                list.push({value:index, text: txt})
            }

            return list
        }
    }
}
export default MyGetStopMotivations

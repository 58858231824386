// router views
import { orderBy, cloneDeep } from 'lodash'

import Login from '@/components/Login'


const allItems = [
  {
    title: [
      { option: 'message.login', num: 1 }
    ],
    path: '/',
    component: Login,
    componentName: 'Login',
    meta: {
      hideInConfig: true,
      functions: [
        { url: '/login', i18nItem: 'log' }
      ]
    },
  }
]

const routes = []

allItems.forEach((group) => {
  if (!group.meta || (group.meta && !group.meta.hideRoute)) {
    getRoutes(group)
  }
})

function getTitle (it, child) {
  let name = ''
  if (child && child.title) {
    if (child.title && Array.isArray(child.title)) {
      child.title.forEach((nameChunk, index) => {
        if (it._i18n) {
          if (typeof nameChunk.num === 'number' && index === 0) {
            name += `${it.$tc(nameChunk.option, nameChunk.num)} `
          } else if (typeof nameChunk.num === 'number' && index !== 0) {
            name += `${it.$tc(nameChunk.option, nameChunk.num).toLowerCase()} `
          } else if (isNaN(nameChunk.num) && index !== 0 && !nameChunk.disableLowerCase) {
            name += `${it.$t(nameChunk.option).toLowerCase()} `
          } else {
            name += `${it.$t(nameChunk.option)} `
          }
        }
      })
    } else {
      name = child.title
    }
  }
  return name
}

function getRoutes (collection) {
  if (collection.path && collection.componentName && collection.component) {
    if (!collection.meta || (collection.meta && !collection.meta.hideRoute)) {
      routes.push({
        path: collection.path,
        name: collection.componentName,
        component: collection.component,
        meta: collection.meta ? collection.meta : {}
      })
    }
  }
  if (collection.child) {
    collection.child.forEach((child) => {
      getRoutes(child)
    })
  }
}

function getSidebarItems (it, collection) {
  const editedCollection = {
    title: getTitle(it, collection)
  }
  if (collection && collection.icon) {
    editedCollection.icon = collection.icon
  }
  if (collection && collection.path) {
    editedCollection.href = collection.path
  }
  if (collection && collection.header) {
    editedCollection.header = collection.header
    editedCollection.hiddenOnCollapse = collection.hiddenOnCollapse
  }
  if (collection && collection.componentName) {
    editedCollection.componentName = collection.componentName
  }

  const children = []
  if (collection && collection.child) {
    collection.child.forEach((child) => {
      if (!child.meta || (child.meta && !child.meta.hideRoute)) {
        const foundChild = getSidebarItems(it, child)
        if (foundChild) {
          children.push(foundChild)
        }
      }
    })
  }
  if (children.length !== 0) {
    editedCollection.child = orderBy(children, 'title')
  }

  let isDynamicRoute = false
  if (collection && collection.path) {
    const pathSections = collection.path.split('/')
    pathSections.forEach((pathSection) => {
      if (pathSection === ':id') {
        isDynamicRoute = true
      }
    })
  }

  if (!isDynamicRoute && collection && collection.path !== '/') {
    return editedCollection.title ? editedCollection : null
  }
  return null
}

function getFooterButtons (it, allItems, config) {
  const buttons = []
  if (allItems && allItems.length > 0) {
    allItems.forEach((item) => {
      if (item.title && item.path && item.path !== '/') {
        if (!item.meta || (item.meta && !item.meta.hideRoute)) {
          let foundItem = null
          if (config && config.length > 0) {
            config.forEach(confItem => {
              if (item.path === confItem.path) foundItem = confItem
            })
          }

          // config[crud], create - read - update - delete
          if ((foundItem && foundItem.meta && foundItem.meta.config && foundItem.meta.config.r) || !foundItem) {
            buttons.push({ title: item.title, href: `${item.path}` })
          }
        }
      }
    })
  }
  return buttons
}

function getConfigItems (it, collection) {
  const editedCollection = {
    title: getTitle(it, collection),
    path: collection.path,
    componentName: collection.componentName,
    config: (collection.meta && collection.meta.config) || { c: false, r: true, u: false, d: false }
  }

  const children = []
  if (collection && collection.child) {
    collection.child.forEach((child) => {
      if (!child.meta || ((child.meta && !child.meta.hideRoute && !child.meta.hideInConfig))) {
        const foundChild = getConfigItems(it, child)
        if (child.meta) foundChild.meta = child.meta
        if (foundChild) {
          children.push(foundChild)
        }
      }
    })
  }
  editedCollection.child = orderBy(children, 'title')

  if (collection && collection.path !== '/') return editedCollection.title ? editedCollection : null
  return null
}

function findRoute (result, routes) {
  let foundItem = null

  if (!routes || routes.length === 0) return null

  routes.forEach(route => {
    if (result.componentName === route.componentName || result.href === route.path) {
      // config[crud], create - read - update - delete
      if (!foundItem && route.config && route.config.r) {
        foundItem = result
      }
    } else if (route.child) {
      // config[crud], create - read - update - delete
      if (!foundItem && route.config && route.config.r) foundItem = findRoute(result, route.child)
    }
  })
  return foundItem
}

function myFilter (results, routes) {
  const editedItems = []

  results.forEach(result => {
    const foundItem = findRoute(result, routes)
    const editedItem = cloneDeep(result)

    if (foundItem || result.header || result.href === '/logout' || result.href === '/home') {
      if (result.child) {
        const children = myFilter(result.child, routes)
        if (children.length !== 0) {
          editedItem.child = children
          editedItems.push(editedItem)
        }
      } else {
        editedItems.push(editedItem)
      }
    }
  })

  return editedItems
}

export default {
  allItems,
  routes,
  footerButtons: (it, config) => getFooterButtons(it, allItems, config),
  sidebarItems: (it, routes) => {
    const results = []
    allItems.forEach((collection) => {
      if (!collection.meta || (collection.meta && !collection.meta.hideRoute)) {
        const result = getSidebarItems(it, collection)
        if (result) {
          results.push(result)
        }
      }
    })
    return routes ? myFilter(results, routes) : results
  },
  configItems: (it) => {
    const results = []
    allItems.forEach((collection) => {
      if (!collection.meta || ((collection.meta && !collection.meta.hideRoute && !collection.meta.hideInConfig))) {
        const result = getConfigItems(it, collection)
        if (result && result.path !== '/home') {
          results.push(result)
        }
      }
    })
    return results
  }
}

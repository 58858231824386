<template>
  <div class="col component-container d-flex align-items-center justify-content-center p-0">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
      <b-col cols="12" sm="12" md="10" lg="9" xl="9" class="p-0">
        <b-card class="text-center main-card">
          <b-row style="justify-content: center">
            <h6 class="title-card-text mb-3 mt-1">
              {{ `${$tc('message.userProfile')}` }}
            </h6>
          </b-row>
          <b-row style="justify-content: center" class="mt-4">
            <h6 class="info-text">
              {{ `${$tc('info.updateInformation')}` }}
            </h6>
          </b-row>
          <b-row class="m-5">
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4">
              <!-- Name -->
              <b-form-group>
                <label class="input-label">
                  {{ `${$tc('message.name')}` }}
                </label>
                <b-form-input v-model="user.firstName"
                              @input="changed = true"
                              class="normal-text-field box-style p-6"
                              id="nw-name"
                              type="text"
                              :placeholder="`${$tc('message.name')}`">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4">
              <!-- Surname -->
              <b-form-group>
                <label class="input-label">
                  {{ `${$tc('message.surname')}` }}
                </label>
                <b-form-input v-model="user.lastName"
                              @input="changed = true"
                              class="normal-text-field box-style p-6"
                              id="nw-surname"
                              type="text"
                              :placeholder="`${$tc('message.surname')}`">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6" class="mb-4">
              <!-- Email -->
              <b-form-group>
                <label class="input-label">
                  {{ `${$tc('message.email')}` }}
                </label>
                <b-form-input v-model="user.email"
                              @input="changed = true"
                              class="normal-text-field box-style p-6"
                              id="nw-email"
                              type="email"
                              :placeholder="`${$tc('message.email')}`"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="center" class="px-5">
            <b-col cols="12" sm="9" md="6" lg="6" xl="6">
              <b-button
                      pill
                      variant="secondary"
                      @click="$router.go(-1)"
                      class="custom-button secondary-button">
                {{ `${$tc('message.cancel')}` }}
              </b-button>
            </b-col>
            <b-col cols="12" sm="9" md="6" lg="6" xl="6">
              <b-button id="update-button"
                        pill
                        :disabled="!changed"
                        @click="updateInformation()"
                        variant="primary"
                        class="custom-button primary-button">
                {{ `${$tc('message.update')}` }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
  name: "UserProfile",
  data() {
    const session = Vue.getLocalStorage('session')
    const telephoneNumber = Vue.getLocalStorage('telephoneNumber')
    const deviceId = Vue.getLocalStorage('deviceID');
    return {
      isMobile: false,
      user: session,
      telephoneNumber: telephoneNumber,
      deviceId: deviceId,
      changed: false
    }
  },
  mounted() {
    this.checkWindowWidth()
    this.user = Vue.getLocalStorage('session')
    this.telephoneNumber = Vue.getLocalStorage('telephoneNumber')
    this.deviceId = Vue.getLocalStorage('deviceID');
  },
  methods: {
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    async updateInformation() {
      this.$root.$emit('activeLoader', true)
      const body = this.user
      body.device_id = this.deviceId
      await axios.post('/update/' + this.telephoneNumber, body, {
        headers: {
          'Authorization': Vue.getLocalStorage('session').token,
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
          this.user = Vue.getLocalStorage('session')
          return
        } else {
          const session = response.data
          session.token = this.user.token
          Vue.setLocalStorage('session', session)
          this.user = response.data
          this.changed = false
          this.$root.$emit('changeLocalStorage')
          this.$root.$emit('successNotification', `${this.$tc('message.userProfile')}, ${this.$tc('success.putSuccess')} `)
        }
        this.$root.$emit('activeLoader', false)
        //this.getPreviousRoute()
        this.$router.go(-1)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    getPreviousRoute() {
      for (var i = this.$router.history.length - 1; i >= 0; i--) {
        if (this.$router.history[i].path !== '/login' && this.$router.history[i].path !== '/home') {
          this.$router.push(this.$router.history[i].path)
          return
        }
      }
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped>

</style>

import {isNil} from "lodash";
import config from "@/../public/config"
import axios from "axios";

function getErrorMessage(it, error) {
    it.$root.$emit('activeLoader', false)
    let errorMessage = ''
    if (!isNil(error) && !isNil(error.data) && !isNil(error.data.code)) {
        errorMessage = it.$tc(`httpCode.${error.status}.${error.data.code}`, 1)
    } else if (error && error.status) {
        if (it && it.$tc) {
            const httpCode = 'httpCode'
            if (error.status === 404) {
                errorMessage = it.$tc(`${httpCode}.${error.status}.0`, 1)
            } else {
                errorMessage = it.$tc(`${httpCode}.${error.status}`, 1)
            }
            if (error.status === 403 || error.status === 440) {
                it.$root.$emit('mustBeDisconnected', errorMessage)
            }
        }
    } else if (error) errorMessage = error
    return errorMessage
}

const MyServerInteraction = {
    install(Vue) {
        Vue.myLogin = (telephoneNumber) => {
            Vue.getDeviceID()
            const url = '/login/' + telephoneNumber
            return axios.post(url, {
                device_id: Vue.getDeviceID()
            }).then(async (response) => {
                const session = response.data
                Vue.setLocalStorage('session', session)
                return response
            }).catch((error) => {
                return error.response
            })
        }
        Vue.myPost = (request, body, headers = null) => {
            return axios.post(request, body, headers).then(async (response) => {
                return response
            }).catch((error) => {
                return error.response
            })
        }
        Vue.isAdvanced = () => {
            return config.advanced
        }
        Vue.getBaseUrlFrontend = () => {
            return config.base_url_frontend
        }
        Vue.manageErrors = (it, error) => {
            it.$root.$emit('activeLoader', false)
            const message = getErrorMessage(it, error)
            if (!isNil(message)) Vue.error(it, message)
            else Vue.error(it, it.$tc('httpCode.422.3', 1))
            return message
        }
        Vue.error = (it, message) => {
            it.$root.$emit('errorNotification', message, it.$tc('message.error'))
        }
        Vue.getBaseUrl = () => {
            return config.base_url
        }
        Vue.postExtra = async (request, body) => {
            try {
                if (isNil(Vue.getLocalStorage('session'))){
                    return await axios.post(request, body)
                } else {
                    return await axios.post(request, body, {
                            headers: {
                                'Authorization': isNil(Vue.getLocalStorage('session').token) ? '' : Vue.getLocalStorage('session').token,
                            },
                        })
                }
            } catch (error) {
                console.log(error)
                return error.response
            }
        }
        Vue.logOperation = async (it, cellNumber, obuId, plateNumber, operationCode, description, session_id) =>{
            await Vue.postExtra('/operation', {
                cell_no: cellNumber,                                // Required Param
                obu_id: obuId !== null ? obuId : "-",
                plate_number: plateNumber !== null ? plateNumber : "-",
                operation_code: operationCode,                      // Required Param
                description: description !== null ? description : "-",
                session_id: session_id                              // Required Param during config procedure
            }).then(async (response) => {
                if (response.status !== 200) {
                    Vue.manageErrors(it, response)
                }
                return response
            }).catch((error) => {
                Vue.manageErrors(it, error)
            })
        }
    }
}
export default MyServerInteraction

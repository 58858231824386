<template>
  <div class="col component-container d-flex align-items-center justify-content-center p-0"
       :class="error ? 'pt-4' : ''">
    <b-container class="col d-flex justify-content-center rounded-circle p-0">
      <b-col cols="12" sm="12" md="10" lg="6" xl="5" class="p-0">
        <b-card class="login-card pl-5 pr-5 pt-4" style="border-radius: 10px">
          <b-row class="pt-2" style="justify-content: center" align-v="center">
            <h6 class="title-card-text mt-4 mb-4" style="margin-top:0 !important;">
              {{ `${$tc('installSteps.title')}` }}
            </h6>
          </b-row>
          <b-row style="justify-content: space-between;" align-v="center">
            <h6 class="info-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('message.obuId')}: ` + obuId }}
            </h6>
            <h6 class="info-text mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('message.plate')}: ` + plateNumber }}
            </h6>
          </b-row>
          <b-row style="justify-content: center" align-v="center">
            <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4">
              {{ `${$tc('installSteps.step2.operationTitle')}` }}
            </h6>
          </b-row>
          <div v-if="error"><!--Errors-->
            <b-row style="justify-content: center" align-v="center">
              <img class="align-content-center" src="@/assets/images/error.svg" alt="Error"/>
            </b-row>
            <div v-if="errType === 1">
              <b-row style="justify-content: center" align-v="center">
                <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4 error-color">
                  {{ `${$tc('installSteps.step2.error1.errorMain')}` }}
                </h6>
              </b-row>
              <b-row style="justify-content: center" align-v="center">
                <h6 class="step-text mr-2 ml-2 mt-4 mb-4 strong-font-weight">
                  {{ `${$tc('installSteps.step2.error1.errorDesc')}` }}
                  <ol>
                    <li>{{ `${$tc('installSteps.step2.error1.first')}` }}</li>
                    <li>{{ `${$tc('installSteps.step2.error1.second')}` }}</li>
                    <li>{{ `${$tc('installSteps.step2.error1.third')}` }}</li>
                    <li>{{ `${$tc('installSteps.step2.error1.fourth')}` }}</li>
                    <ul>
                      <li>{{ `${$tc('installSteps.step2.error1.subF1')}` }}</li>
                      <li>{{ `${$tc('installSteps.step2.error1.subF2')}` }}</li>
                    </ul>
                  </ol>
                </h6>
              </b-row>
              <b-row style="justify-content: center" align-v="center">
                <h6 class="step-text mr-2 ml-2 mt-4 mb-4 strong-font-weight">
                  {{ `${$tc('installSteps.step2.error1.footMessage')}` }}
                </h6>
              </b-row>
            </div>
            <div v-if="errType === 2">
              <b-row style="justify-content: center" align-v="center">
                <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4 error-color">
                  {{ `${$tc('installSteps.step2.error2.errorMain')}` }}
                </h6>
              </b-row>
              <b-row style="justify-content: center" align-v="center">
                <h6 class="step-text mr-2 ml-2 mt-4 mb-4 strong-font-weight">
                  {{ `${$tc('installSteps.step2.error2.errorDesc')}` }}<br>
                  {{ `${$tc('installSteps.step2.error2.footMessage')}` }}
                </h6>
              </b-row>
            </div>
            <div v-else-if="errType === 3">
              <b-row style="justify-content: center" align-v="center">
                <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4 error-color">
                  {{ `${$tc('installSteps.step2.error3.errorMain')}` }}
                </h6>
              </b-row>
              <b-row style="justify-content: center" align-v="center">
                <h6 class="step-text mr-2 ml-2 mt-4 mb-4 strong-font-weight">
                  {{ `${$tc('installSteps.step2.error3.errorDesc')}` }}<br>
                  {{ `${$tc('installSteps.step2.error3.footMessage')}` }}
                </h6>
              </b-row>
            </div>
          </div>
          <div v-else-if="success">
            <b-row style="justify-content: center" align-v="center">
              <h6 class="info-text sub-title mr-2 ml-2 mt-4 mb-4 success-color">
                {{ `${$tc('installSteps.successInstallation')}` }}
              </h6>
            </b-row>
            <b-row style="justify-content: center" align-v="center" class="my-3">
              <img class="align-content-center" src="@/assets/images/success.svg" alt="Success"/>
            </b-row>
          </div>
          <div>
            <b-row align-v="center" v-if="!error && !success">
              <b-col :cols="!monitor ? '11' : '12'">
                <h6 class="step-text mt-4 mb-4 strong-font-weight"
                    :class="!monitor ? ' mr-2 ml-2' : ''">
                  {{ `${$tc('installSteps.step2.instructions')}` }}
                </h6>
              </b-col>
              <b-col cols="1" class="p-0 text-center" v-if="!monitor && !error">
                <QuestionCircleIcon id="popover-obu" :color="color.primary"/>
                <b-popover custom-class="wide-popover" target="popover-obu" triggers="hover" placement="right">

                  <template #title><h6 class="normal-text-field">
                    {{ `${$tc('installSteps.step2.obuPosition')}` }}</h6></template>
                  <b-col>
                    <img src="@/assets/images/windscreen.svg" alt="Info"/>
                  </b-col>

                </b-popover>
              </b-col>

            </b-row>
            <b-row v-if="!monitor && !error">
              <b-col>
                <h6 class="text-center info-text step-text mx-2 my-4">
                  {{ `${$tc('installSteps.step2.instructions1')}` }}
                </h6>
              </b-col>

            </b-row>

            <b-col v-if="!monitor && !error && !success && isAdvanced">
              <b-row class="justify-content-end" align-v="center" @click="showCollapse = !showCollapse">
                <h6 class="bottom-link noselect mr-2 ml-2 mt-2 mb-2">{{
                    `${$tc('installSteps.step2.advancedText')}`
                  }}</h6>
                <ArrowDownIcon :color="color.primary"/>

              </b-row>
              <b-collapse id="adv-options" :visible="showCollapse">
                <b-row class="mb-4">
                  <h6 class="info-text step-text mx-2 mt-2 mb-4">
                    {{ `${$tc('installSteps.step2.datePrompt')}` }}</h6>
                  <div class="pickers-container">
                    <b-form-timepicker v-model="selectedTime" class="info-text"></b-form-timepicker>
                    <b-form-datepicker v-model="selectedDate"
                                      class="info-text"
                                      nav-button-variant="primary"
                                      :no-input="false"
                                      :max="maxDate"
                                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                  </div>
                </b-row>
              </b-collapse>
            </b-col>

            <b-row class="mb-4" style="justify-content: center" align-v="center" v-if="!monitor || error || success">
              <b-button v-if="!success" pill :variant="errType === 2 ? 'primary' : 'secondary'" class="custom-button"
                        :class="errType === 2 ? 'primary-button' : 'secondary-button'"
                        @click="$refs.confirmExitModal.show()">
                <div>{{ `${$tc('installSteps.buttonAbort')}` }}</div>
              </b-button>
              <b-button v-if="errType !== 2 || (!error && success)" pill variant="primary"
                        class="custom-button primary-button"
                        @click="!error ? proceed() : error">
                {{ !error ? `${$tc('installSteps.buttonNext')}` : `${$tc('installSteps.retryButton')}` }}
              </b-button>
            </b-row>

          </div>
          <div v-if="monitor && !error && !success">
            <b-row align-v="center">
              <b-col cols="11">
                <h6 class="info-text step-text mr-2 ml-2 mt-4 mb-4">
                  {{ `${$tc('installSteps.step2.monitorMessage')}` }}
                </h6>
              </b-col>
              <!--TODO: aggiungere una descrizione completa del processo di configurazione dello obu da mostrare
               on popover -->
              <!-- <b-col cols="1" class="p-0 text-center" @click="$refs.configurationInfoModal.show()">
                <QuestionCircleIcon id="popover-obu" :color="color.primary"/>
                <b-popover custom-class="wide-popover" target="popover-obu" triggers="hover" placement="right">

                  <template #title><h6 class="normal-text-field">
                    {{ `${$tc('installSteps.step2.configurationInformation')}` }}</h6></template>
                  <b-col>
                    {{ `${$tc('installSteps.step2.configurationInformationMessage')}` }}
                  </b-col>

                </b-popover>
              </b-col> -->
            </b-row>
            <b-row style="justify-content: center" align-v="center">
              <LoadingIcon class="align-content-center rotate" :color="color.primary"/>
            </b-row>
          </div>
          <div v-if="!success">
            <b-row style="justify-content: center" class="my-3">
              <h6 class="bottom-text mb-3 mt-2 mr-2">
                {{ `${$tc('installSteps.bottomMessage')}` }}
              </h6>
              <b-link class=" bottom-link noselect mb-3 mt-2" @click="backToGuides()">
                {{ `${$tc('installSteps.bottomLink')}` }}
              </b-link>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <!-- <b-modal
              ref="configurationInfoModal"
              id="configuration-info-modal"
              :title="`${$tc('installSteps.step2.configurationInformation')}`"
              size="lg"
              title-class="title-modal-text ml-auto"
              class="custom-modal"
      >
        <template #modal-footer="{ ok }">
          <b-button pill variant="primary" class="primary-button"
                    @click="ok();">
            {{ `${$tc('message.ok')}` }}
          </b-button>
        </template>
        <h6 class="custom-modal-text justify-content-center modal-body-text">
          {{ `${$tc('installSteps.step2.configurationInformationMessage')}` }}</h6>
      </b-modal> -->
      <b-modal
              ref="confirmExitModal"
              id="confirm-exit-modal"
              :title="`${$tc('info.stopInstallationQuestion')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="$refs.installationFailedModal.show()"
              @cancel="$refs.confirmExitModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="ok()">
            {{ `${$tc('message.yes')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="cancel()">
            No
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.fullStopInstallationQuestion')}` }}</h6>
      </b-modal>
      <b-modal
              ref="installationFailedModal"
              id="installation-failed-modal"
              :title="`${$tc('message.failureInstallation')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="motivation !== 52 ? sendMotivation() : $refs.otherFailureMotivationModal.show()"
              @cancel="$refs.confirmExitModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivation === '' || motivation === undefined">
            {{ `${$tc('message.go')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.selectReasonStopInstallation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="8" xl="8">
            <model-select :options="motivationList"
                          v-model="motivation"
                          class="info-text"
                          :placeholder="`${$tc('info.selectMotivation', 1)}`">
            </model-select>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
              ref="otherFailureMotivationModal"
              id="other-motivation-failed-modal"
              :title="`${$tc('message.failureInstallation')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="sendMotivation()"
              @cancel="$refs.otherFailureMotivationModal.hide()"
              class="custom-modal"
      >
        <template #modal-footer="{ ok, cancel }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button mr-auto custom-button" @click="cancel()">
            {{ `${$tc('message.dismiss')}` }}
          </b-button>
          <b-button pill variant="primary" class="custom-button primary-button" @click="ok()"
                    :disabled="motivationDescription.length === 0">
            {{ `${$tc('message.send')}` }}
          </b-button>

        </template>
        <h6 class="custom-modal-text info-text justify-content-center m-3">
          {{ `${$tc('info.insertOtherMotivation')}` }}</h6>

        <b-row style="justify-content: center">
          <b-col cols="12" sm="12" md="10" lg="10" xl="10">
            <b-form-textarea
                    class="custom-modal-text modal-txt-field"
                    style="text-align: start !important; font-weight: bold !important;"
                    v-model="motivationDescription"
                    :placeholder="`${$tc('message.problem')}`"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
              ref="returnOrSubsDevice"
              id="return-subst-device-modal"
              :title="`${$tc('info.returnORReplacementDevice')}`"
              title-class="title-modal-text ml-auto"
              size="lg"
              @ok="$router.push('/home')"
              class="custom-modal"
      >
        <template #modal-footer="{ ok }" style="display: none !important;">
          <b-button pill variant="secondary" class="secondary-button custom-button" @click="ok()">
            {{ `${$tc('message.ok')}` }}
          </b-button>
        </template>
        <h6 class="custom-modal-text info-text justify-content-center">
          {{ `${$tc('info.returnReplaceDeviceMessage')}` }}</h6>
      </b-modal>
    </b-container>
  </div>
</template>


<script>
import Vue from "vue";
import axios from "axios";
import {isNil} from "lodash";
import QuestionCircleIcon from "@/components/icons/QuestionCircleIcon.vue";

export default {
  name: "second-step",
  components: {QuestionCircleIcon},
  data() {
    return {
      isMobile: false,
      obuId: Vue.getLocalStorage('obuId'),
      plateNumber: Vue.getLocalStorage('plateNumber'),
      instStartTime: Vue.getLocalStorage('instStartTime'),
      monitor: false,
      error: false,
      errType: 0,
      success: false,
      motivation: '',
      motivationList: [],
      motivationDescription: '',
      telephoneNumber: Vue.getLocalStorage('telephoneNumber'),
      showAdvanced: false,
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedTime: new Date().toTimeString().substr(0, 5),
      showCollapse: false,
      maxDate: this.getMaxDate(),
      color: {
        primary: 'var(--primary)'
      },
    }
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.interval);
    next();
  },
  mounted() {
    this.checkWindowWidth()
    this.motivationList = Vue.getStopMotivations(this)
    this.$root.$on('change-lang-motivations', () => {
      this.motivationList = Vue.getStopMotivations(this)
    })
  },
  methods: {
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    },
    async startMonitor() {
      this.error = false
      this.errType = false
      this.success = false
      this.monitor = true
      if (this.selectedDate != null && this.selectedTime != null && this.showCollapse) {
        // overwrite date
        const date = Date.parse(this.selectedDate + ' ' + this.selectedTime)

        this.instStartTime = Math.floor(date / 1000)
        Vue.setLocalStorage('instStartTime', this.instStartTime)
      }
      this.monitor = false
      this.success = true
      // await this.monitorPerform()
      // register operation
    },
    proceed() {
      this.$router.push("/install/third-step")
    },
    backToGuides() {
      this.monitor = false
      clearTimeout(this.interval)
      // clearInterval(this.interval)
      this.$router.push("/home/show")
    },
    // Backend Request cycle
    async monitorPerform() {
      var currentState = 'PRE';
      var checkTime = Math.floor(Date.now() / 1000);
      this.pollService(currentState, checkTime)
    },
    async pollService(currentState, checkTime) {
      this.interval = setTimeout(async () => {
        try {
          if (!this.monitor) {
            console.log("Monitor was stopped")
            return
          }
          console.log("Executing request for phase: " + currentState)
          const response = await axios.get('/proxy/config/' + this.obuId + '/' + currentState + '?time=' + this.instStartTime + '&check=' + checkTime, {
            headers: {
              'Authorization': Vue.getLocalStorage('session').token,
              'Session_UUID': Vue.getLocalStorage('session_uuid'),
              'Plate': this.plateNumber,
            },
          })
          if (response.data.error !== "") { // CONFIG HAS ERRORS
            console.log("Backend service found a config error: " + response.data.error)
            this.error = true
            if (currentState === "FIRST_RUN" && response.data.error === "TIMEOUT") {
              this.errType = 1
            } else if (response.data.error.includes('persComplete')){
              this.errType = 3
            } else {
              this.errType = 2
            }
          } else {  // NO CONFIG ERRORS
            if (currentState !== response.data.nextPhase) {  // Different Phase Obtained
              if (response.data.nextPhase === "COMPLETE") { // Config Successfully Completed
                this.monitor = false
                this.success = true
                return
              }
              checkTime = Math.floor(Date.now() / 1000)
              console.log("Received nextPhase " + response.data.nextPhase)
              currentState = response.data.nextPhase
            }
            this.pollService(currentState, checkTime)
          }
        } catch (error) {  // response error
          console.log(error)
          Vue.manageErrors(this, "Backend request error: " + error.response.errMsg)
          this.error = true
          clearTimeout(this.interval)
          return
        }
      }, 2000)
    },
    async sendMotivation() {
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/operation', {
        cell_no: this.telephoneNumber,
        obu_id: this.obuId,
        plate_number: this.plateNumber,
        operation_code: this.motivation,
        description: this.motivationDescription.length > 0 ? this.motivationDescription : '',
        session_id: Vue.getLocalStorage('session_uuid'),
      }, {
        headers: {
          'Authorization': isNil(Vue.getLocalStorage('session').token) ? '' : Vue.getLocalStorage('session').token,
        },
      }).then(async (response) => {
        if (response.status !== 200) {
          Vue.manageErrors(this, response)
        } else {
          this.$refs.returnOrSubsDevice.show()
        }
        this.$root.$emit('activeLoader', false)
      }).catch((error) => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    getMaxDate() {
      const now = new Date()
      const max = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      return max
    }
  },
  watch: {
    showCollapse: function () {
      this.selectedDate = new Date().toISOString().substr(0, 10)
      this.selectedTime = new Date().toTimeString().substr(0, 5)
    }
  },
  computed: {
    isAdvanced() {
      return Vue.isAdvanced()
    }
  }
}
</script>


<style>

</style>

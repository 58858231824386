<template>
    <BaseModal>
        <b-row class="justify-content-center">
            <Title :titleText="!update ? `${$tc('admin.adminMngPage.modal.addAdmin.title')}` : `${$tc('admin.adminMngPage.modal.modAdmin.title')}`"/>
        </b-row>
        <b-row class="justify-content-center">
            <h6 class="mb-3 mt-3">{{ !update ? `${$tc('admin.adminMngPage.modal.addAdmin.body')}` : `${$tc('admin.adminMngPage.modal.modAdmin.body')}` }}</h6>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="`${$tc('admin.adminMngPage.modal.dataLabels.phone')}`">
                    <b-form-input v-model="adminData.username"
                                :placeholder="`${$tc('admin.adminMngPage.modal.dataLabels.phone')}`"
                                :state="checkValidPhone"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="`${$tc('admin.adminMngPage.modal.dataLabels.confPhone')}`">
                    <b-form-input v-model="adminData.confPhone" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="`${$tc('admin.adminMngPage.modal.dataLabels.firstName')}`">
                    <b-form-input v-model="adminData.first_name" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="`${$tc('admin.adminMngPage.modal.dataLabels.lastName')}`">
                    <b-form-input v-model="adminData.last_name" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="`${$tc('admin.adminMngPage.modal.dataLabels.email')}`">
                    <b-form-input v-model="adminData.email" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="`${$tc('admin.adminMngPage.modal.dataLabels.company')}`">
                    <b-form-input v-model="adminData.company" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-form-checkbox name="check-button" switch class="toggle-text mr-5" v-model="adminData.removed">
                {{ `${$tc('admin.adminMngPage.modal.dataLabels.toggle')}` }}
            </b-form-checkbox>
        </b-row>
        <b-row class="justify-content-between">
            <Button :text="`${$tc('admin.adminMngPage.modal.addAdmin.buttons.cancel')}`" 
                    :clickFunc="cancelOp"/>
            <Button :text="!update ? `${$tc('admin.adminMngPage.modal.addAdmin.buttons.proceed')}`:  `${$tc('admin.adminMngPage.modal.modAdmin.buttons.proceed')}`" 
                    :disable="!checkValidForm"
                    :clickFunc="retunData"/>
        </b-row>
    </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import Button from "@/components/admin/subcomps/Button.vue";
import Title from './Title.vue';

export default {
    name: 'AdminDataModal',
    components: { Button, Title, BaseModal },
    props:{
        enable:{
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Modal title...'
        },
        body: {
            type: String,
            default: 'Modal body...'
        },
        retrievedAdmin: {
            // type: Object,
            default: null
        }
    },
    data() {
        return {
            show: false,
            update: false,
            adminData: {
                id: null,
                first_name: '',
                last_name: '',
                username: '',
                confPhone: '',
                email: '',
                company: '',
                removed: false,
            }
        }
    },
    methods:{
        cancelOp(){
            this.adminData = null
            this.retunData()
        },
        toggleModal(){
            this.show = !this.show
        },
        checkValidData(){
            if (this.adminData.username !== null && this.adminData.confPhone !== null && this.adminData.username !== '' && this.adminData.confPhone !== ''){
                return true
            } else {
                return false
            }
        },
        retunData() {
            this.$emit('admin-modal-data', this.adminData)
            this.toggleModal()
        }
    },
    computed: {
        checkValidForm(){
            if(this.adminData.username == null || this.adminData.confPhone == null || this.adminData.removed == null){
                return false
            }
            return this.checkConfNum && this.checkRemoved && this.checkValidPhone && this.isEmail
        },
        checkValidPhone(){
            return this.adminData.username.length === 12 && /^\d{12}$/.test(this.adminData.username)
        },
        checkConfNum() {
            return this.adminData.username === this.adminData.confPhone
        },
        checkRemoved() {
            return this.adminData.removed !== null
        },
        isEmail() {
            if (this.adminData.email !== null && this.adminData.email !== '' && this.adminData.email !== undefined) {
                return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.adminData.email);
            }
            return true
        },

    },
    // click outside function
    mounted() {
        this.show = this.enable
        if (this.retrievedAdmin !== null){
            this.adminData = this.retrievedAdmin
            this.adminData.confPhone = this.adminData.username
            this.update = true
        }
    }
}
</script>

<style>

</style>
<template>
  <svg width="36" height="36" viewBox="0 0 36 36" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.413 16.3253L19.4767 9.39826C20.1476 8.72745 20.5948 8.28025 21.0466 7.82842L5.39441 23.4806C4.7236 23.9278 4.5 24.8222 4.5 25.493V30.1887C4.5 30.8595 4.9472 31.3067 5.61801 31.3067H10.3137C10.9845 31.3067 11.8789 31.0831 12.3261 30.4123L30.6615 12.0769C31.7795 10.9589 31.7795 9.17004 30.6615 8.05202L27.7547 5.14519C26.413 4.25078 24.6242 4.25078 23.5062 5.36879L20.3758 8.49923C20.823 8.05202 20.5994 8.27563 21.0466 7.82842L27.9783 14.7601C27.5311 15.2073 27.9783 14.7601 27.3075 15.4309"/>
  </svg>
</template>
<script>
export default {
  name: 'edit-psw-icon',
  props: {
    color: {
      type: String
    }
  }
}
</script>

<template>
    <div class="shadow">
        <b-navbar class="header p-0 pl-2 pr-2" :toggleable="isMobile" variant="light">
            <!-- Master Row -->
            <b-row align-v="center" class="ml-2 mr-2 w-100 no-gutters">
                <b-row :cols="isMobile ? 0 : 1" id="header-start" class="p-0 ml-2 mr-2"> <!-- Logo and hamburger column -->
                    <b-row class="flex-nowrap" align-v="center">
                        <b-navbar-toggle target="main-collapse" class="b-0 p-0" v-if="$route.name !== 'login'"/>
                        <b-navbar-brand>
                            <company-logo class="logo-header fluid"></company-logo>
                        </b-navbar-brand>
                    </b-row>
                </b-row>

                <!-- Nav element containing Page Title and links -->
                <b-row class="p-0 ml-2 mr-2 px-2" :class="isMobile && $route.name !== 'login' ? 'd-none': ''">
                    <b-row class="w-100" align-v="center">
                    <b-navbar-nav align="center" :class="$route.name === 'login' ? (curScreenSize !== 'cols' ? 'centered-element':'') : 'ml-2 mr-2'">
                        <b-nav-item>
                            <nav-element :textClass="curScreenSize" :style="$route.name === 'login' ? '': ''"  :text="isAdmin ? `${this.$tc('admin.nav.title')}` : `${this.$tc('message.portal')}`"></nav-element>
                        </b-nav-item>
                        <b-row class="flex-nowrap no-gutters" align-v="center" v-if="$route.name !== 'login' && !isAdmin">
                            <b-nav-item @click="goToPage('/home/show')" >
                                <nav-element :isLink="true" :active="$route.name == 'guide'" :textClass="curScreenSize" :text="`${this.$tc('message.guide')}`" />
                            </b-nav-item>
                        </b-row>
                        <b-row class="flex-nowrap no-gutters" align-v="center" v-if="$route.name !== 'login' && isAdmin">
                            <b-nav-item @click="goToPage('/events')">
                                <nav-element :isLink="true"

                                                :textClass="curScreenSize"
                                                :text="`${this.$tc('admin.nav.eventsLink')}`" />
                            </b-nav-item>
                            <b-nav-item @click="goToPage('/admins')">
                                <nav-element :isLink="true"

                                                :textClass="curScreenSize"
                                                :text="`${this.$tc('admin.nav.adminMng')}`" />
                            </b-nav-item>
                        </b-row>
                    </b-navbar-nav>
                    </b-row>
                </b-row>

                <!-- User account action dropdown -->
                <b-col id="header-end" class="p-0 mx-1" v-if="$route.name !== 'login'">
                    <b-row>
                    <b-navbar-nav class="ml-auto" v-if="$route.name !== 'login'">
                        <b-nav-item-dropdown right id="user-dropdown">
                            <template #button-content>
                                <div style="display: inline-block;" class="header-text name-text-header primary-color"
                                    :class="curScreenSize">
                                    <UserIcon class="header-logo-user" :color="color.primary"/>
                                    {{
                                    !isMobile && session.firstName !== undefined && session.lastName !== undefined ? session.firstName + ' ' + session.lastName : ''
                                    }}
                                </div>
                            </template>
                            <b-dropdown-item-text v-if="isMobile && session.firstName !== undefined && session.lastName !== undefined">
                                    <h6 class="header-text dropdown-item-text px-3 ml-1">{{ session.firstName + ' ' + session.lastName }}</h6>
                            </b-dropdown-item-text>
                            <b-dropdown-item>
                                <b-row class="align-items-center flex-nowrap" @click="goToPage('/user_profile')">
                                    <ProfileIcon :color="color.primary" v-if="!isMobile"/>
                                    <b-button class="header-text dropdown-item-text bg-transparent border-0 px-1 ml-1">
                                    {{ `${$tc('message.userProfile')}` }}
                                    </b-button>
                                </b-row>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <b-row class="align-items-center flex-nowrap" @click="goToPage('/change_pin')">
                                    <EditPswIcon :color="color.primary" v-if="!isMobile"/>
                                    <b-button class="header-text dropdown-item-text bg-transparent border-0 px-1 ml-1">
                                    {{ `${$tc('message.changePassword')}` }}
                                    </b-button>
                                </b-row>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <b-row class="align-items-center flex-nowrap" @click="logout()">
                                    <LogoutIcon :color="color.primary" v-if="!isMobile"/>
                                    <b-button class="header-text dropdown-item-text bg-transparent border-0 px-1 ml-1">
                                    {{ `${$tc('message.logout')}` }}
                                    </b-button>
                                </b-row>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-row>
                </b-col>
            <!-- END Master Row -->
            </b-row>
            <b-col class="p-0" v-if="isMobile">
                <b-collapse id="main-collapse" is-nav>
                    <b-navbar-nav align="center" :class="$route.name === 'login' ? 'ml-auto mr-auto' : 'ml-2 mr-2'">
                        <b-nav-item>
                            <nav-element :textClass="'md'" :style="$route.name === 'login' ? '': ''"  :text="isAdmin ? `${this.$tc('admin.nav.title')}` : `${this.$tc('message.portal')}`"></nav-element>
                        </b-nav-item>
                        <b-nav-item @click="goToPage('/home/show')" v-if="$route.name !== 'login' && !isAdmin">
                            <nav-element :isLink="true" :textClass="curScreenSize" :text="`${this.$tc('message.guide')}`" />
                        </b-nav-item>
                        <div v-if="$route.name !== 'login' && isAdmin">
                            <b-nav-item @click="goToPage('/events')">
                                <nav-element :isLink="true" :textClass="curScreenSize" :text="`${this.$tc('admin.nav.eventsLink')}`" />
                            </b-nav-item>
                            <b-nav-item @click="goToPage('/admins')">
                                <nav-element :isLink="true" :textClass="curScreenSize" :text="`${this.$tc('admin.nav.adminMng')}`" />
                            </b-nav-item>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
            </b-col>
        </b-navbar>
    </div>
</template>

<script>
import Vue from "vue";
import NavElement from '../components/admin/subcomps/NavElement.vue';
import CompanyLogo from '../components/images/CompanyLogo.vue'
export default {
    name: "CustomHeader",
    components: { CompanyLogo, NavElement },
    data() {
    return {
        curScreenSize: null,
        logged: false,
        isMobile: false,
        isAdmin: Vue.isAdministrator(),
        session: {
            firstName: '',
            lastName: ''
        },
        color: {
            primary: 'var(--primary)'
        },
        }
    },
    methods: {
        checkWindowWidth() {
        const size = Vue.myGetWindowSize()
        this.curScreenSize = size
        console.log("The computed windows size is: " + size)
        this.isMobile = (size !== 'lg' && size !== 'xl')
        },
        logout() {
        Vue.deleteLocalStorage('session')
        Vue.deleteLocalStorage('telephoneNumber')
        Vue.deleteLocalStorage('plateNumber')
        Vue.deleteLocalStorage('instStartTime')
        Vue.deAuth()
        Vue.delAdmin()
        this.$router.push('/')
        },
        // NavLinks
        goToPage(routeString){
        if (this.$route.path !== routeString){
            this.$router.push(routeString).catch(() => {
                // Se viene triggerato un errore dobbiamo fare qualcosa?
                console.log("Error on push route")
            })
        }
        }
    },
    mounted() {
        this.checkWindowWidth()
        window.addEventListener('resize', this.checkWindowWidth)
    },
    created() {
        this.$root.$on('changeLocalStorage', () => {
        this.session = Vue.getLocalStorage('session')
        if ((this.session.firstName + " " + this.session.lastName).length > 20) {
            this.session.firstName = (this.session.firstName + " " + this.session.lastName).substring(0, 17) + "..."
            this.session.lastName = ''
        }
        })
    },
    beforeUnloadEvent() {
        window.removeEventListener('resize', this.checkWindowWidth)
    },
    watch: {
        '$route': function () {
        this.isAdmin = Vue.isAdministrator()
        }
    }
}
</script>

<style>

</style>
<template>
  <div class="search-box d-flex">
    <SearchIcon :color="color.primary" class="ml-3 mr-2" alt="Search"></SearchIcon>
    <b-form-input class="flex-fill inner-box" v-model="search" :placeholder="placeholder">
    </b-form-input>
  </div>
</template>

<script>
import SearchIcon from '@/components/icons/SearchIcon.vue';

export default {
  components:{SearchIcon},
  props: {
    placeholder: {
      type: String,
      default: 'Search...'
    }
  },
  data() {
    return {
      color: {
        primary: 'var(--primary)'
      },
      search: ''
    }
  },
  watch: {
    search(value) {
      this.$emit('search-changed', value)
    }
  }
}
</script>

<style scoped>

</style>
